import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { GlobalFunctions } from "src/app/app.global";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";
import { CustomPageTitleStrategy } from "src/app/services/custom-page-title-strategy";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { SubscriptionPlanModel } from "../../models/subscriptionPlanModel";
import { SuperAdminService } from "../../services/super-admin.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.css"],
})
export class SettingsComponent implements OnInit {
  panelOpenState = false;
  isAllExpanded = true;
  appPercentage = "";
  isEditMode = false;
  subscriptionPlanId = 0;
  subscriptionPlanModel = new SubscriptionPlanModel();
  subscriptionPlanValidation: FormGroup = new FormGroup("");
  formSubmitted: boolean = false;
  displayedColumns: string[] = [
    "planName",
    "price",
    "durationInMonth",
    "action",
  ];
  dataSource = new MatTableDataSource([]);
  userRole: string = "";
  timezone: any;
  language: any;
  timeFormat: boolean = false;
  timezoneList: any = [];
  languageList: any = [];
  filteredTimezoneList: any = [];
  @ViewChild("searchTimezoneInput") searchTimezoneInput!: ElementRef;

  constructor(
    private _formBuilder: FormBuilder,
    private _customPageTitleStrategy: CustomPageTitleStrategy,
    private _commonService: CommonService,
    private _superAdminService: SuperAdminService,
    private _authService: AuthGuardService,
    private _toastrService: ToastrNotificationService,
    public _globalFunction: GlobalFunctions,
    private _router: Router,
    private _renderer: Renderer2
  ) {
    this.createSubscriptionPlanForm();
    this.getSettings();
    this.getAllSubscriptionPlan();
    this._renderer.listen("window", "click", (e: Event) => {
      this.filteredTimezoneList = this.timezoneList;
      if (this.searchTimezoneInput)
        this.searchTimezoneInput.nativeElement.value = "";
    });
  }

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
      }
    });
    this.getTimezone();
    this.getLanguages();
  }

  /**
   * Method to create a subscription plan form
   */
  public createSubscriptionPlanForm() {
    this.subscriptionPlanValidation = this._formBuilder.group({
      planName: [
        "",
        [Validators.required, this._globalFunction.removeSpaces.bind(this)],
      ],
      planPrice: ["", [Validators.required]],
      durationInMonth: ["", [Validators.required]],
    });
  }

  /**
   * Method to get all subscription plan
   */
  public getAllSubscriptionPlan() {
    this._superAdminService.getAllSubscriptionPlan().subscribe({
      next: (data) => {
        if (data && data.length > 0) {
          this.dataSource = new MatTableDataSource(data);
        } else {
          this.dataSource = new MatTableDataSource([]);
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to save subscription plan
   */
  public saveSubscriptionPlan() {
    if (this.isEditMode) {
      this.updateSubscriptionPlan();
    } else {
      this.addSubscriptionPlan();
    }
  }

  /**
   * Method to add subscription plan
   */
  public addSubscriptionPlan() {
    this.formSubmitted = true;
    if (this.subscriptionPlanValidation.valid) {
      this.subscriptionPlanModel.planName =
        this.subscriptionPlanValidation.get("planName")?.value;
      this.subscriptionPlanModel.planPrice =
        this.subscriptionPlanValidation.get("planPrice")?.value;
      this.subscriptionPlanModel.durationInMonth =
        this.subscriptionPlanValidation.get("durationInMonth")?.value;
      let planParams = {
        planName: this.subscriptionPlanModel.planName,
        price: this.subscriptionPlanModel.planPrice,
        durationInMonth: this.subscriptionPlanModel.durationInMonth,
      };
      this._superAdminService.addSubscriptionPlan(planParams).subscribe({
        next: (data) => {
          if (data && data.success && data.success === true) {
            this._toastrService.showSuccess(
              "settings.addSubscriptionPlanSuccessMessage"
            );
            this.clearSubscriptionPlanData();
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
    }
  }

  /**
   * Method to update subscription plan
   */
  public updateSubscriptionPlan() {
    this.formSubmitted = true;
    if (this.subscriptionPlanValidation.valid) {
      this.subscriptionPlanModel.planName =
        this.subscriptionPlanValidation.get("planName")?.value;
      this.subscriptionPlanModel.planPrice =
        this.subscriptionPlanValidation.get("planPrice")?.value;
      this.subscriptionPlanModel.durationInMonth =
        this.subscriptionPlanValidation.get("durationInMonth")?.value;
      let planParams = {
        subscriptionPlanId: this.subscriptionPlanId,
        planName: this.subscriptionPlanModel.planName,
        price: this.subscriptionPlanModel.planPrice,
        durationInMonth: this.subscriptionPlanModel.durationInMonth,
      };
      this._superAdminService.updateSubscriptionPlan(planParams).subscribe({
        next: (data) => {
          if (data && data.success && data.success === true) {
            this._toastrService.showSuccess(
              "settings.updateSubscriptionPlanSuccessMessage"
            );
            this.clearSubscriptionPlanData();
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
    }
  }

  /**
   * Method to edit subscription plan
   */
  public editSubscriptionPlan(data: any) {
    this.isEditMode = true;
    this.subscriptionPlanId = data.subscriptionPlanId;
    this.subscriptionPlanModel.planName = data.planName;
    this.subscriptionPlanModel.planPrice = data.price;
    this.subscriptionPlanModel.durationInMonth = data.durationInMonth;
  }

  /**
   * Method to delete subscription plan
   */
  public deleteSubscriptionPlan(data: any) {
    if (data.subscriptionPlanId !== null) {
      this._superAdminService
        .deleteSubscriptionPlan(data.subscriptionPlanId)
        .subscribe({
          next: (data) => {
            if (data && data.success && data.success === true) {
              this._toastrService.showSuccess(
                "settings.deleteSubscriptionPlanSuccessMessage"
              );
              this.clearSubscriptionPlanData();
            }
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }

  /**
   * Method to clear subscription plan data
   */
  public clearSubscriptionPlanData() {
    this.isEditMode = false;
    this.formSubmitted = false;
    this.subscriptionPlanId = 0;
    this.subscriptionPlanValidation.reset();
    this.getAllSubscriptionPlan();
  }

  /**
   * Method to get settings
   */
  public getSettings() {
    this._superAdminService.getSettings().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.appPercentage = data.percentage;
          this.timezone = data.timeZoneId;
          this.language = data.appLanguageId > 0 ? data.appLanguageId : 1;
          this.timeFormat = data.timeFormat;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to save appointment commission percentage
   */
  public saveSettings() {
    // if (this.appPercentage === "") {
    //   return this._toastrService.showError("settings.percentageRequireError");
    // }
    if (!this.timezone || !this.language) {
      if (!this.timezone)
        this._toastrService.showError("common.RequiredTimezoneError");
      if (!this.language)
        this._toastrService.showError("providerProfile.requiredLanguageError");
      return;
    }
    let settingsParams = {
      // percentage: this.appPercentage,
      appLanguageId: this.language,
      timeZoneId: this.timezone?.timezoneId,
      timeFormat: this.timeFormat,
    };
    this._superAdminService.saveSettings(settingsParams).subscribe({
      next: (data) => {
        if (data && data.success && data.success === true) {
          this._toastrService.showSuccess(
            "settings.saveSettingsSuccessMessage"
          );
          let currentToken = this._authService.getTokenDetails();
          this._commonService
            .refreshToken(currentToken.access_token, currentToken.refresh_token)
            .subscribe((data) => {
              this._commonService.setCurrentLanguage();
            });
          this._commonService.redirectToLandingPage(this.userRole);
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to cancel settings
   */
  public cancelSettings() {
    this._commonService.redirectToLandingPage(this.userRole);
  }

  /**
   * Method to get all timezone
   */
  public getTimezone() {
    this._commonService.getTimezone().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.timezoneList = data;
          this.filteredTimezoneList = this.timezoneList;
          // this.timezoneList.sort((a: any, b: any) =>
          //   a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          // );

          //Set selected timezone in timezone dropdown
          let currentTimezone = this._commonService.setCurrentTimezone(
            this.timezoneList
          );
          this.timezone = currentTimezone;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get language data
   */
  public getLanguages() {
    this._commonService.getLanguages().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.languageList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to change time format
   */
  public changeTimeFormat(event: MatSlideToggleChange) {
    this.timeFormat = event.checked;
  }

  /**
   * Method to search cities
   */
  public searchTimeZone(event: any) {
    const val = event.target.value.toLowerCase();
    const tempTimezoneList = this.timezoneList.filter((x: any) => {
      if (x.displayName.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredTimezoneList = tempTimezoneList;
  }
}

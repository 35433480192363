export enum clinicApiList {
  addPatient = "/clinic/addpatient",
  addMedicalProvider = "/clinic/addprovider",
  addStaff = "/clinic/addstaff",
  updatePatient = "/clinic/updatepatient",
  updateMedicalProvider = "/clinic/updateprovider",
  updateStaff = "/clinic/updatestaff",
  getPatient = "/clinic/getpatient",
  getMedicalProvider = "/clinic/getprovider",
  getStaff = "/clinic/getstaff",
  getAllPatient = "/clinic/getallpatient",
  getAllMedicalProvider = "/clinic/getallprovider",
  getAllStaff = "/clinic/getallstaff",
  clinicUpdateProfile = "/clinic/updateprofile",
  clinicgetProfile = "/clinic/getprofile",
  login = "/clinic/login",
  refreshToken = "/clinic/refreshtoken",
  forgotPassword = "/clinic/forgotpassword",
  resetPassword = "/clinic/resetpassword",
  signupVerify = "/clinic/signupverify",
  signupComplete = "/clinic/signupcomplete",
  specialties = "/clinic/specialties",
  sendInvite = "/clinic/sendinvite",
  updateAppointmentSettings = "/clinic/updateappointmentsettings",
  addClinicVisitType = "/clinic/addclinicvisittype",
  updateClinicVisitType = "/clinic/updateclinicvisittype",
  deleteClinicVisitType = "/clinic/deleteclinicvisittype",
  getClinicSettings = "/clinic/getclinicsettings",
  getNotificationDurations = "/clinic/getnotificationdurations",
  addAppointmentNotification = "/clinic/addappointmentnotification",
  updateAppointmentNotification = "/clinic/updateappointmentnotification",
  deleteAppointmentNotification = "/clinic/deleteappointmentnotification",
  getProviderProfile = "/clinic/getproviderprofile",
  updateProviderProfile = "/clinic/updateproviderprofile",
  addProviderScheduler = "/clinic/addproviderscheduler",
  updateProviderScheduler = "/clinic/updateproviderscheduler",
  deleteProviderScheduler = "/clinic/deleteproviderscheduler",
  getAllproviderScheduler = "/clinic/getallproviderscheduler",
  getProviderScheduler = "/clinic/getproviderscheduler",
  getProviderClinicVisitType = "/clinic/getproviderclinicvisittype",
  addClinicSubscriptionPlan = "/clinic/addclinicsubscriptionplan",
  getAllClinicSubscriptionPlan = "/clinic/getallclinicsubscriptionplan",
  getAllSubscriptionPlan = "/clinic/getallsubscriptionplan",
  getAllProviderSchedulerSlot = "/clinic/getallproviderschedulerslot",
  addClinicAppointment = "/clinic/addclinicappointment",
  updateClinicAppointment = "/clinic/updateclinicappointment",
  deleteClinicAppointment = "/clinic/deleteclinicappointment",
  getAllUpcomingAppointment = "/clinic/getallupcomingappointment",
  getAllAppointmentHistory = "/clinic/getallappointmenthistory",
  getInsuranceCompany = "/clinic/insurancecompany",
  getNotificationLogs = "/clinic/getlogs",
  getClinicTransactionHistory = "/clinic/getclinictransactionhistory",
  updateWalletSettings = "/clinic/updatewalletsettings",
  getWalletSettings = "/clinic/getwalletsettings",
  getAllPatientByClinic = "/clinic/getallpatientbyclinic",
  getAllProviderByClinic = "/clinic/getallproviderbyclinic",
  getAllAppointmentByClinic = "/clinic/getallappointmentbyclinic",
  addRefundTransaction = "/clinic/addrefundtransaction",
  getDashboardDetails = "/clinic/getdashboarddetails",
  getPatientDetails = "/clinic/getpatientdetails",
  getUpcomingProviderSchedulerSlot = "/clinic/getupcomingproviderschedulerslot",
}

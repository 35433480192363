<div class="tabs-body">
  <div class="mat-elevation-z8 table-responsive">
    <table mat-table [dataSource]="dataSource" class="table" matSort>
      <ng-container matColumnDef="patientName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "appointment.patientNameText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.patientName }}</td>
      </ng-container>
      <ng-container matColumnDef="medicalProviderName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "scheduler.providerNameLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.medicalProviderName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="patientEmail">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "userList.emailLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.patientEmail }}</td>
      </ng-container>
      <!-- <ng-container matColumnDef="appointmentDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "appointment.appointmentDateTimeText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            _globalFunction.convertUTCDateTimeToLocalDateTime(
              element.appointmentDateTime
            )
          }}
        </td>
      </ng-container> -->
      <ng-container matColumnDef="transactionDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "transaction.transactionDateTimeText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.transactionDateTime | date : "MMM dd, yyyy " + timeFormat
          }}
          {{ currentTimeZoneName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="startTime">
        <th mat-header-cell *matHeaderCellDef>
          {{ "transaction.appointmentDurationText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container
            *ngIf="
              element.transactionForId !== transactionForEnum.AppointmentRefund
            "
          >
            {{
              _globalFunction.getSlotTime(
                _globalFunction.convertUTCTimeToLocalTime(element.startTime),
                _globalFunction.convertUTCTimeToLocalTime(element.endTime)
              )
            }}
          </ng-container>
          <ng-container
            *ngIf="
              element.transactionForId === transactionForEnum.AppointmentRefund
            "
          >
            -
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="visitType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "settings.visitTypeLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.visitType }}
        </td>
      </ng-container>
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "transaction.amountFeesText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">$ {{ element.price }}</td>
      </ng-container>
      <ng-container matColumnDef="transactionForId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "transaction.transactionForText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            "common." +
              _globalFunction.getTransactionForById(element.transactionForId)
              | translate
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionTypeId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "transaction.transactionTypeText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            "common." +
              _globalFunction.getTransactionTypeById(element.transactionTypeId)
              | translate
          }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          {{ "userList.noResultFoundText" | translate }}
        </td>
      </tr>
    </table>
  </div>
  <mat-paginator
    [hidden]="!showPaginator"
    #paginator
    style-paginator
    showFirstLastButtons
    itemsPerPageLabel="Page Size"
    [length]="totalRows"
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageChanged($event)"
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>

<div class="left-menu" *ngIf="isLoggedIn | async">
  <ul class="top-list">
    <div *ngFor="let menu of topMenuList">
      <li
        #tooltip="matTooltip"
        matTooltip="{{ menu.tooltip | translate }}"
        matTooltipPosition="right"
        routerLinkActive="active"
      >
        <a href="javascript:void(0)" [routerLink]="menu.routerLink">
          <i [class]="menu.icon"></i>
          <span>{{ menu.name | translate }}</span>
        </a>
      </li>
    </div>
  </ul>

  <div class="bottom-list">
    <div *ngFor="let menu of bottomMenuList">
      <a href="javascript:void(0)" [routerLink]="menu.routerLink">{{ menu.name | translate }}</a>
  </div>
 </div>
</div>
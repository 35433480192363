import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { GlobalFunctions } from "src/app/app.global";
import { TransactionForEnum } from "src/app/enums/transactionForEnum";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-view-transaction",
  templateUrl: "./view-transaction.component.html",
  styleUrls: ["./view-transaction.component.css"],
})
export class ViewTransactionComponent implements OnInit {
  @Input() viewTransactionData: any;
  @Output() showViewTransactionPopup = new EventEmitter<boolean>();
  allTransactionFor = TransactionForEnum;
  timeFormat: any;
  currentTimeZoneName: any;

  constructor(
    public _globalFunction: GlobalFunctions,
    private _commonService: CommonService
  ) {
    this.currentTimeZoneName = this._commonService.getTimeZoneShortName();
    this.timeFormat = this._commonService.getTimeFormat();
  }

  ngOnInit(): void {}

  /**
   * Method to close view transaction section
   */
  public closeViewTransactionPopup() {
    this.showViewTransactionPopup.emit(false);
  }
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { SuperAdminService } from "../../services/super-admin.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class SuperAdminForgotPasswordComponent implements OnInit {
  forgotPasswordValidation: FormGroup = new FormGroup("");
  formSubmitted: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _superAdminService: SuperAdminService,
    private _toastrService: ToastrNotificationService,
    private _router: Router,
    public _translate: TranslateService
  ) {
    this.createForgotPasswordForm();
  }

  ngOnInit() {}

  /**
   * Method to create a forgot password form
   */
  public createForgotPasswordForm() {
    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    this.forgotPasswordValidation = this._formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(emailPattern)]],
    });
  }

  /**
   * Method to submit forgot password
   */
  public submitForgotPassword() {
    this.formSubmitted = true;
    if (this.forgotPasswordValidation.valid) {
      let email = this.forgotPasswordValidation.get("email")?.value;

      this._superAdminService.forgotPassword(email).subscribe({
        next: (data) => {
          if (data && data.success) {
            localStorage.setItem("ForgotPasswordEmail", email);
            this._toastrService.showSuccess(
              "forgotpassword.forgotPasswordSuccessMessage"
            );
            this._router.navigate(["/superadmin/login"]);
          }
        },
        error: (error) => {
          if (
            error &&
            error.error &&
            error.error.ErrorCode &&
            error.error.ErrorCode === "NoDataFound"
          ) {
            this._toastrService.showError("ErrorCode.EmailNotExist");
          } else {
            this._commonService.showErrorMessage(error);
          }
        },
      });
    }
  }
}

<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/icon-appointment.svg" alt="" />
        </div>
        <h2>Live Consultation</h2>
      </div>
    </div>
    <div class="grid-container">
      <!-- <div class="grid-left">
        <app-rooms
          #rooms
          (roomChanged)="onRoomChanged($event)"
          [activeRoomName]="!!activeRoom ? activeRoom.name : null"
        ></app-rooms>
      </div> -->
      <!-- <div class="video-timer">
        <img src="assets/images/icon-clock.png" alt="" />
        <span>00:05</span>
      </div> -->
      <div class="video-control" *ngIf="activeRoom">
        <p>{{ patientName }}</p>
        <a href="javascript:void(0)" (click)="showHideCamera(!isShowVideo)"
          ><img src="assets/images/video.svg" alt="" *ngIf="isShowVideo" /><img
            src="assets/images/video_not.svg"
            alt=""
            *ngIf="!isShowVideo"
        /></a>
        <a href="javascript:void(0)" (click)="onMuteUnmuteAudio(!isMuteAudio)"
          ><img src="assets/images/mike.svg" alt="" *ngIf="!isMuteAudio" /><img
            src="assets/images/mike_not.svg"
            alt=""
            *ngIf="isMuteAudio"
        /></a>
        <!-- <a href="javascript:void(0)"><img src="assets/images/setting.svg" alt="" /></a> -->
        <a href="javascript:void(0)" (click)="onLeaveRoom()"
          ><img src="assets/images/call.svg" alt=""
        /></a>
      </div>
      <div class="grid-content">
        <app-camera
          #camera
          [style.display]="!!activeRoom ? 'none' : 'block'"
        ></app-camera>
        <app-participants
          #participants
          (muteUnmuteAudio)="onMuteUnmuteAudio($event)"
          (participantsChanged)="onParticipantsChanged($event)"
          [style.display]="!!activeRoom ? 'block' : 'none'"
          [activeRoomName]="!!activeRoom ? activeRoom.name : null"
          [showPatientVideo]="showPatientVideo"
        ></app-participants>
      </div>
      <div class="grid-right">
        <app-live-consultation-settings
          #settings
          (settingsChanged)="onSettingsChanged($event)"
          [showProviderVideo]="showProviderVideo"
        ></app-live-consultation-settings>
      </div>
    </div>
  </div>
</div>

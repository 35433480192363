import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { CustomPageTitleStrategy } from "src/app/services/custom-page-title-strategy";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { environment } from "src/environments/environment";
import { ClinicService } from "../../services/clinic.service";

@Component({
  selector: "app-upgrade-subscription",
  templateUrl: "./upgrade-subscription.component.html",
  styleUrls: ["./upgrade-subscription.component.css"],
})
export class UpgradeSubscriptionComponent implements OnInit {
  subscriptionPlanList: any = [];
  selectedSubscriptionPlan: any = "";

  constructor(
    private _commonService: CommonService,
    private _toastrService: ToastrNotificationService,
    private _clinicService: ClinicService
  ) {}

  ngOnInit(): void {
    this.getAllClinicSubscriptionPlan();
  }

  /**
   * Method to get all clinic subscription plan
   */
  public getAllClinicSubscriptionPlan() {
    this._clinicService.getAllClinicSubscriptionPlan().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.subscriptionPlanList = data;
          const currentSubscriptionPlan = this.subscriptionPlanList.find(
            (obj: any) => obj.currentActivePlan === true
          );
          if (currentSubscriptionPlan) {
            this.selectedSubscriptionPlan =
              currentSubscriptionPlan.subscriptionPlanId;
          }
        } else {
          this.subscriptionPlanList = [];
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to select subscription plan
   */
  public selectSubscriptionPlan(planId: any) {
    this.selectedSubscriptionPlan = planId;
  }

  /**
   * Method to save subscription plan
   */
  public saveSubscriptionPlan() {
    let timezoneOffset = this._commonService.getCurrentTimeZoneNewDate().getTimezoneOffset() / 60;
    if (this.selectedSubscriptionPlan !== "") {
      let planParam = {
        subscriptionPlanId: this.selectedSubscriptionPlan,
        TimeZoneOffSet: timezoneOffset,
        successUrl: environment.subscriptionSuccessUrl,
        cancelUrl: environment.subscriptionCancelUrl,
      };
      this._clinicService.addSubscriptionPlan(planParam).subscribe({
        next: (data) => {
          if (data && data.success && data.success === true) {
            if (data.paymentUrl && data.paymentUrl !== null) {
              window.open(data.paymentUrl, "_self");
            }
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
    } else {
      this._toastrService.showError(
        "subscription.selectSubscriptionPlanErrorMessage"
      );
    }
  }
}

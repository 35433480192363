export class ProviderProfileModel {
  firstName: string;
  lastName: string;
  providerPhoto: any;
  email: string;
  phoneCode: string;
  phoneNumber: string;
  dob: string;
  speciality: string;
  visitTypes: [];
  languages: [];
  timezone: any;
  language: string;
  timeFormat: boolean;

  constructor() {
    this.firstName = "";
    this.lastName = "";
    this.providerPhoto = null;
    this.email = "";
    this.phoneCode = "";
    this.phoneNumber = "";
    this.dob = "";
    this.speciality = "";
    this.visitTypes = [];
    this.languages = [];
    this.timezone = null;
    this.language = "";
    this.timeFormat = false;
  }
}

import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GlobalFunctions } from "src/app/app.global";
import { superAdminApiList } from "src/app/enums/superAdminApiEnum";
import { CityModel } from "src/app/models/cityModel";
import { CountryModel } from "src/app/models/countryModel";
import { DocumentModel } from "src/app/models/documentModel";
import { StateModel } from "src/app/models/stateModel";
import { UserModel } from "src/app/modules/user/models/userModel";
import { UserService } from "src/app/modules/user/services/user.service";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { environment } from "src/environments/environment";
import { ClinicModel } from "../../models/clinicModel";
import { SuperAdminService } from "../../services/super-admin.service";

@Component({
  selector: "app-add-edit-clinic",
  templateUrl: "./add-edit-clinic.component.html",
  styleUrls: ["./add-edit-clinic.component.css"],
})
export class AddEditClinicComponent {
  clinicInfoModel = new ClinicModel();
  clinicInfoValidation: FormGroup = new FormGroup("");
  formSubmitted: boolean = false;
  codeSelect: string = "";
  phoneCodeList: CountryModel[] = [];
  countryList: CountryModel[] = [];
  countrySelect: string = "";
  stateSelect: string = "";
  citySelect: string = "";
  stateList: StateModel[] = [];
  cityList: CityModel[] = [];
  clinicPhotos: DocumentModel[] = [];
  clinicPhotoList: string = "";
  clinicDocuments: DocumentModel[] = [];
  clinicDocumentList: string = "";
  addEditClinicText: string = "";
  filteredStateList: any = [];
  filteredCityList: any = [];
  @Input() tabName: string = "";
  @Input() editClinicId: any = "";
  @Output() showClinicPopup = new EventEmitter<boolean>();
  @Output() refreshData = new EventEmitter<boolean>();
  @ViewChild("searchStateInput") searchStateInput!: ElementRef;
  @ViewChild("searchCityInput") searchCityInput!: ElementRef;
  
  constructor(
    private _commonService: CommonService,
    private _superAdminService: SuperAdminService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrNotificationService,
    public _globalFunction: GlobalFunctions,
    private _renderer: Renderer2
  ) {
    this._renderer.listen("window", "click", (e: Event) => {
      this.filteredStateList = this.stateList;
      this.filteredCityList = this.cityList;
      if (this.searchStateInput)
        this.searchStateInput.nativeElement.value = "";
      if (this.searchCityInput)
        this.searchCityInput.nativeElement.value = "";
    });
  }

  ngOnInit(): void {
    this.createUserForm();
    this.getPhoneCode();
    this.getCountry();
  }

  ngOnChanges(): void {
    if (this.editClinicId && this.editClinicId !== null) {
      this.addEditClinicText = "Edit";
      this.getClinicDataByUserId(this.editClinicId);
    } else {
      this.addEditClinicText = "Add";
    }
  }

  /**
   * Method to create a user form
   */
  public createUserForm() {
    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    this.clinicInfoValidation = this._formBuilder.group({
      clinicName: [
        "",
        [Validators.required, this._globalFunction.removeSpaces.bind(this)],
      ],
      phoneCode: ["", [Validators.required]],
      phoneNumber: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(10),
          Validators.pattern("^[0-9]*$"),
        ]),
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(emailPattern),
          this._globalFunction.removeSpaces.bind(this),
        ],
      ],
      einNumber: [
        "",
        [Validators.required, this._globalFunction.removeSpaces.bind(this)],
      ],
      clinicCode: [
        "",
        [
          Validators.required,
          this._globalFunction.removeSpaces.bind(this),
          Validators.pattern("^[a-zA-Z0-9]{6}$"),
        ],
      ],
      countryId: ["", [Validators.required]],
      stateId: ["", [Validators.required]],
      cityId: ["", [Validators.required]],
      address: [
        "",
        [Validators.required, this._globalFunction.removeSpaces.bind(this)],
      ],
      clinicPhotos: [""],
      clinicDocuments: ["", [Validators.required]],
      aboutClinic: ["", this._globalFunction.removeSpaces.bind(this)],
    });
  }

  /**
   * Method to close add clinic section
   */
  public closeClinicPopup() {
    this.showClinicPopup.emit(false);
  }

  /**
   * Method to get phone code data
   */
  public getPhoneCode() {
    this._commonService.getCountry().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.phoneCodeList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to submit clinic data
   */
  public submitClinicInfo() {
    this.formSubmitted = true;
    if (this.clinicInfoValidation.valid) {
      this.clinicInfoModel.clinicName =
        this.clinicInfoValidation.get("clinicName")?.value;
      this.clinicInfoModel.phoneCode =
        this.clinicInfoValidation.get("phoneCode")?.value;
      this.clinicInfoModel.phoneNumber =
        this.clinicInfoValidation.get("phoneNumber")?.value;
      this.clinicInfoModel.email =
        this.clinicInfoValidation.get("email")?.value;
      this.clinicInfoModel.einNumber =
        this.clinicInfoValidation.get("einNumber")?.value;
      this.clinicInfoModel.clinicCode =
        this.clinicInfoValidation.get("clinicCode")?.value;
      this.clinicInfoModel.cityId =
        this.clinicInfoValidation.get("cityId")?.value;
      this.clinicInfoModel.address =
        this.clinicInfoValidation.get("address")?.value;
      this.clinicInfoModel.aboutClinic =
        this.clinicInfoValidation.get("aboutClinic")?.value;
      this.clinicInfoModel.clinicPhotos = this.clinicPhotos;
      this.clinicInfoModel.clinicDocuments = this.clinicDocuments;

      let clinicParam: any = {};
      if (this.editClinicId && this.editClinicId !== null) {
        clinicParam["userId"] = this.editClinicId;
      }
      clinicParam["appURL"] = environment.clinicAppUrl;
      clinicParam["clinicName"] = this.clinicInfoModel.clinicName;
      clinicParam["phoneCode"] = this.clinicInfoModel.phoneCode;
      clinicParam["phoneNo"] = this.clinicInfoModel.phoneNumber;
      clinicParam["email"] = this.clinicInfoModel.email;
      clinicParam["einNumber"] = this.clinicInfoModel.einNumber;
      clinicParam["clinicCode"] = this.clinicInfoModel.clinicCode;
      clinicParam["cityId"] = this.clinicInfoModel.cityId;
      clinicParam["address"] = this.clinicInfoModel.address;
      clinicParam["aboutClinic"] = this.clinicInfoModel.aboutClinic;
      clinicParam["photos"] = this.clinicInfoModel.clinicPhotos;
      clinicParam["documents"] = this.clinicInfoModel.clinicDocuments;

      let apiPath =
        this.editClinicId && this.editClinicId !== null
          ? superAdminApiList.updateClinic
          : superAdminApiList.addClinic;
      let successMessage =
        this.editClinicId && this.editClinicId !== null
          ? "manageClinics.updateClinicSuccessMessage"
          : "manageClinics.addClinicSuccessMessage";

      this.refreshData.emit(false);
      this._superAdminService.addClinic(clinicParam, apiPath).subscribe({
        next: (data) => {
          if (data && data.success && data.success === true) {
            this.refreshData.emit(true);
            this._toastrService.showSuccess(successMessage);
            this.closeClinicPopup();
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
    }
  }

  /**
   * Method to get clinic data by userid
   */
  public getClinicDataByUserId(clinicId: any) {
    let apiPath = superAdminApiList.getClinic;
    this._superAdminService.getClinicDataById(clinicId, apiPath).subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.showClinicPopup.emit(true);
          this.clinicInfoModel.clinicName = data.clinicName;
          this.clinicInfoModel.phoneCode = data.phoneCode;
          this.clinicInfoModel.phoneNumber = data.phoneNo;
          this.clinicInfoModel.email = data.email;
          this.clinicInfoModel.einNumber = data.einNumber;
          this.clinicInfoModel.clinicCode = data.clinicCode;
          this.clinicInfoModel.address = data.address;
          this.clinicPhotos = data.photos;
          this.clinicDocuments = data.documents;
          this.clinicPhotoList = this.clinicPhotos
            .map((x: any) => x.fileName)
            .join(",");
          this.clinicDocumentList = this.clinicDocuments
            .map((x: any) => x.fileName)
            .join(",");
          if (data.countryId !== 0) {
            this.clinicInfoModel.countryId = data.countryId;
            this.changeCountry(data.countryId);
          }
          if (data.stateId !== 0) {
            this.clinicInfoModel.stateId = data.stateId;
            this.changeState(data.stateId);
          }
          if (data.cityId !== 0) {
            this.clinicInfoModel.cityId = data.cityId;
          }
          if (data.aboutClinic !== null) {
            this.clinicInfoModel.aboutClinic = data.aboutClinic;
          }
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to upload clinic photos
   */
  public uploadClinicPhoto(event: any) {
    if (event.target.files) {
      const valid_file_ext = ["jpg", "jpeg", "png"];
      var files = event.target.files;
      var totalClinicPhotosLength =
        files.length +
        this.clinicPhotos.filter((i) => i.fileBytes !== "").length;
      if (totalClinicPhotosLength > 5) {
        return this._toastrService.showError("common.documentLengthError");
      }
      for (let i = 0; i < files.length; i++) {
        const fileSize = event.target.files[i].size / 1048576;
        const fileName = event.target.files[i].name;
        const fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
        var file = files[i];
        if (fileSize > 5) {
          return this._toastrService.showError("common.documentSizeError");
        }
        if (valid_file_ext.indexOf(fileNameExt.toLowerCase()) < 0) {
          event.currentTarget.value = "";
          return this._toastrService.showError("common.documentTypeError");
        }
        let fileExists = this.clinicPhotos.filter((item) => {
          if (item.fileBytes !== "") {
            return item.fileName.includes(fileName);
          }
          return null;
        });
        if (file && fileExists.length === 0) {
          var reader = new FileReader();
          reader.onload = this.convertFileToByteForPhoto.bind(this, fileName);
          reader.readAsBinaryString(file);
        }
      }
    }
  }

  /**
   * Method to convert file to byte for photo
   */
  public convertFileToByteForPhoto(fileName: string, readerEvt: any) {
    var binaryString = readerEvt.target.result;
    let base64textString = btoa(binaryString);
    this.clinicPhotos.push({
      DocumentId: 0,
      fileName: fileName,
      fileBytes: base64textString,
    });
    this.clinicPhotoList = this.clinicPhotos
      .filter((i) => i.fileBytes !== "")
      .map((x) => x.fileName)
      .join(",");
  }

  /**
   * Method to remove clinic photo
   */
  public removeClinicPhoto(clinicPhoto: DocumentModel) {
    this.clinicPhotos = this.clinicPhotos.filter(function (item) {
      if (item.fileName === clinicPhoto.fileName) {
        item.fileBytes = "";
      }
      return item;
    });
    this.clinicPhotoList = this.clinicPhotos
      .filter((i) => i.fileBytes !== "")
      .map((x) => x.fileName)
      .join(",");
  }

  /**
   * Method to upload clinic documents
   */
  public uploadClinicDocument(event: any) {
    if (event.target.files) {
      var files = event.target.files;
      var totalClinicDocumentsLength =
        files.length +
        this.clinicDocuments.filter((i) => i.fileBytes !== "").length;
      if (totalClinicDocumentsLength > 5) {
        return this._toastrService.showError("common.documentLengthError");
      }
      for (let i = 0; i < files.length; i++) {
        const fileSize = event.target.files[i].size / 1048576;
        const fileName = event.target.files[i].name;
        var file = files[i];
        if (fileSize > 5) {
          return this._toastrService.showError("common.documentSizeError");
        }
        let fileExists = this.clinicDocuments.filter((item) => {
          if (item.fileBytes !== "") {
            return item.fileName.includes(fileName);
          }
          return null;
        });
        if (file && fileExists.length === 0) {
          var reader = new FileReader();
          reader.onload = this.convertFileToByteForDocument.bind(
            this,
            fileName
          );
          reader.readAsBinaryString(file);
        }
      }
    }
  }

  /**
   * Method to convert file to byte for photo
   */
  public convertFileToByteForDocument(fileName: string, readerEvt: any) {
    var binaryString = readerEvt.target.result;
    let base64textString = btoa(binaryString);
    this.clinicDocuments.push({
      DocumentId: 0,
      fileName: fileName,
      fileBytes: base64textString,
    });
    this.clinicDocumentList = this.clinicDocuments
      .filter((i) => i.fileBytes !== "")
      .map((x) => x.fileName)
      .join(",");
  }

  /**
   * Method to remove clinic document
   */
  public removeClinicDocument(clinicDocument: DocumentModel) {
    this.clinicDocuments = this.clinicDocuments.filter(function (item) {
      if (item.fileName === clinicDocument.fileName) {
        item.fileBytes = "";
      }
      return item;
    });
    this.clinicDocumentList = this.clinicDocuments
      .filter((i) => i.fileBytes !== "")
      .map((x) => x.fileName)
      .join(",");
  }

  /**
   * Method to get state data by country
   */
  public changeCountry(countryId: number) {
    this.cityList = [];
    this.getState(countryId);
  }

  /**
   * Method to get city data by state
   */
  public changeState(stateId: number) {
    this.getCity(stateId);
  }

  /**
   * Method to get country data
   */
  public getCountry() {
    this._commonService.getCountry().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.countryList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get state data
   */
  public getState(countryId: number) {
    this._commonService.getState(countryId).subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.stateList = data;
          this.filteredStateList = this.stateList
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get city data
   */
  public getCity(stateId: number) {
    this._commonService.getCity(stateId).subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.cityList = data;
          this.filteredCityList = this.cityList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to search states
   */
   public searchStates(event: any) {
    const val = event.target.value.toLowerCase();
    const tempStatesList = this.stateList.filter((x: any) => {
      if (
        x.stateName.toLowerCase().indexOf(val) !== -1 ||
        !val
      ) {
        return x;
      }
    });
    this.filteredStateList = tempStatesList;
  }

  /**
   * Method to search cities
   */
   public searchCities(event: any) {
    const val = event.target.value.toLowerCase();
    const tempCityList = this.cityList.filter((x: any) => {
      if (
        x.cityName.toLowerCase().indexOf(val) !== -1 ||
        !val
      ) {
        return x;
      }
    });
    this.filteredCityList = tempCityList;
  }
}

import { Component, ElementRef, OnInit, Renderer2, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Router } from "@angular/router";
import { GlobalFunctions } from "src/app/app.global";
import { CityModel } from "src/app/models/cityModel";
import { CountryModel } from "src/app/models/countryModel";
import { DocumentModel } from "src/app/models/documentModel";
import { StateModel } from "src/app/models/stateModel";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";
import { CustomPageTitleStrategy } from "src/app/services/custom-page-title-strategy";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { ClinicProfileModel } from "../../models/clinicProfileModel";
import { ClinicService } from "../../services/clinic.service";

@Component({
  selector: "app-clinic-profile",
  templateUrl: "./clinic-profile.component.html",
  styleUrls: ["./clinic-profile.component.css"],
})
export class ClinicProfileComponent implements OnInit {
  clinicProfileModel = new ClinicProfileModel();
  clinicProfileInfoValidation: FormGroup = new FormGroup("");
  formSubmitted: boolean = false;
  clinicPhotos: DocumentModel[] = [];
  clinicPhotoList: string = "";
  countrySelect: string = "";
  stateSelect: string = "";
  citySelect: string = "";
  countryList: CountryModel[] = [];
  stateList: StateModel[] = [];
  cityList: CityModel[] = [];
  clinicName: string = "";
  userRole: string = "";
  timezoneList: any = [];
  languageList: any = [];
  filteredStateList: any = [];
  filteredCityList: any = [];
  filteredTimezoneList: any = [];
  @ViewChild("searchStateInput") searchStateInput!: ElementRef;
  @ViewChild("searchCityInput") searchCityInput!: ElementRef;
  @ViewChild("searchTimezoneInput") searchTimezoneInput!: ElementRef;

  constructor(
    private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _clinicService: ClinicService,
    private _toastrService: ToastrNotificationService,
    private _authService: AuthGuardService,
    public _globalFunction: GlobalFunctions,
    private router: Router,
    private _renderer: Renderer2
  ) {
    this.createClinicProfileForm();
    this._renderer.listen("window", "click", (e: Event) => {
      this.filteredStateList = this.stateList;
      this.filteredCityList = this.cityList;
      this.filteredTimezoneList = this.timezoneList;
      if (this.searchStateInput)
        this.searchStateInput.nativeElement.value = "";
      if (this.searchCityInput)
        this.searchCityInput.nativeElement.value = "";
      if (this.searchTimezoneInput)
        this.searchTimezoneInput.nativeElement.value = "";
    });
  }

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.clinicName = data["firstname"] + " " + data["lastname"];
        this.userRole = data["usertypeid"];
      }
    });
    this.getCountry();
    this.getClinicProfile();
    this.getTimezone();
    this.getLanguages();
  }

  /**
   * Method to create a clinic profile form
   */
  public createClinicProfileForm() {
    this.clinicProfileInfoValidation = this._formBuilder.group({
      einNumber: [
        "",
        [Validators.required, this._globalFunction.removeSpaces.bind(this)],
      ],
      clinicCode: [
        "",
        [
          Validators.required,
          this._globalFunction.removeSpaces.bind(this),
          Validators.pattern("^[a-zA-Z0-9]{6}$"),
        ],
      ],
      country: ["", [Validators.required]],
      state: ["", [Validators.required]],
      city: ["", [Validators.required]],
      address: [
        "",
        [Validators.required, this._globalFunction.removeSpaces.bind(this)],
      ],
      clinicPhoto: [""],
      clinicInfo: ["", this._globalFunction.removeSpaces.bind(this)],
      timezone: ["", [Validators.required]],
      language: ["", [Validators.required]],
      timeFormat: ["", [Validators.required]],
    });
  }

  /**
   * Method to submit clinic profile information
   */
  public submitClinicProfile() {
    this.formSubmitted = true;
    if (this.clinicProfileInfoValidation.valid) {
      this.clinicProfileModel.einNumber =
        this.clinicProfileInfoValidation.get("einNumber")?.value;
      this.clinicProfileModel.clinicCode =
        this.clinicProfileInfoValidation.get("clinicCode")?.value;
      this.clinicProfileModel.city =
        this.clinicProfileInfoValidation.get("city")?.value;
      this.clinicProfileModel.address =
        this.clinicProfileInfoValidation.get("address")?.value;
      this.clinicProfileModel.clinicInfo =
        this.clinicProfileInfoValidation.get("clinicInfo")?.value;
      this.clinicProfileModel.clinicPhoto = this.clinicPhotos;

      this._clinicService.updateProfile(this.clinicProfileModel).subscribe({
        next: (data) => {
          if (data && data.success && data.success === true) {
            this._toastrService.showSuccess(
              "clinicProfile.profileSuccessMessage"
            );

            let currentToken = this._authService.getTokenDetails();
            this._commonService
              .refreshToken(
                currentToken.access_token,
                currentToken.refresh_token
              )
              .subscribe((data) => {
                this._commonService.setCurrentLanguage();
                this._commonService.redirectToLandingPage(this.userRole);
              });
            // this.router.navigate(["/login"]);
            //   this._commonService.redirectToLandingPage(this.userRole);
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
    }
  }

  /**
   * Method to upload clinic photos
   */
  public uploadClinicPhoto(event: any) {
    if (event.target.files) {
      const valid_file_ext = ["jpg", "jpeg", "png"];
      var files = event.target.files;
      let clinicPhotosLength = this.clinicPhotos.filter(
        (x) => x.fileBytes !== ""
      ).length;
      if (files.length + clinicPhotosLength > 5) {
        return this._toastrService.showError("common.documentLengthError");
      }
      for (let i = 0; i < files.length; i++) {
        this.clinicProfileModel.isClinicDocuments = true;
        const fileSize = event.target.files[i].size / 1048576;
        const fileName = event.target.files[i].name;
        const fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
        var file = files[i];
        if (fileSize > 5) {
          return this._toastrService.showError("common.documentSizeError");
        }
        if (valid_file_ext.indexOf(fileNameExt.toLowerCase()) < 0) {
          event.currentTarget.value = "";
          return this._toastrService.showError("common.documentTypeError");
        }
        let fileExists = this.clinicPhotos.filter((item) => {
          if (item.fileBytes !== "") {
            return item.fileName.includes(fileName);
          }
          return null;
        });
        if (file && fileExists.length === 0) {
          var reader = new FileReader();
          reader.onload = this.convertFileToByte.bind(this, fileName);
          reader.readAsBinaryString(file);
        }
      }
    }
  }

  /**
   * Method to convert file to byte
   */
  public convertFileToByte(fileName: string, readerEvt: any) {
    var binaryString = readerEvt.target.result;
    let base64textString = btoa(binaryString);
    this.clinicPhotos.push({
      DocumentId: 0,
      fileName: fileName,
      fileBytes: base64textString,
    });
    this.clinicPhotoList = this.clinicPhotos
      .filter((i) => i.fileBytes !== "")
      .map((x) => x.fileName)
      .join(",");
  }

  /**
   * Method to get country data
   */
  public getCountry() {
    this._commonService.getCountry().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.countryList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get state data
   */
  public getState(countryId: number) {
    this._commonService.getState(countryId).subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.stateList = data;
          this.filteredStateList = this.stateList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get city data
   */
  public getCity(stateId: number) {
    this._commonService.getCity(stateId).subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.cityList = data;
          this.filteredCityList = this.cityList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get state data by country
   */
  public changeCountry(countryId: number) {
    this.cityList = [];
    this.getState(countryId);
  }

  /**
   * Method to get city data by state
   */
  public changeState(stateId: number) {
    this.getCity(stateId);
  }

  /**
   * Method to get clinic profile data
   */
  public getClinicProfile() {
    this._clinicService.getProfile().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.changeCountry(data.countryId);
          this.changeState(data.stateId);
          this.clinicProfileModel.einNumber = data.einNumber;
          this.clinicProfileModel.clinicCode = data.clinicCode;
          this.clinicProfileModel.address = data.address;
          this.clinicProfileModel.language = data.appLanguageId > 0 ? data.appLanguageId : 1;
          if (!this.clinicProfileModel.timezone)
            this.clinicProfileModel.timezone = data.timeZoneId;
          this.clinicProfileModel.timeFormat = data.timeFormat;
          this.clinicPhotos = data.photos;
          this.clinicPhotoList = this.clinicPhotos
            .map((x) => x.fileName)
            .join(",");
          if (data.countryId !== 0) {
            this.clinicProfileModel.country = data.countryId;
            this.changeCountry(data.countryId);
          }
          if (data.stateId !== 0) {
            this.clinicProfileModel.state = data.stateId;
            this.changeState(data.stateId);
          }
          if (data.cityId !== 0) {
            this.clinicProfileModel.city = data.cityId;
          }
          if (data.aboutClinic !== null) {
            this.clinicProfileModel.clinicInfo = data.aboutClinic;
          }
        }
      },
      error: (error) => {
        if (error && error.error && error.error.ErrorCode !== "NoDataFound") {
          this._commonService.showErrorMessage(error);
        }
      },
    });
  }

  /**
   * Method to remove clinic photo
   */
  public removeClinicPhoto(clinicPhoto: DocumentModel) {
    this.clinicPhotos = this.clinicPhotos.filter(function (item) {
      if (item.fileName === clinicPhoto.fileName) {
        item.fileBytes = "";
      }
      return item;
    });
    this.clinicPhotoList = this.clinicPhotos
      .filter((i) => i.fileBytes !== "")
      .map((x) => x.fileName)
      .join(",");
  }

  /**
   * Method to get all timezone
   */
  public getTimezone() {
    this._commonService.getTimezone().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.timezoneList = data;
          this.filteredTimezoneList = this.timezoneList;
          // this.timezoneList.sort((a: any, b: any) =>
          //   a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          // );

          //Set selected timezone in timezone dropdown
          let currentTimezone = this._commonService.setCurrentTimezone(
            this.timezoneList
          );
          this.clinicProfileModel.timezone = currentTimezone;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get language data
   */
  public getLanguages() {
    this._commonService.getLanguages().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.languageList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to change time format
   */
  public changeTimeFormat(event: MatSlideToggleChange) {
    this.clinicProfileModel.timeFormat = event.checked;
  }

  /**
   * Method to search states
   */
   public searchStates(event: any) {
    const val = event.target.value.toLowerCase();
    const tempStatesList = this.stateList.filter((x: any) => {
      if (
        x.stateName.toLowerCase().indexOf(val) !== -1 ||
        !val
      ) {
        return x;
      }
    });
    this.filteredStateList = tempStatesList;
  }

  /**
   * Method to search cities
   */
   public searchCities(event: any) {
    const val = event.target.value.toLowerCase();
    const tempCityList = this.cityList.filter((x: any) => {
      if (
        x.cityName.toLowerCase().indexOf(val) !== -1 ||
        !val
      ) {
        return x;
      }
    });
    this.filteredCityList = tempCityList;
  }

  /**
   * Method to search timezone
   */
   public searchTimeZone(event: any) {
    const val = event.target.value.toLowerCase();
    const tempTimezoneList = this.timezoneList.filter((x: any) => {
      if (
        x.displayName.toLowerCase().indexOf(val) !== -1 ||
        !val
      ) {
        return x;
      }
    });
    this.filteredTimezoneList = tempTimezoneList;
  }
}

import {
  Component,
  OnInit,
  OnChanges,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { GlobalFunctions } from "src/app/app.global";
import { UserTypeEnum } from "src/app/enums/userTypeEnum";
import { CityModel } from "src/app/models/cityModel";
import { SpecialityModel } from "src/app/models/specialityModel";
import { StateModel } from "src/app/models/stateModel";
import { ClinicService } from "src/app/modules/clinic/services/clinic.service";
import { SuperAdminService } from "src/app/modules/super-admin/services/super-admin.service";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.css"],
})
export class FilterComponent implements OnInit, OnChanges {
  userRole: string = "";
  userId: any;
  userTypeEnum = UserTypeEnum;
  todayDate: Date = new Date();
  @Input() filter: boolean = false;
  @Input() showFilterList: any;
  @Output() filterData = new EventEmitter<any>();
  @Output() applyFilterData = new EventEmitter<boolean>();
  specialityList: SpecialityModel[] = [];
  providerList: any = [];
  visitTypeList: any = [];
  stateList: StateModel[] = [];
  cityList: CityModel[] = [];
  updatedByUserList: any = [];
  patientList: any = [];
  filteredPatientList: any = [];
  filteredProviderList: any = [];
  insuranceList: any = [];
  filterTransactionForValue: number = 0;
  filterTransactionTypeValue: number = 0;
  filterClinicValue: any = null;
  filteredClinicList: any = [];
  clinicList: any = [];
  filterSpecialityValue: number = 0;
  filterStatusValue: number = 0;
  filterProviderValue: any = null;
  filterVisitTypeValue: any = 0;
  filterStartDateValue: any = "";
  filterEndDateValue: any = "";
  filterRoleValue: number = 0;
  filterStateValue: number = 0;
  filterCityValue: number = 0;
  filterUpdatedByValue: any = null;
  filterPatientValue: any = null;
  filterInsuranceValue: any = 0;
  filterPatientEmailValue: any = "";
  filterProviderTypeValue: any = "all";
  filterMultiselectProviderValue: any = [];
  filteredStateList: any = [];
  filteredCityList: any = [];
  filteredupdatedByUserList: any = [];
  @ViewChild("searchPatientInput") searchPatientInput!: ElementRef;
  @ViewChild("searchPatientEmailInput") searchPatientEmailInput!: ElementRef;
  @ViewChild("searchProviderInput") searchProviderInput!: ElementRef;
  @ViewChild("searchClinicInput") searchClinicInput!: ElementRef;
  @ViewChild("searchStateInput") searchStateInput!: ElementRef;
  @ViewChild("searchCityInput") searchCityInput!: ElementRef;
  @ViewChild("searchUpdatedByInput") searchUpdatedByInput!: ElementRef;

  constructor(
    private _commonService: CommonService,
    private _clinicService: ClinicService,
    private _authService: AuthGuardService,
    private _superAdminService: SuperAdminService,
    public _globalFunction: GlobalFunctions,
    private _renderer: Renderer2
  ) {
    this.todayDate = this._commonService.getCurrentTimeZoneNewDate();
    this._renderer.listen("window", "click", (e: Event) => {
      this.filteredPatientList = this.patientList;
      this.filteredProviderList = this.providerList;
      this.filteredClinicList = this.clinicList;
      this.filteredStateList = this.stateList;
      this.filteredCityList = this.cityList;
      this.filteredupdatedByUserList = this.updatedByUserList;
      if (this.searchPatientInput)
        this.searchPatientInput.nativeElement.value = "";
      if (this.searchPatientEmailInput)
        this.searchPatientEmailInput.nativeElement.value = "";
      if (this.searchProviderInput)
        this.searchProviderInput.nativeElement.value = "";
      if (this.searchClinicInput)
        this.searchClinicInput.nativeElement.value = "";
      if (this.searchStateInput) this.searchStateInput.nativeElement.value = "";
      if (this.searchCityInput) this.searchCityInput.nativeElement.value = "";
      if (this.searchUpdatedByInput)
        this.searchUpdatedByInput.nativeElement.value = "";
    });
  }

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
        this.userId = data["userid"];
      }
    });

    if (this.showFilterList.includes("notification")) {
      let currentDate = this._commonService.getCurrentTimeZoneNewDate();
      currentDate.setDate(currentDate.getDate() - 29);
      this.filterStartDateValue = currentDate;
      this.filterEndDateValue = this._commonService.getCurrentTimeZoneNewDate();
    }
  }

  ngOnChanges(): void {
    this.loadFilterFields();
  }

  /**
   * Method to load filter fields
   */
  public loadFilterFields() {
    if (this.showFilterList.includes("Speciality")) {
      if (this.specialityList.length === 0) this.getSpeciality();
    }
    if (
      this.showFilterList.includes("Provider") ||
      this.showFilterList.includes("MultiselectProvider")
    ) {
      if (this.providerList.length === 0) this.getAllProviders();
    }
    if (
      this.showFilterList.includes("VisitType") &&
      this.userRole === UserTypeEnum.Provider.toString()
    ) {
      this.getVisitTypeByProvider(this.userId);
    }
    if (this.showFilterList.includes("State")) {
      if (this.stateList.length === 0) this.getState();
      if (this.updatedByUserList.length === 0) this.getAllOfficeStaff();
    }
    if (this.showFilterList.includes("Patient")) {
      if (this.patientList.length === 0) this.getAllPatients();
    }
    if (this.showFilterList.includes("Insurance")) {
      if (this.insuranceList.length === 0) this.getInsuranceCompany();
    }
    if (this.showFilterList.includes("Clinic")) {
      if (this.clinicList.length === 0) this.getAllClinics();
    }
  }

  /**
   * Method to toggle filter section
   */
  public filterClick() {
    this.filter = !this.filter;
  }

  /**
   * Method to set filter data
   */
  public setFilterData() {
    let filterValues: any = {};
    filterValues["filterStatusValue"] = this.filterStatusValue;
    filterValues["filterSpecialityValue"] = this.filterSpecialityValue;
    filterValues["filterProviderValue"] = this.filterProviderValue;
    filterValues["filterVisitTypeValue"] = this.filterVisitTypeValue;
    filterValues["filterStartDateValue"] = this.filterStartDateValue;
    filterValues["filterEndDateValue"] = this.filterEndDateValue;
    filterValues["filterRoleValue"] = this.filterRoleValue;
    filterValues["filterCityValue"] = this.filterCityValue;
    filterValues["filterUpdatedByValue"] = this.filterUpdatedByValue;
    filterValues["filterPatientValue"] = this.filterPatientValue;
    filterValues["filterPatientEmailValue"] = this.filterPatientEmailValue;
    filterValues["filterInsuranceValue"] = this.filterInsuranceValue;
    filterValues["filterClinicValue"] = this.filterClinicValue;
    filterValues["filterTransactionForValue"] = this.filterTransactionForValue;
    filterValues["filterTransactionTypeValue"] =
      this.filterTransactionTypeValue;
    filterValues["filterProviderTypeValue"] = this.filterProviderTypeValue;
    filterValues["filterMultiselectProviderValue"] =
      this.filterMultiselectProviderValue;
    this.filterData.emit(filterValues);
  }

  /**
   * Method to change filter data
   */
  public changeFilter() {
    this.setFilterData();
  }

  /**
   * Method to apply filter
   */
  public applyFilter() {
    if (
      this.filterSpecialityValue > 0 ||
      this.filterStatusValue > 0 ||
      this.filterProviderValue !== null ||
      this.filterVisitTypeValue !== 0 ||
      (this.filterStartDateValue !== "" && this.filterEndDateValue !== "") ||
      this.filterRoleValue > 0 ||
      this.filterCityValue > 0 ||
      this.filterUpdatedByValue !== null ||
      this.filterPatientValue !== null ||
      this.filterPatientEmailValue !== "" ||
      this.filterInsuranceValue !== 0 ||
      this.filterClinicValue !== null ||
      this.filterTransactionForValue > 0 ||
      this.filterTransactionTypeValue > 0 ||
      this.filterProviderTypeValue !== "" ||
      this.filterMultiselectProviderValue.length > 0
    ) {
      this.applyFilterData.emit(true);
    }
  }

  /**
   * Method to clear filter
   */
  public clearFilter() {
    if (
      this.filterSpecialityValue !== 0 ||
      this.filterStatusValue !== 0 ||
      this.filterProviderValue !== null ||
      this.filterVisitTypeValue !== 0 ||
      this.filterStartDateValue !== "" ||
      this.filterEndDateValue !== "" ||
      this.filterRoleValue !== 0 ||
      this.filterStateValue !== 0 ||
      this.filterCityValue !== 0 ||
      this.filterUpdatedByValue !== null ||
      this.filterPatientValue !== null ||
      this.filterPatientEmailValue !== "" ||
      this.filterClinicValue !== null ||
      this.filterInsuranceValue !== 0 ||
      this.filterTransactionForValue > 0 ||
      this.filterTransactionTypeValue > 0 ||
      this.filterProviderTypeValue !== "all" ||
      this.filterMultiselectProviderValue.length > 0
    ) {
      this.filterSpecialityValue = 0;
      this.filterStatusValue = 0;
      this.filterProviderValue = null;
      this.filterVisitTypeValue = 0;
      this.filterStartDateValue = "";
      this.filterEndDateValue = "";
      this.filterRoleValue = 0;
      this.filterStateValue = 0;
      this.filterCityValue = 0;
      this.filterUpdatedByValue = null;
      this.filterPatientValue = null;
      this.filterPatientEmailValue = "";
      this.filterClinicValue = null;
      this.filterInsuranceValue = 0;
      this.filterTransactionForValue = 0;
      this.filterTransactionTypeValue = 0;
      this.filterProviderTypeValue = "all";
      this.filterMultiselectProviderValue = [];

      this.cityList = [];
      this.setFilterData();
      this.applyFilterData.emit(true);
    }
  }

  /**
   * Method to get speciality data
   */
  public getSpeciality() {
    this._commonService.getSpeciality().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.specialityList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get medical provider data
   */
  public getAllProviders() {
    this._clinicService.getAllProviderByClinic().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.providerList = data;
          this.filteredProviderList = this.providerList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to search providers
   */
  public searchProvider(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.providerList.filter((x: any) => {
      if (x.name.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredProviderList = temp;
  }

  /**
   * Method to change provider
   */
  public changeProvider(userId: any) {
    this.filterVisitTypeValue = 0;
    this.getVisitTypeByProvider(userId);
    this.setFilterData();
  }

  /**
   * Method to get visit type by provider
   */
  public getVisitTypeByProvider(userId: any) {
    this._clinicService.getVisitTypeByProviderId(userId).subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.visitTypeList = data;
        } else {
          this.visitTypeList = [];
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get state data
   */
  public getState() {
    this._commonService.getState(1).subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.filterCityValue = 0;
          this.cityList = [];
          this.stateList = data;
          this.filteredStateList = this.stateList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get city data
   */
  public getCity(stateId: number) {
    this._commonService.getCity(stateId).subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.cityList = data;
          this.filteredCityList = this.cityList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to change state dropdown
   */
  public changeState(stateId: number) {
    this.filterCityValue = 0;
    this.cityList = [];
    this.getCity(stateId);
  }

  /**
   * Method to get all office staff data
   */
  public getAllOfficeStaff() {
    this._superAdminService.getAllAdminOfficeStaff().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.updatedByUserList = data;
          this.filteredupdatedByUserList = this.updatedByUserList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get all patients data
   */
  public getAllPatients() {
    this._clinicService.getAllPatientByClinic().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.patientList = data;
          this.filteredPatientList = this.patientList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to search patients
   */
  public searchPatient(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.patientList.filter((x: any) => {
      if (x.name.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredPatientList = temp;
  }

  /**
   * Method to search patients email
   */
  public searchPatientEmail(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.patientList.filter((x: any) => {
      if (x.email.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredPatientList = temp;
  }

  /**
   * Method to get insurance company
   */
  public getInsuranceCompany() {
    this._clinicService.getInsuranceCompany().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.insuranceList = data;
        } else {
          this.insuranceList = [];
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get all clinics data
   */
  public getAllClinics() {
    this._superAdminService.getAllClinicDetails().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.clinicList = data;
          this.filteredClinicList = this.clinicList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to search clinic
   */
  public searchClinic(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.clinicList.filter((x: any) => {
      if (x.name.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredClinicList = temp;
  }

  /**
   * Method to change provider type
   */
  public changeProviderType() {
    this.setFilterData();
  }

  /**
   * Method to change multiselect provider
   */
  public changeMultiselectProvider() {
    this.setFilterData();
  }

  /**
   * Method to search states
   */
  public searchStates(event: any) {
    const val = event.target.value.toLowerCase();
    const tempStatesList = this.stateList.filter((x: any) => {
      if (x.stateName.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredStateList = tempStatesList;
  }

  /**
   * Method to search cities
   */
  public searchCities(event: any) {
    const val = event.target.value.toLowerCase();
    const tempCityList = this.cityList.filter((x: any) => {
      if (x.cityName.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredCityList = tempCityList;
  }

  /**
   * Method to search updated by
   */
  public searchUpdatedBy(event: any) {
    const val = event.target.value.toLowerCase();
    const tempupdatedByUserList = this.updatedByUserList.filter((x: any) => {
      if (x.name.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredupdatedByUserList = tempupdatedByUserList;
  }
}

export class SignUpModel {
    userId: string;
    email: string;
    clinicName: string;
    phoneCode: string;
    phoneNo: string;
    password: string;
    documents: any;

    constructor() {
        this.userId = "";
        this.email = "";
        this.clinicName = "";
        this.phoneCode = "";
        this.phoneNo = "";
        this.password = "";
        this.documents = [];
    }
}

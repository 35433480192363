import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/services/common.service";
import { CustomPageTitleStrategy } from "src/app/services/custom-page-title-strategy";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { ClinicService } from "../../services/clinic.service";

@Component({
  selector: "app-subscription-details",
  templateUrl: "./subscription-details.component.html",
  styleUrls: ["./subscription-details.component.css"],
})
export class SubscriptionDetailsComponent implements OnInit {
  currentSubscriptionPlan: any = "";
  currentTimeZoneName: any;

  constructor(
    private _customPageTitleStrategy: CustomPageTitleStrategy,
    private _commonService: CommonService,
    private _translate: TranslateService,
    private _toastrService: ToastrNotificationService,
    private _clinicService: ClinicService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.currentTimeZoneName = this._commonService.getTimeZoneShortName();
    let paymentResult = this.route.snapshot.queryParamMap.get("payment");
    if (paymentResult && paymentResult === "success") {
      this._toastrService.showSuccess(
        "subscription.addSubscriptionSuccessMessage"
      );

      // Remove query params
      this.router.navigate([], {
        queryParams: {
          payment: null,
        },
        queryParamsHandling: "merge",
      });
    }
  }

  ngOnInit(): void {
    this.getClinicSubscriptionPlan();
  }

  /**
   * Method to get clinic subscription plan
   */
  public getClinicSubscriptionPlan() {
    this._clinicService.getClinicSubscriptionPlan().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.currentSubscriptionPlan = data;
        } else {
          this.router.navigate(["/upgrade-subscription"]);
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { LoginComponent } from "./components/login/login.component";
import { UnAuthorizationComponent } from "./components/unauthorization/unauthorization.component";
import { NotFoundErrorComponent } from "./components/not-found-error/not-found-error.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { ClinicProfileComponent } from "./modules/clinic/components/clinic-profile/clinic-profile.component";
import { UserListComponent } from "./modules/user/components/user-list/user-list.component";
import { AuthGuardService } from "./services/auth-guard.service";
import { SuperAdminLoginComponent } from "./modules/super-admin/components/login/login.component";
import { SuperAdminForgotPasswordComponent } from "./modules/super-admin/components/forgot-password/forgot-password.component";
import { SuperAdminResetPasswordComponent } from "./modules/super-admin/components/reset-password/reset-password.component";
import { ManageClinicComponent } from "./modules/super-admin/components/manage-clinic/manage-clinic.component";
import { RoleGuardService } from "./services/role-guard.service";
import { SettingsComponent } from "./modules/super-admin/components/settings/settings.component";
import { OfficestaffUserListComponent } from "./modules/super-admin/components/officestaff-user-list/officestaff-user-list.component";
import { ClinicSettingsComponent } from "./modules/clinic/components/clinic-settings/clinic-settings.component";
import { ProviderProfileComponent } from "./modules/clinic/components/provider-profile/provider-profile.component";
import { SchedulerListComponent } from "./modules/clinic/components/scheduler-list/scheduler-list.component";
import { SubscriptionDetailsComponent } from "./modules/clinic/components/subscription-details/subscription-details.component";
import { UpgradeSubscriptionComponent } from "./modules/clinic/components/upgrade-subscription/upgrade-subscription.component";
import { UserTypeEnum } from "./enums/userTypeEnum";
import { AnonymousGuardService } from "./services/anonymous-guard.service";
import { AppointmentListComponent } from "./modules/appointment/components/appointment-list/appointment-list.component";
import { NotificationListComponent } from "./modules/notifications/components/notification-list/notification-list.component";
import { TransactionHistoryListComponent } from "./modules/transaction/components/transaction-history-list/transaction-history-list.component";
import { StaticContentListComponent } from "./modules/super-admin/components/static-content-list/static-content-list.component";
import { HomeComponent } from "./modules/live-consultation/components/home/home.component";
import { AddEditStaticContentComponent } from "./modules/super-admin/components/add-edit-static-content/add-edit-static-content.component";
import { ClinicDashboardComponent } from "./modules/clinic/components/clinic-dashboard/clinic-dashboard.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";

// sets up routes constant where you define your routes
const routes: Routes = [
  {
    path: "",
    component: ClinicDashboardComponent,
    title: "dashboard.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [
        UserTypeEnum.Clinic,
        UserTypeEnum.ClinicStaff,
        UserTypeEnum.Provider,
      ],
      breadcrumb: [],
    },
  },
  {
    path: "login",
    component: LoginComponent,
    title: "login.Title",
    canActivate: [AnonymousGuardService],
  },
  {
    path: "login/:type",
    component: LoginComponent,
    title: "login.Title",
    canActivate: [AnonymousGuardService],
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    title: "forgotpassword.Title",
    canActivate: [AnonymousGuardService],
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    title: "resetpassword.Title",
    canActivate: [AnonymousGuardService],
  },
  {
    path: "dashboard",
    component: ClinicDashboardComponent,
    title: "dashboard.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [
        UserTypeEnum.Clinic,
        UserTypeEnum.ClinicStaff,
        UserTypeEnum.Provider,
      ],
      breadcrumb: [],
    },
  },
  { path: "", component: LoginComponent, canActivate: [AnonymousGuardService] },
  {
    path: "clinic-profile",
    component: ClinicProfileComponent,
    title: "clinicProfile.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [UserTypeEnum.Clinic],
      breadcrumb: [
        {
          label: "clinicProfile.clinicProfileHeadingText",
          url: "",
        },
      ],
    },
  },
  {
    path: "users",
    component: UserListComponent,
    title: "userList.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [UserTypeEnum.Clinic, UserTypeEnum.ClinicStaff],
      breadcrumb: [
        {
          label: "userList.userHeadingText",
          url: "",
        },
      ],
    },
  },
  {
    path: "not-found-error",
    component: NotFoundErrorComponent,
    title: "notFoundError.Title",
  },
  {
    path: "unauthorization",
    component: UnAuthorizationComponent,
    title: "noAuthorizationError.Title",
    canActivate: [AuthGuardService],
  },
  {
    path: "manage-clinic",
    component: ManageClinicComponent,
    title: "manageClinics.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [UserTypeEnum.SuperAdmin, UserTypeEnum.OfficeStaff],
      breadcrumb: [
        {
          label: "manageClinics.manageClinicsHeadingText",
          url: "",
        },
      ],
    },
  },
  {
    path: "settings",
    component: SettingsComponent,
    title: "settings.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [UserTypeEnum.SuperAdmin, UserTypeEnum.OfficeStaff],
      breadcrumb: [
        {
          label: "settings.settingsHeadingText",
          url: "",
        },
      ],
    },
  },
  {
    path: "clinic-settings",
    component: ClinicSettingsComponent,
    title: "settings.clinicSettingTitle",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [UserTypeEnum.Clinic],
      breadcrumb: [
        {
          label: "settings.clinicSettingsHeadingText",
          url: "",
        },
      ],
    },
  },
  {
    path: "officestaffusers",
    component: OfficestaffUserListComponent,
    title: "manageOfficeStaffUsers.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [UserTypeEnum.SuperAdmin, UserTypeEnum.OfficeStaff],
      breadcrumb: [
        {
          label: "userList.userHeadingText",
          url: "",
        },
      ],
    },
  },
  {
    path: "provider-profile",
    component: ProviderProfileComponent,
    title: "providerProfile.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [UserTypeEnum.Provider],
      breadcrumb: [
        {
          label: "providerProfile.updateProfileText",
          url: "",
        },
      ],
    },
  },
  {
    path: "scheduler",
    component: SchedulerListComponent,
    title: "scheduler.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [
        UserTypeEnum.Clinic,
        UserTypeEnum.Provider,
        UserTypeEnum.ClinicStaff,
      ],
      breadcrumb: [
        {
          label: "scheduler.schedulerHeadingText",
          url: "",
        },
      ],
    },
  },
  {
    path: "subscription",
    component: SubscriptionDetailsComponent,
    title: "subscription.subscriptionDetailsTitle",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [UserTypeEnum.Clinic, UserTypeEnum.ClinicStaff],
      breadcrumb: [
        {
          label: "subscription.subscriptionHeadingText",
          url: "",
        },
      ],
    },
  },
  {
    path: "upgrade-subscription",
    component: UpgradeSubscriptionComponent,
    title: "subscription.upgradeSubscriptionTitle",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [UserTypeEnum.Clinic, UserTypeEnum.ClinicStaff],
      breadcrumb: [
        {
          label: "subscription.subscriptionHeadingText",
          url: "subscription",
        },
        {
          label: "subscription.upgradeSubscriptionTitleText",
          url: "",
        },
      ],
    },
  },
  {
    path: "appointments",
    component: AppointmentListComponent,
    title: "appointment.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [
        UserTypeEnum.Clinic,
        UserTypeEnum.ClinicStaff,
        UserTypeEnum.Provider,
      ],
      breadcrumb: [
        {
          label: "appointment.appointmentHeadingText",
          url: "",
        },
      ],
    },
  },
  {
    path: "notifications",
    component: NotificationListComponent,
    title: "notification.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [
        UserTypeEnum.Clinic,
        UserTypeEnum.Provider,
        UserTypeEnum.SuperAdmin,
        UserTypeEnum.OfficeStaff,
      ],
      breadcrumb: [
        {
          label: "notification.notificationHeadingText",
          url: "",
        },
      ],
    },
  },
  {
    path: "transaction-history",
    component: TransactionHistoryListComponent,
    title: "transaction.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [
        UserTypeEnum.Clinic,
        UserTypeEnum.SuperAdmin,
        UserTypeEnum.OfficeStaff,
      ],
      breadcrumb: [
        {
          label: "transaction.transactionHistoryHeadingText",
          url: "",
        },
      ],
    },
  },
  {
    path: "static-content",
    component: StaticContentListComponent,
    title: "staticContent.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [UserTypeEnum.SuperAdmin, UserTypeEnum.OfficeStaff],
      breadcrumb: [
        {
          label: "staticContent.staticContentText",
          url: "",
        },
      ],
    },
  },
  {
    path: "add-edit-static-content",
    component: AddEditStaticContentComponent,
    title: "staticContent.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [UserTypeEnum.SuperAdmin, UserTypeEnum.OfficeStaff],
      breadcrumb: [
        {
          label: "staticContent.staticContentText",
          url: "static-content",
        },
        {
          label: "staticContent.addStaticContentText",
          url: "",
        },
      ],
    },
  },
  {
    path: "live-consultation/:appointmentId/:roomName/:patientName",
    component: HomeComponent,
    title: "liveConsultation.Title",
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      expectedRoles: [UserTypeEnum.Provider],
      breadcrumb: [
        {
          label: "liveConsultation.liveConsultationText",
          url: "",
        },
      ],
    },
  },
  {
    path: "superadmin",
    children: [
      {
        path: "",
        component: SuperAdminLoginComponent,
        title: "login.Title",
        canActivate: [AnonymousGuardService],
      },
      {
        path: "login",
        component: SuperAdminLoginComponent,
        title: "login.Title",
        canActivate: [AnonymousGuardService],
      },
      {
        path: "forgot-password",
        component: SuperAdminForgotPasswordComponent,
        title: "forgotpassword.Title",
        canActivate: [AnonymousGuardService],
      },
      {
        path: "reset-password",
        component: SuperAdminResetPasswordComponent,
        title: "resetpassword.Title",
        canActivate: [AnonymousGuardService],
      },
    ],
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
    title: "privacyPolicy.Title",
    canActivate: [AnonymousGuardService],
  },
  { path: "**", redirectTo: "/not-found-error" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

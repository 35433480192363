<div class="drawer-body">
  <div class="drawer-title">
    <img src="assets/images/view-icon.svg" alt="" />
    {{ "transaction.transactionDetailsText" | translate }}
  </div>
  <div class="drawer-content">
    <ul class="view-user">
      <li>
        <span>{{ "manageClinics.clinicNameLabelText" | translate }}</span>
        <p>{{ viewTransactionData.clinicName }}</p>
      </li>
      <li>
        <span>{{ "userList.emailLabelText" | translate }}</span>
        <p>{{ viewTransactionData.clinicEmail }}</p>
      </li>
      <li>
        <span>{{ "userList.contactLabelText" | translate }}</span>
        <p>{{ viewTransactionData.clinicContact }}</p>
      </li>
      <li>
        <span>{{ "transaction.transactionForText" | translate }}</span>
        <p>
          {{
            "common." +
              _globalFunction.getTransactionForById(
                viewTransactionData.transactionForId
              ) | translate
          }}
        </p>
      </li>

      <li
        *ngIf="
          viewTransactionData.transactionForId ===
            allTransactionFor.Subscription ||
          viewTransactionData.transactionForId ===
            allTransactionFor.SubscriptionRefund
        "
      >
        <span>{{ "subscription.StartDateText" | translate }}</span>
        <p>
          {{ viewTransactionData.startDate | date : "MMM dd, yyyy" }}
          {{ currentTimeZoneName }}
        </p>
      </li>
      <li
        *ngIf="
          viewTransactionData.transactionForId ===
            allTransactionFor.Subscription ||
          viewTransactionData.transactionForId ===
            allTransactionFor.SubscriptionRefund
        "
      >
        <span>{{ "scheduler.endDatePlaceholder" | translate }}</span>
        <p>
          {{ viewTransactionData.endDate | date : "MMM dd, yyyy" }}
          {{ currentTimeZoneName }}
        </p>
      </li>
      <li
        *ngIf="
          viewTransactionData.transactionForId ===
            allTransactionFor.Subscription ||
          viewTransactionData.transactionForId ===
            allTransactionFor.SubscriptionRefund
        "
      >
        <span>{{ "common.subscriptionPlanText" | translate }}</span>
        <p>{{ viewTransactionData.planName }}</p>
      </li>

      <li
        *ngIf="
          viewTransactionData.transactionForId ===
            allTransactionFor.Appointment ||
          viewTransactionData.transactionForId ===
            allTransactionFor.AppointmentRefund
        "
      >
        <span>{{ "appointment.patientNameText" | translate }}</span>
        <p>{{ viewTransactionData.patientName }}</p>
      </li>
      <li
        *ngIf="
          viewTransactionData.transactionForId ===
            allTransactionFor.Appointment ||
          viewTransactionData.transactionForId ===
            allTransactionFor.AppointmentRefund
        "
      >
        <span>{{ "appointment.medicalProviderNameText" | translate }}</span>
        <p>{{ viewTransactionData.medicalProviderName }}</p>
      </li>
      <li
        *ngIf="
          viewTransactionData.transactionForId ===
            allTransactionFor.Appointment ||
          viewTransactionData.transactionForId ===
            allTransactionFor.AppointmentRefund
        "
      >
        <span>{{ "appointment.appointmentDateTimeText" | translate }}</span>
        <p>
          {{
            viewTransactionData.appointmentDateTime
              | date : "MMM dd, yyyy " + timeFormat
          }}
          {{ currentTimeZoneName }}
        </p>
      </li>
      <li
        *ngIf="
          viewTransactionData.transactionForId ===
            allTransactionFor.Appointment ||
          viewTransactionData.transactionForId ===
            allTransactionFor.AppointmentRefund
        "
      >
        <span>{{ "transaction.appointmentDurationText" | translate }}</span>
        <p>
          {{ viewTransactionData.duration }}
        </p>
      </li>
      <li
        *ngIf="
          viewTransactionData.transactionForId ===
            allTransactionFor.Appointment ||
          viewTransactionData.transactionForId ===
            allTransactionFor.AppointmentRefund
        "
      >
        <span>{{ "settings.visitTypeLabelText" | translate }}</span>
        <p>{{ viewTransactionData.visitType }}</p>
      </li>
      <li>
        <span>{{ "transaction.amountFeesText" | translate }}</span>
        <p>${{ viewTransactionData.price }}</p>
      </li>
      <li>
        <span>{{ "transaction.transactionTypeText" | translate }}</span>
        <p>
          {{
            "common." +
              _globalFunction.getTransactionTypeById(
                viewTransactionData.transactionTypeId
              ) | translate
          }}
        </p>
      </li>
    </ul>
    <div class="drawer-action mt-4 pt-2">
      <button class="btn btn-secondary" (click)="closeViewTransactionPopup()">
        <img src="assets/images/white-back-icon.svg" alt="" class="me-2" />
        {{ "viewUser.backButtonText" | translate }}
      </button>
    </div>
  </div>
</div>

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
} from "@angular/core";
import { ClinicService } from "src/app/modules/clinic/services/clinic.service";
import { CommonService } from "src/app/services/common.service";
import { environment } from "src/environments/environment";
import { Clipboard } from "@angular/cdk/clipboard";
import { GlobalFunctions } from "src/app/app.global";

@Component({
  selector: "app-view-user",
  templateUrl: "./view-user.component.html",
  styleUrls: ["./view-user.component.css"],
})
export class ViewUserComponent implements OnInit, OnChanges {
  copyButtonImg: string = "assets/images/copy-icon.png";
  @Input() userType: string = "";
  @Input() viewUserData: any;
  @Output() showViewUserPopup = new EventEmitter<boolean>();
  isImagePopupOpen: boolean = false;
  selectedImageSrc: string = "";
  patientInsuranceFrontSide: string = "";
  patientInsuranceBackSide: string = "";
  medicationList: any = [];

  constructor(
    public _globalFunction: GlobalFunctions,
    private _clinicService: ClinicService,
    public _commonService: CommonService,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (
      this.userType === "Patients" &&
      this.viewUserData &&
      this.viewUserData.userId
    )
      this.getPatientDetails(this.viewUserData.userId);
  }

  /**
   * Method to close view user section
   */
  public closeViewUserPopup() {
    this.showViewUserPopup.emit(false);
  }

  /**
   * Method to get patient details
   */
  public getPatientDetails(patientUserId: any) {
    this._clinicService.getPatientDetails(patientUserId).subscribe({
      next: (data) => {
        if (data && data !== null) {
          if (data.parsonalInformation.gender === 1)
            this.viewUserData.patientGenderValue = "Male";
          else if (data.parsonalInformation.gender === 2)
            this.viewUserData.patientGenderValue = "Female";
          else this.viewUserData.patientGenderValue = "Other";

          this.viewUserData.patientUserId = this.viewUserData.userId;
          this.viewUserData.patientPhoto =
            data.parsonalInformation.profilePicturePath;
          this._globalFunction.setPatientProfilePhoto(this.viewUserData);

          this.viewUserData.patientParsonalInformation =
            data.parsonalInformation;
          this.viewUserData.emergencyInformation = data.emergencyInformation;
          this.viewUserData.healthInformation = data.healthInformation;
          this.viewUserData.insuranceInformation = data.insuranceInformation;
          this.viewUserData.pharmacyInformation = data.pharmacyInformation;
          this.patientInsuranceFrontSide =
            this.viewUserData.insuranceInformation.insuranceCardFrontPath != ""
              ? environment.documentPathUrl +
                patientUserId +
                "/" +
                this.viewUserData.insuranceInformation.insuranceCardFrontPath
              : "";
          this.patientInsuranceBackSide =
            this.viewUserData.insuranceInformation.insuranceCardBackPath != ""
              ? environment.documentPathUrl +
                patientUserId +
                "/" +
                this.viewUserData.insuranceInformation.insuranceCardBackPath
              : "";
          this.medicationList =
            this.viewUserData.healthInformation.currenMedications != ""
              ? this.viewUserData.healthInformation.currenMedications.split(
                  "#_$_@_||_@_$_#"
                )
              : [];
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  openImagePopup(imageSrc: string) {
    this.selectedImageSrc = imageSrc;
    this.isImagePopupOpen = true;
  }

  closeImagePopup() {
    this.isImagePopupOpen = false;
    this.selectedImageSrc = "";
  }

  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
    this.copyButtonImg = "assets/images/right-sign-icon.png";
    setTimeout(() => {
      this.copyButtonImg = "assets/images/copy-icon.png";
    }, 500);
  }
}

<div class="error-page">
  <div class="error-content">
    <div class="error-page-img">
      <img src="assets/images/404-img.png" alt="" />
    </div>
    <div class="error-text">
      <h3>{{ "notFoundError.notFoundErrorHeadingText" | translate }}</h3>
      <p>
        {{ "notFoundError.notFoundErrorText" | translate }}
      </p>
      <button class="btn btn-primary text-uppercase" (click)="goToHomePage()">
        <img src="assets/images/back-icon.svg" alt="" class="me-2" />
        {{ "common.backToHomeButtonText" | translate }}
      </button>
    </div>
  </div>
</div>

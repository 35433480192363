export class StaticContentModel {
  staticContentId: string;
  pageTitle: string;
  content: string;

  constructor() {
    this.staticContentId = "";
    this.pageTitle = "";
    this.content = "";
  }
}

export class ClinicModel {
    clinicName: string;
    phoneCode: string;
    phoneNumber: string;
    email: string;
    einNumber: string;
    clinicCode: string;
    countryId: string;
    stateId: string;
    cityId: string;
    address: string;
    aboutClinic: string;
    clinicPhotos: any;
    clinicDocuments: any;
    
    constructor() {
        this.clinicName = "";
        this.phoneCode = "";
        this.phoneNumber = "";
        this.email = "";
        this.einNumber = "";
        this.clinicCode = "";
        this.countryId = "";
        this.stateId = "";
        this.cityId = "";
        this.address = "";
        this.aboutClinic = "";
        this.clinicPhotos = [];
        this.clinicDocuments = [];
    }
}

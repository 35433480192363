import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-view-clinic",
  templateUrl: "./view-clinic.component.html",
  styleUrls: ["./view-clinic.component.css"],
})
export class ViewClinicComponent {
  @Input() tabName: string = "";
  @Input() viewClinicData: any;
  @Output() showViewClinicPopup = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  /**
   * Method to close view clinic section
   */
  public closeViewClinicPopup() {
    this.showViewClinicPopup.emit(false);
  }
}

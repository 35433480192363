import { Component, OnInit, ViewChild } from "@angular/core";
import { OfficestaffUserComponent } from "../officestaff-user/officestaff-user.component";

@Component({
  selector: "app-officestaff-user-list",
  templateUrl: "./officestaff-user-list.component.html",
  styleUrls: ["./officestaff-user-list.component.css"],
})
export class OfficestaffUserListComponent implements OnInit {
  filter: boolean = false;
  showFilterList: any = ["Role", "Status"];
  rightDrawer: boolean = false;
  searchText: string = "";
  searchString: string = "";
  addEditOfficeStaffUser: boolean = false;
  viewOfficeStaffUser: boolean = false;
  refreshOfficeStaffGrid: boolean = false;
  viewOfficeStaffData: any;
  editOfficeStaffId: any;
  filterStatusValue: number = 0;
  filterRoleValue: number = 0;
  @ViewChild(OfficestaffUserComponent, { static: false })
  officeStaffUsers!: OfficestaffUserComponent;
  breadcrumbs: any = [];

  constructor() {}

  ngOnInit(): void {}

  /**
   * Method to toggle filter section
   */
  public filterClick() {
    this.filter = !this.filter;
  }

  /**
   * Method to get filter data
   */
  public getFilterData(data: any) {
    this.filterRoleValue = data.filterRoleValue;
    this.filterStatusValue = data.filterStatusValue;
  }

  /**
   * Method to apply filter data
   */
  public applyFilterData() {
    this.loadOfficeStaffUserGrid(true);
  }

  /**
   * Method to open add office staff user section
   */
  public addOfficeStaffUser() {
    this.rightDrawer = !this.rightDrawer;
    this.addEditOfficeStaffUser = true;
    this.viewOfficeStaffUser = false;
    this.editOfficeStaffId = "";
  }

  /**
   * Method to open view office staff user section
   */
  public viewOfficeStaffUserPopup(data: any) {
    this.rightDrawer = !this.rightDrawer;
    this.addEditOfficeStaffUser = false;
    this.viewOfficeStaffUser = true;
    this.viewOfficeStaffData = data;
  }

  /**
   * Method to open edit office staff user section
   */
  public editOfficeStaffUserPopup(userId: any) {
    this.addEditOfficeStaffUser = true;
    this.viewOfficeStaffUser = false;
    this.editOfficeStaffId = userId;
  }

  /**
   * Method to show/hide office staff section
   */
  public showHideOfficeStaffPopup(showOfficeStaffPopup: any): void {
    this.rightDrawer = showOfficeStaffPopup;
    this.addEditOfficeStaffUser = showOfficeStaffPopup ? true : false;
    this.viewOfficeStaffUser = false;
  }

  /**
   * Method to load office staff data after add/update user
   */
  public loadOfficeStaffData(isRefreshData: boolean): void {
    this.refreshOfficeStaffGrid = isRefreshData;
  }

  /**
   * Method to search data
   */
  public searchDataByKeyword() {
    this.refreshOfficeStaffGrid = false;
    this.searchString = this.searchText;
  }

  /**
   * Method to load office staff user grid
   */
  public loadOfficeStaffUserGrid(applyFilter: boolean = false) {
    this.officeStaffUsers.filterRole = this.filterRoleValue;
    this.officeStaffUsers.filterStatus = this.filterStatusValue;
    this.officeStaffUsers.loadOfficeStaffUsersData(applyFilter);
  }
}

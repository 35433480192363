import { DatePipe } from "@angular/common";
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectionStrategy,
} from "@angular/core";
import * as moment from "moment-timezone";
import { GlobalFunctions } from "src/app/app.global";
import { HoverMenuDirective } from "src/app/directives/hover-menu.directive";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { CommonService } from "src/app/services/common.service";
@Component({
  selector: "app-clinic-dashboard-week-view",
  templateUrl: "./clinic-dashboard-week-view.component.html",
  styleUrls: ["./clinic-dashboard-week-view.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicDashboardWeekViewComponent implements OnInit, OnChanges {
  @Input() viewType: string = "";
  weekDayArray: any = [];
  filteredDashboardDataList: any = [];
  @Input() dashboardDataList: any = [];
  @Input() providerList: any = [];
  @Input() selectedDate: any;
  @Input() filterMultiselectProviderValue: any = [];
  @Input() refreshDataView: boolean = false;
  @Input() searchData: string = "";
  @Output() viewAppointmentData = new EventEmitter<any>();
  @Output() addAppointmentBySlot = new EventEmitter<any>();
  @Output() pasteAppointmentData = new EventEmitter<any>();
  cutPasteAppointentData: any = null;
  cutAppointentData: any = null;
  @ViewChild(HoverMenuDirective) hoverMenu!: HoverMenuDirective;
  currentTimeZoneName: any;
  timeFormat: any;
  timeFormatCaps: any;
  constructor(
    public _commonService: CommonService,
    public _globalFunction: GlobalFunctions,
    private _toastrService: ToastrNotificationService,
    private datePipe: DatePipe
  ) {
    this.timeFormat = this._commonService.getTimeFormat();
    this.timeFormatCaps = this._commonService.getTimeFormat(true);
    this.currentTimeZoneName = this._commonService.getTimeZoneShortName();
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.filteredDashboardDataList = this.dashboardDataList;
    if (this.refreshDataView) this.setWeekDataView();
    if (this.searchData) {
      this.filteredDashboardDataList = this.dashboardDataList.filter(
        (x: any) =>
          x.patientName !== null &&
          x.patientName.toLowerCase().includes(this.searchData.toLowerCase())
      );
    }
  }

  /**
   * Method to set week data view
   */
  public setWeekDataView() {
    if (this.viewType === "week") {
      //get current week dates
      var currentDay = new Date(this.selectedDate);
      var firstDayOfWeek = currentDay.getDate() - currentDay.getDay();
      var weekFirstday = new Date(currentDay.setDate(firstDayOfWeek));
      this.weekDayArray = [];
      for (let i = 0; i < 7; i++) {
        var date = new Date(weekFirstday);
        date.setDate(date.getDate() + i);
        this.weekDayArray.push(date);
      }

      //filter dashboard data
      this.dashboardDataList.forEach((element: any) => {
        // let startTime = moment(element.appointmentDateTime).format("HH:mm");
        // let utcStartTime: any = moment(
        //   this.datePipe.transform(new Date(), "MM/dd/yyyy") + " " + startTime
        // ).tz(moment.tz.guess());
        // element.formattedStartTime = moment(utcStartTime._d).format("HH:mm");
        element.formattedStartTime = moment(element.appointmentDateTime).format(
          "HH:mm"
        );

        // let endTime = moment(element.appointmentDateEndTime).format("HH:mm");
        // let utcEndTime: any = moment(
        //   this.datePipe.transform(new Date(), "MM/dd/yyyy") + " " + endTime
        // ).tz(moment.tz.guess());
        // element.formattedEndTime = moment(utcEndTime._d).format("HH:mm");
        element.formattedEndTime = moment(
          element.appointmentDateEndTime
        ).format("HH:mm");
        element.patientNameInitials = element.patientName
          ? this._globalFunction.getNameInitials(
              element.patientFirstName,
              element.patientLastName
            )
          : "";

        this._globalFunction.setPatientProfilePhoto(element);

        element.isAppointmentPastDate =
          this._commonService.isAppointmentPastDate(
            element.appointmentDateTime
          );
      });

      //sort data by slots time
      this.dashboardDataList.sort((a: any, b: any) =>
        a.formattedStartTime > b.formattedStartTime
          ? 1
          : b.formattedStartTime > a.formattedStartTime
          ? -1
          : 0
      );

      //Set time format
      this.dashboardDataList.forEach((element: any) => {
        element.formattedStartTime = moment(element.appointmentDateTime).format(
          this.timeFormatCaps
        );
        element.formattedEndTime = moment(
          element.appointmentDateEndTime
        ).format(this.timeFormatCaps);
      });
    }
  }

  /**
   * Method to get dashboard data by provider and date
   */
  public getDashboardDataByProviderAndDate(providerUserId: any, date: any) {
    let dashboardData = [];
    if (
      this.filteredDashboardDataList &&
      this.filteredDashboardDataList.length > 0
    ) {
      dashboardData = this.filteredDashboardDataList.filter(
        (x: any) =>
          x.providerUserId === providerUserId &&
          this.datePipe.transform(x.appointmentDateTime, "MM/dd/yyyy") ===
            this.datePipe.transform(date, "MM/dd/yyyy")
      );
    }
    return dashboardData;
  }

  /**
   * Method to view appointment
   */
  public viewAppointment(data: any) {
    if (data && data.appointmentId && data.appointmentId !== null) {
      this.viewAppointmentData.emit(data);
    }
  }

  /**
   * Method to add appointment
   */
  public addAppointment(data: any) {
    if (
      data &&
      !data.appointmentId &&
      !data.isBlocked &&
      !this._commonService.isAppointmentPastDate(data.appointmentDateTime)
    ) {
      this.addAppointmentBySlot.emit(data);
    }
  }

  /**
   * Method to cut the appointment
   */
  public cutAppointment(data: any) {
    this.cutAppointentData = data;
    this._toastrService.showInfo("common.cutAppointmentMessage");
  }

  /**
   * Method to paste the appointment to other time slot
   */
  public pasteAppointment(data: any) {
    this.cutAppointentData.pasteSlotData = data;
    this.pasteAppointmentData.emit(this.cutAppointentData);
    this.cutPasteAppointentData = null;
    this.cutAppointentData = null;
  }

  /**
   * Method to clear the cut appointment data
   */
  public clearCutAppointment() {
    this.cutPasteAppointentData = null;
    this.cutAppointentData = null;
  }

  /**
   * Method to drag start on appointment
   */
  public onDragStart(event: DragEvent, appointmentData: any) {
    this.hoverMenu.clearTimer();
    this.cutAppointentData = appointmentData;
  }

  /**
   * Method to drag over on appointment
   */
  public onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  /**
   * Method to drop the dragged appointment data on empty slot
   */
  public onDrop(event: DragEvent, dropAppointmentSlot: any) {
    this.cutAppointentData.pasteSlotData = dropAppointmentSlot;
    this.pasteAppointmentData.emit(this.cutAppointentData);
    this.cutPasteAppointentData = null;
    this.cutAppointentData = null;
  }
}

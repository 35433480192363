import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import * as moment from "moment-timezone";
import { environment } from "src/environments/environment";
import { AppointmentStatusEnum } from "./enums/appointmentStatusEnum";
import { clinicApiList } from "./enums/clinicApiEnum";
import { TransactionForEnum } from "./enums/transactionForEnum";
import { UserTypeEnum } from "./enums/userTypeEnum";

@Injectable()
export class GlobalFunctions {
  constructor(private datePipe: DatePipe) {}

  allowNumbersOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  allowNumbersWithDotOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      return false;
    }
    return true;
  }

  allowDecimalNumbersOnly(event: KeyboardEvent) {
    const regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
    const specialKeys: Array<string> = [
      "Backspace",
      "Tab",
      "End",
      "Home",
      "ArrowLeft",
      "ArrowRight",
      "Del",
      "Delete",
    ];
    // Allow Backspace, tab, end, and home keys
    if (specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let element: any = event.currentTarget;
    let current: any = element.value;
    const position = element.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key == "Decimal" ? "." : event.key,
      current.slice(position),
    ].join("");
    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }

  getUsersApiPath(userType: string, editUserId: any): string {
    let apiPath = "";
    switch (userType) {
      case "Patients":
        if (editUserId && editUserId !== null) {
          apiPath = clinicApiList.updatePatient;
        } else {
          apiPath = clinicApiList.addPatient;
        }
        break;
      case "Medical Providers":
        if (editUserId && editUserId !== null) {
          apiPath = clinicApiList.updateMedicalProvider;
        } else {
          apiPath = clinicApiList.addMedicalProvider;
        }
        break;
      case "Staff":
        if (editUserId && editUserId !== null) {
          apiPath = clinicApiList.updateStaff;
        } else {
          apiPath = clinicApiList.addStaff;
        }
        break;
      default:
      // code block
    }
    return apiPath;
  }

  getUsersApiSuccessMessages(userType: string, editUserId: any): string {
    let successMessage = "";
    switch (userType) {
      case "Patients":
        if (editUserId && editUserId !== null) {
          successMessage = "addUser.updatePatientSuccessMessage";
        } else {
          successMessage = "addUser.addPatientSuccessMessage";
        }
        break;
      case "Medical Providers":
        if (editUserId && editUserId !== null) {
          successMessage = "addUser.updateProviderSuccessMessage";
        } else {
          successMessage = "addUser.addProviderSuccessMessage";
        }
        break;
      case "Staff":
        if (editUserId && editUserId !== null) {
          successMessage = "addUser.updateStaffSuccessMessage";
        } else {
          successMessage = "addUser.addStaffSuccessMessage";
        }
        break;
      default:
      // code block
    }
    return successMessage;
  }

  /**
   * Method to remove space
   */
  public removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }

  /**
   * Method to get UTC time
   */
  // public getUTCTime(time: any) {
  //   var utcTime = "";
  //   if (time !== "") {
  //     var timeZoneOffset = new Date().getTimezoneOffset();
  //     let currentTime: any = new Date(
  //       this.datePipe.transform(new Date(), "MM/dd/yyyy") + " " + time
  //     ).getTime();
  //     var timeInUTCHours = String(
  //       new Date(currentTime + timeZoneOffset * 60 * 1000).getHours()
  //     ).padStart(2, "0");
  //     var timeInUTCMinutes = String(
  //       new Date(currentTime + timeZoneOffset * 60 * 1000).getMinutes()
  //     ).padStart(2, "0");
  //     utcTime = timeInUTCHours + ":" + timeInUTCMinutes;
  //   }
  //   return utcTime;
  // }

  /**
   * Method to convert UTC time to local time
   */
  public convertUTCTimeToLocalTime(time: any) {
    let utcDate: any = new Date(
      this.datePipe.transform(new Date(), "MM/dd/yyyy") + " " + time
    );
    var currentTime = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000
    );
    var currentTimeHours = String(currentTime.getHours()).padStart(2, "0");
    var currentTimeMinutes = String(currentTime.getMinutes()).padStart(2, "0");
    return currentTimeHours + ":" + currentTimeMinutes;
  }

  /**
   * Method to convert UTC date time to local date time
   */
  public convertUTCDateTimeToLocalDateTime(datetime: any) {
    let utcDate: any = new Date(datetime);
    var currentTime = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000
    );
    var currentTimeHours = String(currentTime.getHours()).padStart(2, "0");
    var currentTimeMinutes = String(currentTime.getMinutes()).padStart(2, "0");
    return (
      this.datePipe.transform(new Date(datetime), "MM/dd/yyyy") +
      " " +
      currentTimeHours +
      ":" +
      currentTimeMinutes
    );
  }

  /**
   * Method to get UTC date
   */
  public getUTCDate(date: any) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }

  /**
   * Method to convert UTC date to local date
   */
  public convertUTCDateToLocalDate(date: any) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }

  /**
   * Method to get slot time
   */
  public getSlotTime(startTime: any, endTime: any) {
    var timeStart = startTime;
    var timeEnd = endTime;
    var slotTime = "";

    if (timeStart !== "" && timeEnd !== "") {
      let startTime: any = new Date(
        this.datePipe.transform(new Date(), "MM/dd/yyyy") + " " + timeStart
      ).getTime();
      let endTime: any = new Date(
        this.datePipe.transform(new Date(), "MM/dd/yyyy") + " " + timeEnd
      ).getTime();

      let timeDiff = endTime - startTime;
      let minutesDiff = Math.round(timeDiff / 1000 / 60);
      slotTime = minutesDiff > 0 ? minutesDiff + " min(s)" : "";
    }
    return slotTime;

    // var hours = minutes / 60;
    // var rhours = Math.floor(hours);
    // var minutes = (hours - rhours) * 60;
    // var rminutes = Math.round(minutes);
    // this.schedulerInfoModel.slotTime =
    //   rhours + " hour, " + rminutes + " minute.";
  }

  /**
   * Method to get sidebar menu
   */
  public getLeftMenu() {
    const menuList = [
      {
        name: "leftmenu.Dashboard",
        tooltip: "leftmenu.Dashboard",
        routerLink: "/dashboard",
        icon: "icon-dashboard nav-icon",
        allowedRoles: [
          UserTypeEnum.Clinic,
          UserTypeEnum.ClinicStaff,
          UserTypeEnum.Provider,
        ],
        headerType: "TOP",
      },
      {
        name: "leftmenu.Appointments",
        tooltip: "leftmenu.Appointments",
        routerLink: "/appointments",
        icon: "nav-icon icon-appointments",
        allowedRoles: [
          UserTypeEnum.Clinic,
          UserTypeEnum.ClinicStaff,
          UserTypeEnum.Provider,
        ],
        headerType: "TOP",
      },
      {
        name: "leftmenu.Users",
        tooltip: "leftmenu.Users",
        routerLink: "/users",
        icon: "nav-icon icon-users",
        allowedRoles: [UserTypeEnum.Clinic, UserTypeEnum.ClinicStaff],
        headerType: "TOP",
      },
      {
        name: "leftmenu.Users",
        tooltip: "leftmenu.Users",
        routerLink: "/officestaffusers",
        icon: "nav-icon icon-users",
        allowedRoles: [UserTypeEnum.SuperAdmin, UserTypeEnum.OfficeStaff],
        headerType: "TOP",
      },
      {
        name: "manageClinics.manageClinicsHeadingText",
        tooltip: "manageClinics.manageClinicsHeadingText",
        routerLink: "/manage-clinic",
        icon: "nav-icon manage-clinic",
        allowedRoles: [UserTypeEnum.SuperAdmin, UserTypeEnum.OfficeStaff],
        headerType: "TOP",
      },
      {
        name: "leftmenu.Notifications",
        tooltip: "leftmenu.Notifications",
        routerLink: "/notifications",
        icon: "nav-icon icon-notifications",
        allowedRoles: [
          UserTypeEnum.Clinic,
          UserTypeEnum.Provider,
          UserTypeEnum.SuperAdmin,
          UserTypeEnum.OfficeStaff,
        ],
        headerType: "TOP",
      },
      {
        name: "leftmenu.TransactionHistory",
        tooltip: "leftmenu.TransactionHistory",
        routerLink: "/transaction-history",
        icon: "nav-icon icon-transaction-history",
        allowedRoles: [
          UserTypeEnum.Clinic,
          UserTypeEnum.SuperAdmin,
          UserTypeEnum.OfficeStaff,
        ],
        headerType: "TOP",
      },

      {
        name: "common.clinicText",
        tooltip: "common.clinicText",
        routerLink: "/clinic-profile",
        icon: "nav-icon icon-profile",
        allowedRoles: [UserTypeEnum.Clinic],
        headerType: "BOTTOM",
      },
      {
        name: "leftmenu.Profile",
        tooltip: "leftmenu.Profile",
        routerLink: "/provider-profile",
        icon: "nav-icon icon-profile",
        allowedRoles: [UserTypeEnum.Provider],
        headerType: "BOTTOM",
      },
      {
        name: "scheduler.schedulerHeadingText",
        tooltip: "scheduler.schedulerHeadingText",
        routerLink: "/scheduler",
        icon: "nav-icon icon-appointments",
        allowedRoles: [
          UserTypeEnum.Clinic,
          UserTypeEnum.ClinicStaff,
          UserTypeEnum.Provider,
        ],
        headerType: "BOTTOM",
      },
      {
        name: "leftmenu.StaticContent",
        tooltip: "leftmenu.StaticContent",
        routerLink: "/static-content",
        icon: "nav-icon icon-static-content",
        allowedRoles: [UserTypeEnum.SuperAdmin, UserTypeEnum.OfficeStaff],
        headerType: "BOTTOM",
      },
      {
        name: "leftmenu.Settings",
        tooltip: "leftmenu.Settings",
        routerLink: "/settings",
        icon: "nav-icon icon-settings",
        allowedRoles: [UserTypeEnum.SuperAdmin, UserTypeEnum.OfficeStaff],
        headerType: "BOTTOM",
      },
      {
        name: "leftmenu.Settings",
        tooltip: "leftmenu.Settings",
        routerLink: "/clinic-settings",
        icon: "nav-icon icon-settings",
        allowedRoles: [UserTypeEnum.Clinic],
        headerType: "BOTTOM",
      },
      {
        name: "leftmenu.Subscription",
        tooltip: "leftmenu.Subscription",
        routerLink: "/subscription",
        icon: "nav-icon icon-subscription",
        allowedRoles: [UserTypeEnum.Clinic, UserTypeEnum.ClinicStaff],
        headerType: "BOTTOM",
      },
      // {
      //   name: "leftmenu.LiveConsultation",
      //   tooltip: "leftmenu.LiveConsultation",
      //   routerLink: "/live-consultation",
      //   icon: "nav-icon icon-transaction-history",
      //   allowedRoles: [UserTypeEnum.Provider],
      // },
    ];
    return menuList;
  }

  public getAppointmentStatus(status: number): string {
    let appointmentStatus = "";
    switch (status) {
      case AppointmentStatusEnum.Pending:
        appointmentStatus = "Pending";
        break;
      case AppointmentStatusEnum.Reschedule:
        appointmentStatus = "Reschedule";
        break;
      case AppointmentStatusEnum.Completed:
        appointmentStatus = "Completed";
        break;
      case AppointmentStatusEnum.Missed:
        appointmentStatus = "Missed";
        break;
      case AppointmentStatusEnum.Cancelled:
        appointmentStatus = "Canceled";
        break;
      default:
      // code block
    }
    return appointmentStatus;
  }

  public getTransactionForById(id: number): string {
    let transactionFor = "";
    switch (id) {
      case TransactionForEnum.Appointment:
        transactionFor = "Appointment";
        break;
      case TransactionForEnum.AppointmentRefund:
        transactionFor = "Refund";
        break;
      case TransactionForEnum.Subscription:
        transactionFor = "Subscription";
        break;
      case TransactionForEnum.SubscriptionRefund:
        transactionFor = "Subscription Refund";
        break;
      default:
      // code block
    }
    return transactionFor;
  }

  public getTransactionTypeById(id: number): string {
    let transactionType = "";
    switch (id) {
      case 1:
        transactionType = "Credit";
        break;
      case 2:
        transactionType = "Debit";
        break;
      default:
      // code block
    }
    return transactionType;
  }

  public handleInput(event: KeyboardEvent): void {
    event.stopPropagation();
  }

  public getEventsData(): any {
    let EventsData = [
      {
        EventId: 1,
        EventType: "NewAppointment",
        EventName: "New Appointment",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: true,
      },
      {
        EventId: 2,
        EventType: "Rescheduled",
        EventName: "Rescheduled",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: true,
      },
      {
        EventId: 3,
        EventType: "Cancelled",
        EventName: "Cancelled",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: true,
      },
      {
        EventId: 4,
        EventType: "AppointmentPaymentSuccess",
        EventName: "Appointment Payment Success",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: true,
      },
      {
        EventId: 5,
        EventType: "AppointmentPaymentFailed",
        EventName: "Appointment Payment Failed",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: true,
      },
      {
        EventId: 6,
        EventType: "AppointmentPaymentRefundSuccess",
        EventName: "Appointment Payment Refund Success",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: true,
      },
      {
        EventId: 7,
        EventType: "AppointmentPaymentRefundFailed",
        EventName: "Appointment Payment Refund Failed",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: true,
      },
      {
        EventId: 8,
        EventType: "ClinicSubscriptionPaid",
        EventName: "Clinic Subscription Paid",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 9,
        EventType: "ClinicSubscriptionRenewed",
        EventName: "Clinic Subscription Renewed",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 10,
        EventType: "ClinicSubscriptionRefund",
        EventName: "Clinic Subscription Refund",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 11,
        EventType: "ClinicSubscriptionRefundSuccess",
        EventName: "Clinic Subscription Refund Success",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 12,
        EventType: "ClinicSubscriptionRefundFailed",
        EventName: "Clinic Subscription Refund Failed",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 13,
        EventType: "ClinicRegistrationReceived",
        EventName: "Clinic Registration Received",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 14,
        EventType: "ClinicRegistrationApproved",
        EventName: "Clinic Registration Approved",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 15,
        EventType: "ClinicRegistrationRejected",
        EventName: "Clinic Registration Rejected",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 16,
        EventType: "RefundRequestFromClinic",
        EventName: "Refund Request From Clinic",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 17,
        EventType: "RefundIssuedToClinic",
        EventName: "Refund Issued To Clinic",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 18,
        EventType: "ClinicSuccessfullyRegistered",
        EventName: "Clinic Successfully Registered",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 19,
        EventType: "PatientSuccessfullyRegistered",
        EventName: "Patient Successfully Registered",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 20,
        EventType: "MedicalProviderSuccessfullyRegistered",
        EventName: "Medical Provider Successfully Registered",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: true,
      },
      {
        EventId: 21,
        EventType: "StaffMemberSuccessfullyRegistered",
        EventName: "Staff Member Successfully Registered",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 22,
        EventType: "InviteSentToPatient",
        EventName: "Invite Sent To Patient",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 23,
        EventType: "InviteSentToMedicalProvider",
        EventName: "Invite Sent To Medical Provider",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: true,
      },
      {
        EventId: 24,
        EventType: "InviteSentToStaffMember",
        EventName: "Invite Sent To Staff Member",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 25,
        EventType: "PaymentReceived",
        EventName: "Payment Received",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 26,
        EventType: "AppointmentPaymentRefund",
        EventName: "Appointment Payment Refund",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 27,
        EventType: "PaymentRefund",
        EventName: "Payment Refund",
        AllowToAdmin: true,
        AllowToClinic: true,
        AllowToProvider: true,
      },
      {
        EventId: 28,
        EventType: "Completed",
        EventName: "Completed",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: false,
      },
      {
        EventId: 29,
        EventType: "Missed",
        EventName: "Missed",
        AllowToAdmin: false,
        AllowToClinic: true,
        AllowToProvider: false,
      },
    ];

    return EventsData;
  }

  getDayHours(step: any, yourTime: any) {
    if (!step) {
      return [];
    }
    const slotArray = [];
    const startMinutes = yourTime ? this.howManyMinutesPassed(yourTime) : 0;
    const parsedSlotSize = parseInt(step.toString(), 10);

    for (let i = startMinutes; i < 24 * 60; i += parsedSlotSize) {
      slotArray.push({
        time: this.convertMinutesToTimeFormat(i),
        minutes: i,
      });
    }

    return [...slotArray];
  }

  howManyMinutesPassed(time: any) {
    const [hour, minutes] = time
      .split(":")
      .map((value: any) => parseInt(value, 10));
    return hour * 60 + minutes;
  }

  public convertMinutesToTimeFormat(mins: any) {
    let h: string | number = Math.floor(mins / 60);
    let m: string | number = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}:00`;
  }

  formatTime(time: any) {
    time = time.substr(0, 5);
    return time;
  }

  formatTimeTo12Hour(time: any) {
    const H = +time.substr(0, 2);
    const h = H % 12 || 12;
    const ampm = H < 12 || H === 24 ? " AM" : " PM";
    return h + time.substr(2, 3) + ampm;
  }

  // public isAppointmentPastDate(appointmentDateTime: any) {
  //   let isPast = true;
  //   let appointmentDate: any = this.datePipe.transform(
  //     appointmentDateTime,
  //     "MM/dd/yyyy"
  //   );
  //   let todayDate: any = this.datePipe.transform(new Date(), "MM/dd/yyyy");

  //   if (new Date(appointmentDate) > new Date(todayDate)) isPast = false;

  //   if (appointmentDate === todayDate) {
  //     let currentTime: any = this.datePipe.transform(new Date(), "HH:mm");
  //     let appointmentTime: any = this.datePipe.transform(
  //       appointmentDateTime + "z",
  //       "HH:mm"
  //     );
  //     isPast = appointmentTime < currentTime;
  //   }
  //   return isPast;
  // }

  /**
   * Method to join appointment by appointment date
   */
  // public joinAppointmentByAppointmentDate(
  //   appointmentDateTime: any,
  //   startTime: any,
  //   endTime: any
  // ) {
  //   let allowJoinAppointment = false;
  //   let appointmentDate: any = this.datePipe.transform(
  //     appointmentDateTime,
  //     "MM/dd/yyyy"
  //   );
  //   let todayDate: any = this.datePipe.transform(new Date(), "MM/dd/yyyy");

  //   if (appointmentDate === todayDate) {
  //     let currentDateTime: any = new Date();
  //     let appointentStartTime = this.convertUTCTimeToLocalTime(startTime);
  //     let appointentEndTime = this.convertUTCTimeToLocalTime(endTime);
  //     let appointentStartDateTime: any = moment(
  //       this.datePipe.transform(new Date(), "MM/dd/yyyy") +
  //         " " +
  //         appointentStartTime
  //     ).add(-15, "minutes");
  //     let appointentEndDateTime: any = moment(
  //       this.datePipe.transform(new Date(), "MM/dd/yyyy") +
  //         " " +
  //         appointentEndTime
  //     ).add(15, "minutes");

  //     allowJoinAppointment =
  //       currentDateTime >= appointentStartDateTime._d &&
  //       currentDateTime <= appointentEndDateTime._d;
  //   }
  //   return allowJoinAppointment;
  // }

  /**
   * Method to get name initials
   */
  public getNameInitials(firstName: string, lastName: string) {
    let nameInitials = firstName.substring(0, 1).toUpperCase();
    nameInitials += " " + lastName.charAt(0).toUpperCase() + lastName.slice(1);
    return nameInitials;
  }

  /**
   * Method to set patient profile photo
   */
  public setPatientProfilePhoto(element: any) {
    if (
      element &&
      element.patientUserId &&
      element.patientPhoto &&
      element.patientPhoto !== null
    )
      element.patientProfilePhoto =
        environment.documentPathUrl +
        element.patientUserId +
        "/" +
        element.patientPhoto;
    else element.patientProfilePhoto = "assets/images/profile-image.png";
    return element;
  }

  /**
   * Method to convert UTC datetime to local datetime
   */
  public convertUTCDateToLocalDatetime(datetime: any) {
    let localDatetime = new Date(datetime + "Z");
    return localDatetime;
  }

  /**
   * Method to convert local datetime to UTC datetime
   */
  public convertLocalDatetimeToUTCDatetime(datetime: any) {
    let isoDateString = new Date(datetime).toISOString();
    return isoDateString;
  }

  /**
   * Method to get slot duration
   */
  public getSlotDuration(startTime: any, endTime: any) {
    var timeStart = startTime;
    var timeEnd = endTime;
    var slotTime = 0;

    if (timeStart !== "" && timeEnd !== "") {
      let startTime: any = new Date(
        this.datePipe.transform(new Date(), "MM/dd/yyyy") + " " + timeStart
      ).getTime();
      let endTime: any = new Date(
        this.datePipe.transform(new Date(), "MM/dd/yyyy") + " " + timeEnd
      ).getTime();

      let timeDiff = endTime - startTime;
      let minutesDiff = Math.round(timeDiff / 1000 / 60);
      slotTime = minutesDiff > 0 ? minutesDiff : 0;
    }
    return slotTime;
  }

  /**
   * Method to add minutes to time
   */
  public addMinutesToTime(time: any, minsToAdd: any) {
    function D(J: any) {
      return (J < 10 ? "0" : "") + J;
    }
    var piece = time.split(":");
    var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

    return D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60);
  }
}

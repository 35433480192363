<div class="drawer-body">
  <div class="drawer-title">
    <img src="assets/images/view-icon.svg" alt="" />
    {{ "common.viewText" | translate }} {{ tabName }}
  </div>
  <div class="drawer-content">
    <ul class="view-user">
      <li>
        <span>{{ "manageClinics.clinicNameLabelText" | translate }}</span>
        <p>{{ viewClinicData.clinicName }}</p>
      </li>
      <li>
        <span>{{ "viewUser.contactNumberLabelText" | translate }}</span>
        <p>{{ viewClinicData.phoneNumber }}</p>
      </li>
      <li>
        <span>{{ "userList.emailLabelText" | translate }}</span>
        <p>{{ viewClinicData.email }}</p>
      </li>
      <li>
        <span>{{ "clinicProfile.EINNumberLabel" | translate }}</span>
        <p>{{ viewClinicData.einNumber }}</p>
      </li>
      <li>
        <span>{{ "common.CityLabel" | translate }}</span>
        <p>{{ viewClinicData.city }}</p>
      </li>
      <li>
        <span>{{ "clinicProfile.AddressLabel" | translate }}</span>
        <p>{{ viewClinicData.address }}</p>
      </li>
      <li>
        <span>{{ "clinicProfile.AboutClinicLabel" | translate }}</span>
        <p>{{ viewClinicData.aboutClinic }}</p>
      </li>
      <li>
        <span>{{ "manageClinics.clinicPhotosLabelText" | translate }}</span>
        <div class="col-md-12">
          <div class="form-group">
            <ul class="upload-photos">
              <div *ngFor="let photo of viewClinicData.photos">
                <li *ngIf="photo.fileBytes">
                  <span class="upload-photo-image"
                    ><img
                      src="data:image/png;base64,{{ photo.fileBytes }}"
                      alt=""
                  /></span>
                  <p>{{ photo.fileName }}</p>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </li>
    </ul>
    <div class="drawer-action mt-4 pt-2">
      <button class="btn btn-secondary" (click)="closeViewClinicPopup()">
        <img src="assets/images/white-back-icon.svg" alt="" class="me-2" />
        {{ "viewUser.backButtonText" | translate }}
      </button>
    </div>
  </div>
</div>

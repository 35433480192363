<div class="tabs-body">
  <div class="mat-elevation-z8 table-responsive static-content">
    <table mat-table [dataSource]="dataSource" class="table" matSort>
      <ng-container matColumnDef="pageTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "staticContent.pageTitleText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.pageTitle }}
        </td>
      </ng-container>

      <ng-container matColumnDef="content">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "staticContent.contentText" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [innerHtml]="element.content"
        ></td>
        <!-- <td mat-cell *matCellDef="let element">
          <ckeditor
            [editor]="Editor"
            [data]="element.content"
            [disabled]="true"
            [config]="{ toolbar: [] }"
          ></ckeditor>
        </td> -->
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{ "userList.actionLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="table-action">
            <a
              href="javascript:void(0)"
              (click)="this.deleteStaticContent(element)"
              ><img src="assets/images/delete-icon.svg" alt=""
            /></a>
            <a
              href="javascript:void(0)"
              (click)="this.editStaticContent(element)"
              ><img src="assets/images/edit-icon.svg" alt=""
            /></a>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          {{ "userList.noResultFoundText" | translate }}
        </td>
      </tr>
    </table>
  </div>
  <mat-paginator
    [hidden]="!showPaginator"
    #paginator
    style-paginator
    showFirstLastButtons
    itemsPerPageLabel="Page Size"
    [length]="totalRows"
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageChanged($event)"
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>

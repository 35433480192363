import { DocumentModel } from "src/app/models/documentModel";

export class ClinicProfileModel {
  einNumber: string;
  clinicCode: string;
  country: string;
  state: string;
  city: string;
  address: string;
  clinicPhoto: DocumentModel[];
  clinicInfo: string;
  isClinicDocuments: boolean;
  timezone: any;
  language: string;
  timeFormat: boolean;

  constructor() {
    this.einNumber = "";
    this.clinicCode = "";
    this.country = "";
    this.state = "";
    this.city = "";
    this.address = "";
    this.clinicPhoto = [];
    this.clinicInfo = "";
    this.isClinicDocuments = false;
    this.timezone = null;
    this.language = "";
    this.timeFormat = false;
  }
}

export class SchedulerModel {
  providerSchedulerId: string;
  providerUserId: string;
  startTime: string;
  endTime: string;
  slotTime: string;
  slotMinutes: string;
  startDate: string;
  endDate: string;
  visitType: string;
  excludeWeekend: boolean;
  blockSlot: boolean;

  constructor() {
    this.providerSchedulerId = "";
    this.providerUserId = "";
    this.startTime = "";
    this.endTime = "";
    this.slotTime = "";
    this.slotMinutes = "";
    this.startDate = "";
    this.endDate = "";
    this.visitType = "";
    this.excludeWeekend = false;
    this.blockSlot = false;
  }
}

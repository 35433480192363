<div class="drawer-body" *ngIf="appointmentSection">
  <div class="top-actions text-end">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="scheduleAppointment()"
    >
      <!-- <img src="assets/images/save-icon.svg" alt="" class="me-2" /> -->
      {{ appointmentButtonText | translate }}
    </button>
  </div>
  <div class="drawer-title">
    <img src="assets/images/add-icon.svg" alt="" />
    {{ appointentHeadingText | translate }}
  </div>
  <div class="drawer-content">
    <form
      [formGroup]="appointmentInfoValidation"
      (ngSubmit)="submitAppointment()"
    >
      <div class="appointment-bg">
        <div class="select-doctor">
          <div class="d-flex">
            <div class="lable-img">
              <img src="assets/images/icon-appointment-user.svg" alt="" />
            </div>
            <div class="form-group">
              <mat-form-field
                class="example-form-field"
                *ngIf="editAppointmentData !== null"
              >
                <input
                  matInput
                  [value]="
                    editAppointmentData.patientName +
                    ' - ' +
                    (editAppointmentData?.patientDOB | date : 'MMM dd, yyyy')
                  "
                  required
                  disabled
                />
              </mat-form-field>
              <mat-form-field *ngIf="editAppointmentData === null">
                <mat-select
                  placeholder="{{
                    'appointment.selectPatientText' | translate
                  }}"
                  formControlName="patientUserId"
                  [(ngModel)]="appointmentInfoModel.patientUser"
                  (selectionChange)="changePatient($event)"
                >
                  <input
                    type="text"
                    #searchPatientInput
                    (keyup)="searchPatient($event)"
                    (keydown)="_globalFunction.handleInput($event)"
                    placeholder="{{
                      'appointment.searchPatientText' | translate
                    }}"
                    class="search-input"
                  />
                  <mat-select-trigger>
                    {{
                      appointmentInfoModel.patientUser?.name +
                        " - " +
                        (appointmentInfoModel.patientUser?.dob
                          | date : "MMM dd, yyyy")
                    }}
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let patient of filteredPatientList"
                    [value]="patient"
                    ><span class="value">{{ patient?.name }}</span>
                    <span class="value"
                      >{{ patient?.dob | date : "MMM dd, yyyy" }}
                    </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div
                *ngIf="
                  appointmentInfoValidation.controls['patientUserId'].invalid &&
                  (appointmentInfoValidation.controls['patientUserId'].dirty ||
                    appointmentInfoValidation.controls['patientUserId']
                      .touched ||
                    formSubmitted)
                "
              >
                <mat-error
                  *ngIf="
                    appointmentInfoValidation
                      .get('patientUserId')
                      ?.hasError('required')
                  "
                  >{{
                    "appointment.selectPatientErrorText" | translate
                  }}</mat-error
                >
              </div>
            </div>
          </div>
        </div>
        <div class="select-doctor appointment-top-header">
          <div class="d-flex w-100">
            <div class="lable-img">
              <img src="assets/images/icon-appointment-doctor.svg" alt="" />
            </div>
            <div class="form-group">
              <mat-form-field
                class="pl-0 example-full-width"
                *ngIf="
                  userRole === userTypeEnum.Provider.toString() ||
                  (!editAppointmentData?.requestFromDashboard &&
                    editAppointmentData !== null)
                "
              >
                <input
                  matInput
                  [value]="selectedProviderName"
                  required
                  disabled
                />
              </mat-form-field>
              <mat-form-field
                class="pl-0"
                *ngIf="
                  userRole !== userTypeEnum.Provider.toString() &&
                  (editAppointmentData === null ||
                    editAppointmentData?.requestFromDashboard)
                "
              >
                <mat-select
                  placeholder="{{
                    'scheduler.selectProviderPlaceholder' | translate
                  }}"
                  formControlName="providerUserId"
                  [(ngModel)]="appointmentInfoModel.providerUserId"
                  (selectionChange)="changeProvider($event)"
                >
                  <input
                    type="text"
                    #searchProviderInput
                    (keyup)="searchProvider($event)"
                    (keydown)="_globalFunction.handleInput($event)"
                    placeholder="{{
                      'appointment.searchProviderText' | translate
                    }}"
                    class="search-input"
                  />
                  <mat-option
                    *ngFor="let provider of filteredProviderList"
                    [value]="provider.userId"
                  >
                    {{ provider?.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div
                *ngIf="
                  appointmentInfoValidation.controls['providerUserId']
                    .invalid &&
                  (appointmentInfoValidation.controls['providerUserId'].dirty ||
                    appointmentInfoValidation.controls['providerUserId']
                      .touched ||
                    formSubmitted)
                "
              >
                <mat-error
                  *ngIf="
                    appointmentInfoValidation
                      .get('providerUserId')
                      ?.hasError('required')
                  "
                  >{{
                    "scheduler.requiredProviderError" | translate
                  }}</mat-error
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="appointment-method"
          *ngIf="showUpcomingProviderSlot === true"
        >
          <div class="select-available-date custom-date-slider">
            <div slickContainer #slickController="slick" [slickConfig]="config">
              <ng-container>
                <div class="" slickItem>
                  <div class="available-date-box selected-custom-date-box">
                    <div class="me-2">
                      <img
                        src="assets/images/icon-appointment-calender.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <p>
                        {{ sliderStartDatetime | date : "MMM dd" }}
                      </p>
                      <p>
                        {{ sliderStartDatetime | date : "EEEE" }}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container>
                <div class="" slickItem>
                  <div class="available-date-box selected-custom-date-box">
                    <div class="me-2">
                      <img
                        src="assets/images/icon-appointment-time.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <p>
                        {{ sliderStartDatetime | date : timeFormat }}
                      </p>
                      <!-- <p>{{ currentTimeZoneName }}</p> -->
                      <p>
                        {{ appointmentInfoModel.timezone?.shortName }}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <button
              type="button"
              (click)="goToDateSelectionSection()"
              class="arrow-previous"
            >
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
        <div *ngIf="showUpcomingProviderSlot === false">
          <div class="appointment-available">
            <div
              class="d-flex justify-content-between"
              *ngIf="appointmentInfoModel.scheduleDate !== ''"
            >
              <div class="d-flex align-items-center">
                <div class="lable-img appointment-date">
                  <img
                    src="assets/images/icon-appointment-calender.svg"
                    alt=""
                  />
                </div>
                <div class="calender-time">
                  <a href="javascript:void(0)">{{
                    appointmentInfoModel.scheduleDate | date : "MMM dd, yyyy"
                  }}</a>
                </div>
              </div>
              <p
                class="time"
                *ngIf="
                  appointmentInfoModel.slotStartTime !== '' &&
                  appointmentInfoModel.slotEndTime !== ''
                "
              >
                {{ appointmentInfoModel.slotStartTime }} -
                {{ appointmentInfoModel.slotEndTime }}
              </p>
            </div>
            <div class="available-checkbox">
              <mat-checkbox (change)="showOnlyAvailableTimes($event)">{{
                "appointment.showOnlyAvailableTimesText" | translate
              }}</mat-checkbox>
            </div>
          </div>
          <div class="select-available-date">
            <div slickContainer #slickController="slick" [slickConfig]="config">
              <ng-container>
                <div class="" slickItem *ngFor="let item of datesForSlider">
                  <div
                    class="available-date-box"
                    [ngClass]="{
                      active:
                        (appointmentInfoModel.scheduleDate
                          | date : 'MM/dd/yyyy') ===
                        (item | date : 'MM/dd/yyyy')
                    }"
                    (click)="selectDate(item)"
                  >
                    <p>{{ item | date : "MMM dd" }}</p>
                    <span>{{ item | date : "EEEE" }}</span>
                  </div>
                </div>
              </ng-container>
            </div>
            <button
              type="button"
              (click)="slickController.prev()"
              class="arrow-next"
            >
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button
              type="button"
              (click)="slickController.next()"
              class="arrow-previous"
            >
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
          <div class="select-available-label">
            <div class="form-group me-2">
              <mat-form-field class="example-form-field timezone">
                <mat-label>{{
                  "appointment.selectTimezoneText" | translate
                }}</mat-label>
                <mat-select
                  placeholder="{{
                    'appointment.selectTimezoneText' | translate
                  }}"
                  formControlName="timezone"
                  [(ngModel)]="appointmentInfoModel.timezone"
                  (selectionChange)="changeTimezone($event.value)"
                >
                  <!-- <mat-option *ngFor="let tz of tzNames" [value]="tz">{{
                    getTimezoneCode(tz)
                  }}</mat-option> -->
                  <input
                    type="text"
                    #searchTimezoneInput
                    (keyup)="searchTimeZone($event)"
                    (keydown)="_globalFunction.handleInput($event)"
                    placeholder="{{ 'common.searchTimezoneText' | translate }}"
                    class="search-input"
                  />
                  <mat-option
                    *ngFor="let tz of filteredTimezoneList"
                    [value]="tz"
                    >{{ tz.displayName }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>

            <div class="toggle-time">
              <mat-slide-toggle
                class="toggle"
                [checked]="selectedTimeFormat"
                (change)="changeTimeFormat($event)"
              ></mat-slide-toggle>
            </div>
            <div class="available-date" (click)="picker.open()">
              <mat-form-field class="example-full-width" appearance="fill">
                <input
                  matInput
                  type="text"
                  class="field-disable"
                  formControlName="scheduleDate"
                  [(ngModel)]="appointmentInfoModel.scheduleDate"
                  placeholder="{{
                    'scheduler.selectDateLabelText' | translate
                  }}"
                  [matDatepicker]="picker"
                  (dateChange)="changeScheduleDate()"
                  [min]="todayDate"
                  required
                  readonly
                  hidden
                />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <img src="assets/images/calender-icon.svg" alt="" />
            </div>
          </div>
        </div>

        <div
          class="appointment-time"
          *ngIf="showUpcomingProviderSlot === false"
        >
          <div *ngIf="morningSchedulerSlotList.length > 0">
            <p class="mb-1">
              <b>{{ "appointment.morningText" | translate }}</b>
            </p>
            <ul class="mb-3">
              <li
                *ngFor="let morningSlot of morningSchedulerSlotList"
                [ngClass]="{
                  disable:
                    (morningSlot.appointmentBooked === true ||
                      morningSlot.isBlocked) &&
                    morningSlot.providerSchedulerId !==
                      editAppointmentData?.providerSchedulerId,
                  active:
                    (morningSlot.providerSchedulerId ===
                      appointmentInfoModel.providerSchedulerId ||
                      morningSlot.providerSchedulerId ===
                        editAppointmentData?.pasteSlotData
                          ?.providerSchedulerId) &&
                    (morningSlot.appointmentBooked === false ||
                      morningSlot.providerSchedulerId ===
                        editAppointmentData?.providerSchedulerId)
                }"
                (click)="changeSlot(morningSlot)"
              >
                {{ morningSlot?.formattedStartTime }}
              </li>
            </ul>
          </div>

          <div *ngIf="afternoonSchedulerSlotList.length > 0">
            <p class="mb-1">
              <b>{{ "appointment.afternoonText" | translate }}</b>
            </p>
            <ul class="mb-3">
              <li
                *ngFor="let afterNoonSlot of afternoonSchedulerSlotList"
                [ngClass]="{
                  disable:
                    (afterNoonSlot.appointmentBooked === true ||
                      afterNoonSlot.isBlocked) &&
                    afterNoonSlot.providerSchedulerId !==
                      editAppointmentData?.providerSchedulerId,
                  active:
                    (afterNoonSlot.providerSchedulerId ===
                      appointmentInfoModel.providerSchedulerId ||
                      afterNoonSlot.providerSchedulerId ===
                        editAppointmentData?.pasteSlotData
                          ?.providerSchedulerId) &&
                    (afterNoonSlot.appointmentBooked === false ||
                      afterNoonSlot.providerSchedulerId ===
                        editAppointmentData?.providerSchedulerId)
                }"
                (click)="changeSlot(afterNoonSlot)"
              >
                {{ afterNoonSlot?.formattedStartTime }}
              </li>
            </ul>
          </div>

          <div *ngIf="eveningSchedulerSlotList.length > 0">
            <p class="mb-1">
              <b>{{ "appointment.eveningText" | translate }}</b>
            </p>
            <ul class="mb-2">
              <li
                *ngFor="let eveningSlot of eveningSchedulerSlotList"
                [ngClass]="{
                  disable:
                    (eveningSlot.appointmentBooked === true ||
                      eveningSlot.isBlocked) &&
                    eveningSlot.providerSchedulerId !==
                      editAppointmentData?.providerSchedulerId,
                  active:
                    (eveningSlot.providerSchedulerId ===
                      appointmentInfoModel.providerSchedulerId ||
                      eveningSlot.providerSchedulerId ===
                        editAppointmentData?.pasteSlotData
                          ?.providerSchedulerId) &&
                    (eveningSlot.appointmentBooked === false ||
                      eveningSlot.providerSchedulerId ===
                        editAppointmentData?.providerSchedulerId)
                }"
                (click)="changeSlot(eveningSlot)"
              >
                {{ eveningSlot?.formattedStartTime }}
              </li>
            </ul>
          </div>

          <div *ngIf="isSliderDateChanged">
            <mat-error
              *ngIf="
                morningSchedulerSlotList.length <= 0 &&
                afternoonSchedulerSlotList.length <= 0 &&
                eveningSchedulerSlotList.length <= 0
              "
              >{{ "common.noAvailableSlots" | translate }}</mat-error
            >
          </div>
          <!-- <mat-tab-group [(selectedIndex)]="selectedAppointmentTimeTabIndex">
            <mat-tab label="{{ 'appointment.earlyMorningText' | translate }}">
              <div
                class="mat-body"
                *ngIf="earlyMorningSchedulerSlotList.length > 0"
              >
                <ul>
                  <ng-container
                    *ngFor="
                      let earlyMorningSlot of earlyMorningSchedulerSlotList
                    "
                  >
                    <li
                      [ngClass]="{
                        disable:
                          earlyMorningSlot.appointmentBooked === true &&
                          earlyMorningSlot.providerSchedulerId !==
                            editAppointmentData?.providerSchedulerId,
                        active:
                          (earlyMorningSlot.providerSchedulerId ===
                            appointmentInfoModel.providerSchedulerId ||
                            earlyMorningSlot.providerSchedulerId ===
                              editAppointmentData?.pasteSlotData
                                ?.providerSchedulerId) &&
                          (earlyMorningSlot.appointmentBooked === false ||
                            earlyMorningSlot.providerSchedulerId ===
                              editAppointmentData?.providerSchedulerId)
                      }"
                      (click)="changeSlot(earlyMorningSlot)"
                    >
                      {{ earlyMorningSlot?.formattedStartTime }}
                    </li>
                  </ng-container>
                </ul>
              </div>
              <div *ngIf="earlyMorningSchedulerSlotList.length === 0">
                <div class="text-center">
                  {{ "common.noAvailableSlots" | translate }}
                </div>
              </div>
            </mat-tab>
            <mat-tab label="{{ 'appointment.morningText' | translate }}">
              <div class="mat-body" *ngIf="morningSchedulerSlotList.length > 0">
                <ul>
                  <ng-container
                    *ngFor="let morningSlot of morningSchedulerSlotList"
                  >
                    <li
                      [ngClass]="{
                        disable:
                          morningSlot.appointmentBooked === true &&
                          morningSlot.providerSchedulerId !==
                            editAppointmentData?.providerSchedulerId,
                        active:
                          (morningSlot.providerSchedulerId ===
                            appointmentInfoModel.providerSchedulerId ||
                            morningSlot.providerSchedulerId ===
                              editAppointmentData?.pasteSlotData
                                ?.providerSchedulerId) &&
                          (morningSlot.appointmentBooked === false ||
                            morningSlot.providerSchedulerId ===
                              editAppointmentData?.providerSchedulerId)
                      }"
                      (click)="changeSlot(morningSlot)"
                    >
                      {{ morningSlot?.formattedStartTime }}
                    </li>
                  </ng-container>
                </ul>
              </div>
              <div *ngIf="morningSchedulerSlotList.length === 0">
                <div class="text-center">
                  {{ "common.noAvailableSlots" | translate }}
                </div>
              </div>
            </mat-tab>
            <mat-tab label="{{ 'appointment.afternoonText' | translate }}">
              <div
                class="mat-body"
                *ngIf="afternoonSchedulerSlotList.length > 0"
              >
                <ul>
                  <ng-container
                    *ngFor="let afterNoonSlot of afternoonSchedulerSlotList"
                  >
                    <li
                      [ngClass]="{
                        disable:
                          afterNoonSlot.appointmentBooked === true &&
                          afterNoonSlot.providerSchedulerId !==
                            editAppointmentData?.providerSchedulerId,
                        active:
                          (afterNoonSlot.providerSchedulerId ===
                            appointmentInfoModel.providerSchedulerId ||
                            afterNoonSlot.providerSchedulerId ===
                              editAppointmentData?.pasteSlotData
                                ?.providerSchedulerId) &&
                          (afterNoonSlot.appointmentBooked === false ||
                            afterNoonSlot.providerSchedulerId ===
                              editAppointmentData?.providerSchedulerId)
                      }"
                      (click)="changeSlot(afterNoonSlot)"
                    >
                      {{ afterNoonSlot?.formattedStartTime }}
                    </li>
                  </ng-container>
                </ul>
              </div>
              <div *ngIf="afternoonSchedulerSlotList.length === 0">
                <div class="text-center">
                  {{ "common.noAvailableSlots" | translate }}
                </div>
              </div>
            </mat-tab>
            <mat-tab label="{{ 'appointment.eveningText' | translate }}">
              <div class="mat-body" *ngIf="eveningSchedulerSlotList.length > 0">
                <ul>
                  <ng-container
                    *ngFor="let eveningSlot of eveningSchedulerSlotList"
                  >
                    <li
                      [ngClass]="{
                        disable:
                          eveningSlot.appointmentBooked === true &&
                          eveningSlot.providerSchedulerId !==
                            editAppointmentData?.providerSchedulerId,
                        active:
                          (eveningSlot.providerSchedulerId ===
                            appointmentInfoModel.providerSchedulerId ||
                            eveningSlot.providerSchedulerId ===
                              editAppointmentData?.pasteSlotData
                                ?.providerSchedulerId) &&
                          (eveningSlot.appointmentBooked === false ||
                            eveningSlot.providerSchedulerId ===
                              editAppointmentData?.providerSchedulerId)
                      }"
                      (click)="changeSlot(eveningSlot)"
                    >
                      {{ eveningSlot?.formattedStartTime }}
                    </li>
                  </ng-container>
                </ul>
              </div>
              <div *ngIf="eveningSchedulerSlotList.length === 0">
                <div class="text-center">
                  {{ "common.noAvailableSlots" | translate }}
                </div>
              </div>
            </mat-tab>
          </mat-tab-group> -->
          <div
            *ngIf="
              appointmentInfoModel.providerSchedulerId === '' && formSubmitted
            "
          >
            <mat-error>{{
              "appointment.selectAppointmentTimeErrorText" | translate
            }}</mat-error>
          </div>
        </div>

        <div class="appointment-method" *ngIf="visitTypeList.length > 0">
          <div class="d-flex">
            <div class="lable-img">
              <img
                src="assets/images/icon-appointment-stethoscope.svg"
                alt=""
              />
            </div>
            <div class="appointment-option">
              <ul>
                <li
                  [ngClass]="{
                    active: visit.clinicVisitTypeId === selectedSlotVisitTypeId
                  }"
                  *ngFor="let visit of visitTypeList"
                >
                  <a href="javascript:void(0)">{{ visit.visitType }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex mt-2">
            <div class="lable-img">&nbsp;</div>
            <div class="appointment-option">
              <ul>
                <li
                  [ngClass]="{
                    active: visit.clinicVisitTypeId === selectedSlotVisitTypeId
                  }"
                  *ngFor="let visit of visitTypeList"
                >
                  <a href="javascript:void(0)"
                    >{{
                      visit.clinicVisitTypeId === selectedSlotVisitTypeId
                        ? selectedSlotDuration
                        : ""
                    }}
                    ${{ visit.price }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          class="appointment-top-header align-items-start"
          *ngIf="usersInsuranceCompany"
        >
          <div class="lable-img">
            <img src="assets/images/icon-appointment-dollar.svg" alt="" />
          </div>
          <div class="appointment-option ml-3" style="font-size: 14px">
            <p class="mb-0">{{ "appointment.healthInsurance" | translate }}</p>
            <span class="d-block"
              ><strong>{{ usersInsuranceCompany }}</strong></span
            >
            <span class="d-block"
              ><strong>{{ usersInsurancesubscriberMemberId }}</strong></span
            >
          </div>
        </div>
        <div class="select-doctor apointment-notes border-bottom-0">
          <div class="d-flex">
            <div class="lable-img">
              <img src="assets/images/icon-appointment-textarea.svg" alt="" />
            </div>
            <div class="form-group">
              <mat-form-field class="example-full-width type-textarea">
                <mat-label>{{
                  "appointment.addAppointmentNotesText" | translate
                }}</mat-label>
                <textarea
                  matInput
                  type="text"
                  rows="3"
                  formControlName="appointmentNotes"
                  [(ngModel)]="appointmentInfoModel.appointmentNotes"
                  placeholder="{{
                    'appointment.addAppointmentNotesText' | translate
                  }}"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="drawer-action mt-2">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="scheduleAppointment()"
        >
          <img src="assets/images/save-icon.svg" alt="" class="me-2" />
          {{ "appointment.scheduleText" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary ms-3"
          (click)="closeAppointmentPopup()"
        >
          <img src="assets/images/white-close-icon.svg" alt="" class="me-2" />
          {{ "addUser.cancelButtonText" | translate }}
        </button>
      </div> -->
    </form>
  </div>
</div>

<div class="drawer-body" *ngIf="confirmPaymentSection">
  <div class="top-actions text-end">
    <button
      type="button"
      class="btn btn-secondary mr-2"
      (click)="backToAppointment()"
    >
      {{ "viewUser.backButtonText" | translate }}
    </button>

    <button type="button" class="btn btn-secondary" (click)="confirmPayment()">
      <span *ngIf="isMakeReschedulePayment">
        {{ "appointment.confirmPaymentText" | translate }}
      </span>
      <span *ngIf="!isMakeReschedulePayment">
        {{ "appointment.confirmRescheduleText" | translate }}
      </span>
    </button>
  </div>
  <div class="drawer-title">
    <span *ngIf="isMakeReschedulePayment">{{
      "appointment.makePaymentText" | translate
    }}</span>
    <span *ngIf="!isMakeReschedulePayment">{{
      appointentHeadingText | translate
    }}</span>
  </div>
  <a
    href="javascript:void(0)"
    routerLink="/transaction-history"
    *ngIf="
      isMakeReschedulePayment &&
      (userRole === userTypeEnum.Clinic.toString() ||
        userRole === userTypeEnum.SuperAdmin.toString() ||
        userRole === userTypeEnum.OfficeStaff.toString())
    "
    >{{ "transaction.transactionHistoryText" | translate }}</a
  >
  <div class="drawer-content">
    <ul class="view-user">
      <li>
        <div class="d-flex align-items-center">
          <img
            src="assets/images/icon-appointment-doctor.svg"
            alt=""
            class="me-3"
          />
          {{ selectedProviderName }}
        </div>
      </li>
      <li class="appointment-available">
        <div class="d-flex align-items-center">
          <img
            src="assets/images/icon-appointment-calender.svg"
            alt=""
            class="lable-img me-3"
          />
          {{ appointmentInfoModel.scheduleDate | date : "MMM dd, yyyy" }}
          {{ appointmentInfoModel.slotStartTime }} -
          {{ appointmentInfoModel.slotEndTime }}
          {{ appointmentInfoModel.timezone?.shortName }}
          <!-- {{ currentTimeZoneName }} -->
        </div>
      </li>
      <li>
        <span *ngFor="let visit of visitTypeList">
          <p
            *ngIf="visit.clinicVisitTypeId === selectedSlotVisitTypeId"
            class="d-flex align-items-center"
          >
            <img
              src="assets/images/icon-appointment-stethoscope.svg"
              alt=""
              class="appointment-visittype me-3"
            />
            {{ visit.visitType }},
            {{
              visit.clinicVisitTypeId === selectedSlotVisitTypeId
                ? selectedSlotDuration
                : ""
            }}
            ${{ setAppointmentVisitPrice(visit.price) }}
          </p>
        </span>
      </li>
      <li>
        <div class="d-flex align-items-center">
          <img
            src="assets/images/icon-appointment-user.svg"
            alt=""
            class="patient-name-dob me-3"
          />
          {{ appointmentInfoModel.patientUser?.name }} -
          {{ appointmentInfoModel.patientUser?.dob | date : "MMM dd, yyyy" }}
        </div>
      </li>
      <li *ngIf="appointmentInfoModel.appointmentNotes">
        <p class="d-flex align-items-center">
          <img
            src="assets/images/icon-appointment-textarea.svg"
            alt=""
            class="me-3"
          />
          {{ appointmentInfoModel.appointmentNotes }}
        </p>
      </li>
      <li *ngIf="isMakeReschedulePayment">
        <div class="form-group">
          <mat-form-field class="example-full-width">
            <mat-label>{{ "appointment.discountText" | translate }}</mat-label>
            <span matSuffix>%</span>
            <input
              matInput
              type="text"
              [(ngModel)]="appointmentDiscount"
              placeholder="{{
                'appointment.enterDiscountPercentageText' | translate
              }}"
              (keypress)="_globalFunction.allowNumbersWithDotOnly($event)"
              maxlength="5"
              (input)="onDiscountChange($event)"
            />
          </mat-form-field>
        </div>
      </li>
      <li>
        <p>
          {{ "appointment.totalText" | translate }}: ${{
            setAppointmentDiscountAmount()
          }}
        </p>
      </li>
    </ul>
    <!-- <div *ngIf="isMakeReschedulePayment">
      <div>
        <mat-checkbox [(ngModel)]="isTermsAndCondition"
          >{{ "signup.termsAndConditionAgreeMessage" | translate }}
          <a href="javascript:void(0)" (click)="openStaticContentPopup()">{{
            "signup.termsAndConditionLinkText" | translate
          }}</a>
        </mat-checkbox>
      </div>
    </div> -->
    <!-- <div class="drawer-action mt-4 pt-2">

      <button
        type="button"
        class="btn btn-primary ms-3"
        (click)="backToAppointment()"
      >
        <img src="assets/images/white-back-icon.svg" alt="" class="me-2" />
        {{ "viewUser.backButtonText" | translate }}
      </button>
    </div> -->
  </div>
</div>

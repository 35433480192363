import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { superAdminApiList } from "src/app/enums/superAdminApiEnum";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { StaticContentModel } from "../../models/staticContentModel";
import { SuperAdminService } from "../../services/super-admin.service";
// import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as ClassicEditor from "../../../../../assets/ckeditor-custom-build/build/ckeditor";
import { StaticPageTitleEnum } from "src/app/enums/staticPageTitleEnum";
import { Router } from "@angular/router";

export class UploadAdapter {
  private loader;
  constructor(loader: any) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file: any) =>
        new Promise((resolve, reject) => {
          var myReader = new FileReader();
          myReader.onloadend = (e) => {
            resolve({ default: myReader.result });
          };

          myReader.readAsDataURL(file);
        })
    );
  }
}

@Component({
  selector: "app-add-edit-static-content",
  templateUrl: "./add-edit-static-content.component.html",
  styleUrls: ["./add-edit-static-content.component.css"],
})
export class AddEditStaticContentComponent implements OnInit {
  public Editor: any = ClassicEditor;
  staticPageTitleEnum = StaticPageTitleEnum;
  staticContentInfoModel = new StaticContentModel();
  staticContentInfoValidation: FormGroup = new FormGroup("");
  formSubmitted: boolean = false;
  addEditStaticContentText: string = "";
  @Input() editStaticContentData: any = "";
  @Output() refreshStaticContentData = new EventEmitter<boolean>();

  constructor(
    private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _toastrService: ToastrNotificationService,
    private _superAdminService: SuperAdminService,
    private _router: Router
  ) {
    let staticContentData = this._router.getCurrentNavigation()?.extras.state;
    if (staticContentData)
      this.editStaticContentData = staticContentData["editStaticContentData"];
    if (
      this.editStaticContentData &&
      this.editStaticContentData !== null &&
      this.editStaticContentData.id &&
      this.editStaticContentData.id !== null
    ) {
      this.addEditStaticContentText = "Edit";
      this.setEditStaticContentDate();
    } else {
      this.addEditStaticContentText = "Add";
    }
  }

  ngOnInit(): void {
    this.createStaticContentForm();
  }

  onReady(eventData: any) {
    eventData.plugins.get("FileRepository").createUploadAdapter = function (
      loader: any
    ) {
      console.log("loader : ", loader);
      console.log(btoa(loader.file));
      return new UploadAdapter(loader);
    };
  }

  /**
   * Method to create a static content form
   */
  public createStaticContentForm() {
    this.staticContentInfoValidation = this._formBuilder.group({
      pageTitle: ["", [Validators.required]],
      content: ["", [Validators.required]],
    });
  }

  /**
   * Method to set edit static content data
   */
  public setEditStaticContentDate() {
    this.staticContentInfoModel.pageTitle =
      this.editStaticContentData.pageTitleId.toString();
    this.staticContentInfoModel.content = this.editStaticContentData.content;
  }

  /**
   * Method to open add static content section
   */
  public closeStaticContentPopup() {
    this._router.navigate(["/static-content"]);
  }

  /**
   * Method to submit static content data
   */
  public submitStaticContentInfo() {
    this.formSubmitted = true;
    let staticContentParam: any = {};
    if (this.editStaticContentData && this.editStaticContentData !== null) {
      staticContentParam["id"] = this.editStaticContentData.id;
    }

    if (this.staticContentInfoValidation.valid) {
      staticContentParam["pageTitleId"] = this.staticContentInfoModel.pageTitle;
      staticContentParam["content"] = this.staticContentInfoModel.content;

      let apiPath =
        this.editStaticContentData &&
        this.editStaticContentData !== null &&
        this.editStaticContentData.id &&
        this.editStaticContentData.id !== null
          ? superAdminApiList.updateStaticContent
          : superAdminApiList.addStaticContent;
      let successMessage =
        this.editStaticContentData &&
        this.editStaticContentData !== null &&
        this.editStaticContentData.id &&
        this.editStaticContentData.id !== null
          ? "staticContent.updateStaticContentSuccessMessage"
          : "staticContent.AddStaticContentSuccessMessage";

      this.refreshStaticContentData.emit(false);
      this._superAdminService
        .addStaticContentScheduler(staticContentParam, apiPath)
        .subscribe({
          next: (data) => {
            if (data && data.success && data.success === true) {
              this.refreshStaticContentData.emit(true);
              this._toastrService.showSuccess(successMessage);
              this.closeStaticContentPopup();
            }
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }
}

import { Injectable } from "@angular/core";
import { ReplaySubject, Observable } from "rxjs";

export type Devices = MediaDeviceInfo[];

@Injectable()
export class DeviceService {
  $devicesUpdated: Observable<Promise<Devices>>;

  private deviceBroadcast = new ReplaySubject<Promise<Devices>>();

  constructor() {
    if (navigator && navigator.mediaDevices) {
      navigator.mediaDevices.ondevicechange = (_: Event) => {
        this.deviceBroadcast.next(this.getDeviceOptions());
      };
    }

    this.$devicesUpdated = this.deviceBroadcast.asObservable();
    this.deviceBroadcast.next(this.getDeviceOptions());
  }

  public async isGrantedMediaPermissions() {
    // if (
    //   navigator &&
    //   navigator.userAgent &&
    //   navigator.userAgent.indexOf("Chrome") < 0
    // ) {
    //   return true; // Follows standard workflow for non-Chrome browsers.
    // }

    let isDeviceAvailable = false;
    if (navigator && navigator["permissions"]) {
      try {
        const result = await navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(
            (stream) => {
              let videoDevices: any = stream.getVideoTracks();
              if (videoDevices && videoDevices.length > 0) {
                console.log("videoDevices kind: " + videoDevices[0].kind);
                console.log("videoDevices enabled: " + videoDevices[0].enabled);
                console.log("videoDevices label: " + videoDevices[0].label);
                console.log("videoDevices muted: " + videoDevices[0].muted);
                console.log(
                  "videoDevices readyState: " + videoDevices[0].readyState
                );
              }

              let audioDevices: any = stream.getAudioTracks();
              if (audioDevices && audioDevices.length > 0) {
                console.log("audioDevices kind: " + audioDevices[0].kind);
                console.log("audioDevices enabled: " + audioDevices[0].enabled);
                console.log("audioDevices label: " + audioDevices[0].label);
                console.log("audioDevices muted: " + audioDevices[0].muted);
                console.log(
                  "audioDevices readyState: " + audioDevices[0].readyState
                );
              }
              console.log("devices available");
              isDeviceAvailable = true;
            },
            (e) => {
              console.log("error occurred while getting device");
              isDeviceAvailable = false;
            }
          );

        //const result = await navigator["permissions"].query({ name: "camera" });
        // if (result) {
        //   if (result.state === "granted") {
        //     return true;
        //   } else {
        //     const isGranted = await new Promise<boolean>((resolve) => {
        //       result.onchange = (_: Event) => {
        //         const granted = _.target["state"] === "granted";
        //         if (granted) {
        //           resolve(true);
        //         }
        //       };
        //     });

        //     return isGranted;
        //   }
        // }
      } catch (e) {
        // This is only currently supported in Chrome.
        // https://stackoverflow.com/a/53155894/2410379
        isDeviceAvailable = false;
      }
    }

    return isDeviceAvailable;
  }

  private async getDeviceOptions(): Promise<any> {
    const isGranted = await this.isGrantedMediaPermissions();
    if (navigator && navigator.mediaDevices && isGranted) {
      let devices = await this.tryGetDevices();
      if (devices.every((d: any) => !d.label)) {
        devices = await this.tryGetDevices();
      }
      return devices.filter((d: any) => !!d.label);
    }

    return null;
  }

  private async tryGetDevices() {
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    const devices = ["audioinput", "audiooutput", "videoinput"].reduce(
      (options: any, kind) => {
        return (options[kind] = mediaDevices.filter(
          (device) => device.kind === kind
        ));
      },
      [] as Devices
    );

    return devices;
  }
}

import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { GlobalSpinnerService } from "./services/global-spinner.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = "Better Now";
  visibility: boolean = false;
  visibilityChanged: Subscription = new Subscription();

  constructor(
    public router: Router,
    public translate: TranslateService,
    public _spinnerService: GlobalSpinnerService
  ) {
    translate.addLangs(["en", "es", "pt"]);
    translate.setDefaultLang("en");
  }

  ngOnInit() {
    let currentLanguage = localStorage.getItem("Language");
    if (currentLanguage !== null) {
      this.translate.use(currentLanguage);
    }
  }

  ngAfterViewInit() {
    this.visibilityChanged = this._spinnerService.spinnerState.subscribe(
      (state: boolean) => (this.visibility = state)
    );
  }

  ngOnDestroy() {
    this.visibilityChanged.unsubscribe();
  }
}

import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { AuthGuardService } from "src/app/services/auth-guard.service";

@Component({
  selector: "app-left-menu",
  templateUrl: "./left-menu.component.html",
  styleUrls: ["./left-menu.component.css"],
})
export class LeftMenuComponent implements OnInit {
  isLoggedIn: Observable<boolean>;
  userRole: string = "";
  menuList: any = [];
  topMenuList: any = [];
  bottomMenuList: any = [];

  constructor(private _authService: AuthGuardService) {
    this.isLoggedIn = this._authService.isLoggedIn;
  }

  ngOnInit(): void {
    this.isLoggedIn.subscribe((isLogin) => {
      if (isLogin) {
        this._authService.getUserData().subscribe((data) => {
          if (data) {
            this.userRole = data["usertypeid"];
            this.menuList = data["leftMenuList"];
            if (this.menuList) {
              this.topMenuList = this.menuList.filter(
                (f: any) => f.headerType === "TOP"
              );
              this.bottomMenuList = this.menuList.filter(
                (f: any) => f.headerType === "BOTTOM"
              );
            }
          }
        });
      }
    });
  }
}

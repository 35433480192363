import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-view-officestaff-user",
  templateUrl: "./view-officestaff-user.component.html",
  styleUrls: ["./view-officestaff-user.component.css"],
})
export class ViewOfficestaffUserComponent {
  @Input() viewOfficeStaffData: any;
  @Output() showViewOfficeStaffPopup = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  /**
   * Method to close view office staff section
   */
  public closeViewOfficeStaffPopup() {
    this.showViewOfficeStaffPopup.emit(false);
  }
}

import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthGuardService } from 'src/app/services/auth-guard.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  isLoggedIn: Observable<boolean>;

  constructor(
    private _authService: AuthGuardService
  ) {
    this.isLoggedIn = this._authService.isLoggedIn;
  }
}

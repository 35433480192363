export enum superAdminApiList {
  login = "/superadmin/login",
  refreshToken = "/superadmin/refreshtoken",
  forgotPassword = "/superadmin/forgotpassword",
  resetPassword = "/superadmin/resetpassword",
  addClinic = "/superadmin/addclinic",
  updateClinic = "/superadmin/updateclinic",
  getallClinic = "/superadmin/getallclinic",
  getClinic = "/superadmin/getclinic",
  updateClinicRegistration = "/superadmin/updateclinicregistration",
  getallClinicRegistration = "/superadmin/getallclinicregistration",
  getClinicRegistration = "/superadmin/getclinicregistration",
  addOfficeStaff = "/superadmin/addofficestaff",
  updateOfficeStaff = "/superadmin/updateofficestaff",
  getAllOfficeStaff = "/superadmin/getallofficestaff",
  getOfficeStaff = "/superadmin/getofficestaff",
  getAllAdminOfficeStaff = "/superadmin/getalladminofficestaff",
  updateICareVIPSettings = "/superadmin/updateicarevipsettings",
  getICareVIPSettings = "/superadmin/geticarevipsettings",
  addSubscriptionPlan = "/superadmin/addsubscriptionplan",
  updateSubscriptionPlan = "/superadmin/updatesubscriptionplan",
  getAllSubscriptionPlan = "/superadmin/getallsubscriptionplan",
  deleteSubscriptionPlan = "/superadmin/deletesubscriptionplan",
  getNotificationLogs = "/superadmin/getlogs",
  getAdminTransactionHistory = "/superadmin/getadmintransactionhistory",
  getAllClinicDetails = "/superadmin/getallclinicdetails",
  getAllPatientByClinic = "/superadmin/getallpatientbyclinic",
  getAllClinicSubscriptionPlan = "/superadmin/getallclinicsubscriptionplan",
  addStaticContent = "/superadmin/addstaticcontent",
  updateStaticContent = "/superadmin/updatestaticcontent",
  deleteStaticContent = "/superadmin/deletestaticcontent",
  getallStaticContent = "/superadmin/getallstaticcontent",
  getAllAppointmentByClinic = "/superadmin/getallappointmentbyclinic",
  addRefundTransaction = "/superadmin/addrefundtransaction",
}

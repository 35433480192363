<div class="drawer-body">
  <div class="drawer-title">
    <img src="assets/images/view-icon.svg" alt="" />
    {{ "manageClinics.clinicRequestDetailsHeadingText" | translate }}
  </div>
  <div class="drawer-content">
    <ul class="view-user">
      <li>
        <span>{{ "manageClinics.clinicNameLabelText" | translate }}</span>
        <p>{{ viewClinicRequestData.clinicName }}</p>
      </li>
      <li>
        <span>{{ "userList.emailLabelText" | translate }}</span>
        <p>{{ viewClinicRequestData.email }}</p>
      </li>
      <li>
        <span>{{ "viewUser.contactNumberLabelText" | translate }}</span>
        <p>{{ viewClinicRequestData.phoneNumber }}</p>
      </li>
      <li>
        <span>{{ "manageClinics.clinicDocumentsLabelText" | translate }}</span>
        <div class="form-group download-documents">
          <ul class="upload-documents">
              <li *ngFor="let doc of viewClinicRequestData.documents">
                <a href="javascript:void(0)"  (click)="downloadClinicDocuments(doc)" *ngIf="doc.fileBytes" class="btn btn-secondary">
                 <img src="assets/images/download-icon.png" alt=""> {{ doc.fileName }}
                </a>
              </li>
          </ul>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            (click)="downloadAllClinicDocuments()"
          >
            {{ "common.downloadAllText" | translate }}
          </button>
        </div>
      </li>
      <li>
        <!-- <span>{{ "manageClinics.requestStatusLabelText" | translate }}</span> -->
        <div class="form-group">
          <mat-form-field>
            <mat-label
              >{{
                "manageClinics.requestStatusLabelText" | translate
              }}</mat-label
            >
            <mat-select
              placeholder="{{ 'userList.statusPlaceholder' | translate }}"
              [(ngModel)]="requestStatus"
            >
              <mat-option value="1">Approve</mat-option>
              <mat-option value="2">Reject</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li>
        <span>{{ "common.updatedByLabelText" | translate }}</span>
        <p>
          {{
            viewClinicRequestData.viewEditMode === "edit"
              ? loggedUserName
              : viewClinicRequestData.updatedBy
          }}
        </p>
      </li>
    </ul>
    <div class="drawer-action">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="saveClinicRequest()"
      >
        <img src="assets/images/save-icon.svg" alt="" class="me-2" />
        {{ "addUser.saveButtonText" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary ms-3"
        (click)="closeViewClinicRequestPopup()"
      >
        <img src="assets/images/white-close-icon.svg" alt="" class="me-2" />
        {{ "addUser.cancelButtonText" | translate }}
      </button>
    </div>
  </div>
</div>

import { DatePipe } from "@angular/common";
import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment-timezone";
import { GlobalFunctions } from "src/app/app.global";
import { ConfirmationDialogComponent } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { StylePaginatorDirective } from "src/app/directives/style-paginator.directive";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { ClinicService } from "../../services/clinic.service";

@Component({
  selector: "app-scheduler",
  templateUrl: "./scheduler.component.html",
  styleUrls: ["./scheduler.component.css"],
})
export class SchedulerComponent implements AfterViewInit {
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource([]);
  @ViewChild(StylePaginatorDirective, { static: true })
  paginator!: StylePaginatorDirective;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @Input() searchData: string = "";
  @Input() refreshSchedulerData: boolean = false;
  @Output() editSchedulerId = new EventEmitter<any>();
  pageNumber: number = 1;
  totalRows: number = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  @Input() filterProvider: any = null;
  @Input() filterVisitType: any = "";
  @Input() filterStartDate: any = "";
  @Input() filterEndDate: any = "";
  showPaginator: boolean = true;
  userRole: string = "";
  userId: any;
  timeFormat: any;
  currentTimeZoneName: any;

  constructor(
    private _commonService: CommonService,
    private _clinicService: ClinicService,
    private _toastrService: ToastrNotificationService,
    public _globalFunction: GlobalFunctions,
    private _authService: AuthGuardService,
    private datePipe: DatePipe,
    private dialog: MatDialog
  ) {
    this.currentTimeZoneName = this._commonService.getTimeZoneShortName();
    this.timeFormat = this._commonService.getTimeFormat();
  }

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
        this.userId = data["userid"];
      }
    });
    this.setDisplayedColumns();
    this.loadSchedulerData();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.loadSchedulerData());
  }

  ngOnChanges() {
    this.filterData();
  }

  /**
   * Method to set displayed columns
   */
  public setDisplayedColumns() {
    if (this.userRole !== "3") {
      this.displayedColumns.push("name");
      this.displayedColumns.push("email");
    }
    this.displayedColumns.push("startTime");
    this.displayedColumns.push("endTime");
    this.displayedColumns.push("slotTime");
    this.displayedColumns.push("visitType");
    this.displayedColumns.push("scheduleDate");
    this.displayedColumns.push("action");
  }

  /**
   * Method to edit scheduler
   */
  public editScheduler(data: any) {
    this.editSchedulerId.emit(data.providerSchedulerId);
  }

  /**
   * Method to get scheduler data
   */
  public loadSchedulerData(applyFilter: boolean = false) {
    if (this.sort.direction === "") this.sort.active = "";
    this.currentPage = applyFilter ? 0 : this.currentPage;
    let schedulerParam = {
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      sortColumnName: this.sort.active,
      sortDirection: this.sort.direction === "asc" ? true : false,
      startDate:
        this.filterStartDate !== ""
          ? this.datePipe.transform(this.filterStartDate, "yyyy-MM-dd")
          : null,
      endDate:
        this.filterEndDate !== ""
          ? this.datePipe.transform(this.filterEndDate, "yyyy-MM-dd")
          : null,
      visitType: this.filterVisitType !== "" ? this.filterVisitType : 0,
      providerUserId:
        this.userRole && this.userRole === "3"
          ? this.userId
          : this.filterProvider,
      offset: moment().tz(moment.tz.guess()).utcOffset(),
    };
    this._clinicService.getAllScheduler(schedulerParam).subscribe({
      next: (data) => {
        if (
          data &&
          data !== null &&
          data.data &&
          data.data !== null &&
          data.data.length > 0
        ) {
          this.dataSource = new MatTableDataSource(data.data);
        } else {
          this.dataSource = new MatTableDataSource([]);
        }
        this.totalRows = data.totalRecords;
        this.paginator.matPag.length = this.totalRows;
        if (applyFilter) {
          this.paginator._curPageObj.pageIndex = 0;
          this.paginator._rangeStart = 0;
          this.paginator._rangeEnd = 1;
        }
        this.paginator.initPageRange();
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to delete scheduler
   */
  public deleteScheduler(data: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "scheduler.deleteSchedulerConfirmText",
        confirmTitleText: "common.confirmDeleteText",
        buttonText: {
          ok: "common.yesText",
          cancel: "common.noText",
        },
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if (data.providerSchedulerId !== null) {
          this._clinicService
            .deleteScheduler(data.providerSchedulerId)
            .subscribe({
              next: (data) => {
                if (data && data.success && data.success === true) {
                  this._toastrService.showSuccess(
                    "scheduler.deleteSchedulerSuccessMessage"
                  );
                  this.loadSchedulerData();
                }
              },
              error: (error) => {
                this._commonService.showErrorMessage(error);
              },
            });
        }
      }
    });
  }

  /**
   * Method to filter data
   */
  public pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadSchedulerData();
  }

  /**
   * Method to filter data
   */
  public filterData() {
    this.showPaginator = true;
    if (this.searchData.trim() !== "") {
      this.showPaginator = false;
    }
    this.refreshSchedulerGrid(this.refreshSchedulerData);
    this.dataSource.filter = this.searchData.trim().toLocaleLowerCase();
  }

  /**
   * Method to refresh grid
   */
  public refreshSchedulerGrid(isRefreshData: boolean) {
    if (isRefreshData) {
      this.loadSchedulerData();
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { ClinicService } from "src/app/modules/clinic/services/clinic.service";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";

@Component({
  selector: "app-add-edit-wallet",
  templateUrl: "./add-edit-wallet.component.html",
  styleUrls: ["./add-edit-wallet.component.css"],
})
export class AddEditWalletComponent implements OnInit {
  isCreditCard: boolean = false;
  isDebitCard: boolean = false;
  constructor(
    private _clinicService: ClinicService,
    private _commonService: CommonService,
    private _toastrService: ToastrNotificationService
  ) {}

  ngOnInit(): void {
    this.getWalletSettings();
  }

  /**
   * Method to get wallet settings
   */
  public getWalletSettings() {
    this._clinicService.getWalletSettings().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.isCreditCard =
            data.isCreditCard !== null ? data.isCreditCard : false;
          this.isDebitCard =
            data.isDebitCard !== null ? data.isDebitCard : false;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to update wallet settings
   */
  public updateWalletSettings() {
    let walletParams = {
      isCreditCard: this.isCreditCard,
      isDebitCard: this.isDebitCard,
    };
    this._clinicService.updateWalletSettings(walletParams).subscribe({
      next: (data) => {
        if (data && data.success && data.success === true) {
          this._toastrService.showSuccess(
            "transaction.updateWalletSuccessMessage"
          );
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }
}

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import * as moment from "moment-timezone";
import { GlobalFunctions } from "src/app/app.global";
import { ConfirmationDialogComponent } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { AppointmentStatusEnum } from "src/app/enums/appointmentStatusEnum";
import { clinicApiList } from "src/app/enums/clinicApiEnum";
import { UserTypeEnum } from "src/app/enums/userTypeEnum";
import { ClinicService } from "src/app/modules/clinic/services/clinic.service";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { environment } from "src/environments/environment";
import { AppointmentService } from "../../services/appointment.service";
import { Clipboard } from "@angular/cdk/clipboard";
@Component({
  selector: "app-view-appointment",
  templateUrl: "./view-appointment.component.html",
  styleUrls: ["./view-appointment.component.css"],
})
export class ViewAppointmentComponent implements OnInit, OnChanges {
  userRole: string = "";
  userId: any;
  appointmentStatusEnum = AppointmentStatusEnum;
  userTypeEnum = UserTypeEnum;
  isImagePopupOpen: boolean = false;
  selectedImageSrc: string = "";
  copyButtonImg: string = "assets/images/copy-icon.png";
  @Input() viewAppointmentData: any;
  @Output() showViewAppointmentPopup = new EventEmitter<boolean>();
  @Output() refreshData = new EventEmitter<boolean>();
  RoomName = "";
  appointmentRoomName = "";
  patientPhoto: string = "";
  currentTimeZoneName: any;
  timeFormat: any;
  patientInsuranceFrontSide: string = "";
  patientInsuranceBackSide: string = "";
  medicationList: any = [];

  constructor(
    public _globalFunction: GlobalFunctions,
    public _commonService: CommonService,
    private _authService: AuthGuardService,
    private _appointmentService: AppointmentService,
    private _toastrService: ToastrNotificationService,
    private _clinicService: ClinicService,
    private dialog: MatDialog,
    private _router: Router,
    private clipboard: Clipboard
  ) {
    this.timeFormat = this._commonService.getTimeFormat();
    this.currentTimeZoneName = this._commonService.getTimeZoneShortName();
  }

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
        this.userId = data["userid"];
      }
    });
  }

  ngOnChanges(): void {
    this._globalFunction.setPatientProfilePhoto(this.viewAppointmentData);
    let patientUserId = this.viewAppointmentData.patientUserId;
    if (patientUserId) this.getPatientDetails(patientUserId);
    if (!this.viewAppointmentData?.discount)
      this.viewAppointmentData.discount = "0";
    if (this.viewAppointmentData.patientGender === 1)
      this.viewAppointmentData.patientGenderValue = "Male";
    else if (this.viewAppointmentData.patientGender === 2)
      this.viewAppointmentData.patientGenderValue = "Female";
    else this.viewAppointmentData.patientGenderValue = "Other";
  }

  /**
   * Method to close view user section
   */
  public closeViewAppointmentPopup() {
    this.showViewAppointmentPopup.emit(false);
  }

  /**
   * Method to edit patient information
   */
  public editPatientInfo() {
    this._router.navigateByUrl("/users", {
      state: {
        requestFrom: "appointment",
        patientUserID: this.viewAppointmentData.patientUserId,
      },
    });
  }

  /**
   * Method to missed appointment
   */
  public missedAppointment(data: any) {
    this.updateAppointment(data, AppointmentStatusEnum.Missed);
  }

  /**
   * Method to complete appointment
   */
  public completeAppointment(data: any) {
    this.updateAppointment(data, AppointmentStatusEnum.Completed);
  }

  /**
   * Method to update appointment
   */
  public updateAppointment(appointmentData: any, appointmentStatus: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "appointment.updateAppointmentConfirmText",
        confirmTitleText: "common.confirmUpdateText",
        buttonText: {
          ok: "common.yesText",
          cancel: "common.noText",
        },
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let appointmentParam: any = {};
        appointmentParam["appointmentId"] = appointmentData?.appointmentId;
        appointmentParam["status"] = appointmentStatus;
        appointmentParam["providerSchedulerId"] =
          appointmentData.providerSchedulerId;
        appointmentParam["providerUserId"] = appointmentData.providerUserId;
        appointmentParam["patientUserId"] = appointmentData.patientUserId;
        appointmentParam["notes"] = appointmentData.appointmentNotes;
        appointmentParam["sendNotification"] = true;
        appointmentParam["discount"] = 0;
        appointmentParam["successUrl"] = "";
        appointmentParam["cancelUrl"] = "";

        let apiPath = clinicApiList.updateClinicAppointment;
        this._appointmentService
          .addClinicAppointment(appointmentParam, apiPath)
          .subscribe({
            next: (data) => {
              if (data && data.success && data.success === true) {
                this._toastrService.showSuccess(
                  "appointment.updateAppointmentSuccessMessage"
                );
                this.refreshData.emit(true);
              }
              if (
                appointmentStatus === AppointmentStatusEnum.Missed &&
                data &&
                data.refund &&
                data.refund !== ""
              ) {
                if (data.refund === "RefundSuccess") {
                  this._toastrService.showSuccess("ErrorCode." + data.refund);
                } else {
                  this._toastrService.showError("ErrorCode." + data.refund);
                }
              }
            },
            error: (error) => {
              this._commonService.showErrorMessage(error);
            },
          });
      }
    });
  }

  /**
   * Method to join video call for appointment
   */
  public joinVideoCallForAppointment() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "liveConsultation.joinMeetingConfirmText",
        confirmTitleText: "common.confirmText",
        buttonText: {
          ok: "common.yesText",
          cancel: "common.noText",
        },
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let providerSchedulerId = this.viewAppointmentData.providerSchedulerId;
        let appointmentId = this.viewAppointmentData.appointmentId;
        let roomName = providerSchedulerId;
        let patientName = this.viewAppointmentData.patientName;
        this._router.navigateByUrl(
          "/live-consultation/" +
            appointmentId +
            "/" +
            roomName +
            "/" +
            patientName +
            ""
        );
      }
    });
  }

  /**
   * Method to get patient details
   */
  public getPatientDetails(patientUserId: any) {
    this._clinicService.getPatientDetails(patientUserId).subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.viewAppointmentData.patientParsonalInformation =
            data.parsonalInformation;
          this.viewAppointmentData.emergencyInformation =
            data.emergencyInformation;
          this.viewAppointmentData.healthInformation = data.healthInformation;
          this.viewAppointmentData.insuranceInformation =
            data.insuranceInformation;
          this.viewAppointmentData.pharmacyInformation =
            data.pharmacyInformation;
          this.patientInsuranceFrontSide =
            this.viewAppointmentData.insuranceInformation
              .insuranceCardFrontPath != ""
              ? environment.documentPathUrl +
                patientUserId +
                "/" +
                this.viewAppointmentData.insuranceInformation
                  .insuranceCardFrontPath
              : "";
          this.patientInsuranceBackSide =
            this.viewAppointmentData.insuranceInformation
              .insuranceCardBackPath != ""
              ? environment.documentPathUrl +
                patientUserId +
                "/" +
                this.viewAppointmentData.insuranceInformation
                  .insuranceCardBackPath
              : "";
          this.medicationList =
            this.viewAppointmentData.healthInformation.currenMedications != ""
              ? this.viewAppointmentData.healthInformation.currenMedications.split(
                  "#_$_@_||_@_$_#"
                )
              : "";
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  openImagePopup(imageSrc: string) {
    this.selectedImageSrc = imageSrc;
    this.isImagePopupOpen = true;
  }

  closeImagePopup() {
    this.isImagePopupOpen = false;
    this.selectedImageSrc = "";
  }

  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
    this.copyButtonImg = "assets/images/right-sign-icon.png";
    setTimeout(() => {
      this.copyButtonImg = "assets/images/copy-icon.png";
    }, 500);
  }
}

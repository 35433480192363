<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/icon-appointment.svg" alt="" />
        </div>
        <h2>
          {{ "scheduler.schedulerHeadingText" | translate }}
        </h2>
      </div>
      <div class="page-title-right">
        <div class="search">
          <div class="form-group">
            <mat-form-field class="example-form-field">
              <input
                matInput
                [(ngModel)]="searchText"
                type="text"
                placeholder="{{ 'userList.searchUserPlaceholder' | translate }}"
                (keyup)="searchDataByKeyword()"
              />
              <button matSuffix>
                <img src="assets/images/icon-search.svg" alt="" />
              </button>
            </mat-form-field>
          </div>
        </div>
        <button class="btn btn-primary ms-3" (click)="addScheduler()">
          <img src="assets/images/plus-icon.svg" alt="" class="me-2" />
          {{ "scheduler.addSchedulerButtonText" | translate }}
        </button>
        <button
          class="btn btn-primary ms-3"
          (click)="filterClick()"
          [ngClass]="filter ? 'active' : ''"
        >
          <img src="assets/images/filter-icon.svg" alt="" />
        </button>
      </div>
    </div>

    <app-filter
      (filterData)="getFilterData($event)"
      (applyFilterData)="applyFilterData()"
      [filter]="filter"
      [showFilterList]="showFilterList"
    ></app-filter>

    <div class="content-page">
      <app-scheduler
        [refreshSchedulerData]="refreshSchedulerGrid"
        [searchData]="searchString"
        (editSchedulerId)="editSchedulerPopup($event)"
        #scheduler
      ></app-scheduler>
    </div>

    <div class="right-drawer" [ngClass]="rightDrawer ? 'open' : ''">
      <button class="btn btn-link" (click)="showHideSchedulerPopup(false)">
        <img src="assets/images/close-icon.svg" alt="" />
      </button>
      <app-add-edit-scheduler
        *ngIf="addEditScheduler"
        (refreshSchedulerData)="loadSchedulerData($event)"
        (showSchedulerPopup)="showHideSchedulerPopup($event)"
        [editSchedulerId]="editSchedulerId"
      ></app-add-edit-scheduler>
    </div>
  </div>
</div>

<div class="drawer-body">
  <div class="drawer-title">
    <img src="assets/images/add-icon.svg" alt="" />
    {{ "common." + addEditClinicText | translate }}
    {{ "common." + userType | translate }}
  </div>
  <div class="drawer-content">
    <form [formGroup]="userInfoValidation" (ngSubmit)="submitUserInfo()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "viewUser.emailAddressLabelText" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="email"
                [(ngModel)]="userInfoModel.email"
                placeholder="{{ 'login.emailPlaceholder' | translate }}"
                required
              />
              <span matPrefix
                ><img src="assets/images/email.svg" alt=""
              /></span>
            </mat-form-field>
            <div
              *ngIf="
                userInfoValidation.controls['email'].invalid &&
                (userInfoValidation.controls['email'].dirty ||
                  userInfoValidation.controls['email'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="userInfoValidation.get('email')?.hasError('required')"
                >{{ "common.requiredEmailError" | translate }}</mat-error
              >
              <mat-error
                *ngIf="userInfoValidation.get('email')?.hasError('pattern')"
                >{{ "common.invalidEmailError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "addUser.firstNameLabelText" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="firstName"
                [(ngModel)]="userInfoModel.firstName"
                placeholder="{{ 'addUser.firstNamePlaceholder' | translate }}"
                required
              />
              <span matPrefix
                ><img src="assets/images/user-icon.svg" alt=""
              /></span>
            </mat-form-field>
            <div
              *ngIf="
                userInfoValidation.controls['firstName'].invalid &&
                (userInfoValidation.controls['firstName'].dirty ||
                  userInfoValidation.controls['firstName'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  userInfoValidation.get('firstName')?.hasError('required')
                "
                >{{ "addUser.requiredFirstNameError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "addUser.lastNameLabelText" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="lastName"
                [(ngModel)]="userInfoModel.lastName"
                placeholder="{{ 'addUser.lastNamePlaceholder' | translate }}"
                required
              />
              <span matPrefix
                ><img src="assets/images/user-icon.svg" alt=""
              /></span>
            </mat-form-field>
            <div
              *ngIf="
                userInfoValidation.controls['lastName'].invalid &&
                (userInfoValidation.controls['lastName'].dirty ||
                  userInfoValidation.controls['lastName'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="userInfoValidation.get('lastName')?.hasError('required')"
                >{{ "addUser.requiredLastNameError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-3">
                <mat-form-field>
                  <mat-label>{{
                    "signup.phoneCodePlaceholder" | translate
                  }}</mat-label>
                  <span matPrefix style="width: 20px; display: inline-block"
                    ><img src="assets/images/phone.svg" alt=""
                  /></span>
                  <mat-select
                    placeholder="{{
                      'signup.phoneCodePlaceholder' | translate
                    }}"
                    [(value)]="codeSelect"
                    formControlName="phoneCode"
                    [(ngModel)]="userInfoModel.phoneCode"
                  >
                    <mat-option
                      *ngFor="let code of phoneCodeList"
                      [value]="code.phoneCode"
                      >{{ code.phoneCode }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="
                    userInfoValidation.controls['phoneCode'].invalid &&
                    (userInfoValidation.controls['phoneCode'].dirty ||
                      userInfoValidation.controls['phoneCode'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      userInfoValidation.get('phoneCode')?.hasError('required')
                    "
                    >{{
                      "signup.requiredPhoneCodeError" | translate
                    }}</mat-error
                  >
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group mb-3">
                <mat-form-field class="example-form-field">
                  <mat-label>{{
                    "userList.contactLabelText" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="phoneNumber"
                    [(ngModel)]="userInfoModel.phoneNumber"
                    placeholder="{{
                      'viewUser.contactNumberLabelText' | translate
                    }}"
                    (keypress)="_globalFunction.allowNumbersOnly($event)"
                    required
                  />
                </mat-form-field>
                <div
                  *ngIf="
                    userInfoValidation.controls['phoneNumber'].invalid &&
                    (userInfoValidation.controls['phoneNumber'].dirty ||
                      userInfoValidation.controls['phoneNumber'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      userInfoValidation
                        .get('phoneNumber')
                        ?.hasError('required')
                    "
                    >{{ "signup.requiredPhoneError" | translate }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      userInfoValidation
                        .get('phoneNumber')
                        ?.hasError('minlength')
                    "
                    >{{ "signup.minLengthPhoneError" | translate }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      userInfoValidation
                        .get('phoneNumber')
                        ?.hasError('maxlength')
                    "
                    >{{ "signup.maxLengthPhoneError" | translate }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      userInfoValidation.get('phoneNumber')?.hasError('pattern')
                    "
                    >{{ "signup.patternPhoneError" | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="userType === 'Medical Providers'">
          <div class="form-group mb-3">
            <mat-form-field>
              <mat-label>{{
                "viewUser.specialityLabelText" | translate
              }}</mat-label>
              <span matPrefix style="width: 20px; display: inline-block"
                ><img src="assets/images/user-icon.svg" alt=""
              /></span>
              <mat-select
                placeholder="{{ 'addUser.specialityPlaceholder' | translate }}"
                formControlName="speciality"
                [(ngModel)]="userInfoModel.speciality"
              >
                <mat-option
                  *ngFor="let item of specialityList"
                  [value]="item.specialtyId"
                  >{{ item.specialty }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="
                userInfoValidation.controls['speciality'].invalid &&
                (userInfoValidation.controls['speciality'].dirty ||
                  userInfoValidation.controls['speciality'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  userInfoValidation.get('speciality')?.hasError('required')
                "
                >{{ "addUser.requiredSpecialityError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "viewUser.dateOfBirthLabelText" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                class="field-disable"
                formControlName="dob"
                [(ngModel)]="userInfoModel.dob"
                placeholder="{{
                  'viewUser.dateOfBirthPlaceholder' | translate
                }}"
                [matDatepicker]="picker"
                [max]="todayDate"
                required
                readonly
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <span matPrefix
                ><img src="assets/images/calender-icon.svg" alt=""
              /></span>
            </mat-form-field>
            <div
              *ngIf="
                userInfoValidation.controls['dob'].invalid &&
                (userInfoValidation.controls['dob'].dirty ||
                  userInfoValidation.controls['dob'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="userInfoValidation.get('dob')?.hasError('required')"
                >{{ "addUser.requiredDateOfBirth" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="userType === 'Medical Providers'">
          <div class="form-group mb-3">
            <mat-form-field class="example-full-width">
              <mat-label>{{ "common.visitTypeText" | translate }}</mat-label>
              <span matPrefix style="width: 20px; display: inline-block"
                ><img src="assets/images/visit-type-icon.svg" alt=""
              /></span>
              <mat-select
                multiple
                placeholder="{{
                  'scheduler.selectVisitTypePlaceholder' | translate
                }}"
                formControlName="visitTypes"
                [(ngModel)]="userInfoModel.visitTypes"
              >
                <mat-option
                  *ngFor="let item of visitTypeList"
                  [value]="item.clinicVisitTypeId"
                  >{{ item.visitType }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="
                userInfoValidation.controls['visitTypes'].invalid &&
                (userInfoValidation.controls['visitTypes'].dirty ||
                  userInfoValidation.controls['visitTypes'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  userInfoValidation.get('visitTypes')?.hasError('required')
                "
                >{{
                  "providerProfile.requiredVisitTypeError" | translate
                }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="userType === 'Medical Providers'">
          <div class="form-group mb-3">
            <mat-form-field class="example-full-width">
              <mat-label>{{ "common.languagesText" | translate }}</mat-label>
              <span matPrefix style="width: 20px; display: inline-block"
                ><img src="assets/images/language.svg" alt=""
              /></span>
              <mat-select
                multiple
                placeholder="{{ 'common.selectLanguageLabel' | translate }}"
                formControlName="languages"
                [(ngModel)]="userInfoModel.languages"
              >
                <mat-option
                  *ngFor="let item of languageList"
                  [value]="item.languageId"
                  >{{ item.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="
                userInfoValidation.controls['languages'].invalid &&
                (userInfoValidation.controls['languages'].dirty ||
                  userInfoValidation.controls['languages'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  userInfoValidation.get('languages')?.hasError('required')
                "
                >{{
                  "providerProfile.requiredLanguageError" | translate
                }}</mat-error
              >
            </div>
          </div>
        </div>

        <div class="col-md-12" *ngIf="userType === 'Medical Providers'">
          <div class="form-group mb-3">
            <mat-form-field class="example-full-width">
              <mat-label>{{
                "appointment.selectTimezoneText" | translate
              }}</mat-label>
              <span matPrefix style="width: 20px; display: inline-block"
                ><img src="assets/images/timezone.svg" alt=""
              /></span>
              <mat-select
                placeholder="{{ 'appointment.selectTimezoneText' | translate }}"
                formControlName="timezone"
                [(ngModel)]="userInfoModel.timezone"
              >
                <input
                  type="text"
                  #searchTimezoneInput
                  (keyup)="searchTimeZone($event)"
                  (keydown)="_globalFunction.handleInput($event)"
                  placeholder="{{ 'common.searchTimezoneText' | translate }}"
                  class="search-input"
                />
                <mat-option
                  *ngFor="let tz of filteredTimezoneList"
                  [value]="tz"
                  >{{ tz.displayName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="
                userInfoValidation.controls['timezone'].invalid &&
                (userInfoValidation.controls['timezone'].dirty ||
                  userInfoValidation.controls['timezone'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="userInfoValidation.get('timezone')?.hasError('required')"
                >{{ "common.RequiredTimezoneError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
      </div>
      <div class="drawer-action">
        <button type="submit" class="btn btn-secondary">
          <img src="assets/images/save-icon.svg" alt="" class="me-2" />
          {{ "addUser.saveButtonText" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary ms-3"
          (click)="closeUserPopup()"
        >
          <img src="assets/images/white-close-icon.svg" alt="" class="me-2" />
          {{ "addUser.cancelButtonText" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

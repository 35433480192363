import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { StylePaginatorDirective } from "src/app/directives/style-paginator.directive";
import { superAdminApiList } from "src/app/enums/superAdminApiEnum";
import { UserService } from "src/app/modules/user/services/user.service";
import { CommonService } from "src/app/services/common.service";
import { SuperAdminService } from "../../services/super-admin.service";

@Component({
  selector: "app-clinic-list",
  templateUrl: "./clinic-list.component.html",
  styleUrls: ["./clinic-list.component.css"],
})
export class ClinicListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    "name",
    "email",
    "contact",
    "city",
    "status",
    "action",
  ];
  dataSource = new MatTableDataSource([]);
  @ViewChild(StylePaginatorDirective, { static: true })
  paginator!: StylePaginatorDirective;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @Input() searchData: string = "";
  @Input() refreshClinicData: boolean = false;
  @Output() viewClinicData = new EventEmitter<any>();
  @Output() editClinicId = new EventEmitter<any>();
  pageNumber: number = 1;
  totalRows: number = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  @Input() filterStatus: number = 0;
  @Input() filterCityId: number = 0;
  @Output() selectedPatients = new EventEmitter<any>();
  selectedPatientsArray: any = [];
  showPaginator: boolean = true;

  constructor(
    private _userService: UserService,
    private _commonService: CommonService,
    private _superAdminService: SuperAdminService
  ) {}

  ngOnInit(): void {
    this.loadClinicData();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.loadClinicData());
  }

  ngOnChanges() {
    this.filterData();
  }

  /**
   * Method to view clinic
   */
  public viewClinic(data: any) {
    this.getClinicDataByUserId(data);
  }

  /**
   * Method to edit patient
   */
  public editClinic(data: any) {
    this.editClinicId.emit(data.userId);
  }

  /**
   * Method to get clinic data
   */
  public loadClinicData(applyFilter: boolean = false) {
    if (this.sort.direction === "") this.sort.active = "";
    this.currentPage = applyFilter ? 0 : this.currentPage;
    let clinicParam = {
      cityId: this.filterCityId,
      status: this.filterStatus,
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      sortColumnName: this.sort.active,
      sortDirection: this.sort.direction === "asc" ? true : false,
    };
    this._superAdminService.getAllClinic(clinicParam).subscribe({
      next: (data) => {
        if (
          data &&
          data !== null &&
          data.data &&
          data.data !== null &&
          data.data.length > 0
        ) {
          this.dataSource = new MatTableDataSource(data.data);
        } else {
          this.dataSource = new MatTableDataSource([]);
        }
        this.totalRows = data.totalRecords;
        this.paginator.matPag.length = this.totalRows;
        if (applyFilter) {
          this.paginator._curPageObj.pageIndex = 0;
          this.paginator._rangeStart = 0;
          this.paginator._rangeEnd = 1;
        }
        this.paginator.initPageRange();
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to filter data
   */
  public pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadClinicData();
  }

  /**
   * Method to filter data
   */
  public filterData() {
    this.showPaginator = true;
    if (this.searchData.trim() !== "") {
      this.showPaginator = false;
    }
    this.refreshClinicGrid(this.refreshClinicData);
    this.dataSource.filter = this.searchData.trim().toLocaleLowerCase();
  }

  /**
   * Method to refresh grid
   */
  public refreshClinicGrid(isRefreshData: boolean) {
    if (isRefreshData) {
      this.loadClinicData();
    }
  }

  /**
   * Method to get clinic data by userid
   */
  public getClinicDataByUserId(clinicDetails: any) {
    let apiPath = superAdminApiList.getClinic;
    this._superAdminService
      .getClinicDataById(clinicDetails.userId, apiPath)
      .subscribe({
        next: (data) => {
          if (data && data !== null) {
            let clinicData = {
              aboutClinic: data.aboutClinic,
              address: data.address,
              clinicCode: data.clinicCode,
              clinicName: data.clinicName,
              einNumber: data.einNumber,
              email: data.email,
              phoneNumber: data.phoneCode + "-" + data.phoneNo,
              city: clinicDetails.city,
              photos: data.photos,
            };
            this.viewClinicData.emit(clinicData);
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
  }
}

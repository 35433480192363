import { Component, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { LoginModel } from "src/app/models/loginModel";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";

@Component({
  selector: "app-signup-email",
  templateUrl: "./signup-email.component.html",
  styleUrls: ["./signup-email.component.css"],
})
export class SignupEmailComponent {
  loginModel = new LoginModel();
  signupEmailValidation: FormGroup = new FormGroup("");
  formSubmitted: boolean = false;
  languageSelect: string = "en";
  languageList: any = [];
  @Output() activeLoginSignUpTab = new EventEmitter<number>();
  @Output() activeSignUpComponent = new EventEmitter<string>();

  constructor(
    private _formBuilder: FormBuilder,
    private _translate: TranslateService,
    private _commonService: CommonService,
    private _title: Title
  ) {
    this.createSignUpEmailForm();
    this.loadLanguages();
  }

  /**
   * Method to load languages
   */
  public loadLanguages() {
    this.languageList.push(
      {
        value: "en",
        text: "English",
      },
      {
        value: "pt",
        text: "Portuguese",
      },
      {
        value: "es",
        text: "Spanish",
      }
    );
  }

  /**
   * Method to create a signup email form
   */
  public createSignUpEmailForm() {
    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    this.signupEmailValidation = this._formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(emailPattern)]],
    });
  }

  /**
   * Method to submit a signup email form
   */
  public submitSignUpEmail() {
    this.loginModel.email = this.signupEmailValidation.get("email")?.value;
    let selectedLanguage = this.languageSelect;
    this.formSubmitted = true;
    if (this.signupEmailValidation.valid) {
      this._commonService.signupEmail(this.loginModel.email, "").subscribe({
        next: (data) => {
          if (
            data &&
            data.success &&
            data.success === true &&
            data.userId &&
            data.userId !== null
          ) {
            localStorage.setItem("signupEmail", this.loginModel.email);
            localStorage.setItem("Language", selectedLanguage);
            this._translate.use(selectedLanguage);
            this.activeSignUpComponent.emit("signupEmailVerification");
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
    }
  }

  /**
   * Method to go to login tab
   */
  public signIn() {
    this.activeLoginSignUpTab.emit(0);
  }

  /**
   * Method to change language
   */
  public changeLanguage(language: string) {
    localStorage.setItem("Language", language);
    this._translate.use(language);
  }
}

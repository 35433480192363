import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GlobalFunctions } from "src/app/app.global";
import { superAdminApiList } from "src/app/enums/superAdminApiEnum";
import { CountryModel } from "src/app/models/countryModel";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { environment } from "src/environments/environment";
import { OfficeStaffModel } from "../../models/officeStaffModel";
import { SuperAdminService } from "../../services/super-admin.service";

@Component({
  selector: "app-add-edit-officestaff-user",
  templateUrl: "./add-edit-officestaff-user.component.html",
  styleUrls: ["./add-edit-officestaff-user.component.css"],
})
export class AddEditOfficestaffUserComponent {
  officeStaffInfoModel = new OfficeStaffModel();
  officeStaffInfoValidation: FormGroup = new FormGroup("");
  formSubmitted: boolean = false;
  codeSelect: string = "";
  phoneCodeList: CountryModel[] = [];
  addEditOfficeStaffText: string = "";
  @Input() editOfficeStaffId: any = "";
  @Output() showOfficeStaffPopup = new EventEmitter<boolean>();
  @Output() refreshOfficeStaffData = new EventEmitter<boolean>();

  constructor(
    private _commonService: CommonService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrNotificationService,
    private _superAdminService: SuperAdminService,
    public _globalFunction: GlobalFunctions
  ) {}

  ngOnInit(): void {
    this.createOfficeStaffForm();
    this.getPhoneCode();
  }

  ngOnChanges(): void {
    if (this.editOfficeStaffId && this.editOfficeStaffId !== null) {
      this.addEditOfficeStaffText = "Edit";
      this.getOfficeStaffDataById(this.editOfficeStaffId);
    } else {
      this.addEditOfficeStaffText = "Add";
    }
  }

  /**
   * Method to create a office staff form
   */
  public createOfficeStaffForm() {
    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    this.officeStaffInfoValidation = this._formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(emailPattern),
          this._globalFunction.removeSpaces.bind(this),
        ],
      ],
      firstName: [
        "",
        [Validators.required, this._globalFunction.removeSpaces.bind(this)],
      ],
      lastName: [
        "",
        [Validators.required, this._globalFunction.removeSpaces.bind(this)],
      ],
      phoneCode: ["", [Validators.required]],
      phoneNumber: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(10),
          Validators.pattern("^[0-9]*$"),
        ]),
      ],
      role: ["", [Validators.required]],
    });
  }

  /**
   * Method to open add office staff section
   */
  public closeOfficeStaffPopup() {
    this.showOfficeStaffPopup.emit(false);
  }

  /**
   * Method to get phone code data
   */
  public getPhoneCode() {
    this._commonService.getCountry().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.phoneCodeList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to submit office staff data
   */
  public submitOfficeStaffInfo() {
    this.formSubmitted = true;
    if (this.officeStaffInfoValidation.valid) {
      this.officeStaffInfoModel.email =
        this.officeStaffInfoValidation.get("email")?.value;
      this.officeStaffInfoModel.firstName =
        this.officeStaffInfoValidation.get("firstName")?.value;
      this.officeStaffInfoModel.lastName =
        this.officeStaffInfoValidation.get("lastName")?.value;
      this.officeStaffInfoModel.phoneCode =
        this.officeStaffInfoValidation.get("phoneCode")?.value;
      this.officeStaffInfoModel.phoneNumber =
        this.officeStaffInfoValidation.get("phoneNumber")?.value;
      this.officeStaffInfoModel.role =
        this.officeStaffInfoValidation.get("role")?.value;

      let officeStaffParam: any = {};
      if (this.editOfficeStaffId && this.editOfficeStaffId !== null) {
        officeStaffParam["userId"] = this.editOfficeStaffId;
      } else {
        officeStaffParam["email"] = this.officeStaffInfoModel.email;
      }
      officeStaffParam["firstName"] = this.officeStaffInfoModel.firstName;
      officeStaffParam["lastName"] = this.officeStaffInfoModel.lastName;
      officeStaffParam["phoneCode"] = this.officeStaffInfoModel.phoneCode;
      officeStaffParam["phoneNo"] = this.officeStaffInfoModel.phoneNumber;
      officeStaffParam["role"] = this.officeStaffInfoModel.role;
      officeStaffParam["appURL"] = environment.superAdminAppUrl;

      let apiPath =
        this.editOfficeStaffId && this.editOfficeStaffId !== null
          ? superAdminApiList.updateOfficeStaff
          : superAdminApiList.addOfficeStaff;
      let successMessage =
        this.editOfficeStaffId && this.editOfficeStaffId !== null
          ? "manageOfficeStaffUsers.updateOfficeStaffSuccessMessage"
          : "manageOfficeStaffUsers.addOfficeStaffSuccessMessage";

      this.refreshOfficeStaffData.emit(false);
      this._superAdminService
        .addOfficeStaff(officeStaffParam, apiPath)
        .subscribe({
          next: (data) => {
            if (data && data.success && data.success === true) {
              this.refreshOfficeStaffData.emit(true);
              this._toastrService.showSuccess(successMessage);
              this.closeOfficeStaffPopup();
            }
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }

  /**
   * Method to get office staff data by userid
   */
  public getOfficeStaffDataById(Id: any) {
    this._superAdminService
      .getOfficeStaffDataById(Id, superAdminApiList.getOfficeStaff)
      .subscribe({
        next: (data) => {
          if (data && data !== null) {
            this.showOfficeStaffPopup.emit(true);
            this.officeStaffInfoModel.email = data.email;
            this.officeStaffInfoModel.firstName = data.firstName;
            this.officeStaffInfoModel.lastName = data.lastName;
            this.officeStaffInfoModel.phoneCode = data.phoneCode;
            this.officeStaffInfoModel.phoneNumber = data.phoneNo;
            this.officeStaffInfoModel.role = data.role.toString();
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
  }
}

import {
  Component,
  OnInit,
  OnChanges,
  AfterViewInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { StylePaginatorDirective } from "src/app/directives/style-paginator.directive";
import { CommonService } from "src/app/services/common.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-staff",
  templateUrl: "./staff.component.html",
  styleUrls: ["./staff.component.css"],
})
export class StaffComponent implements OnInit, OnChanges, AfterViewInit {
  displayedColumns: string[] = [
    "checkboxselect",
    "name",
    "email",
    "dob",
    "contact",
    "status",
    "action",
  ];
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(StylePaginatorDirective, { static: true })
  paginator!: StylePaginatorDirective;
  @Input() searchData: string = "";
  @Input() refreshUserData: boolean = false;
  @Output() viewUserData = new EventEmitter<any>();
  @Output() editUserId = new EventEmitter<any>();
  pageNumber: number = 1;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  @Input() filterStatus: number = 0;
  @Output() selectedStaff = new EventEmitter<any>();
  selectedStaffArray: any = [];
  showPaginator: boolean = true;

  constructor(
    private _userService: UserService,
    private _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.loadStaffData();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.loadStaffData());
  }

  ngOnChanges() {
    this.filterData();
  }

  /**
   * Method to view staff
   */
  public viewStaff(data: any) {
    this.viewUserData.emit(data);
  }

  /**
   * Method to edit staff
   */
  public editStaff(data: any) {
    this.editUserId.emit(data.userId);
  }

  /**
   * Method to get staff data
   */
  public loadStaffData(applyFilter: boolean = false) {
    if (this.sort.direction === "") this.sort.active = "";
    this.currentPage = applyFilter ? 0 : this.currentPage;
    let staffParam = {
      status: this.filterStatus,
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      sortColumnName: this.sort.active,
      sortDirection: this.sort.direction === "asc" ? true : false,
    };
    this._userService.getAllStaff(staffParam).subscribe({
      next: (data) => {
        if (
          data &&
          data !== null &&
          data.data &&
          data.data !== null &&
          data.data.length > 0
        ) {
          this.dataSource = new MatTableDataSource(data.data);
        } else {
          this.dataSource = new MatTableDataSource([]);
        }
        this.totalRows = data.totalRecords;
        this.paginator.matPag.length = this.totalRows;
        if (applyFilter) {
          this.paginator._curPageObj.pageIndex = 0;
          this.paginator._rangeStart = 0;
          this.paginator._rangeEnd = 1;
        }
        this.paginator.initPageRange();
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to filter data
   */
  public pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadStaffData();
  }

  /**
   * Method to filter data
   */
  public filterData() {
    this.showPaginator = true;
    if (this.searchData.trim() !== "") {
      this.showPaginator = false;
    }
    this.refreshStaffGrid(this.refreshUserData);
    this.dataSource.filter = this.searchData.trim().toLocaleLowerCase();
  }

  /**
   * Method to refresh grid
   */
  public refreshStaffGrid(isRefreshData: boolean) {
    if (isRefreshData) {
      this.loadStaffData();
    }
  }

  /**
   * Method to select staff
   */
  public selectStaff(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedStaffArray.push(event.source.name);
    } else {
      this.selectedStaffArray = this.selectedStaffArray.filter(function (
        item: any
      ) {
        return item !== event.source.name;
      });
    }
    this.selectedStaff.emit(this.selectedStaffArray);
  }

  /**
   * Method to select all staff
   */
  public selectAllStaff() {
    if (this.selectedStaffArray.length === this.dataSource.data.length) {
      this.selectedStaffArray = [];
    } else {
      this.selectedStaffArray = [];
      this.dataSource.data.forEach((data: any) =>
        this.selectedStaffArray.push(data.userId)
      );
    }
    this.selectedStaff.emit(this.selectedStaffArray);
  }
}

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-static-content-dialog",
  templateUrl: "./static-content-dialog.component.html",
  styleUrls: ["./static-content-dialog.component.css"],
})
export class StaticContentDialogComponent {
  pageTitle: string = "";
  staticContent: string = "";
  closeButtonText = "common.closeText";

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<StaticContentDialogComponent>
  ) {
    if (data) {
      this.pageTitle = data.pageTitle || this.pageTitle;
      this.staticContent = data.staticContent || this.staticContent;
    }
  }

  onCloseClick(): void {
    this.dialogRef.close(true);
  }
}

<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/icon-static-content.svg" alt="" />
        </div>
        <h2>
          {{ "staticContent.staticContentHeadingText" | translate }}
        </h2>
      </div>
      <div class="page-title-right">
        <div class="search">
          <div class="form-group">
            <mat-form-field class="example-form-field">
              <input
                matInput
                [(ngModel)]="searchText"
                type="text"
                placeholder="{{ 'userList.searchUserPlaceholder' | translate }}"
                (keyup)="searchDataByKeyword()"
              />
              <button matSuffix>
                <img src="assets/images/icon-search.svg" alt="" />
              </button>
            </mat-form-field>
          </div>
        </div>
        <button class="btn btn-primary ms-3" (click)="addStaticContent()">
          <img src="assets/images/plus-icon.svg" alt="" class="me-2" />
          {{ "staticContent.addContentText" | translate }}
        </button>
      </div>
    </div>

    <div class="content-page">
      <app-static-content
        [refreshStaticContentData]="refreshStaticContentGrid"
        [searchData]="searchString"
        #staticContent
      ></app-static-content>
    </div>
  </div>
</div>

<div class="error-page">
  <div class="error-content">
    <div class="error-page-img">
      <img src="assets/images/401-img.png" alt="" />
    </div>
    <div class="error-text">
      <div class="no-author-text">
        <h3>{{ "noAuthorizationError.noAuthorizationErrorHeadingText" | translate }}</h3>
      </div>
      <div class="page-not-available">
        <p>{{ "noAuthorizationError.noAuthorizationErrorText1" | translate }}</p>
        <p>{{ "noAuthorizationError.noAuthorizationErrorText2" | translate }}</p>
      </div>
      <button class="btn btn-primary text-uppercase mt-5" (click)="goToHomePage()">
        <img src="assets/images/back-icon.svg" alt="" class="me-2" />
        {{ "common.backToHomeButtonText" | translate }}
      </button>
    </div>
  </div>
</div>

import { Component, ViewChild } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { TranslateService } from "@ngx-translate/core";
import { CityModel } from "src/app/models/cityModel";
import { StateModel } from "src/app/models/stateModel";
import { CommonService } from "src/app/services/common.service";
import { CustomPageTitleStrategy } from "src/app/services/custom-page-title-strategy";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { SuperAdminService } from "../../services/super-admin.service";
import { ClinicListComponent } from "../clinic-list/clinic-list.component";
import { ClinicRequestListComponent } from "../clinic-request-list/clinic-request-list.component";

@Component({
  selector: "app-manage-clinic",
  templateUrl: "./manage-clinic.component.html",
  styleUrls: ["./manage-clinic.component.css"],
})
export class ManageClinicComponent {
  filter: boolean = false;
  showFilterList: any = [];
  rightDrawer: boolean = false;
  tabIndex = 0;
  searchText: string = "";
  searchString: string = "";
  addEditClinic: boolean = false;
  viewClinic: boolean = false;
  viewEditClinicRequest: boolean = false;
  refreshClinicGrid: boolean = false;
  refreshClinicRequestGrid: boolean = false;
  viewClinicData: any;
  viewClinicRequestData: any;
  viewEditMode: string = "";
  editClinicId: any;
  filterStateValue: number = 0;
  filterCityValue: number = 0;
  filterStatusValue: number = 0;
  filterUpdatedByValue: any = null;
  @ViewChild(ClinicListComponent, { static: false })
  clinicList!: ClinicListComponent;
  @ViewChild(ClinicRequestListComponent, { static: false })
  clinicRequests!: ClinicRequestListComponent;
  tabName: string = "";
  breadcrumbs: any = [];

  constructor(
    private _customPageTitleStrategy: CustomPageTitleStrategy,
    private _commonService: CommonService,
    private _superAdminService: SuperAdminService,
    private _translate: TranslateService,
    private _toastrService: ToastrNotificationService
  ) {}

  ngOnInit(): void {
    this._translate
      .get("manageClinics.clinicsTabName")
      .subscribe((data: string) => {
        this.tabName = data;
      });

    this.setFilterFields();
  }

  /**
   * Method to set filter fields
   */
  public setFilterFields() {
    this.showFilterList = [];
    if (this.tabIndex === 0) {
      this.showFilterList.push("State");
      this.showFilterList.push("City");
      this.showFilterList.push("Status");
    }
    if (this.tabIndex === 1) {
      this.showFilterList.push("UpdateBy");
      this.showFilterList.push("ClinicRequestStatus");
    }
  }

  /**
   * Method to toggle filter section
   */
  public filterClick() {
    this.filter = !this.filter;
  }

  /**
   * Method to get filter data
   */
  public getFilterData(data: any) {
    this.filterStateValue = data.filterStateValue;
    this.filterCityValue = data.filterCityValue;
    this.filterStatusValue = data.filterStatusValue;
    this.filterUpdatedByValue = data.filterUpdatedByValue;
  }

  /**
   * Method to apply filter data
   */
  public applyFilterData() {
    this.loadClinicGrid(true);
  }

  /**
   * Method to open add clinic section
   */
  public addClinic() {
    this.rightDrawer = !this.rightDrawer;
    this.addEditClinic = true;
    this.viewClinic = false;
    this.viewEditClinicRequest = false;
    this.editClinicId = "";
  }

  /**
   * Method to open view clinic section
   */
  public viewClinicPopup(data: any) {
    this.rightDrawer = !this.rightDrawer;
    this.addEditClinic = false;
    this.viewClinic = true;
    this.viewEditClinicRequest = false;
    this.viewClinicData = data;
  }

  /**
   * Method to open view clinic request section
   */
  public viewClinicRequestPopup(data: any) {
    this.rightDrawer = !this.rightDrawer;
    this.addEditClinic = false;
    this.viewClinic = false;
    this.viewEditClinicRequest = true;
    this.viewClinicRequestData = data;
  }

  /**
   * Method to open edit clinic section
   */
  public editClinicPopup(clinicId: any) {
    this.addEditClinic = true;
    this.viewClinic = false;
    this.editClinicId = clinicId;
  }

  /**
   * Method to show/hide clinic section
   */
  public showHideClinicPopup(showClinicPopup: any): void {
    this.rightDrawer = showClinicPopup;
    this.addEditClinic = showClinicPopup ? true : false;
    this.viewClinic = false;
  }

  /**
   * Method to show/hide clinic request section
   */
  public showHideClinicRequestPopup(showClinicRequestPopup: any): void {
    this.rightDrawer = showClinicRequestPopup;
    this.viewEditClinicRequest = showClinicRequestPopup ? true : false;
  }

  /**
   * Method to load clinic data after add/update clinic
   */
  public loadClinicData(isRefreshData: boolean): void {
    this.refreshClinicGrid = isRefreshData;
  }

  /**
   * Method to load clinic request data after update clinic registration request
   */
  public loadClinicRequestData(isRefreshData: boolean): void {
    this.refreshClinicRequestGrid = isRefreshData;
  }

  /**
   * Method to changes clinic tab
   */
  public changeTab(event: MatTabChangeEvent) {
    this.tabIndex = event.index;
    this.tabName = event.tab.textLabel;
    this.setFilterFields();
  }

  /**
   * Method to search data
   */
  public searchDataByKeyword() {
    this.refreshClinicGrid = false;
    this.searchString = this.searchText;
  }

  /**
   * Method to load clinic grid
   */
  public loadClinicGrid(applyFilter: boolean = false) {
    if (this.tabIndex === 0) {
      this.clinicList.filterCityId = this.filterCityValue;
      this.clinicList.filterStatus = this.filterStatusValue;
      this.clinicList.loadClinicData(applyFilter);
    }
    if (this.tabIndex === 1) {
      this.clinicRequests.filterStatus = this.filterStatusValue;
      this.clinicRequests.filterUpdatedBy = this.filterUpdatedByValue;
      this.clinicRequests.loadClinicRequestsData(applyFilter);
    }
  }
}

<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/notification.svg" alt="" />
        </div>
        <h2>
          {{ "notification.notificationHeadingText" | translate }}
        </h2>
      </div>
      <div class="page-title-right">
        <div class="search">
          <div class="form-group"></div>
        </div>
        <button
          class="btn btn-primary ms-3"
          (click)="filterClick()"
          [ngClass]="filter ? 'active' : ''"
        >
          <img src="assets/images/filter-icon.svg" alt="" />
        </button>
      </div>
    </div>

    <app-filter
      (filterData)="getFilterData($event)"
      (applyFilterData)="applyFilterData()"
      [filter]="filter"
      [showFilterList]="showFilterList"
    ></app-filter>

    <div class="content-page">
      <app-notification #notification></app-notification>
    </div>
  </div>
</div>

import { Component, ViewChild, OnInit, AfterViewInit } from "@angular/core";
import {
  createLocalAudioTrack,
  Room,
  LocalTrack,
  LocalVideoTrack,
  LocalAudioTrack,
  RemoteParticipant,
} from "twilio-video";
import { RoomsComponent } from "../rooms/rooms.component";
import { CameraComponent } from "../camera/camera.component";
import { LiveConsultationSettingsComponent } from "../settings/settings.component";
import { ParticipantsComponent } from "../participants/participants.component";
import { NamedRoom, VideoChatService } from "../../services/videochat.service";
// import {
//   HubConnection,
//   HubConnectionBuilder,
//   LogLevel,
// } from "@microsoft/signalr";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { DeviceService } from "../../services/device.service";

@Component({
  selector: "app-home",
  styleUrls: ["./home.component.css"],
  templateUrl: "./home.component.html",
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild("rooms") rooms!: RoomsComponent;
  @ViewChild("camera") camera!: CameraComponent;
  @ViewChild("settings") settings!: LiveConsultationSettingsComponent;
  @ViewChild("participants") participants!: ParticipantsComponent;
  showProviderVideo: boolean = true;
  showPatientVideo: boolean = true;

  activeRoom!: any;
  appointmentRoomId: any = "";
  roomName: any;
  appointmentId: any;
  joinRequestRoomName: any;
  isMuteAudio: boolean = false;
  isShowVideo: boolean = true;
  patientName: string;

  // private notificationHub!: HubConnection;

  constructor(
    private readonly videoChatService: VideoChatService,
    private deviceService: DeviceService,
    private _commonService: CommonService,
    private _toastrService: ToastrNotificationService,
    private _router: Router,
    private route: ActivatedRoute
  ) {
    // let videoCallData = this._router.getCurrentNavigation()?.extras.state;
    // if (videoCallData) {
    this.roomName = this.route.snapshot.params["roomName"];
    this.appointmentId = this.route.snapshot.params["appointmentId"];
    this.patientName = this.route.snapshot.params["patientName"];
    // }
  }

  async ngOnInit() {
    // const builder = new HubConnectionBuilder()
    //   .configureLogging(LogLevel.Information)
    //   .withUrl(`${environment.videoApiURL}/notificationHub`);
    // this.notificationHub = builder.build();
    // this.notificationHub.on("RoomsUpdated", async (updated) => {
    //   if (updated) {
    //     await this.rooms.updateRooms();
    //   }
    // });
    // await this.notificationHub.start();
  }

  ngAfterViewInit(): void {
    // setTimeout(() => this.onRoomChanged(this.roomName), 0);
    setTimeout(() => this.getRoomByAppointmentId(), 0);
  }

  async onSettingsChanged(deviceInfo: any) {
    await this.camera.initializePreview(deviceInfo.deviceId);
    if (this.settings.isPreviewing) {
      const track = await this.settings.showPreviewCamera();
      if (this.activeRoom) {
        const localParticipant = this.activeRoom.localParticipant;
        localParticipant.videoTracks.forEach((publication: any) =>
          publication.unpublish()
        );
        await localParticipant.publishTrack(track);
      }
    }
  }

  async showHideCamera(showVideo: boolean) {
    if (this.activeRoom && this.activeRoom.localParticipant) {
      this.activeRoom.localParticipant.videoTracks.forEach(
        (publication: any) => {
          if (showVideo) {
            publication.track.enable();
            this.showProviderVideo = true;
          } else {
            publication.track.disable();
            this.showProviderVideo = false;
          }
        }
      );
      this.isShowVideo = showVideo;
    }
  }

  async onLeaveRoom() {
    if (this.activeRoom) {
      //For hide the camera
      this.activeRoom.localParticipant.audioTracks.forEach(
        (publication: any) => {
          const attachedElements = publication.track.detach();
          publication.track.stop();
          publication.track.disable();
          attachedElements.forEach((element: any) => element.remove());
        }
      );

      this.activeRoom.localParticipant.videoTracks.forEach(
        (publication: any) => {
          const attachedElements = publication.track.detach();
          publication.track.stop();
          publication.track.disable();
          attachedElements.forEach((element: any) => element.remove());
        }
      );

      this.activeRoom.localParticipant.tracks.forEach((publication: any) => {
        publication.track.stop();
        const attachedElements = publication.track.detach();
        attachedElements.forEach((element: any) => element.remove());
      });

      this.activeRoom.disconnect();
      this.activeRoom = null;

      //Clear room in database on disconnect video call
      this.deleteAppointmentRoom();
    }

    // const videoDevice = this.settings.hidePreviewCamera();
    // if (videoDevice)
    //   await this.camera.initializePreview(videoDevice && videoDevice.deviceId);

    this.participants.clear();
    // await this.camera.initializePreview("");
    this._router.navigate(["/appointments"]);
  }

  async onMuteUnmuteAudio(isUnmute: boolean) {
    this.isMuteAudio = isUnmute;
    var localParticipant = this.activeRoom.localParticipant;
    if (localParticipant) {
      localParticipant.audioTracks.forEach(function (audioTrack: any) {
        if (isUnmute) audioTrack.track.disable();
        else audioTrack.track.enable();
      });
    }
  }

  async onRoomChanged(roomName: string) {
    const isGranted = await this.deviceService.isGrantedMediaPermissions();
    if (!isGranted) {
      return this._toastrService.showError(
        "liveConsultation.deviceNotFoundError"
      );
    }
    if (roomName) {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
      }

      this.camera.finalizePreview();

      const tracks = await Promise.all([
        createLocalAudioTrack(),
        this.settings.showPreviewCamera(),
      ]);

      this.activeRoom = await this.videoChatService.joinOrCreateRoom(
        roomName,
        tracks
      );

      if (this.participants && this.activeRoom) {
        this.activeRoom.participants.forEach((participant: any) => {
          //show/hide video according to patient settings
          participant.tracks.forEach((track: any) => {
            if (track.kind === "video") {
              if (track.isTrackEnabled) {
                this.showPatientVideo = true;
              } else {
                this.showPatientVideo = false;
              }
            }
          });
        });
        this.participants.initialize(this.activeRoom.participants);
      }

      this.registerRoomEvents();

      // this.notificationHub.send("RoomsUpdated", true);
    }

    if (this.activeRoom) {
      let currentUserRoom = await this.getRoomByUniqueName(roomName);
      if (currentUserRoom && currentUserRoom !== null) {
        console.log("RoomId: " + currentUserRoom.id);
        console.log("RoomName: " + currentUserRoom.name);
        if (this.appointmentRoomId !== currentUserRoom.id) {
          this.updateAppointmentRoom(currentUserRoom.id);
        }
      }
    }
  }

  onParticipantsChanged(_: boolean) {
    this.videoChatService.nudge();
  }

  private registerRoomEvents() {
    this.activeRoom
      .on("disconnected", (room: Room) => {
        room.localParticipant.tracks.forEach((publication) =>
          this.detachLocalTrack(publication.track)
        );
        this.onLeaveRoom();
      })
      .on("participantConnected", (participant: RemoteParticipant) =>
        this.participants.add(participant)
      )
      .on("participantDisconnected", (participant: RemoteParticipant) => {
        this.onLeaveRoom();
        this.participants.remove(participant);
      })
      .on("dominantSpeakerChanged", (dominantSpeaker: RemoteParticipant) =>
        this.participants.loudest(dominantSpeaker)
      )
      .on("trackDisabled", (track: any) => {
        if (track.kind === "video") this.showPatientVideo = false;
      })
      .on("trackEnabled", (track: any) => {
        if (track.kind === "video") this.showPatientVideo = true;
      });
  }

  private detachLocalTrack(track: LocalTrack) {
    if (this.isDetachable(track)) {
      track.detach().forEach((el) => el.remove());
    }
  }

  private isDetachable(
    track: LocalTrack
  ): track is LocalAudioTrack | LocalVideoTrack {
    return (
      !!track &&
      ((track as LocalAudioTrack).detach !== undefined ||
        (track as LocalVideoTrack).detach !== undefined)
    );
  }

  async getAllRooms() {
    return (await this.videoChatService.getAllRooms()) as NamedRoom[];
  }

  async getRoomByUniqueName(roomName: string) {
    return (await this.videoChatService.getRoomByUniqueName(
      roomName
    )) as NamedRoom;
  }

  /**
   * Method to update appointment room
   */
  public updateAppointmentRoom(roomId: any) {
    this._commonService
      .updateAppointmentRoom(this.appointmentId, roomId)
      .subscribe({
        next: (data) => {
          if (data && data.success && data.success === true) {
            console.log("Room updated in appointment");
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
  }

  /**
   * Method to get room by appointment id
   */
  public async getRoomByAppointmentId() {
    this._commonService.getRoomByAppointmentId(this.appointmentId).subscribe({
      next: (data) => {
        if (
          data &&
          data !== null &&
          (data.errorCode === "" || data.errorCode === "RoomNotFound")
        ) {
          this.appointmentRoomId = data.roomId;
          this.onRoomChanged(this.roomName);
        } else if (
          data &&
          data !== null &&
          data.success === false &&
          data.errorCode !== ""
        ) {
          return this._toastrService.showError("ErrorCode." + data.errorCode);
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to delete appointment room
   */
  public deleteAppointmentRoom() {
    this._commonService.deleteAppointmentRoom(this.appointmentId).subscribe({
      next: (data) => {
        if (data && data.success && data.success === true) {
          console.log("Room deleted in appointment");
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }
}

import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginModel } from "src/app/models/loginModel";
import { TokenModel } from "src/app/models/tokenModel";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { TranslateService } from "@ngx-translate/core";
import { SuperAdminService } from "../../services/super-admin.service";
import { UserTypeEnum } from "src/app/enums/userTypeEnum";
import { superAdminApiList } from "src/app/enums/superAdminApiEnum";
import { clinicApiList } from "src/app/enums/clinicApiEnum";
import { ClinicService } from "src/app/modules/clinic/services/clinic.service";
import * as moment from "moment-timezone";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class SuperAdminLoginComponent implements OnInit {
  loginModel = new LoginModel();
  loginValidation: FormGroup = new FormGroup("");
  formSubmitted: boolean = false;
  showPassword: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _superAdminService: SuperAdminService,
    private _commonService: CommonService,
    private _clinicService: ClinicService,
    private _authService: AuthGuardService,
    private _router: Router,
    private _toastrService: ToastrNotificationService,
    public _translate: TranslateService
  ) {
    this.createLoginForm();
  }

  ngOnInit() {}

  /**
   * Method to create a login form
   */
  public createLoginForm() {
    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    this.loginValidation = this._formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(emailPattern)]],
      password: ["", [Validators.required]],
      rememberMe: [""],
    });

    //set user credentials for remember me
    let email = localStorage.getItem("email");
    let password = localStorage.getItem("password");
    if (email !== null && password !== null) {
      this.loginModel.email = email || "";
      this.loginModel.password = password || "";
    }
  }

  /**
   * Method to submit login
   */
  public submitLogin() {
    this.formSubmitted = true;
    if (this.loginValidation.valid) {
      this.loginModel.email = this.loginValidation.get("email")?.value;
      this.loginModel.password = this.loginValidation.get("password")?.value;

      this._superAdminService
        .login(this.loginModel.email, this.loginModel.password)
        .subscribe({
          next: (data) => {
            if (
              data &&
              data.accessToken &&
              data.expiresOn &&
              data.refreshToken
            ) {
              const userTokenDetails: TokenModel = {
                access_token: data.accessToken,
                expiry_date: data.expiresOn,
                refresh_token: data.refreshToken,
              };
              //this._toastrService.showSuccess("login.loginSuccessMessage");
              let userData = this._authService.getDecodedAccessToken(
                userTokenDetails.access_token
              );
              this._authService.loggedInUserData.next(userData);
              if (
                userData &&
                userData.isresetpassword &&
                userData.isresetpassword === "true" &&
                userData.userid &&
                userData.userid !== null
              ) {
                localStorage.setItem(
                  "ForgotPasswordEmail",
                  this.loginModel.email
                );
                localStorage.setItem("ResetPasswordUserId", userData.userid);
                this._authService.loggedIn.next(false);
                this._router.navigate(["/superadmin/reset-password"]);
              } else {
                localStorage.setItem(
                  "userTokenDetails",
                  JSON.stringify(userTokenDetails)
                );
                this.getNotificationData(userData);
                this._authService.loggedIn.next(true);
                this._commonService.redirectToLandingPage(userData.usertypeid);
              }
            }
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }

  /**
   * Method to show/hide password
   */
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * Method to get notification data
   */
  public getNotificationData(userData: any) {
    if (userData && userData !== null) {
      //Get only last 30 days data
      let currentDate = this._commonService.getCurrentTimeZoneNewDate();
      currentDate.setDate(currentDate.getDate() - 29);
      let filterStartDate = currentDate;
      let filterEndDate = this._commonService.getCurrentTimeZoneNewDate();

      let notificationParam = {
        sortColumnName: "",
        sortDirection: true,
        pageIndex: 0,
        pageSize: 10,
        fromDate: filterStartDate,
        toDate: filterEndDate,
        isReadAll: false,
        offset: moment().tz(moment.tz.guess()).utcOffset(),
      };
      let apiPath = superAdminApiList.getNotificationLogs;
      this._clinicService
        .getNotificationLogs(notificationParam, apiPath)
        .subscribe({
          next: (data) => {
            if (data && data !== null) {
              localStorage.setItem(
                "unReadNotificationCount",
                data.notificationCount
              );
              this._authService.unReadNotificationData.next(true);
            }
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }
}

import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, throwError } from "rxjs";
import { clinicApiList } from "src/app/enums/clinicApiEnum";
import { CommonService } from "src/app/services/common.service";
import { RestAPIService } from "src/app/services/restapi.service";
import { environment } from "src/environments/environment";
import { UserModel } from "../models/userModel";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private _commonService: CommonService,
    private _restAPIService: RestAPIService
  ) {}

  /**
   * Method to get all medical providers
   */
  public getAllProvider(getProviderParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    let providerParam = {
      specialtyId: getProviderParam.specialtyId,
      status: getProviderParam.status,
      pageIndex: getProviderParam.pageIndex,
      pageSize: getProviderParam.pageSize,
      sortColumnName: getProviderParam.sortColumnName,
      sortDirection: getProviderParam.sortDirection,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.getAllMedicalProvider,
        providerParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all staff
   */
  public getAllStaff(getStaffParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    let staffParam = {
      status: getStaffParam.status,
      pageIndex: getStaffParam.pageIndex,
      pageSize: getStaffParam.pageSize,
      sortColumnName: getStaffParam.sortColumnName,
      sortDirection: getStaffParam.sortDirection,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.getAllStaff,
        staffParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all patients
   */
  public getAllPatient(getPatientParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    let patientParam = {
      status: getPatientParam.status,
      pageIndex: getPatientParam.pageIndex,
      pageSize: getPatientParam.pageSize,
      sortColumnName: getPatientParam.sortColumnName,
      sortDirection: getPatientParam.sortDirection,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.getAllPatient,
        patientParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to add user
   */
  public addUser(userParam: UserModel, apiPath: string): Observable<any> {
    return this._restAPIService
      .post<any>(environment.apiURL + apiPath, userParam)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get user data by id
   */
  public getUserDataById(userId: any, apiPath: string): Observable<any> {
    return this._restAPIService
      .get<any>(environment.apiURL + apiPath + "?UserId=" + userId)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to send invite to users
   */
  public sendInvite(userInviteParams: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.sendInvite,
        userInviteParams,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }
}

import { Component, ViewChild, OnInit } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { GlobalFunctions } from "src/app/app.global";
import { UserTypeEnum } from "src/app/enums/userTypeEnum";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { AdminTransactionHistoryComponent } from "../admin-transaction-history/admin-transaction-history.component";
import { ClinicTransactionHistoryComponent } from "../clinic-transaction-history/clinic-transaction-history.component";

@Component({
  selector: "app-transaction-history-list",
  templateUrl: "./transaction-history-list.component.html",
  styleUrls: ["./transaction-history-list.component.css"],
})
export class TransactionHistoryListComponent implements OnInit {
  filter: boolean = false;
  showFilterList: any = [
    "TransactionDate",
    "TransactionFor",
    "TransactionHistory",
  ];
  rightDrawer: boolean = false;
  showFilter: boolean = true;
  tabIndex = 0;
  searchText: string = "";
  searchString: string = "";
  refreshTransactionHistoryGrid: boolean = false;
  filterStartDateValue: any = "";
  filterEndDateValue: any = "";
  filterPatientValue: any = null;
  filterProviderValue: any = null;
  filterVisitTypeValue: any = 0;
  filterTransactionForValue: number = 0;
  filterTransactionTypeValue: number = 0;
  filterClinicValue: any = null;
  @ViewChild(ClinicTransactionHistoryComponent, { static: false })
  clinicTransactionHistory!: ClinicTransactionHistoryComponent;
  @ViewChild(AdminTransactionHistoryComponent, { static: false })
  adminTransactionHistory!: AdminTransactionHistoryComponent;
  addRefund: boolean = false;
  viewTransaction: boolean = false;
  viewTransactionData: any;
  userRole: string = "";
  userId: any;

  constructor(
    private _authService: AuthGuardService,
    public _globalFunction: GlobalFunctions
  ) {}

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
        this.userId = data["userid"];
      }
    });
    if (
      this.userRole !== UserTypeEnum.SuperAdmin.toString() &&
      this.userRole !== UserTypeEnum.OfficeStaff.toString()
    ) {
      this.showFilterList.push("Patient");
      this.showFilterList.push("Provider");
      this.showFilterList.push("VisitType");
      this.showFilterList.push("TransactionType");
    }
    if (
      this.userRole === UserTypeEnum.SuperAdmin.toString() ||
      this.userRole === UserTypeEnum.OfficeStaff.toString()
    ) {
      this.showFilterList.push("Clinic");
    }
  }

  /**
   * Method to changes tab
   */
  public changeTab(event: MatTabChangeEvent) {
    this.tabIndex = event.index;
    if (this.tabIndex === 1) {
      this.showFilter = false;
    } else {
      this.showFilter = true;
    }
  }

  /**
   * Method to toggle filter section
   */
  public filterClick() {
    this.filter = !this.filter;
  }

  /**
   * Method to get filter data
   */
  public getFilterData(data: any) {
    this.filterStartDateValue = data.filterStartDateValue;
    this.filterEndDateValue = data.filterEndDateValue;
    this.filterPatientValue = data.filterPatientValue;
    this.filterProviderValue = data.filterProviderValue;
    this.filterVisitTypeValue = data.filterVisitTypeValue;
    this.filterClinicValue = data.filterClinicValue;
    this.filterTransactionForValue = data.filterTransactionForValue;
    this.filterTransactionTypeValue = data.filterTransactionTypeValue;
  }

  /**
   * Method to apply filter data
   */
  public applyFilterData() {
    this.loadTransactionHistoryGrid(true);
  }

  /**
   * Method to open add refund section
   */
  public addNewRefund() {
    this.rightDrawer = !this.rightDrawer;
    this.addRefund = true;
    this.viewTransaction = false;
  }

  /**
   * Method to show/hide popup section
   */
  public showHidePopup(ShowPopup: any): void {
    this.rightDrawer = ShowPopup;
    this.addRefund = ShowPopup;
  }

  /**
   * Method to load transaction history data
   */
  public loadTransactionHistoryData(isRefreshData: boolean): void {
    this.refreshTransactionHistoryGrid = isRefreshData;
  }

  /**
   * Method to search data
   */
  public searchDataByKeyword() {
    this.refreshTransactionHistoryGrid = false;
    this.searchString = this.searchText;
  }

  /**
   * Method to load transaction history grid
   */
  public loadTransactionHistoryGrid(applyFilter: boolean = false) {
    if (this.userRole === UserTypeEnum.Clinic.toString()) {
      this.clinicTransactionHistory.filterStartDate = this.filterStartDateValue;
      this.clinicTransactionHistory.filterEndDate = this.filterEndDateValue;
      this.clinicTransactionHistory.filterPatient = this.filterPatientValue;
      this.clinicTransactionHistory.filterProvider = this.filterProviderValue;
      this.clinicTransactionHistory.filterVisitType = this.filterVisitTypeValue;
      this.clinicTransactionHistory.filterTransactionFor =
        this.filterTransactionForValue;
      this.clinicTransactionHistory.filterTransactionType =
        this.filterTransactionTypeValue;
      this.clinicTransactionHistory.loadClinicTransactionHistoryData(
        applyFilter
      );
    }
    if (
      this.userRole === UserTypeEnum.SuperAdmin.toString() ||
      this.userRole === UserTypeEnum.OfficeStaff.toString()
    ) {
      this.adminTransactionHistory.filterStartDate = this.filterStartDateValue;
      this.adminTransactionHistory.filterEndDate = this.filterEndDateValue;
      this.adminTransactionHistory.filterClinic = this.filterClinicValue;
      this.adminTransactionHistory.filterTransactionFor =
        this.filterTransactionForValue;
      this.adminTransactionHistory.loadAdminTransactionHistoryData(applyFilter);
    }
  }

  /**
   * Method to open view transaction details section
   */
  public viewTransactionDetailsPopup(data: any) {
    this.rightDrawer = !this.rightDrawer;
    this.viewTransaction = true;
    this.viewTransactionData = data;
    this.addRefund = false;
  }
}

import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationStart,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { TokenModel } from "../models/tokenModel";
import { BehaviorSubject, Observable } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthGuardService } from "./auth-guard.service";

@Injectable({
  providedIn: "root",
})
export class RoleGuardService implements CanActivate {
  constructor(private router: Router, private _authService: AuthGuardService) {}

  /**
   * Method to check user access while accessing url
   */
  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let isAccess = this.isAuthorized(route);
    if (isAccess) {
      return true;
    } else {
      this.router.navigate(["/unauthorization"]);
      return false;
    }
  }

  public isAuthorized(route: ActivatedRouteSnapshot): boolean {
    const currentUserDetails: any = this._authService.getUserDetails() || null;
    if (currentUserDetails && currentUserDetails !== null) {
      const currentUserRole = currentUserDetails.usertypeid;
      const expectedRoles = route.data["expectedRoles"];
      const rollMatches = expectedRoles.includes(parseInt(currentUserRole));
      return rollMatches;
    }
    return false;
  }
}

import { Component, OnInit, Renderer2 } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordValidation: FormGroup = new FormGroup("");
  formSubmitted: boolean = false;
  forgotPasswordEmail: string = "";
  passwordStrength: number = 0;
  showPasswordStrength: boolean = true;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  isValidPassword: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _toastrService: ToastrNotificationService,
    private _router: Router,
    private _renderer: Renderer2,
    public _translate: TranslateService
  ) {
    this.forgotPasswordEmail =
      localStorage.getItem("ForgotPasswordEmail") || "";
    this.createResetPasswordForm();
    this._renderer.listen("window", "click", (e: Event) => {
      this.showPasswordStrength = false;
    });
  }

  ngOnInit(): void {
    if (!this.forgotPasswordEmail) {
      this._router.navigate(["/forgot-password"]);
    }
  }

  /**
   * Method to create a reset password form
   */
  public createResetPasswordForm() {
    this.resetPasswordValidation = this._formBuilder.group({
      password: ["", [Validators.compose([Validators.required])]],
      confirmPassword: [
        "",
        [
          Validators.compose([
            Validators.required,
            this.validatePasswordMatch.bind(this),
          ]),
        ],
      ],
    });
  }

  /**
   * Method to match the password and confirm password
   */
  private validatePasswordMatch(fieldControl: FormControl) {
    if (
      fieldControl.value !== this.resetPasswordValidation.get("password")?.value
    ) {
      return { NotEqual: true };
    } else {
      return null;
    }
  }

  /**
   * Method to submit reset password
   */
  public submitResetPassword() {
    if (
      this.resetPasswordValidation.get("password")?.value !==
      this.resetPasswordValidation.get("confirmPassword")?.value
    ) {
      return this._toastrService.showError(
        "common.passwordConfirmPasswordNotMatchError"
      );
    }

    this.formSubmitted = true;
    if (this.resetPasswordValidation.valid && this.isValidPassword) {
      let email = this.forgotPasswordEmail;
      let password = this.resetPasswordValidation.get("password")?.value;
      let userId = localStorage.getItem("ResetPasswordUserId") || "";

      this._commonService.resetPassword(email, password, userId).subscribe({
        next: (data) => {
          if (data && data.success) {
            localStorage.removeItem("ForgotPasswordEmail");
            this._toastrService.showSuccess(
              "resetpassword.resetPasswordSuccessMessage"
            );
            this._router.navigate(["/login"]);
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
    }
  }

  /**
   * Method to set password strength
   */
  public onPasswordStrengthChanged(strength: number) {
    this.passwordStrength = strength;
    if (this.passwordStrength > 80) {
      this.isValidPassword = true;
    }
  }

  /**
   * Method when password changes
   */
  public onPasswordChange(data: Event): void {
    this.resetPasswordValidation.patchValue({
      confirmPassword:
        this.resetPasswordValidation.get("confirmPassword")?.value,
    });

    this.showPasswordStrength = true;
    if (this.passwordStrength > 80) {
      this.isValidPassword = true;
      this.showPasswordStrength = false;
      this.passwordStrength = 0;
    } else {
      this.showPasswordStrength = true;
    }
  }

  /**
   * Method to show/hide password
   */
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * Method to show/hide confirm password
   */
  public toggleConfirmPasswordVisibility(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}

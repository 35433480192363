import { Component, ViewChild } from "@angular/core";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { SchedulerComponent } from "../scheduler/scheduler.component";

@Component({
  selector: "app-scheduler-list",
  templateUrl: "./scheduler-list.component.html",
  styleUrls: ["./scheduler-list.component.css"],
})
export class SchedulerListComponent {
  filter: boolean = false;
  showFilterList: any = ["VisitType", "StartDateEndDate"];
  rightDrawer: boolean = false;
  searchText: string = "";
  searchString: string = "";
  addEditScheduler: boolean = false;
  refreshSchedulerGrid: boolean = false;
  editSchedulerId: any;
  filterProviderValue: any = "";
  filterVisitTypeValue: any = "";
  filterStartDateValue: any = "";
  filterEndDateValue: any = "";
  @ViewChild(SchedulerComponent, { static: false })
  scheduler!: SchedulerComponent;
  userRole: string = "";
  userId: any;

  constructor(private _authService: AuthGuardService) {}

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
        this.userId = data["userid"];
      }
    });
    if (this.userRole !== "3") {
      this.showFilterList.push("Provider");
    }
  }

  /**
   * Method to toggle filter section
   */
  public filterClick() {
    this.filter = !this.filter;
  }

  /**
   * Method to get filter data
   */
  public getFilterData(data: any) {
    this.filterProviderValue = data.filterProviderValue;
    this.filterVisitTypeValue = data.filterVisitTypeValue;
    this.filterStartDateValue = data.filterStartDateValue;
    this.filterEndDateValue = data.filterEndDateValue;
  }

  /**
   * Method to apply filter data
   */
  public applyFilterData() {
    this.loadSchedulerGrid(true);
  }

  /**
   * Method to open add scheduler section
   */
  public addScheduler() {
    this.rightDrawer = !this.rightDrawer;
    this.addEditScheduler = true;
    this.editSchedulerId = "";
  }

  /**
   * Method to open edit scheduler section
   */
  public editSchedulerPopup(userId: any) {
    this.addEditScheduler = true;
    this.editSchedulerId = userId;
  }

  /**
   * Method to show/hide scheduler section
   */
  public showHideSchedulerPopup(showSchedulerPopup: any): void {
    this.rightDrawer = showSchedulerPopup;
    this.addEditScheduler = showSchedulerPopup ? true : false;
  }

  /**
   * Method to load scheduler data after add/update scheduler
   */
  public loadSchedulerData(isRefreshData: boolean): void {
    this.refreshSchedulerGrid = isRefreshData;
  }

  /**
   * Method to search data
   */
  public searchDataByKeyword() {
    this.refreshSchedulerGrid = false;
    this.searchString = this.searchText;
  }

  /**
   * Method to load scheduler grid
   */
  public loadSchedulerGrid(applyFilter: boolean = false) {
    this.scheduler.filterProvider = this.filterProviderValue;
    this.scheduler.filterVisitType = this.filterVisitTypeValue;
    this.scheduler.filterStartDate = this.filterStartDateValue;
    this.scheduler.filterEndDate = this.filterEndDateValue;
    this.scheduler.loadSchedulerData(applyFilter);
  }
}

<div class="tabs-body px-4 py-4">
  <h4>{{ "transaction.managePaymentMethodsText" | translate }}</h4>
  <div class="on-off-btn toggle-time">
    <div class="d-inline-flex align-items-center mb-3">
      <span>{{ "transaction.creditCardText" | translate }}</span>
      <mat-slide-toggle
        [(ngModel)]="isCreditCard"
        (change)="updateWalletSettings()"
      ></mat-slide-toggle>
    </div>
  </div>
  <div class="on-off-btn toggle-time">
    <div class="d-inline-flex align-items-center mb-3">
      <span>{{ "transaction.debitCardText" | translate }}</span>
      <mat-slide-toggle
        [(ngModel)]="isDebitCard"
        (change)="updateWalletSettings()"
      ></mat-slide-toggle>
    </div>
  </div>
</div>

import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BreadCrumb } from "../../models/breadcrumbModel";
import { map } from "rxjs/operators";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { UserTypeEnum } from "src/app/enums/userTypeEnum";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit {
  dashboardLabel: string = "";
  dashboardUrl: string = "";
  userRole: string = "";
  breadcrumbs$ = this.router.events.pipe(
    map((event) => this.buildBreadCrumb(this.activatedRoute.root))
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _authService: AuthGuardService
  ) {}

  ngOnInit() {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
      }
    });

    if (this.userRole === UserTypeEnum.Clinic.toString()) {
      this.dashboardLabel = "dashboard.headingText";
      this.dashboardUrl = "/dashboard";
    }
    if (this.userRole === UserTypeEnum.ClinicStaff.toString()) {
      this.dashboardLabel = "dashboard.headingText";
      this.dashboardUrl = "/dashboard";
    }
    if (this.userRole === UserTypeEnum.Provider.toString()) {
      this.dashboardLabel = "dashboard.headingText";
      this.dashboardUrl = "/dashboard";
    }
    if (
      this.userRole === UserTypeEnum.SuperAdmin.toString() ||
      this.userRole === UserTypeEnum.OfficeStaff.toString()
    ) {
      this.dashboardLabel = "dashboard.headingText";
      this.dashboardUrl = "/manage-clinic";
    }
  }

  buildBreadCrumb(
    route: any,
    breadcrumbs: Array<BreadCrumb> = []
  ): Array<BreadCrumb> {
    let breadcrumbData =
      route.routeConfig && route.routeConfig.data
        ? route.routeConfig.data["breadcrumb"]
        : [
            {
              label: this.dashboardLabel,
              url: this.dashboardUrl,
            },
          ];
    if (breadcrumbData) {
      breadcrumbData.forEach((element: any) => {
        breadcrumbs.push(element);
      });
    }
    const newBreadcrumbs = [...breadcrumbs];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }
}

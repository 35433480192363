export class UserModel {
  email: string;
  firstName: string;
  lastName: string;
  phoneCode: string;
  phoneNumber: string;
  speciality: string;
  dob: string;
  visitTypes: [];
  languages: [];
  timezone: any;

  constructor() {
    this.email = "";
    this.firstName = "";
    this.lastName = "";
    this.phoneCode = "";
    this.phoneNumber = "";
    this.speciality = "";
    this.dob = "";
    this.visitTypes = [];
    this.languages = [];
    this.timezone = null;
  }
}

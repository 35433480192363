<div class="drawer-body" *ngIf="userType === 'Patients'">
  <div class="drawer-title">
    <div class="view-appoinment-title">
      <div>
        <img src="assets/images/blue-user-icon.png" alt="" />
        <h4>{{ "common.personalInfoText" | translate }}</h4>
      </div>
    </div>
  </div>
  <div class="drawer-content">
    <div class="view-appointment-top">
      <div class="view-appointment-img">
        <img src="{{ viewUserData.patientProfilePhoto }}" alt="" />
      </div>
      <div class="view-appointment-content">
        <p>{{ viewUserData.name }}</p>
        <p>{{ viewUserData.dob | date : "MMM dd, yyyy" }}</p>
        <p>{{ viewUserData.patientGenderValue }}</p>
      </div>
    </div>
    <div
      class="appointment-more-content"
      *ngIf="viewUserData?.patientParsonalInformation"
    >
      <p>{{ viewUserData.contact }}</p>
      <p>{{ viewUserData.email }}</p>
      <p>
        {{ viewUserData?.patientParsonalInformation?.timeZone }}
        {{ viewUserData?.patientParsonalInformation?.timeZoneShortName }}
      </p>
      <p>
        <span *ngIf="viewUserData?.patientParsonalInformation?.address"
          >{{ viewUserData?.patientParsonalInformation?.address }},</span
        >
        <span *ngIf="viewUserData?.patientParsonalInformation?.city"
          >{{ viewUserData?.patientParsonalInformation?.city }},</span
        >
        <span *ngIf="viewUserData?.patientParsonalInformation?.state"
          >{{ viewUserData?.patientParsonalInformation?.state }},</span
        >
        <span *ngIf="viewUserData?.patientParsonalInformation?.country"
          >{{ viewUserData?.patientParsonalInformation?.country }} -</span
        >
        <span *ngIf="viewUserData?.patientParsonalInformation?.zipCode">{{
          viewUserData?.patientParsonalInformation?.zipCode
        }}</span>
      </p>
    </div>
    <div class="appointment-more-details">
      <div class="view-appoinment-title">
        <div>
          <img src="assets/images/heart-icon.png" alt="" />
          <h4>{{ "appointment.insuranceInformationText" | translate }}</h4>
        </div>
      </div>
      <div
        class="details-box-content"
        *ngIf="viewUserData.insuranceInformation"
      >
        <p>{{ viewUserData.insuranceInformation.insuranceCompany }}</p>
        <p>{{ viewUserData.insuranceInformation.subscriberMemberId }}</p>
        <p>{{ viewUserData.insuranceInformation.insuranceType }}</p>

        <div class="thumbnail-container">
          <img
            *ngIf="patientInsuranceFrontSide"
            class="thumbnail"
            [src]="patientInsuranceFrontSide"
            alt="Thumbnail 1"
            (click)="openImagePopup(patientInsuranceFrontSide)"
          />
          <img
            *ngIf="patientInsuranceBackSide"
            class="thumbnail"
            [src]="patientInsuranceBackSide"
            alt="Thumbnail 2"
            (click)="openImagePopup(patientInsuranceBackSide)"
          />
          <!-- Add more thumbnail images as needed -->
        </div>

        <!-- Image Popup -->
        <div *ngIf="isImagePopupOpen" class="image-popup">
          <div class="image-popup-content">
            <span class="close-icon" (click)="closeImagePopup()">&times;</span>
            <img [src]="selectedImageSrc" alt="Full Image" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>

    <div class="appointment-more-details">
      <div class="view-appoinment-title">
        <div>
          <img src="assets/images/home-plus-icon.png" alt="" />
          <h4>{{ "appointment.pharmacyInformationText" | translate }}</h4>
        </div>
      </div>
      <div class="details-box-content" *ngIf="viewUserData.pharmacyInformation">
        <h6>{{ viewUserData.pharmacyInformation.pharmacyName }}</h6>
        <p *ngIf="viewUserData.pharmacyInformation.pharmacyPhoneNo">
          {{
            viewUserData.pharmacyInformation.pharmacyPhoneCode +
              " - " +
              viewUserData.pharmacyInformation.pharmacyPhoneNo
          }}
        </p>
        <div
          class="copy-content"
          *ngIf="viewUserData.pharmacyInformation.pharmacyAddress"
        >
          {{ viewUserData.pharmacyInformation.pharmacyAddress }}
          <button
            (click)="copyText(viewUserData.pharmacyInformation.pharmacyAddress)"
            class="copy-icons"
          >
            <img [src]="copyButtonImg" alt="" />
          </button>
        </div>
      </div>
    </div>

    <div class="appointment-more-details">
      <div class="view-appoinment-title">
        <div>
          <img src="assets/images/emergency-icon.png" alt="" />
          <h4>{{ "appointment.emergencyContactText" | translate }}</h4>
        </div>
      </div>
      <div
        class="details-box-content"
        *ngIf="viewUserData.emergencyInformation"
      >
        <h6>
          {{ viewUserData.emergencyInformation.emergencyContactInfo }}
        </h6>
        <p>
          {{ viewUserData.emergencyInformation.emergencyContactRelationship }}
        </p>
        <p *ngIf="viewUserData.emergencyInformation.emergencyPhoneNo">
          {{
            viewUserData.emergencyInformation.emergencyPhoneCode +
              " - " +
              viewUserData.emergencyInformation.emergencyPhoneNo
          }}
        </p>
      </div>
    </div>

    <div class="appointment-more-details">
      <div class="view-appoinment-title">
        <div>
          <img src="assets/images/health-information.png" alt="" />
          <h4>{{ "appointment.healthInformtationText" | translate }}</h4>
        </div>
      </div>
      <div class="details-box-content" *ngIf="viewUserData.healthInformation">
        <p>
          <strong>{{ "appointment.allergiesText" | translate }}</strong>
        </p>
        <p>
          {{
            viewUserData.healthInformation.medicationsAllergies == ""
              ? ""
              : viewUserData.healthInformation.medicationsAllergies
          }}
        </p>
        <p>&nbsp;</p>

        <p>
          <strong>{{ "appointment.medicationsText" | translate }} </strong>
        </p>
        <div class="d-flex flex-wrap">
          <p *ngFor="let item of medicationList; let i = index" class="pe-2">
            {{ item }}{{ i === medicationList.length - 1 ? "" : ",&nbsp;" }}
          </p>
        </div>
        <p>&nbsp;</p>

        <p>
          <strong
            >{{ "appointment.medicalConditionsText" | translate }}
          </strong>
        </p>
        <p>
          {{ viewUserData.healthInformation.existingMedicalConditions }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="drawer-body" *ngIf="userType !== 'Patients'">
  <div class="drawer-title">
    <img src="assets/images/view-icon.svg" alt="" /> View {{ userType }}
  </div>
  <div class="drawer-content">
    <ul class="view-user">
      <li>
        <span>{{ "viewUser.emailAddressLabelText" | translate }}</span>
        <p>{{ viewUserData.email }}</p>
      </li>
      <li>
        <span>{{ "viewUser.nameLabelText" | translate }}</span>
        <p>{{ viewUserData.name }}</p>
      </li>
      <li>
        <span>{{ "viewUser.contactNumberLabelText" | translate }}</span>
        <p>{{ viewUserData.contact }}</p>
      </li>
      <li *ngIf="userType === 'Medical Providers'">
        <span>{{ "viewUser.specialityLabelText" | translate }}</span>
        <p>{{ viewUserData.specialty }}</p>
      </li>
      <li>
        <span>{{ "viewUser.dateOfBirthLabelText" | translate }}</span>
        <p>{{ viewUserData.dob | date : "MMM dd, yyyy" }}</p>
      </li>
      <li>
        <span>{{ "viewUser.statusLabelText" | translate }}</span>
        <p>
          {{
            (viewUserData.status === true ? "common.Active" : "common.Inactive")
              | translate
          }}
        </p>
      </li>
    </ul>
    <div class="drawer-action mt-4 pt-2">
      <button class="btn btn-secondary" (click)="closeViewUserPopup()">
        <img src="assets/images/white-back-icon.svg" alt="" class="me-2" />
        {{ "viewUser.backButtonText" | translate }}
      </button>
    </div>
  </div>
</div>

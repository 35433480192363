<div class="login-page">
  <div class="login-box">
    <div class="login-content">
      <div class="login-logo">
        <a href="javascript:void()"
          ><img src="assets/images/logo.svg" alt=""
        /></a>
      </div>
      <div class="customer-tabs">
        <h2 class="page-headings">
          {{ "forgotpassword.forgotPasswordHeadingText" | translate }}
        </h2>
        <form
          [formGroup]="forgotPasswordValidation"
          (ngSubmit)="submitForgotPassword()"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <mat-form-field class="example-full-width">
                  <span matPrefix
                    ><img src="assets/images/icon-user.png" alt=""
                  /></span>
                  <mat-label>{{
                    "common.emailPlaceholder" | translate
                  }}</mat-label>
                  <input
                    type="email"
                    matInput
                    placeholder="{{ 'common.emailPlaceholder' | translate }}"
                    formControlName="email"
                  />
                </mat-form-field>
                <div
                  *ngIf="
                    forgotPasswordValidation.controls['email'].invalid &&
                    (forgotPasswordValidation.controls['email'].dirty ||
                      forgotPasswordValidation.controls['email'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      forgotPasswordValidation
                        .get('email')
                        ?.hasError('required')
                    "
                    >{{ "common.requiredEmailError" | translate }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      forgotPasswordValidation.get('email')?.hasError('pattern')
                    "
                    >{{ "common.invalidEmailError" | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
            <div class="login-btn">
              <button class="btn btn-primary">
                {{ "forgotpassword.submitButtonText" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <p class="copy-right">{{ "footer.footerText" | translate }}</p>
</div>

<div class="privacy-page">
  <div class="login-header">
    <div class="login-header-left">
      <a href="javascript:void(0)"
        ><img src="assets/images/logo-with-slogan.svg" alt=""
      /></a>
    </div>
    <div class="login-header-right">
      {{ "login.anyQuestionsText" | translate }}
      <a href="mailto:admin@betternowmedical.com">{{
        "login.emailUsText" | translate
      }}</a>
    </div>
  </div>
  <div class="disclaimer-box">
    <div class="disclaimer-page">
      <div class="disclaimer-title">Privacy Policy</div>
      <div class="disclaimer-body" [innerHTML]="privacyPolicyContent"></div>
    </div>
  </div>
  <div class="copyright w-100">{{ "footer.footerText" | translate }}</div>
</div>

<div id="participant-list">
  <div id="alone" [ngClass]="{ table: isAlone, 'd-none': !isAlone }">
    <p class="text-center text-monospace h3" style="display: table-cell">
      You're the only one in this meeting. <i class="far fa-frown"></i>
      <br />
      <br />
      As others join, they'll start showing up here...
    </p>
  </div>
  <div [ngClass]="{ 'd-none': isAlone }">
    <nav class="navbar navbar-expand-lg navbar-dark bg-light shadow">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <button
            type="button"
            class="btn btn-lg leave-room"
            title="Click to leave this room."
            (click)="onLeaveRoom()"
          >
            Leave Meeting?
          </button>
          <button
            type="button"
            class="btn btn-lg leave-room"
            title="Click to leave this room."
            (click)="onMuteUnmuteAudio()"
          >
            <span *ngIf="!this.isMuteAudio">Mute</span>
            <span *ngIf="this.isMuteAudio">UnMute</span>
          </button>
        </li>
      </ul>
    </nav>
    <div #list>
      <div class="novideo-placeholder" *ngIf="!showPatientVideo"></div>
    </div>
  </div>
</div>

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";
import { CustomPageTitleStrategy } from "src/app/services/custom-page-title-strategy";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent {
  availableClasses: string[] = ["slide-close", "slide-open"];
  currentClassIdx: number = 0;
  bodyClass: string;
  isLoggedIn: Observable<boolean>;
  loggedUserName: Observable<string> = new Observable();
  loggedUserClinicCode: Observable<string> = new Observable();
  userRole: string = "";
  unReadNotificationCount: any = "0";

  constructor(
    private _commonService: CommonService,
    private _router: Router,
    private _authService: AuthGuardService,
    private _customPageTitleStrategy: CustomPageTitleStrategy
  ) {
    this.bodyClass = this.availableClasses[this.currentClassIdx];
    this.changeBodyClass();
    this.isLoggedIn = this._authService.isLoggedIn;
  }

  ngOnInit() {
    this.isLoggedIn = this._authService.isLoggedIn;
    this.isLoggedIn.subscribe((isLogin) => {
      if (isLogin) {
        this._authService.getUserData().subscribe((data) => {
          if (data) {
            this.loggedUserName = data["firstname"];
            this.userRole = data["usertypeid"];
            this.loggedUserClinicCode = data["cliniccode"];
          }
        });
      }
    });
    this._authService.unReadNotificationData.subscribe((data) => {
      if (data) {
        this.unReadNotificationCount = localStorage.getItem(
          "unReadNotificationCount"
        );
      }
    });
  }

  public changeBodyClass() {
    // get html body element
    const bodyElement = document.body;

    if (bodyElement) {
      this.currentClassIdx = this.getNextClassIdx();
      const nextClass = this.availableClasses[this.currentClassIdx];
      const activeClass = this.availableClasses[this.getPrevClassIdx()];

      // remove existing class (needed if theme is being changed)
      bodyElement.classList.remove(activeClass);
      // add next theme class
      bodyElement.classList.add(nextClass);

      this.bodyClass = nextClass;
    }
  }

  public getPrevClassIdx(): number {
    return this.currentClassIdx === 0
      ? this.availableClasses.length - 1
      : this.currentClassIdx - 1;
  }

  public getNextClassIdx(): number {
    return this.currentClassIdx === this.availableClasses.length - 1
      ? 0
      : this.currentClassIdx + 1;
  }

  public logout() {
    let userDetails: any = this._authService.getUserDetails();
    this._commonService.logout();
    this._authService.loggedIn.next(false);
    this._commonService.redirectToLoginPageByUserRole(userDetails.usertypeid);
  }
}

export class SubscriptionPlanModel {
    planName: string;
    planPrice: string;
    durationInMonth: string;
    
    constructor() {
        this.planName = "";
        this.planPrice = "";
        this.durationInMonth = "";
    }
}

import { Component } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { GlobalFunctions } from "src/app/app.global";
import { clinicApiList } from "src/app/enums/clinicApiEnum";
import { CommonService } from "src/app/services/common.service";
import { CustomPageTitleStrategy } from "src/app/services/custom-page-title-strategy";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { ClinicService } from "../../services/clinic.service";

@Component({
  selector: "app-clinic-settings",
  templateUrl: "./clinic-settings.component.html",
  styleUrls: ["./clinic-settings.component.css"],
})
export class ClinicSettingsComponent {
  panelOpenState = false;
  isAllExpanded = true;
  appointmentVisitDisplayedColumns: string[] = ["visitType", "price", "action"];
  appointmentNotificationDisplayedColumns: string[] = ["duration", "action"];
  appointmentVisitDataSource = new MatTableDataSource([]);
  appointmentNotificationDataSource = new MatTableDataSource([]);
  advanceAppointmentBooking: string = "";
  appointmentRescheduleBefore: string = "";
  appointmentCancellationBefore: string = "";
  noShowAppointmentCharges: string = "";
  paymentAccountId: string = "";
  appointmentCommissionPaidBy: string = "clinic";
  appointmentVisitType: string = "";
  appointmentVisitPrice: string = "";
  isAppointmentVisitEditMode: boolean = false;
  appointmentNotificationDuration: string = "";
  appointmentNotificationDurationId: number = 1;
  appointmentNotificationDurationList: any;
  isAppointmentNotificationEditMode: boolean = false;
  clinicVisitTypeId = 0;
  appointmentNotificationId = 0;

  constructor(
    private _customPageTitleStrategy: CustomPageTitleStrategy,
    private _commonService: CommonService,
    private _clinicService: ClinicService,
    private _toastrService: ToastrNotificationService,
    public _globalFunction: GlobalFunctions,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.getClinicSettings();
    this.getNotificationDurations();
  }

  /**
   * Method to get appointments settings data
   */
  public getClinicSettings() {
    this._clinicService.getClinicSettings().subscribe({
      next: (data) => {
        if (data && data !== null) {
          if (data.appointmentSettings && data.appointmentSettings !== null) {
            this.advanceAppointmentBooking =
              data.appointmentSettings.advanceBookingDays;
            this.appointmentCancellationBefore =
              data.appointmentSettings.cancellationBeforeHrs;
            this.noShowAppointmentCharges =
              data.appointmentSettings.noShowAppointmentCharges;
            this.paymentAccountId = data.appointmentSettings.paymentAccountId;
            this.appointmentRescheduleBefore =
              data.appointmentSettings.rescheduleBeforeHrs;
            this.appointmentCommissionPaidBy =
              data.appointmentSettings.commissionPaidBy === true
                ? "patient"
                : "clinic";
          }
          if (data.clinicVisitTypes && data.clinicVisitTypes.length > 0) {
            this.appointmentVisitDataSource = new MatTableDataSource(
              data.clinicVisitTypes
            );
          } else {
            this.appointmentVisitDataSource = new MatTableDataSource([]);
          }
          if (
            data.appointmentNotifications &&
            data.appointmentNotifications.length > 0
          ) {
            this.appointmentNotificationDataSource = new MatTableDataSource(
              data.appointmentNotifications
            );
          } else {
            this.appointmentNotificationDataSource = new MatTableDataSource([]);
          }
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get notification durations
   */
  public getNotificationDurations() {
    this._clinicService.getNotificationDurations().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.appointmentNotificationDurationList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to save appointment visit
   */
  public saveAppointmentVisit() {
    let errorMessage = "";
    if (
      this.appointmentVisitType.trim() === null ||
      this.appointmentVisitType.trim() === ""
    ) {
      errorMessage +=
        this._commonService.getTranslatedMessage(
          "settings.visitTypeRequiredErrorText"
        ) + "<br />";
    }
    if (
      this.appointmentVisitPrice === null ||
      this.appointmentVisitPrice === ""
    ) {
      errorMessage +=
        this._commonService.getTranslatedMessage(
          "settings.visitPriceRequiredErrorText"
        ) + "<br />";
    }

    if (errorMessage !== "") {
      this._toastrService.showError(errorMessage, "ngx-toastr-w300");
      return;
    }

    if (this.isAppointmentVisitEditMode) {
      this.updateAppointmentVisit();
    } else {
      this.addAppointmentVisit();
    }
  }

  /**
   * Method to add appointment visit
   */
  public addAppointmentVisit() {
    let clinicVisitParams = {
      visitType: this.appointmentVisitType,
      price: this.appointmentVisitPrice,
    };
    this._clinicService
      .saveClinicVisitType(clinicVisitParams, clinicApiList.addClinicVisitType)
      .subscribe({
        next: (data) => {
          if (data && data.success && data.success === true) {
            this._toastrService.showSuccess(
              "settings.addAppointmentsVisitPricingSuccessMessage"
            );
            this.clearAppointmentVisitData();
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
  }

  /**
   * Method to update appointment visit
   */
  public updateAppointmentVisit() {
    let clinicVisitParams = {
      clinicVisitTypeId: this.clinicVisitTypeId,
      visitType: this.appointmentVisitType,
      price: this.appointmentVisitPrice,
    };
    this._clinicService
      .saveClinicVisitType(
        clinicVisitParams,
        clinicApiList.updateClinicVisitType
      )
      .subscribe({
        next: (data) => {
          if (data && data.success && data.success === true) {
            this._toastrService.showSuccess(
              "settings.updateAppointmentsVisitPricingSuccessMessage"
            );
            this.clearAppointmentVisitData();
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
  }

  /**
   * Method to edit appointment visit type
   */
  public editAppointmentVisitType(data: any) {
    this.isAppointmentVisitEditMode = true;
    this.clinicVisitTypeId = data.clinicVisitTypeId;
    this.appointmentVisitType = data.visitType;
    this.appointmentVisitPrice = data.price;
  }

  /**
   * Method to delete appointment visit type
   */
  public deleteAppointmentVisitType(data: any) {
    if (data.clinicVisitTypeId !== null) {
      this._clinicService
        .deleteClinicVisitType(data.clinicVisitTypeId)
        .subscribe({
          next: (data) => {
            if (data && data.success && data.success === true) {
              this._toastrService.showSuccess(
                "settings.deleteAppointmentsVisitPricingSuccessMessage"
              );
              this.clearAppointmentVisitData();
            }
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }

  /**
   * Method to save appointment notification
   */
  public saveAppointmentNotification() {
    if (
      this.appointmentNotificationDuration === null ||
      this.appointmentNotificationDuration === ""
    ) {
      this._toastrService.showError(
        "settings.durationRequiredErrorText",
        "ngx-toastr-w300"
      );
      return;
    }
    if (this.isAppointmentNotificationEditMode) {
      this.updateAppointmentNotification();
    } else {
      this.addAppointmentNotification();
    }
  }

  /**
   * Method to add appointment notification
   */
  public addAppointmentNotification() {
    let notificationParams = {
      notificationDuration: this.appointmentNotificationDuration,
      notificationDurationId: this.appointmentNotificationDurationId,
    };
    this._clinicService
      .saveAppointmentNotification(
        notificationParams,
        clinicApiList.addAppointmentNotification
      )
      .subscribe({
        next: (data) => {
          if (data && data.success && data.success === true) {
            this._toastrService.showSuccess(
              "settings.addNotificationsSettingSuccessMessage"
            );
            this.clearNotificationData();
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
  }

  /**
   * Method to update appointment notification
   */
  public updateAppointmentNotification() {
    let notificationParams = {
      appointmentNotificationId: this.appointmentNotificationId,
      notificationDuration: this.appointmentNotificationDuration,
      notificationDurationId: this.appointmentNotificationDurationId,
    };
    this._clinicService
      .saveAppointmentNotification(
        notificationParams,
        clinicApiList.updateAppointmentNotification
      )
      .subscribe({
        next: (data) => {
          if (data && data.success && data.success === true) {
            this._toastrService.showSuccess(
              "settings.updateNotificationsSettingSuccessMessage"
            );
            this.clearNotificationData();
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
  }

  /**
   * Method to edit appointment notification
   */
  public editAppointmentNotification(data: any) {
    this.isAppointmentNotificationEditMode = true;
    this.appointmentNotificationId = data.appointmentNotificationId;
    this.appointmentNotificationDuration = data.notificationDuration;
    this.appointmentNotificationDurationId = data.notificationDurationId;
  }

  /**
   * Method to delete appointment notification
   */
  public deleteAppointmentNotification(data: any) {
    if (data.appointmentNotificationId !== null) {
      this._clinicService
        .deleteAppointmentNotification(data.appointmentNotificationId)
        .subscribe({
          next: (data) => {
            if (data && data.success && data.success === true) {
              this._toastrService.showSuccess(
                "settings.deleteNotificationsSettingSuccessMessage"
              );
              this.clearNotificationData();
            }
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }

  /**
   * Method to save appointment settings
   */
  public saveAppointmentSettings() {
    let errorMessage = this.validateAppointmentSettings();

    if (errorMessage !== "") {
      this._toastrService.showError(errorMessage, "ngx-toastr-w450");
      return;
    }

    let appSettingParams = {
      advanceBookingDays: this.advanceAppointmentBooking,
      rescheduleBeforeHrs: this.appointmentRescheduleBefore,
      cancellationBeforeHrs: this.appointmentCancellationBefore,
      noShowAppointmentCharges: this.noShowAppointmentCharges,
      commissionPaidBy:
        this.appointmentCommissionPaidBy === "patient" ? true : false,
      PaymentAccountId: this.paymentAccountId,
    };
    this._clinicService.saveAppointmentSettings(appSettingParams).subscribe({
      next: (data) => {
        if (data && data.success && data.success === true) {
          this._toastrService.showSuccess(
            "settings.saveAppointmentsSettingSuccessMessage"
          );
          this._router.navigate(["/dashboard"]);
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to clear appointment visit data
   */
  public clearAppointmentVisitData() {
    this.isAppointmentVisitEditMode = false;
    this.clinicVisitTypeId = 0;
    this.appointmentVisitType = "";
    this.appointmentVisitPrice = "";
    this.getClinicSettings();
  }

  /**
   * Method to clear notifications data
   */
  public clearNotificationData() {
    this.isAppointmentNotificationEditMode = false;
    this.appointmentNotificationDuration = "";
    this.getClinicSettings();
  }

  /**
   * Method to validate appointment settings
   */
  public validateAppointmentSettings() {
    let errorMessage = "";
    if (
      this.advanceAppointmentBooking === null ||
      this.advanceAppointmentBooking === ""
    ) {
      errorMessage +=
        this._commonService.getTranslatedMessage(
          "settings.daysRequiredErrorText"
        ) + "<br />";
    }
    if (
      this.noShowAppointmentCharges === null ||
      this.noShowAppointmentCharges === ""
    ) {
      errorMessage +=
        this._commonService.getTranslatedMessage(
          "settings.amountRequiredErrorText"
        ) + "<br />";
    }
    if (
      this.appointmentRescheduleBefore === null ||
      this.appointmentRescheduleBefore === ""
    ) {
      errorMessage +=
        this._commonService.getTranslatedMessage(
          "settings.rescheduleHoursRequiredErrorText"
        ) + "<br />";
    }
    if (
      this.appointmentCancellationBefore === null ||
      this.appointmentCancellationBefore === ""
    ) {
      errorMessage +=
        this._commonService.getTranslatedMessage(
          "settings.cancellationHoursRequiredErrorText"
        ) + "<br />";
    }
    if (this.paymentAccountId === null || this.paymentAccountId === "") {
      errorMessage +=
        this._commonService.getTranslatedMessage(
          "settings.paymentAccountIdRequiredErrorText"
        ) + "<br />";
    }
    return errorMessage;
  }
}

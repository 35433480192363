<div class="tabs-body">
  <div class="mat-elevation-z8 table-responsive">
    <table mat-table [dataSource]="dataSource" class="table" matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "viewUser.nameLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "userList.emailLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef>
          {{ "userList.contactLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.contact }}
        </td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "common.CityLabel" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.city }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          {{ "viewUser.statusLabelText" | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="element.status === true ? 'active' : 'inactive'"
        >
          {{
            (element.status === true ? "common.Active" : "common.Inactive")
              | translate
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{ "userList.actionLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="table-action">
            <a href="javascript:void(0)" (click)="this.viewClinic(element)"
              ><img src="assets/images/view-icon.svg" alt=""
            /></a>
            <a href="javascript:void(0)" (click)="this.editClinic(element)"
              ><img src="assets/images/edit-icon.svg" alt=""
            /></a>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          {{ "userList.noResultFoundText" | translate }}
        </td>
      </tr>
    </table>
  </div>
  <mat-paginator
    [hidden]="!showPaginator"
    #paginator
    style-paginator
    showFirstLastButtons
    itemsPerPageLabel="Page Size"
    [length]="totalRows"
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageChanged($event)"
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>

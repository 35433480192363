<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/plan-icon.svg" alt="" />
        </div>
        <h2>{{ "subscription.subscriptionHeadingText" | translate }}</h2>
      </div>
    </div>
    <div class="subscription-page">
      <h4>{{ "subscription.upgradeSubscriptionHeadingText" | translate }}</h4>
      <div class="row">
        <div
          class="col-md-4 col- col-lg-3 col-xxl-2"
          *ngFor="let plan of subscriptionPlanList"
        >
          <div
            class="subscription-box"
            [ngClass]="{
              currentplan: plan.currentActivePlan === true,
              active: plan.subscriptionPlanId === selectedSubscriptionPlan
            }"
            (click)="selectSubscriptionPlan(plan.subscriptionPlanId)"
          >
            <div>
              <h2>$ {{ plan.price }}</h2>
              <h4>{{ plan.planName }}</h4>
              <div class="select-round"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-action">
        <button
          class="btn btn-primary mt-5 min-width-120"
          (click)="saveSubscriptionPlan()"
        >
          <img src="assets/images/dollar-icon.svg" alt="" class="me-2" />
          {{ "subscription.PayText" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

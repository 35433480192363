import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationStart,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { HeaderComponent } from "../components/header/header/header.component";
import { AuthGuardService } from "./auth-guard.service";

@Injectable({
  providedIn: "root",
})
export class AnonymousGuardService implements CanActivate {
  constructor(
    private router: Router,
    private _authGuardService: AuthGuardService,
    public _headerComponent: HeaderComponent
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (val && val.url) {
          localStorage.setItem("iCareVIP_url", val.url);
        }
      }
    });
  }

  /**
   * Method to check user access while accessing url
   */
  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const authenticated = await this.checkAuthenticationAsPromise(state);
    return authenticated;
  }

  /**
   * Method to check user authentication
   */
  public checkAuthenticationAsPromise(
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let userDetails = this._authGuardService.getUserDetails();
      if (userDetails && userDetails !== "") {
        this.router.navigate(["/dashboard"]);
        return resolve(false);
      } else {
        this._headerComponent.ngOnInit();
        return resolve(true);
      }
    });
  }
}

<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/icon-appointment.svg" alt="" />
        </div>
        <h2>{{ "leftmenu.Appointments" | translate }}</h2>
      </div>
      <div class="page-title-right">
        <div class="search">
          <div class="form-group mb-0">
            <mat-form-field class="example-form-field">
              <input
                matInput
                [(ngModel)]="searchText"
                type="text"
                placeholder="{{ 'userList.searchUserPlaceholder' | translate }}"
                (keyup)="searchDataByKeyword()"
              />
              <button matSuffix>
                <img src="assets/images/icon-search.svg" alt="" />
              </button>
            </mat-form-field>
          </div>
        </div>
        <button class="btn btn-primary ms-3" (click)="addNewAppointment()">
          <img src="assets/images/plus-icon.svg" alt="" class="me-2" />
          {{ "appointment.newAppointmentText" | translate }}
        </button>
        <button
          class="btn btn-primary ms-3"
          (click)="filterClick()"
          [ngClass]="filter ? 'active' : ''"
        >
          <img src="assets/images/filter-icon.svg" alt="" />
        </button>
      </div>
    </div>

    <app-filter
      (filterData)="getFilterData($event)"
      (applyFilterData)="applyFilterData()"
      [filter]="filter"
      [showFilterList]="showFilterList"
    ></app-filter>

    <div class="content-page">
      <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
        <mat-tab
          label="{{ 'appointment.upcomingAppointmentsText' | translate }}"
        >
          <app-upcoming-appointments
            *ngIf="tabIndex === 0"
            [refreshAppointmentData]="refreshAppointmentGrid"
            [searchData]="searchString"
            (viewAppointmentData)="viewAppointmentPopup($event)"
            (editAppointmentData)="editAppointmentPopup($event)"
            #upcomingAppointment
          ></app-upcoming-appointments>
        </mat-tab>
        <mat-tab label="{{ 'appointment.appointmentHistory' | translate }}">
          <app-appointment-history
            *ngIf="tabIndex === 1"
            [refreshAppointmentData]="refreshAppointmentGrid"
            [searchData]="searchString"
            #appointmentHistory
          ></app-appointment-history>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="drawer-backdrop" [ngClass]="rightDrawer ? 'open' : ''"></div>
    <div class="right-drawer" [ngClass]="rightDrawer ? 'open' : ''">
      <button class="btn btn-link" (click)="showHideAppointmentPopup(false)">
        <img src="assets/images/close-icon.svg" alt="" />
      </button>
      <app-add-appointment
        *ngIf="addAppointment"
        (refreshAppointmentData)="loadUpcomingAppointmentData($event)"
        (showAppointmentPopup)="showHideAppointmentPopup($event)"
        [editAppointmentData]="editAppointmentData"
      ></app-add-appointment>
      <app-view-appointment
        *ngIf="viewAppointment"
        [viewAppointmentData]="viewAppointmentData"
        (showViewAppointmentPopup)="showHideAppointmentPopup($event)"
      ></app-view-appointment>
    </div>
  </div>
</div>

<ul>
  <li *ngFor="let breadcrumb of breadcrumbs$ | async; last as isLast">
    <a
      *ngIf="!isLast; else lastRoute"
      href="javascript:void(0)"
      [routerLink]="[breadcrumb.url]"
      >{{ breadcrumb.label | translate }}</a
    >
    <ng-template #lastRoute>{{ breadcrumb.label | translate }}</ng-template>
  </li>
</ul>

import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GlobalFunctions } from "src/app/app.global";
import { clinicApiList } from "src/app/enums/clinicApiEnum";
import { CountryModel } from "src/app/models/countryModel";
import { SpecialityModel } from "src/app/models/specialityModel";
import { ClinicService } from "src/app/modules/clinic/services/clinic.service";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { UserModel } from "../../models/userModel";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-add-edit-user",
  templateUrl: "./add-edit-user.component.html",
  styleUrls: ["./add-edit-user.component.css"],
})
export class AddEditUserComponent implements OnInit, OnChanges {
  userInfoModel = new UserModel();
  userInfoValidation: FormGroup = new FormGroup("");
  formSubmitted: boolean = false;
  codeSelect: string = "";
  phoneCodeList: CountryModel[] = [];
  specialityList: SpecialityModel[] = [];
  languageList: any = [];
  visitTypeList: any = [];
  addEditClinicText: string = "";
  todayDate: Date = new Date();
  timezoneList: any = [];
  filteredTimezoneList: any = [];
  @Input() userType: string = "";
  @Input() editUserId: any = "";
  @Output() showUserPopup = new EventEmitter<boolean>();
  @Output() refreshData = new EventEmitter<boolean>();
  @ViewChild("searchTimezoneInput") searchTimezoneInput!: ElementRef;
  
  constructor(
    private _userService: UserService,
    private _commonService: CommonService,
    private _clinicService: ClinicService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrNotificationService,
    public _globalFunction: GlobalFunctions,
    private datePipe: DatePipe,
    private _renderer: Renderer2 
  ) { 
    this.todayDate = this._commonService.getCurrentTimeZoneNewDate();
    this._renderer.listen("window", "click", (e: Event) => {
      this.filteredTimezoneList = this.timezoneList;
      if (this.searchTimezoneInput)
        this.searchTimezoneInput.nativeElement.value = "";
    });
  }

  ngOnInit(): void {
    this.createUserForm();
    this.getPhoneCode();
    this.getSpeciality();
    this.getLanguages();
    this.getVisitTypes();
    this.getTimezone();
  }

  ngOnChanges(): void {
    if (this.editUserId && this.editUserId !== null) {
      this.addEditClinicText = "Edit";
      this.getUserDataByUserId(this.editUserId);
    } else {
      this.addEditClinicText = "Add";
    }
  }

  /**
   * Method to create a user form
   */
  public createUserForm() {
    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    this.userInfoValidation = this._formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(emailPattern),
          this._globalFunction.removeSpaces.bind(this),
        ],
      ],
      firstName: [
        "",
        [Validators.required, this._globalFunction.removeSpaces.bind(this)],
      ],
      lastName: [
        "",
        [Validators.required, this._globalFunction.removeSpaces.bind(this)],
      ],
      phoneCode: ["", [Validators.required]],
      phoneNumber: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(10),
          Validators.pattern("^[0-9]*$"),
        ]),
      ],
      speciality: [
        "",
        this.userType === "Medical Providers" ? [Validators.required] : [],
      ],
      dob: ["", [Validators.required]],
      visitTypes: [
        [],
        this.userType === "Medical Providers" ? [Validators.required] : [],
      ],
      languages: [
        [],
        this.userType === "Medical Providers" ? [Validators.required] : [],
      ],
      timezone: [
        [],
        this.userType === "Medical Providers" ? [Validators.required] : [],
      ],
    });
  }

  /**
   * Method to open add user section
   */
  public closeUserPopup() {
    this.showUserPopup.emit(false);
  }

  /**
   * Method to get phone code data
   */
  public getPhoneCode() {
    this._commonService.getCountry().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.phoneCodeList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get speciality data
   */
  public getSpeciality() {
    this._commonService.getSpeciality().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.specialityList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to submit user data
   */
  public submitUserInfo() {
    this.formSubmitted = true;
    if (this.userInfoValidation.valid) {
      this.userInfoModel.email = this.userInfoValidation.get("email")?.value;
      this.userInfoModel.firstName =
        this.userInfoValidation.get("firstName")?.value;
      this.userInfoModel.lastName =
        this.userInfoValidation.get("lastName")?.value;
      this.userInfoModel.phoneCode =
        this.userInfoValidation.get("phoneCode")?.value;
      this.userInfoModel.phoneNumber =
        this.userInfoValidation.get("phoneNumber")?.value;
      this.userInfoModel.speciality =
        this.userInfoValidation.get("speciality")?.value;
      this.userInfoModel.dob = this.userInfoValidation.get("dob")?.value;

      let userParam: any = {};
      if (this.editUserId && this.editUserId !== null) {
        userParam["userId"] = this.editUserId;
      }
      userParam["email"] = this.userInfoModel.email;
      userParam["firstName"] = this.userInfoModel.firstName;
      userParam["lastName"] = this.userInfoModel.lastName;
      userParam["phoneCode"] = this.userInfoModel.phoneCode;
      userParam["phoneNo"] = this.userInfoModel.phoneNumber;
      userParam["dob"] = this.datePipe.transform(
        this.userInfoModel.dob,
        "yyyy-MM-dd"
      );

      let apiPath = this._globalFunction.getUsersApiPath(
        this.userType,
        this.editUserId
      );
      let successMessage = this._globalFunction.getUsersApiSuccessMessages(
        this.userType,
        this.editUserId
      );
      if (this.userType === "Medical Providers") {
        userParam["specialtyId"] = this.userInfoModel.speciality;
        userParam["visitTypes"] = this.userInfoModel.visitTypes.join(",");
        userParam["languages"] = this.userInfoModel.languages.join(",");
        userParam["timeZoneId"] = this.userInfoModel.timezone?.timezoneId;
      }
      this.refreshData.emit(false);
      this._userService.addUser(userParam, apiPath).subscribe({
        next: (data) => {
          if (data && data.success && data.success === true) {
            this.refreshData.emit(true);
            this._toastrService.showSuccess(successMessage);
            this.closeUserPopup();
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
    }
  }

  /**
   * Method to get user data by userid
   */
  public getUserDataByUserId(userId: any) {
    let apiPath = "";
    if (this.userType === "Patients") {
      apiPath = clinicApiList.getPatient;
    }
    if (this.userType === "Medical Providers") {
      apiPath = clinicApiList.getMedicalProvider;
    }
    if (this.userType === "Staff") {
      apiPath = clinicApiList.getStaff;
    }
    this._userService.getUserDataById(userId, apiPath).subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.showUserPopup.emit(true);
          this.userInfoModel.email = data.email;
          this.userInfoModel.firstName = data.firstName;
          this.userInfoModel.lastName = data.lastName;
          this.userInfoModel.phoneCode = data.phoneCode;
          this.userInfoModel.phoneNumber = data.phoneNo;
          this.userInfoModel.speciality = data.specialtyId;
          this.userInfoModel.dob = data.dob;
          var langArray = data.languages?.split(",").map(function (value: any) {
            return Number(value);
          });
          var visitTypeArray = data.visitTypes?.split(",").map(function (value: any) {
            return Number(value);
          });
          this.userInfoModel.languages = langArray;
          this.userInfoModel.visitTypes = visitTypeArray;
          if (data.timeZoneId > 0) {
            this.userInfoModel.timezone = data.timeZoneId;
            if(this.timezoneList.length > 0) {
              let currentTimezone = this.timezoneList.filter(
                (i: any) => i.timezoneId === parseInt(data.timeZoneId)
              );
              this.userInfoModel.timezone = currentTimezone && currentTimezone.length > 0
                ? currentTimezone[0]
                : null;
            }
          }
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get language data
   */
  public getLanguages() {
    this._commonService.getLanguages().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.languageList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get visit types data
   */
  public getVisitTypes() {
    this._clinicService.getClinicSettings().subscribe({
      next: (data) => {
        if (data && data.clinicVisitTypes && data.clinicVisitTypes !== null) {
          this.visitTypeList = data.clinicVisitTypes;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get all timezone
   */
  public getTimezone() {
    this._commonService.getTimezone().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.timezoneList = data;
          this.filteredTimezoneList = this.timezoneList;
          // this.timezoneList.sort((a: any, b: any) =>
          //   a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          // );
        }
        if (this.userInfoModel.timezone) {
          let currentTimezone = this.timezoneList.filter(
            (i: any) => i.timezoneId === parseInt(this.userInfoModel.timezone)
          );
          this.userInfoModel.timezone = currentTimezone && currentTimezone.length > 0
            ? currentTimezone[0]
            : null;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to search timezone
   */
   public searchTimeZone(event: any) {
    const val = event.target.value.toLowerCase();
    const tempTimezoneList = this.timezoneList.filter((x: any) => {
      if (
        x.displayName.toLowerCase().indexOf(val) !== -1 ||
        !val
      ) {
        return x;
      }
    });
    this.filteredTimezoneList = tempTimezoneList;
  }

}

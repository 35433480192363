import { DatePipe } from "@angular/common";
import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment-timezone";
import { GlobalFunctions } from "src/app/app.global";
import { StylePaginatorDirective } from "src/app/directives/style-paginator.directive";
import { clinicApiList } from "src/app/enums/clinicApiEnum";
import { superAdminApiList } from "src/app/enums/superAdminApiEnum";
import { UserTypeEnum } from "src/app/enums/userTypeEnum";
import { ClinicService } from "src/app/modules/clinic/services/clinic.service";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"],
})
export class NotificationComponent implements OnInit {
  displayedColumns: string[] = ["notification", "datetime"];
  dataSource = new MatTableDataSource([]);
  notificationData: any = [];
  @ViewChild(StylePaginatorDirective, { static: true })
  paginator!: StylePaginatorDirective;
  pageNumber: number = 1;
  totalRows: number = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  @Input() filterStartDate: any = "";
  @Input() filterEndDate: any = "";
  userRole: string = "";
  todayDate: Date = new Date();
  eventTypeList: any = [];
  selectedEventType: any = [];
  timeFormat: any;
  currentTimeZoneName: any;

  constructor(
    private _commonService: CommonService,
    private _clinicService: ClinicService,
    private _authService: AuthGuardService,
    public _globalFunction: GlobalFunctions,
    private _translate: TranslateService,
    private datePipe: DatePipe
  ) {
    this.todayDate = this._commonService.getCurrentTimeZoneNewDate();
    this.currentTimeZoneName = this._commonService.getTimeZoneShortName();
    this.timeFormat = this._commonService.getTimeFormat();
  }

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
      }
    });
    this.eventTypeList = this._globalFunction.getEventsData();
    this.eventTypeList = this.eventTypeList.filter(
      (i: any) =>
        (i.AllowToAdmin &&
          (this.userRole === UserTypeEnum.SuperAdmin.toString() ||
            this.userRole === UserTypeEnum.OfficeStaff.toString())) ||
        (i.AllowToClinic && this.userRole === UserTypeEnum.Clinic.toString()) ||
        (i.AllowToProvider &&
          this.userRole === UserTypeEnum.Provider.toString())
    );
    this.loadNotificationData();
  }

  /**
   * Method to filter data
   */
  public pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadNotificationData();
  }

  /**
   * Method to get notification data
   */
  public loadNotificationData(applyFilter: boolean = false) {
    //Get only last 30 days data
    if (this.filterStartDate === "" && this.filterEndDate === "") {
      let currentDate = this._commonService.getCurrentTimeZoneNewDate();
      currentDate.setDate(currentDate.getDate() - 29);
      this.filterStartDate = currentDate;
      this.filterEndDate = this._commonService.getCurrentTimeZoneNewDate();
    }

    this.currentPage = applyFilter ? 0 : this.currentPage;
    let notificationParam = {
      sortColumnName: "",
      sortDirection: true,
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      fromDate:
        this.filterStartDate !== ""
          ? this.datePipe.transform(this.filterStartDate, "yyyy-MM-dd")
          : null,
      toDate:
        this.filterEndDate !== ""
          ? this.datePipe.transform(this.filterEndDate, "yyyy-MM-dd")
          : null,
      isReadAll: true,
      offset: moment().tz(moment.tz.guess()).utcOffset(),
    };
    let apiPath =
      this.userRole === UserTypeEnum.SuperAdmin.toString() ||
      this.userRole === UserTypeEnum.OfficeStaff.toString()
        ? superAdminApiList.getNotificationLogs
        : clinicApiList.getNotificationLogs;
    this._clinicService
      .getNotificationLogs(notificationParam, apiPath)
      .subscribe({
        next: (data) => {
          if (
            data &&
            data !== null &&
            data.data &&
            data.data !== null &&
            data.data.length > 0
          ) {
            this.notificationData = [];
            this.prepareNotificationData(data.data);
            this.dataSource = new MatTableDataSource(this.notificationData);
          } else {
            this.dataSource = new MatTableDataSource([]);
          }
          this.totalRows = data.totalRecords;
          this.paginator.matPag.length = this.totalRows;
          if (applyFilter) {
            this.paginator._curPageObj.pageIndex = 0;
            this.paginator._rangeStart = 0;
            this.paginator._rangeEnd = 1;
          }
          this.paginator.initPageRange();

          //Update unread notification count
          localStorage.setItem(
            "unReadNotificationCount",
            data.notificationCount
          );
          this._authService.unReadNotificationData.next(true);
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
  }

  /**
   * Method to prepare notification data
   */
  public prepareNotificationData(logData: any) {
    logData.forEach((element: any) => {
      this.prepareNotificationDataByEventType(element);
    });
    // this.eventTypeList = this.notificationData.filter(
    //   (a: any, i: any) =>
    //     this.notificationData.findIndex(
    //       (s: any) => a.eventType === s.eventType
    //     ) === i
    // );
  }

  /**
   * Method to prepare notification data by event type
   */
  public prepareNotificationDataByEventType(data: any) {
    let log = "";
    switch (data.eventType) {
      case "NewAppointment":
        if (this.userRole === UserTypeEnum.Provider.toString()) {
          log = "notification.NewAppointment.provider";
        } else {
          log = "notification.NewAppointment.clinic";
        }
        break;
      case "Rescheduled":
        if (this.userRole === UserTypeEnum.Provider.toString()) {
          log = "notification.Rescheduled.provider";
        } else {
          log = "notification.Rescheduled.clinic";
        }
        break;
      case "Cancelled":
        if (this.userRole === UserTypeEnum.Provider.toString()) {
          log = "notification.Cancelled.provider";
        } else {
          log = "notification.Cancelled.clinic";
        }
        break;
      case "AppointmentPaymentSuccess":
        if (this.userRole === UserTypeEnum.Provider.toString()) {
          log = "notification.AppointmentPaymentSuccess.provider";
        } else {
          log = "notification.AppointmentPaymentSuccess.clinic";
        }
        break;
      case "AppointmentPaymentFailed":
        if (this.userRole === UserTypeEnum.Provider.toString()) {
          log = "notification.AppointmentPaymentFailed.provider";
        } else {
          log = "notification.AppointmentPaymentFailed.clinic";
        }
        break;
      case "AppointmentPaymentRefundSuccess":
        if (this.userRole === UserTypeEnum.Provider.toString()) {
          log = "notification.AppointmentPaymentRefundSuccess.provider";
        } else {
          log = "notification.AppointmentPaymentRefundSuccess.clinic";
        }
        break;
      case "AppointmentPaymentRefundFailed":
        if (this.userRole === UserTypeEnum.Provider.toString()) {
          log = "notification.AppointmentPaymentRefundFailed.provider";
        } else {
          log = "notification.AppointmentPaymentRefundFailed.clinic";
        }
        break;
      case "ClinicSubscriptionPaid":
        if (
          this.userRole === UserTypeEnum.SuperAdmin.toString() ||
          this.userRole === UserTypeEnum.OfficeStaff.toString()
        ) {
          log = "notification.ClinicSubscriptionPaid.admin";
        } else {
          log = "notification.ClinicSubscriptionPaid.clinic";
        }
        break;
      case "ClinicSubscriptionRenewed":
        if (
          this.userRole === UserTypeEnum.SuperAdmin.toString() ||
          this.userRole === UserTypeEnum.OfficeStaff.toString()
        ) {
          log = "notification.ClinicSubscriptionRenewed.admin";
        }
        if (this.userRole === UserTypeEnum.Clinic.toString()) {
          log = "notification.ClinicSubscriptionRenewed.clinic";
        }
        break;
      case "ClinicSubscriptionRefund":
        if (
          this.userRole === UserTypeEnum.SuperAdmin.toString() ||
          this.userRole === UserTypeEnum.OfficeStaff.toString()
        ) {
          log = "notification.ClinicSubscriptionRefund.admin";
        }
        if (this.userRole === UserTypeEnum.Clinic.toString()) {
          log = "notification.ClinicSubscriptionRefund.clinic";
        }
        break;
      case "ClinicSubscriptionRefundSuccess":
        if (
          this.userRole === UserTypeEnum.SuperAdmin.toString() ||
          this.userRole === UserTypeEnum.OfficeStaff.toString()
        ) {
          log = "notification.ClinicSubscriptionRefundSuccess.admin";
        }
        if (this.userRole === UserTypeEnum.Clinic.toString()) {
          log = "notification.ClinicSubscriptionRefundSuccess.clinic";
        }
        break;
      case "ClinicSubscriptionRefundFailed":
        if (
          this.userRole === UserTypeEnum.SuperAdmin.toString() ||
          this.userRole === UserTypeEnum.OfficeStaff.toString()
        ) {
          log = "notification.ClinicSubscriptionRefundFailed.admin";
        }
        if (this.userRole === UserTypeEnum.Clinic.toString()) {
          log = "notification.ClinicSubscriptionRefundFailed.clinic";
        }
        break;
      case "ClinicRegistrationReceived":
        log = "notification.ClinicRegistrationReceived.clinic";
        break;
      case "ClinicRegistrationApproved":
        log = "notification.ClinicRegistrationApproved.clinic";
        break;
      case "ClinicRegistrationRejected":
        log = "notification.ClinicRegistrationRejected.clinic";
        break;
      case "RefundRequestFromClinic":
        log = "notification.RefundRequestFromClinic.clinic";
        break;
      case "RefundIssuedToClinic":
        log = "notification.RefundIssuedToClinic.clinic";
        break;
      case "ClinicSuccessfullyRegistered":
        log = "notification.ClinicSuccessfullyRegistered.clinic";
        break;
      case "PatientSuccessfullyRegistered":
        log = "notification.PatientSuccessfullyRegistered.clinic";
        break;
      case "MedicalProviderSuccessfullyRegistered":
        log = "notification.MedicalProviderSuccessfullyRegistered.clinic";
        break;
      case "StaffMemberSuccessfullyRegistered":
        log = "notification.StaffMemberSuccessfullyRegistered.clinic";
        break;
      case "InviteSentToPatient":
        log = "notification.InviteSentToPatient.clinic";
        break;
      case "InviteSentToMedicalProvider":
        if (
          this.userRole === UserTypeEnum.SuperAdmin.toString() ||
          this.userRole === UserTypeEnum.OfficeStaff.toString()
        ) {
          log = "notification.InviteSentToMedicalProvider.admin";
        }
        if (this.userRole === UserTypeEnum.Clinic.toString()) {
          log = "notification.InviteSentToMedicalProvider.clinic";
        }
        break;
      case "InviteSentToStaffMember":
        log = "notification.InviteSentToStaffMember.clinic";
        break;
      case "PaymentReceived":
        log = "notification.PaymentReceived.clinic";
        break;
      case "AppointmentPaymentRefund":
        if (this.userRole === UserTypeEnum.Provider.toString()) {
          log = "notification.AppointmentPaymentRefund.provider";
        } else {
          log = "notification.AppointmentPaymentRefund.clinic";
        }
        break;
      case "PaymentRefund":
        log = "notification.PaymentRefund.clinic";
        break;
      case "Completed":
        log = "notification.Completed.clinic";
        break;
      case "Missed":
        log = "notification.Missed.clinic";
        break;
      default:
      // code block
    }
    if (log !== "") {
      this.notificationData.push({
        notification: log,
        notificationDetails: data,
      });
    }
  }

  /**
   * Method to filter notification by event type
   */
  public selectEventType(eventType: string) {
    const index: number = this.selectedEventType.indexOf(eventType);
    if (index !== -1) this.selectedEventType.splice(index, 1);
    else this.selectedEventType.push(eventType);
  }

  /**
   * Method to prepare notification message by language
   */
  public prepareMessageByLanguage(notificationData: any) {
    let message = "";
    this._translate
      .get(notificationData.notification)
      .subscribe((log: string) => {
        message = log;
      });
    return message
      .replace(
        "{patientName}",
        notificationData.notificationDetails.patientName
      )
      .replace(
        "{providerName}",
        notificationData.notificationDetails.providerName
      )
      .replace("{clinicName}", notificationData.notificationDetails.clinicName);
  }
}

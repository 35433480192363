<div class="sidebar pb-2">
  <div class="right-content">
    <div class="dashboard-page">
      <div class="daboard-header">
        <div class="page-title">
          <div class="page-title-left">
            <div class="selected-date">
              <div class="selected-date-left">
                <h6 *ngIf="currentDayText">{{ currentDayText }}</h6>
                <p>
                  <b>{{ currentDateText }}</b>
                  <span>({{ totalData }})</span>
                </p>
              </div>
              <div class="selected-date-right" (click)="picker.open()">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ "common.chooseDate" | translate }}</mat-label>
                  <input
                    matInput
                    [matDatepicker]="picker"
                    [(ngModel)]="selectedDate"
                    (dateChange)="changeDate()"
                    readonly
                    hidden
                  />
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker
                    #picker
                    [startView]="dateStartView"
                    (monthSelected)="closeDatePicker($event, picker)"
                  ></mat-datepicker>
                </mat-form-field>
                <button class="btn btn-gray">
                  <img src="assets/images/calender-icon.svg" alt="" />
                </button>
              </div>
            </div>

            <div class="change-date">
              <ul>
                <li
                  [ngClass]="{ active: viewType === 'day' }"
                  (click)="changeView('day')"
                >
                  <a href="javascript:void(0)">{{
                    "common.todayText" | translate
                  }}</a>
                </li>
                <li
                  [ngClass]="{ active: viewType === 'week' }"
                  (click)="changeView('week')"
                >
                  <a href="javascript:void(0)">{{
                    "common.weekText" | translate
                  }}</a>
                </li>
                <li
                  [ngClass]="{ active: viewType === 'month' }"
                  (click)="changeView('month')"
                >
                  <a href="javascript:void(0)">{{
                    "common.monthText" | translate
                  }}</a>
                </li>
              </ul>
            </div>
            <div class="next-previous-date">
              <ul>
                <li (click)="selectPrevious()">
                  <a href="javascript:void(0)"
                    ><mat-icon>keyboard_arrow_left</mat-icon></a
                  >
                </li>
                <li (click)="selectNext()">
                  <a href="javascript:void(0)"
                    ><mat-icon>keyboard_arrow_right</mat-icon></a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="page-title-right">
            <div class="search mr-2">
              <div class="form-group">
                <mat-form-field class="example-form-field">
                  <input
                    matInput
                    type="text"
                    placeholder="{{ 'common.searchByPatientText' | translate }}"
                    [(ngModel)]="searchString"
                    (keyup)="searchByPatient()"
                  />
                  <button matSuffix>
                    <img src="assets/images/icon-search.svg" alt="" />
                  </button>
                </mat-form-field>
              </div>
            </div>
            <div
              class="form-group mb-0 provider-select"
              *ngIf="
                viewType === 'month' &&
                userRole !== userTypeEnum.Provider.toString()
              "
            >
              <mat-form-field appearance="fill">
                <mat-label>{{
                  "scheduler.selectProviderPlaceholder" | translate
                }}</mat-label>
                <mat-select
                  placeholder="{{
                    'scheduler.selectProviderPlaceholder' | translate
                  }}"
                  [(ngModel)]="selectedProviderValue"
                  (selectionChange)="changeProvider()"
                >
                  <input
                    type="text"
                    #searchProviderInput
                    (keyup)="searchProvider($event)"
                    (keydown)="_globalFunction.handleInput($event)"
                    placeholder="{{
                      'appointment.searchProviderText' | translate
                    }}"
                    class="search-input"
                  />
                  <mat-option
                    *ngFor="let provider of filteredProviderList"
                    [value]="provider.userId"
                    >{{ provider.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <button
              class="btn btn-primary ms-3 min-width-btn"
              (click)="addNewAppointment()"
            >
              <img src="assets/images/plus-icon.svg" alt="" class="me-2" />
              {{ "common.appointmentText" | translate }}
            </button>
            <button *ngIf="userRole !== userTypeEnum.Provider.toString()"
              class="btn btn-primary ms-3 min-width-btn"
              (click)="addPatient()"
            >
              <img src="assets/images/plus-icon.svg" alt="" class="me-2" />
              {{ "common.patientText" | translate }}
            </button>

            <button
              class="btn btn-primary ms-3"
              (click)="filterClick()"
              [ngClass]="filter ? 'active' : ''"
            >
              <img src="assets/images/filter-icon.svg" alt="" />
            </button>
          </div>
        </div>
        <app-filter
          (filterData)="getFilterData($event)"
          (applyFilterData)="applyFilterData()"
          [filter]="filter"
          [showFilterList]="showFilterList"
        ></app-filter>
      </div>
      <div class="dashboard-body">
        <app-clinic-dashboard-day-view
          *ngIf="viewType === 'day'"
          [viewType]="viewType"
          [dashboardDataList]="dashboardDataList"
          [providerList]="providerList"
          [filterMultiselectProviderValue]="multiselectProviderList"
          (viewAppointmentData)="viewAppointmentPopup($event, true)"
          (addAppointmentBySlot)="addAppointmentPopup($event)"
          [refreshDataView]="refreshDataView"
          [searchData]="searchString"
          (pasteAppointmentData)="pasteAppointmentPopup($event, true)"
        ></app-clinic-dashboard-day-view>
        <app-clinic-dashboard-week-view
          *ngIf="viewType === 'week'"
          [viewType]="viewType"
          [dashboardDataList]="dashboardDataList"
          [providerList]="providerList"
          [selectedDate]="selectedDate"
          [filterMultiselectProviderValue]="multiselectProviderList"
          (viewAppointmentData)="viewAppointmentPopup($event, true)"
          (addAppointmentBySlot)="addAppointmentPopup($event)"
          [refreshDataView]="refreshDataView"
          [searchData]="searchString"
          (pasteAppointmentData)="pasteAppointmentPopup($event, true)"
        ></app-clinic-dashboard-week-view>
        <app-clinic-dashboard-month-view
          *ngIf="viewType === 'month'"
          [viewType]="viewType"
          [dashboardDataList]="dashboardDataList"
          [selectedProviderValue]="selectedProviderValue"
          [selectedDate]="selectedDate"
          (viewAppointmentData)="viewAppointmentPopup($event, true)"
          (addAppointmentBySlot)="addAppointmentPopup($event)"
          [refreshDataView]="refreshDataView"
          [searchData]="searchString"
          (pasteAppointmentData)="pasteAppointmentPopup($event, true)"
        ></app-clinic-dashboard-month-view>
      </div>
    </div>

    <div class="drawer-backdrop" [ngClass]="rightDrawer ? 'open' : ''"></div>
    <div class="right-drawer" [ngClass]="rightDrawer ? 'open' : ''">
      <button class="btn btn-link" (click)="showHideAppointmentPopup(false)">
        <img src="assets/images/close-icon.svg" alt="" />
      </button>
      <app-add-appointment
        *ngIf="addAppointment"
        (showAppointmentPopup)="showHideAppointmentPopup($event)"
        [requestFromClinicDashboard]="requestFromClinicDashboard"
        [editAppointmentData]="pasteAppointmentData"
      ></app-add-appointment>
      <app-clinic-dashboard-add-appointment
        *ngIf="addAppointmentBySlot"
        [appointmentData]="viewAppointmentData"
        (showAppointmentPopup)="showHideAppointmentPopup($event)"
        (refreshData)="refreshDashboard($event)"
      >
      </app-clinic-dashboard-add-appointment>
      <app-view-appointment
        *ngIf="viewAppointment"
        [viewAppointmentData]="viewAppointmentData"
        (showViewAppointmentPopup)="showHideAppointmentPopup($event)"
        (refreshData)="refreshDashboard($event)"
      ></app-view-appointment>
    </div>
  </div>
</div>

import { Component } from "@angular/core";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-unauthorization",
  templateUrl: "./unauthorization.component.html",
  styleUrls: ["./unauthorization.component.css"],
})
export class UnAuthorizationComponent {
  userRole: string = "";

  constructor(
    private _commonService: CommonService,
    private _authService: AuthGuardService
  ) {}

  public goToHomePage() {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
      }
    });
    this._commonService.redirectToLandingPage(this.userRole);
  }
}

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Renderer2,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { GlobalFunctions } from "src/app/app.global";
import { StaticPageTitleEnum } from "src/app/enums/staticPageTitleEnum";
import { CountryModel } from "src/app/models/countryModel";
import { DocumentModel } from "src/app/models/documentModel";
import { SignUpModel } from "src/app/models/signUpModel";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit {
  signUpModel = new SignUpModel();
  signUpValidation: FormGroup = new FormGroup("");
  formSubmitted: boolean = false;
  codeSelect: string = "";
  phoneCodeList: CountryModel[] = [];
  passwordStrength: number = 0;
  clinicDocuments: DocumentModel[] = [];
  clinicDocumentList: string = "";
  isTermsAndCondition: boolean = false;
  showPasswordStrength: boolean = true;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  isValidPassword: boolean = false;
  @Output() activeSignUpComponent = new EventEmitter<string>();
  @Output() activeLoginSignUpTab = new EventEmitter<number>();

  constructor(
    private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _toastrService: ToastrNotificationService,
    public _translate: TranslateService,
    private _renderer: Renderer2,
    public _globalFunction: GlobalFunctions
  ) {
    this.createSignUpForm();
    this._renderer.listen("window", "click", (e: Event) => {
      this.showPasswordStrength = false;
    });
  }

  ngOnInit() {
    this.getPhoneCode();
  }

  /**
   * Method to create a login form
   */
  public createSignUpForm() {
    this.signUpValidation = this._formBuilder.group({
      clinicName: ["", [Validators.required]],
      phoneCode: ["", [Validators.required]],
      phoneNo: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(10),
          Validators.pattern("^[0-9]*$"),
        ]),
      ],
      clinicDocument: ["", [Validators.required]],
      password: ["", [Validators.compose([Validators.required])]],
      confirmPassword: [
        "",
        [
          Validators.compose([
            Validators.required,
            this.validatePasswordMatch.bind(this),
          ]),
        ],
      ],
      isTermsAndCondition: [""],
    });
  }

  /**
   * Method to match the password and confirm password
   */
  private validatePasswordMatch(fieldControl: FormControl) {
    if (fieldControl.value !== this.signUpValidation.get("password")?.value) {
      return { NotEqual: true };
    } else {
      return null;
    }
  }

  /**
   * Method to submit login
   */
  public submitSignUp() {
    this.formSubmitted = true;
    if (this.signUpValidation.valid && this.isValidPassword) {
      if (!this.isTermsAndCondition) {
        return this._toastrService.showError(
          "signup.requiredTermsAndConditions"
        );
      }
      this.signUpModel.clinicName =
        this.signUpValidation.get("clinicName")?.value;
      this.signUpModel.userId =
        localStorage.getItem("signupUserId")?.toString() || "";
      this.signUpModel.email =
        localStorage.getItem("signupEmail")?.toString() || "";
      this.signUpModel.phoneCode =
        this.signUpValidation.get("phoneCode")?.value;
      this.signUpModel.phoneNo = this.signUpValidation.get("phoneNo")?.value;
      this.signUpModel.password = this.signUpValidation.get("password")?.value;
      this.signUpModel.documents = this.clinicDocuments;

      this._commonService.signUp(this.signUpModel).subscribe({
        next: (data) => {
          if (data && data.success && data.success === true) {
            this._toastrService.showSuccess("signup.signUpSuccessMessage");
            this.activeSignUpComponent.emit("signupEmail");
            this.activeLoginSignUpTab.emit(0);
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
    }
  }

  /**
   * Method to get phone code data
   */
  public getPhoneCode() {
    this._commonService.getCountry().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.phoneCodeList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to set password strength
   */
  public onPasswordStrengthChanged(strength: number) {
    this.passwordStrength = strength;
    if (this.passwordStrength > 80) {
      this.showPasswordStrength = false;
      this.isValidPassword = true;
    } else {
      this.isValidPassword = false;
      this.showPasswordStrength = true;
    }
  }

  /**
   * Method to change confirm password
   */
  public onConfirmPasswordChanged() {
    if (
      this.signUpValidation.get("password")?.value ===
        this.signUpValidation.get("confirmPassword")?.value &&
      this.passwordStrength > 80
    ) {
      this.isValidPassword = true;
    } else {
      this.isValidPassword = false;
    }
  }

  /**
   * Method when password changes
   */
  public onPasswordChange(data: Event): void {
    this.signUpValidation.patchValue({
      confirmPassword: this.signUpValidation.get("confirmPassword")?.value,
    });

    this.showPasswordStrength = true;
    if (this.passwordStrength > 80) {
      this.isValidPassword = true;
      this.showPasswordStrength = false;
      this.passwordStrength = 0;
    } else {
      this.isValidPassword = false;
      this.showPasswordStrength = true;
    }
  }

  /**
   * Method to upload clinic documents
   */
  public fileChange(event: any) {
    if (event.target.files) {
      this.clinicDocuments = [];
      var files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const fileSize = event.target.files[i].size / 1048576;
        var file = files[i];
        if (fileSize > 5) {
          return this._toastrService.showError("common.documentSizeError");
        }
        if (file) {
          var reader = new FileReader();
          reader.onload = this.convertFileToByte.bind(this, file.name);
          reader.readAsBinaryString(file);
        }
      }
    }
  }

  /**
   * Method to convert file to byte
   */
  public convertFileToByte(fileName: string, readerEvt: any) {
    var binaryString = readerEvt.target.result;
    let base64textString = btoa(binaryString);
    this.clinicDocuments.push({
      DocumentId: 0,
      fileName: fileName,
      fileBytes: base64textString,
    });
    this.clinicDocumentList = this.clinicDocuments
      .map((x) => x.fileName)
      .join(",");
  }

  /**
   * Method to show/hide password
   */
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * Method to show/hide confirm password
   */
  public toggleConfirmPasswordVisibility(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  /**
   * Method to open static content popup
   */
  public async openStaticContentPopup() {
    this._commonService.getStaticContentByPageTitleId(
      StaticPageTitleEnum.TermsOfUse
    );
  }
}

import { Component, ViewChild } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { GlobalFunctions } from "src/app/app.global";
import { UserTypeEnum } from "src/app/enums/userTypeEnum";
import { ClinicService } from "src/app/modules/clinic/services/clinic.service";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";
import { AppointmentHistoryComponent } from "../appointment-history/appointment-history.component";
import { UpcomingAppointmentsComponent } from "../upcoming-appointments/upcoming-appointments.component";

@Component({
  selector: "app-appointment-list",
  templateUrl: "./appointment-list.component.html",
  styleUrls: ["./appointment-list.component.css"],
})
export class AppointmentListComponent {
  userRole: string = "";
  userId: any;
  filter: boolean = false;
  showFilterList: any = [];
  rightDrawer: boolean = false;
  addAppointment: boolean = false;
  tabIndex = 0;
  searchText: string = "";
  searchString: string = "";
  appointmentType: string = "";
  refreshAppointmentGrid: boolean = false;
  todayDate: Date = new Date();
  @ViewChild(UpcomingAppointmentsComponent, { static: false })
  upcomingAppointments!: UpcomingAppointmentsComponent;
  @ViewChild(AppointmentHistoryComponent, { static: false })
  appointmentHistory!: AppointmentHistoryComponent;
  filterStartDateValue: any = "";
  filterEndDateValue: any = "";
  filterPatientValue: any = null;
  filterPatientEmailValue: any = "";
  filterProviderValue: any = null;
  filterVisitTypeValue: any = 0;
  filterInsuranceValue: any = 0;
  filterStatusValue: any = 0;
  viewAppointment: boolean = false;
  viewAppointmentData: any;
  editAppointmentData: any;

  constructor(
    private _commonService: CommonService,
    private _clinicService: ClinicService,
    public _globalFunction: GlobalFunctions,
    private _authService: AuthGuardService
  ) {
    this.todayDate = this._commonService.getCurrentTimeZoneNewDate();
  }

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
        this.userId = data["userid"];
      }
    });
    this.setFilterFields();
  }

  /**
   * Method to set filter fields
   */
  public setFilterFields() {
    this.showFilterList = [];
    this.showFilterList.push("FromDateToDate");
    this.showFilterList.push("Patient");
    this.showFilterList.push("VisitType");
    this.showFilterList.push("Insurance");
    if (this.userRole !== UserTypeEnum.Provider.toString())
      this.showFilterList.push("Provider");
    if (this.userRole === UserTypeEnum.Provider.toString())
      this.showFilterList.push("PatientEmail");
    if (this.tabIndex === 1) {
      this.showFilterList.push("AppointmentHistory");
      this.showFilterList.push("AppointmentStatus");
    }
  }

  /**
   * Method to open add appointment section
   */
  public addNewAppointment() {
    this.rightDrawer = !this.rightDrawer;
    this.viewAppointment = false;
    this.addAppointment = true;
    this.editAppointmentData = null;
  }

  /**
   * Method to open view appointment section
   */
  public viewAppointmentPopup(data: any) {
    this.rightDrawer = !this.rightDrawer;
    this.viewAppointment = true;
    this.viewAppointmentData = data;
  }

  /**
   * Method to open edit appointment section
   */
  public editAppointmentPopup(appointmentData: any) {
    this.addAppointment = true;
    this.viewAppointment = false;
    this.editAppointmentData = appointmentData;
  }

  /**
   * Method to show/hide appointment section
   */
  public showHideAppointmentPopup(showUserPopup: any): void {
    this.rightDrawer = showUserPopup;
    this.addAppointment = showUserPopup ? true : false;
  }

  /**
   * Method to load upcoming appointment data after add/update scheduler
   */
  public loadUpcomingAppointmentData(isRefreshData: any): void {
    this.refreshAppointmentGrid = isRefreshData;
  }

  /**
   * Method to changes appointments tab
   */
  public changeTab(event: MatTabChangeEvent) {
    this.tabIndex = event.index;
    this.appointmentType = event.tab.textLabel;
    this.setFilterFields();
  }

  /**
   * Method to search data
   */
  public searchDataByKeyword() {
    this.refreshAppointmentGrid = false;
    this.searchString = this.searchText;
  }

  /**
   * Method to toggle filter section
   */
  public filterClick() {
    this.filter = !this.filter;
  }

  /**
   * Method to get filter data
   */
  public getFilterData(data: any) {
    this.filterPatientValue = data.filterPatientValue;
    this.filterPatientEmailValue = data.filterPatientEmailValue;
    this.filterProviderValue = data.filterProviderValue;
    this.filterVisitTypeValue = data.filterVisitTypeValue;
    this.filterInsuranceValue = data.filterInsuranceValue;
    this.filterStatusValue = data.filterStatusValue;
    this.filterStartDateValue = data.filterStartDateValue;
    this.filterEndDateValue = data.filterEndDateValue;
  }

  /**
   * Method to apply filter data
   */
  public applyFilterData() {
    this.loadAppointmentGrid(true);
  }

  /**
   * Method to load appointment grid
   */
  public loadAppointmentGrid(applyFilter: boolean = false) {
    if (this.tabIndex === 0) {
      this.upcomingAppointments.filterStartDate = this.filterStartDateValue;
      this.upcomingAppointments.filterEndDate = this.filterEndDateValue;
      this.upcomingAppointments.filterPatient = this.filterPatientValue;
      this.upcomingAppointments.filterPatientEmail =
        this.filterPatientEmailValue;
      this.upcomingAppointments.filterProvider = this.filterProviderValue;
      this.upcomingAppointments.filterVisitType = this.filterVisitTypeValue;
      this.upcomingAppointments.filterInsurance = this.filterInsuranceValue;
      this.upcomingAppointments.filterStatus = this.filterStatusValue;
      this.upcomingAppointments.loadUpcomingAppointmentData(applyFilter);
    }
    if (this.tabIndex === 1) {
      this.appointmentHistory.filterStartDate = this.filterStartDateValue;
      this.appointmentHistory.filterEndDate = this.filterEndDateValue;
      this.appointmentHistory.filterPatient = this.filterPatientValue;
      this.appointmentHistory.filterPatientEmail = this.filterPatientEmailValue;
      this.appointmentHistory.filterProvider = this.filterProviderValue;
      this.appointmentHistory.filterVisitType = this.filterVisitTypeValue;
      this.appointmentHistory.filterInsurance = this.filterInsuranceValue;
      this.appointmentHistory.filterStatus = this.filterStatusValue;
      this.appointmentHistory.loadAppointmentHistoryData(applyFilter);
    }
  }
}

<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/notification.svg" alt="" />
        </div>
        <h2>{{ "settings.settingsHeadingText" | translate }}</h2>
      </div>
    </div>
    <div class="content-page">
      <div class="settings-page form-page">
        <div class="setting-accordion pb-3">
          <mat-accordion [multi]="true">
            <mat-expansion-panel [expanded]="isAllExpanded">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "settings.subscriptionPlanHeadingText" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-class-body">
                <div class="d-flex flex-wrap">
                  <form
                    [formGroup]="subscriptionPlanValidation"
                    (ngSubmit)="saveSubscriptionPlan()"
                    class="frmSubscriptionPlan"
                  >
                    <div class="form-group float-label">
                      <mat-label class="min-width-auto pe-2"
                        >{{ "settings.planNameLabelText" | translate }}
                        <span class="required">*</span>
                      </mat-label>
                      <div class="max-width-120 me-3">
                        <mat-form-field>
                          <input
                            matInput
                            formControlName="planName"
                            [(ngModel)]="subscriptionPlanModel.planName"
                            required
                          />
                        </mat-form-field>
                        <div
                          *ngIf="
                            subscriptionPlanValidation.controls['planName']
                              .invalid && formSubmitted
                          "
                        >
                          <mat-error
                            *ngIf="
                              subscriptionPlanValidation
                                .get('planName')
                                ?.hasError('required')
                            "
                            >{{
                              "settings.planNameRequiredErrorText" | translate
                            }}</mat-error
                          >
                        </div>
                      </div>
                    </div>
                    <div class="form-group float-label">
                      <mat-label class="min-width-auto pe-2"
                        >{{ "settings.planPriceLabelText" | translate }}
                        <span class="required">*</span>
                      </mat-label>
                      <div class="max-width-120 me-3">
                        <mat-form-field>
                          <span matPrefix>$</span>
                          <input
                            matInput
                            formControlName="planPrice"
                            [(ngModel)]="subscriptionPlanModel.planPrice"
                            (keypress)="
                              _globalFunction.allowNumbersOnly($event)
                            "
                            maxlength="5"
                            required
                          />
                        </mat-form-field>
                        <div
                          *ngIf="
                            subscriptionPlanValidation.controls['planPrice']
                              .invalid && formSubmitted
                          "
                        >
                          <mat-error
                            *ngIf="
                              subscriptionPlanValidation
                                .get('planPrice')
                                ?.hasError('required')
                            "
                            >{{
                              "settings.planPriceRequiredErrorText" | translate
                            }}</mat-error
                          >
                        </div>
                      </div>
                    </div>
                    <div class="form-group float-label">
                      <mat-label class="min-width-auto pe-2"
                        >{{ "settings.durationInMonthLabelText" | translate }}
                        <span class="required">*</span>
                      </mat-label>
                      <div class="max-width-120 me-3">
                        <mat-form-field>
                          <input
                            matInput
                            type="text"
                            min="1"
                            formControlName="durationInMonth"
                            [(ngModel)]="subscriptionPlanModel.durationInMonth"
                            (keypress)="
                              _globalFunction.allowNumbersOnly($event)
                            "
                            maxlength="5"
                            required
                          />
                        </mat-form-field>
                        <div
                          *ngIf="
                            (subscriptionPlanValidation.controls[
                              'durationInMonth'
                            ].invalid ||
                              subscriptionPlanValidation.get('durationInMonth')
                                ?.value === 0) &&
                            formSubmitted
                          "
                        >
                          <mat-error
                            *ngIf="
                              subscriptionPlanValidation
                                .get('durationInMonth')
                                ?.hasError('required') ||
                              subscriptionPlanValidation
                                .get('durationInMonth')
                                ?.hasError('pattern') ||
                              subscriptionPlanValidation.get('durationInMonth')
                                ?.value === 0
                            "
                            >{{
                              "settings.durationInMonthRequiredErrorText"
                                | translate
                            }}</mat-error
                          >
                        </div>
                      </div>
                    </div>
                    <div type="submit" class="form-group">
                      <button class="btn btn-primary" *ngIf="!isEditMode">
                        <img
                          src="assets/images/plus-icon.svg"
                          alt=""
                          class="me-2"
                        />
                        {{ "common.addText" | translate }}
                      </button>
                      <button class="btn btn-primary" *ngIf="isEditMode">
                        <img
                          src="assets/images/save-icon.svg"
                          alt=""
                          class="me-2"
                        />
                        {{ "common.updateText" | translate }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div class="mat-elevation-z8 table-responsive">
                <table mat-table [dataSource]="dataSource" class="table border">
                  <ng-container matColumnDef="planName">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "settings.planNameLabelText" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.planName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "settings.planPriceLabelText" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      $ {{ element.price }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="durationInMonth">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "settings.durationInMonthLabelText" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.durationInMonth }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "userList.actionLabelText" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="table-action">
                        <a
                          href="javascript:void(0)"
                          (click)="editSubscriptionPlan(element)"
                          ><img src="assets/images/edit-icon.svg" alt=""
                        /></a>
                        <a
                          href="javascript:void(0)"
                          (click)="deleteSubscriptionPlan(element)"
                          ><img src="assets/images/delete-icon.svg" alt=""
                        /></a>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </mat-expansion-panel>
            <!-- <mat-expansion-panel [expanded]="isAllExpanded">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{
                    "settings.appointmentCommissionPercentageHeadingText"
                      | translate
                  }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-class-body">
                <div class="d-flex flex-wrap">
                  <div class="form-group float-label">
                    <mat-label class="min-width-auto pe-2"
                      >{{ "settings.percentageLabelText" | translate }}
                      <span class="required">*</span>
                    </mat-label>
                    <mat-form-field class="max-width-120 me-3">
                      <span matSuffix>%</span>
                      <input
                        matInput
                        [(ngModel)]="appPercentage"
                        (keypress)="
                          _globalFunction.allowNumbersWithDotOnly($event)
                        "
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-secondary me-3"
                (click)="saveSettings()"
              >
                <img src="assets/images/save-icon.svg" alt="" class="me-2" />
                {{ "addUser.saveButtonText" | translate }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                routerLink="/dashboard"
              >
                <img
                  src="assets/images/white-close-icon.svg"
                  alt=""
                  class="me-2"
                />
                {{ "addUser.cancelButtonText" | translate }}
              </button>
            </mat-expansion-panel> -->

            <mat-expansion-panel [expanded]="isAllExpanded">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "common.mySettingsText" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-class-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label
                        >{{ "appointment.selectTimezoneText" | translate
                        }}<span class="required">*</span></mat-label
                      >
                      <div class="w-100">
                        <mat-form-field class="example-form-field timezone">
                          <mat-select
                            placeholder="{{
                              'appointment.selectTimezoneText' | translate
                            }}"
                            [(ngModel)]="timezone"
                          >
                            <input
                              type="text"
                              #searchTimezoneInput
                              (keyup)="searchTimeZone($event)"
                              (keydown)="_globalFunction.handleInput($event)"
                              placeholder="{{
                                'common.searchTimezoneText' | translate
                              }}"
                              class="search-input"
                            />
                            <mat-option
                              *ngFor="let tz of filteredTimezoneList"
                              [value]="tz"
                              >{{ tz.displayName }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label
                        >{{ "common.selectLanguageLabel" | translate
                        }}<span class="required">*</span></mat-label
                      >
                      <div class="w-100">
                        <mat-form-field class="example-form-field timezone">
                          <mat-select
                            placeholder="{{
                              'common.selectLanguageLabel' | translate
                            }}"
                            [(ngModel)]="language"
                          >
                            <mat-option
                              *ngFor="let item of languageList"
                              [value]="item.languageId"
                              >{{ item.name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label
                        >{{ "common.timeFormatText" | translate
                        }}<span class="required">*</span></mat-label
                      >
                      <div class="w-100">
                        <div class="form-group">
                          <div class="toggle-time">
                            <mat-slide-toggle
                              class="toggle"
                              [checked]="timeFormat"
                              [(ngModel)]="timeFormat"
                              (change)="changeTimeFormat($event)"
                            ></mat-slide-toggle>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-secondary me-3"
                (click)="saveSettings()"
              >
                <img src="assets/images/save-icon.svg" alt="" class="me-2" />
                {{ "addUser.saveButtonText" | translate }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                (click)="cancelSettings()"
              >
                <img
                  src="assets/images/white-close-icon.svg"
                  alt=""
                  class="me-2"
                />
                {{ "addUser.cancelButtonText" | translate }}
              </button>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>

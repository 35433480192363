import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationStart,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { TokenModel } from "../models/tokenModel";
import { BehaviorSubject, Observable } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";
import { GlobalFunctions } from "../app.global";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private _globalFunction: GlobalFunctions
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (val && val.url) {
          localStorage.setItem("iCareVIP_url", val.url);
        }
      }
    });
  }

  public loggedIn = new BehaviorSubject<boolean>(false);
  public userRole = new BehaviorSubject<string>("");
  public loggedInUserData = new BehaviorSubject<any>("");
  public unReadNotificationData = new BehaviorSubject<boolean>(false);

  /**
   * Method to check user is loggedin or not
   */
  get isLoggedIn() {
    let tokenValue = localStorage.getItem("userTokenDetails");
    if (tokenValue) {
      this.loggedIn.next(true);
    } else {
      this.loggedIn.next(false);
    }
    return this.loggedIn.asObservable();
  }

  public getUserDetails() {
    let userData;
    this.getUserData().subscribe((data) => {
      userData = data;
    });
    return userData;
  }

  /**
   * Method to get user data
   */
  public getUserData(): Observable<any> {
    let userData: any;
    let tokenData = localStorage.getItem("userTokenDetails");
    if (tokenData && tokenData !== null) {
      let tokenDetails = JSON.parse(tokenData) || "";
      const userTokenDetails: TokenModel = {
        access_token: tokenDetails.access_token,
        expiry_date: tokenDetails.expiry_date,
        refresh_token: tokenDetails.refresh_token,
      };
      let isTokenExpired = this.checkTokenExpired(userTokenDetails);
      if (isTokenExpired) {
        localStorage.removeItem("userTokenDetails");
        location.reload();
      }
      userData = this.getDecodedAccessToken(userTokenDetails.access_token);
      if (userData && userData !== null) {
        let leftMenuList = this._globalFunction.getLeftMenu();
        leftMenuList = leftMenuList.filter((x) =>
          x.allowedRoles.includes(parseInt(userData.usertypeid))
        );
        userData["leftMenuList"] = leftMenuList;
      }
    }
    this.loggedInUserData.next(userData);
    return this.loggedInUserData.asObservable();
  }

  /**
   * Method to check user access while accessing url
   */
  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const authenticated = await this.checkAuthenticationAsPromise(state);
    return authenticated;
  }

  /**
   * Method to check user authentication
   */
  public checkAuthenticationAsPromise(
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let userDetails = this.getUserDetails();
      if (userDetails && userDetails !== "") {
        return resolve(true);
      } else {
        this.router.navigate(["/login"]);
        return resolve(false);
      }
    });
  }

  /**
   * Method to check token expired or not
   */
  public checkTokenExpired(tokenDetails: TokenModel) {
    const currentDate = new Date().toISOString();
    const expiryDate = new Date(tokenDetails.expiry_date).toISOString();
    return expiryDate < currentDate;
  }

  /**
   * Method to get details from access token
   */
  public getDecodedAccessToken(token: string): any {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    return decodedToken;
  }

  /**
   * Method to get token details
   */
  public getTokenDetails(): TokenModel {
    let tokenDetails;
    let tokenData = localStorage.getItem("userTokenDetails");
    if (tokenData && tokenData !== null) {
      tokenDetails = JSON.parse(tokenData) || "";
    }
    return tokenDetails;
  }
}

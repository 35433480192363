import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  OnInit,
  OnChanges,
  AfterViewInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment-timezone";
import { GlobalFunctions } from "src/app/app.global";
import { ConfirmationDialogComponent } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { StylePaginatorDirective } from "src/app/directives/style-paginator.directive";
import { AppointmentStatusEnum } from "src/app/enums/appointmentStatusEnum";
import { clinicApiList } from "src/app/enums/clinicApiEnum";
import { UserTypeEnum } from "src/app/enums/userTypeEnum";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { AppointmentService } from "../../services/appointment.service";

@Component({
  selector: "app-upcoming-appointments",
  templateUrl: "./upcoming-appointments.component.html",
  styleUrls: ["./upcoming-appointments.component.css"],
})
export class UpcomingAppointmentsComponent
  implements OnInit, OnChanges, AfterViewInit
{
  userRole: string = "";
  userTypeEnum = UserTypeEnum;
  displayedColumns: string[] = [
    "patientName",
    "patientEmail",
    "medicalProviderName",
    "appointmentDateTime",
    "contact",
    "visitType",
    "insurance",
    "action",
  ];
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(StylePaginatorDirective, { static: true })
  paginator!: StylePaginatorDirective;
  @Input() searchData: string = "";
  @Input() refreshAppointmentData: boolean = false;
  pageNumber: number = 1;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  showPaginator: boolean = true;
  @Input() filterStartDate: any = "";
  @Input() filterEndDate: any = "";
  @Input() filterPatient: any = null;
  @Input() filterPatientEmail: any = "";
  @Input() filterProvider: any = null;
  @Input() filterVisitType: any = 0;
  @Input() filterInsurance: any = 0;
  @Input() filterStatus: any = 0;
  @Output() viewAppointmentData = new EventEmitter<any>();
  @Output() editAppointmentData = new EventEmitter<any>();
  timeFormat: any;
  currentTimeZoneName: any;

  constructor(
    public _commonService: CommonService,
    private _appointmentService: AppointmentService,
    private _authService: AuthGuardService,
    public _globalFunction: GlobalFunctions,
    private datePipe: DatePipe,
    private _toastrService: ToastrNotificationService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.currentTimeZoneName = this._commonService.getTimeZoneShortName();
    this.timeFormat = this._commonService.getTimeFormat();
    let paymentResult = this.route.snapshot.queryParamMap.get("payment");
    if (paymentResult && paymentResult === "success") {
      this._toastrService.showSuccess(
        "appointment.addAppointmentSuccessMessage"
      );
      // Remove query params
      this.router.navigate([], {
        queryParams: {
          payment: null,
        },
        queryParamsHandling: "merge",
      });
    }

    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
      }
    });
    if (this.userRole === this.userTypeEnum.Provider.toString())
      this.displayedColumns = this.displayedColumns.filter(
        (x: any) => x !== "medicalProviderName"
      );
  }

  ngOnInit(): void {
    this.loadUpcomingAppointmentData();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.loadUpcomingAppointmentData());
  }

  ngOnChanges() {
    this.filterData();
  }

  /**
   * Method to get upcoming appointment data
   */
  public loadUpcomingAppointmentData(applyFilter: boolean = false) {
    if (this.sort.direction === "") this.sort.active = "";
    this.currentPage = applyFilter ? 0 : this.currentPage;
    let appointmentParam = {
      sortColumnName: this.sort.active,
      sortDirection:
        this.sort.direction === ""
          ? true
          : this.sort.direction === "asc"
          ? true
          : false,
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      fromDate:
        this.filterStartDate !== ""
          ? this.datePipe.transform(
              this._globalFunction.getUTCDate(
                new Date(this.filterStartDate + " UTC")
              ),
              "yyyy-MM-dd"
            )
          : null,
      toDate:
        this.filterEndDate !== ""
          ? this.datePipe.transform(
              this._globalFunction.getUTCDate(
                new Date(this.filterEndDate + " UTC")
              ),
              "yyyy-MM-dd"
            )
          : null,
      patientUserId: this.filterPatient,
      patientEmail: this.filterPatientEmail,
      providerUserId: this.filterProvider,
      visitTypeId: this.filterVisitType,
      insuranceId: this.filterInsurance,
      status: this.filterStatus,
      offset: moment().tz(moment.tz.guess()).utcOffset(),
    };
    this._appointmentService
      .getAllUpcomingAppointment(appointmentParam)
      .subscribe({
        next: (data) => {
          if (
            data &&
            data !== null &&
            data.data &&
            data.data !== null &&
            data.data.length > 0
          ) {
            this.dataSource = new MatTableDataSource(data.data);
          } else {
            this.dataSource = new MatTableDataSource([]);
          }
          this.totalRows = data.totalRecords;
          this.paginator.matPag.length = this.totalRows;
          if (applyFilter) {
            this.paginator._curPageObj.pageIndex = 0;
            this.paginator._rangeStart = 0;
            this.paginator._rangeEnd = 1;
          }
          this.paginator.initPageRange();
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
  }

  /**
   * Method to filter data
   */
  public pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadUpcomingAppointmentData();
  }

  /**
   * Method to filter data
   */
  public filterData() {
    this.showPaginator = true;
    if (this.searchData.trim() !== "") {
      this.showPaginator = false;
    }
    this.refreshUpcomingAppoinmentGrid(this.refreshAppointmentData);
    this.dataSource.filter = this.searchData.trim().toLocaleLowerCase();
  }

  /**
   * Method to refresh grid
   */
  public refreshUpcomingAppoinmentGrid(isRefreshData: boolean) {
    if (isRefreshData) {
      this.loadUpcomingAppointmentData();
    }
  }

  /**
   * Method to view appointment
   */
  public viewAppointment(data: any) {
    this.viewAppointmentData.emit(data);
  }

  /**
   * Method to edit appointment
   */
  public editAppointment(data: any) {
    this.editAppointmentData.emit(data);
  }

  /**
   * Method to missed appointment
   */
  public missedAppointment(data: any) {
    this.updateAppointment(data, AppointmentStatusEnum.Missed);
  }

  /**
   * Method to complete appointment
   */
  public completeAppointment(data: any) {
    this.updateAppointment(data, AppointmentStatusEnum.Completed);
  }

  /**
   * Method to update appointment
   */
  public updateAppointment(appointmentData: any, appointmentStatus: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "appointment.updateAppointmentConfirmText",
        confirmTitleText: "common.confirmUpdateText",
        buttonText: {
          ok: "common.yesText",
          cancel: "common.noText",
        },
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let appointmentParam: any = {};
        appointmentParam["appointmentId"] = appointmentData?.appointmentId;
        appointmentParam["status"] = appointmentStatus;
        appointmentParam["providerSchedulerId"] =
          appointmentData.providerSchedulerId;
        appointmentParam["providerUserId"] = appointmentData.providerUserId;
        appointmentParam["patientUserId"] = appointmentData.patientUserId;
        appointmentParam["notes"] = appointmentData.appointmentNotes;
        appointmentParam["sendNotification"] = true;
        appointmentParam["discount"] = 0;
        appointmentParam["successUrl"] = "";
        appointmentParam["cancelUrl"] = "";

        let apiPath = clinicApiList.updateClinicAppointment;
        this._appointmentService
          .addClinicAppointment(appointmentParam, apiPath)
          .subscribe({
            next: (data) => {
              if (data && data.success && data.success === true) {
                this._toastrService.showSuccess(
                  "appointment.updateAppointmentSuccessMessage"
                );
                this.loadUpcomingAppointmentData();
              }
              if (
                appointmentStatus === AppointmentStatusEnum.Missed &&
                data &&
                data.refund &&
                data.refund !== ""
              ) {
                if (data.refund === "RefundSuccess") {
                  this._toastrService.showSuccess("ErrorCode." + data.refund);
                } else {
                  this._toastrService.showError("ErrorCode." + data.refund);
                }
              }
            },
            error: (error) => {
              this._commonService.showErrorMessage(error);
            },
          });
      }
    });
  }

  /**
   * Method to delete appointment
   */
  public deleteAppointment(data: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "appointment.deleteAppointmentConfirmText",
        confirmTitleText: "common.confirmDeleteText",
        buttonText: {
          ok: "common.yesText",
          cancel: "common.noText",
        },
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if (data.appointmentId !== null) {
          this._appointmentService
            .deleteAppointment(data.appointmentId)
            .subscribe({
              next: (data) => {
                if (data && data.success && data.success === true) {
                  this._toastrService.showSuccess(
                    "appointment.deleteAppointmentSuccessMessage"
                  );
                  this.loadUpcomingAppointmentData();
                }
                if (data && data.refund && data.refund !== "") {
                  if (data.refund === "RefundSuccess") {
                    this._toastrService.showSuccess("ErrorCode." + data.refund);
                  } else {
                    this._toastrService.showError("ErrorCode." + data.refund);
                  }
                }
              },
              error: (error) => {
                this._commonService.showErrorMessage(error);
              },
            });
        }
      }
    });
  }
}

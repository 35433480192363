export class AppointmentModel {
  providerSchedulerId: string;
  providerUserId: string;
  scheduleDate: string;
  slotStartTime: string;
  slotEndTime: string;
  timezone: any;
  patientUser: any;
  appointmentNotes: string;

  constructor() {
    this.providerSchedulerId = "";
    this.providerUserId = "";
    this.scheduleDate = "";
    this.slotStartTime = "";
    this.slotEndTime = "";
    this.timezone = null;
    this.patientUser = null;
    this.appointmentNotes = "";
  }
}

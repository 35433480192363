<div class="row">
  <div class="col-md-12">
    <div class="form-group">
      <div class="remember-me-text">
        <div class="signup-verify-email">
          <strong class="verify-email-label">{{
            "signup.lblVerifyEmailText" | translate
          }}</strong
          ><br />
          <span class="sent-email-label"
            >{{ "signup.lblSentEmailText" | translate }}
          </span>
          <span class="emailid">{{ emailId }}</span
          ><br />
          <span class="signup-continue-label">{{
            "signup.lblSignUpContinueText" | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="form-group">
      <mat-form-field class="example-full-width">
        <span matPrefix><img src="assets/images/icon-lock.png" alt="" /></span>
        <mat-label>{{ 'common.passwordPlaceholder' | translate }}</mat-label>
        <input
          type="password"
          matInput
          placeholder="{{ 'common.passwordPlaceholder' | translate }}"
          [(ngModel)]="password"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="col-md-12">
    <div class="form-group">
      <div class="login-btn">
        <button class="btn btn-primary" (click)="signUpContinue()">
          {{ "common.continueButtonText" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="remember-me-text">
      <div class="forgot-password">
        <span class="resend-email-label"
          >{{ "signup.lblDidntReceiveText" | translate }}
        </span>
        <a href="javascript:void(0);" (click)="resendPasswordEmail()">{{
          "signup.resendButtonText" | translate
        }}</a>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="remember-me-text">
      <div class="forgot-password">
        <span class="already-have-account-label"
          >{{ "signup.alreadyHaveAccountText" | translate }}
        </span>
        <a href="javascript:void(0);" (click)="signIn()">{{
          "signup.signInButtonText" | translate
        }}</a>
      </div>
    </div>
  </div>
</div>

export class LoginModel {
    email: string;
    password: string;
    remember_me: boolean;

    constructor() {
        this.email = "";
        this.password = "";
        this.remember_me = false;
    }
}

import {
  Component,
  OnInit,
  AfterViewInit,
  OnChanges,
  ViewChild,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ConfirmationDialogComponent } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { StylePaginatorDirective } from "src/app/directives/style-paginator.directive";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { SuperAdminService } from "../../services/super-admin.service";

import * as ClassicEditor from "../../../../../assets/ckeditor-custom-build/build/ckeditor";

@Component({
  selector: "app-static-content",
  templateUrl: "./static-content.component.html",
  styleUrls: ["./static-content.component.css"],
})
export class StaticContentComponent
  implements OnInit, AfterViewInit, OnChanges
{
  displayedColumns: string[] = ["pageTitle", "content", "action"];
  dataSource = new MatTableDataSource([]);
  @ViewChild(StylePaginatorDirective, { static: true })
  paginator!: StylePaginatorDirective;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @Input() searchData: string = "";
  @Input() refreshStaticContentData: boolean = false;
  pageNumber: number = 1;
  totalRows: number = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  showPaginator: boolean = true;
  public Editor: any = ClassicEditor;

  constructor(
    private _commonService: CommonService,
    private _toastrService: ToastrNotificationService,
    private _superAdminService: SuperAdminService,
    private _router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadStaticContentData();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.loadStaticContentData());
  }

  ngOnChanges() {
    this.refreshStaticContentList();
  }

  /**
   * Method to refresh static content list
   */
  public refreshStaticContentList() {
    this.showPaginator = true;
    if (this.searchData.trim() !== "") {
      this.showPaginator = false;
    }
    this.refreshStaticContentGrid(this.refreshStaticContentData);
    this.dataSource.filter = this.searchData.trim().toLocaleLowerCase();
  }

  /**
   * Method to refresh grid
   */
  public refreshStaticContentGrid(isRefreshData: boolean) {
    if (isRefreshData) {
      this.loadStaticContentData();
    }
  }

  /**
   * Method to edit static content
   */
  public editStaticContent(data: any) {
    this._router.navigateByUrl("/add-edit-static-content", {
      state: { editStaticContentData: data },
    });
  }

  /**
   * Method to get static content data
   */
  public loadStaticContentData() {
    if (this.sort.direction === "") this.sort.active = "";
    let staticContentParam = {
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      sortColumnName: this.sort.active,
      sortDirection: this.sort.direction === "asc" ? true : false,
    };
    this._superAdminService.getAllStaticContent(staticContentParam).subscribe({
      next: (data) => {
        if (
          data &&
          data !== null &&
          data.data &&
          data.data !== null &&
          data.data.length > 0
        ) {
          this.dataSource = new MatTableDataSource(data.data);
        } else {
          this.dataSource = new MatTableDataSource([]);
        }
        this.totalRows = data.totalRecords;
        this.paginator.matPag.length = this.totalRows;
        this.paginator.initPageRange();
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to delete static content
   */
  public deleteStaticContent(data: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "staticContent.deleteStaticContentConfirmText",
        confirmTitleText: "common.confirmDeleteText",
        buttonText: {
          ok: "common.yesText",
          cancel: "common.noText",
        },
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if (data.id !== null) {
          this._superAdminService.deleteStaticContent(data.id).subscribe({
            next: (data) => {
              if (data && data.success && data.success === true) {
                this._toastrService.showSuccess(
                  "staticContent.deleteStaticContentSuccessMessage"
                );
                this.loadStaticContentData();
              }
            },
            error: (error) => {
              this._commonService.showErrorMessage(error);
            },
          });
        }
      }
    });
  }

  /**
   * Method to filter data
   */
  public pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadStaticContentData();
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { ClinicService } from "src/app/modules/clinic/services/clinic.service";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { NotificationComponent } from "../notification/notification.component";

@Component({
  selector: "app-notification-list",
  templateUrl: "./notification-list.component.html",
  styleUrls: ["./notification-list.component.css"],
})
export class NotificationListComponent implements OnInit {
  filter: boolean = false;
  showFilterList: any = ["notification", "FromDateToDate", "Notification"];
  filterStartDateValue: any = "";
  filterEndDateValue: any = "";
  @ViewChild(NotificationComponent, { static: false })
  notification!: NotificationComponent;
  userRole: string = "";
  maxDate: any = null;

  constructor(
    private _clinicService: ClinicService,
    private _authService: AuthGuardService,
    private _toastrService: ToastrNotificationService
  ) {}

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
      }
    });
  }

  /**
   * Method to toggle filter section
   */
  public filterClick() {
    this.filter = !this.filter;
  }

  /**
   * Method to get filter data
   */
  public getFilterData(data: any) {
    this.filterStartDateValue = data.filterStartDateValue;
    this.filterEndDateValue = data.filterEndDateValue;
  }

  /**
   * Method to apply filter data
   */
  public applyFilterData() {
    let numberOfDays = Math.round(
      (this.filterEndDateValue - this.filterStartDateValue) /
        (1000 * 60 * 60 * 24)
    );
    if (numberOfDays < 30) {
      this.loadNotificationGrid(true);
    } else {
      this._toastrService.showError(
        "notification.dateRangeFilterErrorMessageForDuration"
      );
    }
  }

  /**
   * Method to load notification grid
   */
  public loadNotificationGrid(applyFilter: boolean = false) {
    this.notification.filterStartDate = this.filterStartDateValue;
    this.notification.filterEndDate = this.filterEndDateValue;
    this.notification.loadNotificationData(applyFilter);
  }

  /**
   * Method to reset max date
   */
  public resetMaxDate() {
    if (this.filterStartDateValue && this.filterStartDateValue !== "") {
      this.maxDate = null;
    }
  }

  /**
   * Method to set max date
   */
  public setMaxDate() {
    let tempdate = this.filterStartDateValue;
    tempdate.setDate(tempdate.getDate() + 30);
    this.maxDate = new Date(tempdate);
  }
}

<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/icon-static-content.svg" alt="" />
        </div>
        <h2>
          {{ addEditStaticContentText | translate }}
          {{ "staticContent.staticContentText" | translate }}
        </h2>
      </div>
    </div>

    <div class="content-page">
      <div class="drawer-content">
        <form
          [formGroup]="staticContentInfoValidation"
          (ngSubmit)="submitStaticContentInfo()"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-3">
                <mat-form-field>
                  <mat-label>{{
                    "staticContent.pageTitleText" | translate
                  }}</mat-label>
                  <mat-select
                    placeholder="{{
                      'staticContent.selectPageTitleText' | translate
                    }}"
                    formControlName="pageTitle"
                    [(ngModel)]="staticContentInfoModel.pageTitle"
                    required
                  >
                    <mat-option value="{{ staticPageTitleEnum.AboutUs }}"
                      >About Us</mat-option
                    >
                    <mat-option value="{{ staticPageTitleEnum.PrivacyPolicy }}"
                      >Privacy Policy</mat-option
                    >
                    <mat-option value="{{ staticPageTitleEnum.TermsOfUse }}"
                      >Terms Of Use</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="
                    staticContentInfoValidation.controls['pageTitle'].invalid &&
                    (staticContentInfoValidation.controls['pageTitle'].dirty ||
                      staticContentInfoValidation.controls['pageTitle']
                        .touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      staticContentInfoValidation
                        .get('pageTitle')
                        ?.hasError('required')
                    "
                    >{{
                      "staticContent.selectPageTitleErrorMessage" | translate
                    }}</mat-error
                  >
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-3">
                <mat-label
                  >{{ "staticContent.contentText" | translate
                  }}<span class="required">*</span></mat-label
                >
                <ckeditor
                  [editor]="Editor"
                  (ready)="onReady($event)"
                  [config]="{ placeholder: 'Enter Content' }"
                  formControlName="content"
                  placeholder="Enter Content"
                  [(ngModel)]="staticContentInfoModel.content"
                  required
                >
                </ckeditor>
                <div
                  *ngIf="
                    staticContentInfoValidation.controls['content'].invalid &&
                    (staticContentInfoValidation.controls['content'].dirty ||
                      staticContentInfoValidation.controls['content'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      staticContentInfoValidation
                        .get('content')
                        ?.hasError('required')
                    "
                    >{{
                      "staticContent.enterContentTextErrorMessage" | translate
                    }}</mat-error
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="drawer-action">
            <button type="submit" class="btn btn-secondary">
              <img src="assets/images/save-icon.svg" alt="" class="me-2" />
              {{ "addUser.saveButtonText" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-primary ms-3"
              (click)="closeStaticContentPopup()"
            >
              <img
                src="assets/images/white-close-icon.svg"
                alt=""
                class="me-2"
              />
              {{ "addUser.cancelButtonText" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

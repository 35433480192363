<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/clinic.svg" alt="" />
        </div>
        <h2>{{ "manageClinics.manageClinicsHeadingText" | translate }}</h2>
      </div>
      <div class="page-title-right">
        <div class="search">
          <div class="form-group">
            <mat-form-field class="example-form-field">
              <input
                matInput
                [(ngModel)]="searchText"
                type="text"
                placeholder="{{ 'userList.searchUserPlaceholder' | translate }}"
                (keyup)="searchDataByKeyword()"
              />
              <button matSuffix>
                <img src="assets/images/icon-search.svg" alt="" />
              </button>
            </mat-form-field>
          </div>
        </div>
        <button class="btn btn-primary ms-3" (click)="addClinic()">
          <img src="assets/images/plus-icon.svg" alt="" class="me-2" />
          {{ "manageClinics.addClinicButtonText" | translate }}
        </button>
        <button
          class="btn btn-primary ms-3"
          (click)="filterClick()"
          [ngClass]="filter ? 'active' : ''"
        >
          <img src="assets/images/filter-icon.svg" alt="" />
        </button>
      </div>
    </div>

    <app-filter
      (filterData)="getFilterData($event)"
      (applyFilterData)="applyFilterData()"
      [filter]="filter"
      [showFilterList]="showFilterList"
    ></app-filter>

    <div class="content-page">
      <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
        <mat-tab label="{{ 'manageClinics.clinicsTabName' | translate }}">
          <app-clinic-list
            *ngIf="tabIndex === 0"
            [refreshClinicData]="refreshClinicGrid"
            [searchData]="searchString"
            (viewClinicData)="viewClinicPopup($event)"
            (editClinicId)="editClinicPopup($event)"
            #clinicList
          ></app-clinic-list>
        </mat-tab>
        <mat-tab
          label="{{ 'manageClinics.clinicsRequestTabName' | translate }}"
        >
          <app-clinic-request-list
            *ngIf="tabIndex === 1"
            [refreshClinicRequestData]="refreshClinicRequestGrid"
            [searchData]="searchString"
            (viewClinicRequestData)="viewClinicRequestPopup($event)"
            (editClinicId)="editClinicPopup($event)"
            #clinicRequests
          ></app-clinic-request-list>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="drawer-backdrop" [ngClass]="rightDrawer ? 'open' : ''"></div>
    <div class="right-drawer" [ngClass]="rightDrawer ? 'open' : ''">
      <button class="btn btn-link" (click)="showHideClinicPopup(false)">
        <img src="assets/images/close-icon.svg" alt="" />
      </button>
      <app-add-edit-clinic
        *ngIf="addEditClinic"
        (refreshData)="loadClinicData($event)"
        (showClinicPopup)="showHideClinicPopup($event)"
        [editClinicId]="editClinicId"
        [tabName]="tabName"
      ></app-add-edit-clinic>
      <app-view-clinic
        *ngIf="viewClinic"
        [viewClinicData]="viewClinicData"
        (showViewClinicPopup)="showHideClinicPopup($event)"
        [tabName]="tabName"
      ></app-view-clinic>
      <app-view-edit-clinic-request
        *ngIf="viewEditClinicRequest"
        (refreshData)="loadClinicRequestData($event)"
        [viewClinicRequestData]="viewClinicRequestData"
        (showViewClinicRequestPopup)="showHideClinicRequestPopup($event)"
        [tabName]="tabName"
      ></app-view-edit-clinic-request>
    </div>
  </div>
</div>

<div class="notification-box">
  <!-- <div class="notification-filter">
    <ul>
      <li class="mb-2" (click)="selectEventType('All')">
        <button
          class="notification-filter-btn"
          [ngClass]="selectedEventType.includes('All') ? 'active' : ''"
        >
          All
        </button>
      </li>
      <li
        class="mb-2"
        (click)="selectEventType(event.EventType)"
        *ngFor="let event of eventTypeList"
      >
        <button
          class="notification-filter-btn"
          [ngClass]="
            selectedEventType.includes(event.EventType) ? 'active' : ''
          "
        >
          {{ event.EventName }}
        </button>
      </li>
    </ul>
  </div> -->
  <div class="notification-table">
    <div class="mat-elevation-z8 table-responsive">
      <table mat-table [dataSource]="dataSource" class="table">
        <ng-container matColumnDef="notification">
          <th mat-header-cell *matHeaderCellDef>
            {{ "notification.notificationText" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <p>
              <strong>{{
                "notification.events." + element.notificationDetails.eventType
                  | translate
              }}</strong>
            </p>
            <p>{{ prepareMessageByLanguage(element) }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="datetime">
          <th mat-header-cell *matHeaderCellDef>
            {{ "notification.dateTimeText" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <p
              *ngIf="
                (element.notificationDetails.createdOn
                  | date : 'MM/dd/yyyy') === (todayDate | date : 'MM/dd/yyyy')
              "
            >
              Today,
              {{ element.notificationDetails.createdOn | date : timeFormat }}
              {{ currentTimeZoneName }}
            </p>
            <p
              *ngIf="
                (element.notificationDetails.createdOn
                  | date : 'MM/dd/yyyy') !== (todayDate | date : 'MM/dd/yyyy')
              "
            >
              {{
                element.notificationDetails.createdOn
                  | date : "MMM dd, yyyy " + timeFormat
              }}
              {{ currentTimeZoneName }}
            </p>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            {{ "userList.noResultFoundText" | translate }}
          </td>
        </tr>
      </table>
    </div>
    <mat-paginator
      #paginator
      style-paginator
      showFirstLastButtons
      itemsPerPageLabel="Page Size"
      [length]="totalRows"
      [pageIndex]="currentPage"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="pageChanged($event)"
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</div>

import { Component, OnInit } from "@angular/core";
import { StaticPageTitleEnum } from "src/app/enums/staticPageTitleEnum";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.css"],
})
export class PrivacyPolicyComponent implements OnInit {
  privacyPolicyContent: any;

  constructor(private _commonService: CommonService) {}

  ngOnInit() {
    this.getStaticContentData();
  }

  public async getStaticContentData() {
    this._commonService
      .getStaticContentDataByPageTitleId(StaticPageTitleEnum.PrivacyPolicy)
      .subscribe({
        next: (result) => {
          if (result && result !== null) {
            this.privacyPolicyContent = result.content;
          }
        },
      });
  }
}

<div class="drawer-body">
  <div class="btn-group my-3">
    <!-- <button
      class="btn btn-primary active"
      (click)="closeViewAppointmentPopup()"
    >
      <img src="assets/images/white-back-icon.svg" alt="" class="me-2" />
      {{ "viewUser.backButtonText" | translate }}
    </button> -->
    <button
      class="btn btn-primary"
      (click)="missedAppointment(viewAppointmentData)"
      *ngIf="
        viewAppointmentData.requestFromDashboard &&
        viewAppointmentData.appointmentStatus !==
          appointmentStatusEnum.Completed &&
        viewAppointmentData.appointmentStatus !==
          appointmentStatusEnum.Missed &&
        _commonService.isAppointmentPastDate(
          viewAppointmentData.appointmentDateEndTime
        )
      "
    >
      {{ "common.missedText" | translate }}
    </button>
    <button
      class="btn btn-primary"
      (click)="completeAppointment(viewAppointmentData)"
      *ngIf="
        viewAppointmentData.requestFromDashboard &&
        viewAppointmentData.appointmentStatus !==
          appointmentStatusEnum.Completed &&
        viewAppointmentData.appointmentStatus !==
          appointmentStatusEnum.Missed &&
        _commonService.isAppointmentPastDate(
          viewAppointmentData.appointmentDateEndTime
        )
      "
    >
      {{ "common.completeText" | translate }}
    </button>
  </div>
  <div class="drawer-title">
    <!-- <img src="assets/images/view-icon.svg" alt="" />
    {{ "appointment.appointmentDetailsText" | translate }} -->
    <div class="view-appoinment-title">
      <div>
        <img src="assets/images/blue-user-icon.png" alt="" />
        <h4>{{ "common.personalInfoText" | translate }}</h4>
      </div>
      <button
        (click)="editPatientInfo()"
        *ngIf="userRole !== userTypeEnum.Provider.toString()"
      >
        <img src="assets/images/view-edit-icon.svg" alt="" />
      </button>
    </div>
  </div>
  <div class="drawer-content">
    <div class="view-appointment-top">
      <div class="view-appointment-img">
        <img src="{{ viewAppointmentData.patientProfilePhoto }}" alt="" />
      </div>
      <div class="view-appointment-content">
        <p>{{ viewAppointmentData.patientName }}</p>
        <p>{{ viewAppointmentData.patientDOB | date : "MMM dd, yyyy" }}</p>
        <p>{{ viewAppointmentData.patientGenderValue }}</p>
      </div>
    </div>
    <div
      class="appointment-more-content"
      *ngIf="viewAppointmentData?.patientParsonalInformation"
    >
      <p>{{ viewAppointmentData.patientContact }}</p>
      <p>{{ viewAppointmentData.patientEmail }}</p>
      <p>
        {{ viewAppointmentData?.patientParsonalInformation?.timeZone }}
        {{ viewAppointmentData?.patientParsonalInformation?.timeZoneShortName }}
      </p>
      <p>
        <span *ngIf="viewAppointmentData?.patientParsonalInformation?.address"
          >{{ viewAppointmentData?.patientParsonalInformation?.address }},</span
        >
        <span *ngIf="viewAppointmentData?.patientParsonalInformation?.city"
          >{{ viewAppointmentData?.patientParsonalInformation?.city }},</span
        >
        <span *ngIf="viewAppointmentData?.patientParsonalInformation?.state"
          >{{ viewAppointmentData?.patientParsonalInformation?.state }},</span
        >
        <span *ngIf="viewAppointmentData?.patientParsonalInformation?.country"
          >{{
            viewAppointmentData?.patientParsonalInformation?.country
          }}
          -</span
        >
        <span
          *ngIf="viewAppointmentData?.patientParsonalInformation?.zipCode"
          >{{ viewAppointmentData?.patientParsonalInformation?.zipCode }}</span
        >
      </p>
    </div>
    <div class="appointment-doctor-details">
      <div class="appointment-top">
        <div class="appointment-top-date">
          <span class="days">{{
            viewAppointmentData.appointmentDateTime | date : "EEEE"
          }}</span>
          <span class="months">{{
            viewAppointmentData.appointmentDateTime | date : "MMM"
          }}</span>
          <span class="dates">{{
            viewAppointmentData.appointmentDateTime | date : "dd"
          }}</span>
        </div>
        <div class="appointment-top-right">
          <p class="appointment-type">{{ viewAppointmentData.visitType }}</p>
          <p>{{ viewAppointmentData.medicalProviderName }}</p>
          <p>
            {{ viewAppointmentData.appointmentDateTime | date : timeFormat }} -
            {{ viewAppointmentData.appointmentDateEndTime | date : timeFormat }}
            {{ currentTimeZoneName }}
          </p>
          <p>{{ viewAppointmentData.insurance }}</p>
        </div>
        <button
          *ngIf="userRole === userTypeEnum.Provider.toString()"
          class="btn btn-secondary pay-btn"
          (click)="joinVideoCallForAppointment()"
          [disabled]="
            !(
              userRole === userTypeEnum.Provider.toString() &&
              (viewAppointmentData.appointmentStatus ===
                appointmentStatusEnum.Pending ||
                viewAppointmentData.appointmentStatus ===
                  appointmentStatusEnum.Reschedule)
            )
          "
        >
          Join
        </button>
      </div>
      <div class="appointment-payment">
        <div class="appointment-payment-list">
          <div class="text-lable">
            {{ "appointment.appointmentStatusText" | translate }}
          </div>
          <div class="text-value">
            {{
              "common." +
                _globalFunction.getAppointmentStatus(
                  viewAppointmentData.appointmentStatus
                ) | translate
            }}
          </div>
        </div>
        <div class="appointment-payment-list">
          <div class="text-lable">
            {{ "appointment.durationPriceText" | translate }}
          </div>
          <div class="text-value">
            {{ viewAppointmentData.duration }} min(s), ${{
              viewAppointmentData.price
            }}
          </div>
        </div>
        <div class="appointment-payment-list">
          <div class="text-lable">
            {{ "appointment.discountText" | translate }}
          </div>
          <div class="text-value">{{ viewAppointmentData.discount }}%</div>
        </div>
        <div class="appointment-payment-list">
          <div class="text-lable">
            {{ "common.finalPriceText" | translate }}
          </div>
          <div class="text-value">${{ viewAppointmentData.finalPrice }}</div>
        </div>
      </div>
      <div class="appointment-notes">
        <p>{{ "appointment.appointmentNotesText" | translate }}</p>
        <div class="form-group">
          <textarea class="form-control" disabled>{{
            viewAppointmentData.notes
          }}</textarea>
        </div>
      </div>
    </div>

    <div class="appointment-more-details">
      <div class="view-appoinment-title">
        <div>
          <img src="assets/images/blue-user-icon.png" alt="" />
          <h4>{{ "appointment.patientNotesText" | translate }}</h4>
        </div>
      </div>
      <div class="view-appointment-notes">
        <textarea class="form-control" disabled>{{
          viewAppointmentData.consultationReason
        }}</textarea>
      </div>
    </div>

    <div class="appointment-more-details">
      <div class="view-appoinment-title">
        <div>
          <img src="assets/images/heart-icon.png" alt="" />
          <h4>{{ "appointment.insuranceInformationText" | translate }}</h4>
        </div>
      </div>
      <div
        class="details-box-content"
        *ngIf="viewAppointmentData.insuranceInformation"
      >
        <p>{{ viewAppointmentData.insuranceInformation.insuranceCompany }}</p>
        <p>{{ viewAppointmentData.insuranceInformation.subscriberMemberId }}</p>
        <p>{{ viewAppointmentData.insuranceInformation.insuranceType }}</p>

        <div class="thumbnail-container">
          <img
            *ngIf="patientInsuranceFrontSide"
            class="thumbnail"
            [src]="patientInsuranceFrontSide"
            alt="Thumbnail 1"
            (click)="openImagePopup(patientInsuranceFrontSide)"
          />
          <img
            *ngIf="patientInsuranceBackSide"
            class="thumbnail"
            [src]="patientInsuranceBackSide"
            alt="Thumbnail 2"
            (click)="openImagePopup(patientInsuranceBackSide)"
          />
          <!-- Add more thumbnail images as needed -->
        </div>

        <!-- Image Popup -->
        <div *ngIf="isImagePopupOpen" class="image-popup">
          <div class="image-popup-content">
            <span class="close-icon" (click)="closeImagePopup()">&times;</span>
            <img [src]="selectedImageSrc" alt="Full Image" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>

    <div class="appointment-more-details">
      <div class="view-appoinment-title">
        <div>
          <img src="assets/images/home-plus-icon.png" alt="" />
          <h4>{{ "appointment.pharmacyInformationText" | translate }}</h4>
        </div>
      </div>
      <div
        class="details-box-content"
        *ngIf="viewAppointmentData.pharmacyInformation"
      >
        <h6>{{ viewAppointmentData.pharmacyInformation.pharmacyName }}</h6>
        <p *ngIf="viewAppointmentData.pharmacyInformation.pharmacyPhoneNo">
          {{
            viewAppointmentData.pharmacyInformation.pharmacyPhoneCode +
              " - " +
              viewAppointmentData.pharmacyInformation.pharmacyPhoneNo
          }}
        </p>
        <div
          class="copy-content"
          *ngIf="viewAppointmentData.pharmacyInformation.pharmacyAddress"
        >
          {{ viewAppointmentData.pharmacyInformation.pharmacyAddress }}
          <button
            (click)="
              copyText(viewAppointmentData.pharmacyInformation.pharmacyAddress)
            "
            class="copy-icons"
          >
            <img [src]="copyButtonImg" alt="" />
          </button>
        </div>
      </div>
    </div>

    <div class="appointment-more-details">
      <div class="view-appoinment-title">
        <div>
          <img src="assets/images/emergency-icon.png" alt="" />
          <h4>{{ "appointment.emergencyContactText" | translate }}</h4>
        </div>
      </div>
      <div
        class="details-box-content"
        *ngIf="viewAppointmentData.emergencyInformation"
      >
        <h6>
          {{ viewAppointmentData.emergencyInformation.emergencyContactInfo }}
        </h6>
        <p>
          {{
            viewAppointmentData.emergencyInformation
              .emergencyContactRelationship
          }}
        </p>
        <p *ngIf="viewAppointmentData.emergencyInformation.emergencyPhoneNo">
          {{
            viewAppointmentData.emergencyInformation.emergencyPhoneCode +
              " - " +
              viewAppointmentData.emergencyInformation.emergencyPhoneNo
          }}
        </p>
      </div>
    </div>

    <div class="appointment-more-details">
      <div class="view-appoinment-title">
        <div>
          <img src="assets/images/health-information.png" alt="" />
          <h4>{{ "appointment.healthInformtationText" | translate }}</h4>
        </div>
      </div>
      <div
        class="details-box-content"
        *ngIf="viewAppointmentData.healthInformation"
      >
        <p>
          <strong>{{ "appointment.allergiesText" | translate }}</strong>
        </p>
        <p>
          {{
            viewAppointmentData.healthInformation.medicationsAllergies == ""
              ? ""
              : viewAppointmentData.healthInformation.medicationsAllergies
          }}
        </p>
        <p>&nbsp;</p>

        <p>
          <strong>{{ "appointment.medicationsText" | translate }} </strong>
        </p>
        <div class="d-flex flex-wrap">
          <p *ngFor="let item of medicationList; let i = index" class="pe-2">
            {{ item }}{{ i === medicationList.length - 1 ? "" : ",&nbsp;" }}
          </p>
        </div>
        <p>&nbsp;</p>

        <p>
          <strong
            >{{ "appointment.medicalConditionsText" | translate }}
          </strong>
        </p>
        <p>
          {{ viewAppointmentData.healthInformation.existingMedicalConditions }}
        </p>
      </div>
    </div>

    <!-- <ul class="view-user">
      <li>
        <div class="appointment-photo">
          <img src="{{ viewAppointmentData.patientProfilePhoto }}" alt="" />
        </div>
      </li>
      <li>
        <span>{{ "appointment.patientNameText" | translate }}</span>
        <p>{{ viewAppointmentData.patientName }}</p>
      </li>
      <li>
        <span>{{ "appointment.patientEmail" | translate }}</span>
        <p>{{ viewAppointmentData.patientEmail }}</p>
      </li>
      <li>
        <span>{{ "appointment.patientDOB" | translate }}</span>
        <p>{{ viewAppointmentData.patientDOB | date : "MM/dd/YYYY" }}</p>
      </li>
      <li>
        <span>{{ "userList.contactLabelText" | translate }}</span>
        <p *ngIf="viewAppointmentData.contact !== ''">
          {{ viewAppointmentData.contact }}
        </p>
        <p *ngIf="viewAppointmentData.patientContact !== ''">
          {{ viewAppointmentData.patientContact }}
        </p>
      </li>
      <li>
        <span>{{ "appointment.medicalProviderNameText" | translate }}</span>
        <p>{{ viewAppointmentData.medicalProviderName }}</p>
      </li>
      <li>
        <span>{{ "appointment.appointmentDateTimeText" | translate }}</span>
        <p>
          {{
            viewAppointmentData.appointmentDateTime | date : "MM/dd/yyyy HH:mm"
          }}
        </p>
      </li>
      <li>
        <span>{{ "common.visitTypeText" | translate }}</span>
        <p>{{ viewAppointmentData.visitType }}</p>
      </li>
      <li>
        <span>{{ "appointment.insuranceText" | translate }}</span>
        <p>{{ viewAppointmentData.insurance }}</p>
      </li>
      <li>
        <div class="row">
          <div class="col-4">
            <span>{{ "appointment.durationPriceText" | translate }}</span>
            <p>
              {{ viewAppointmentData.duration }} min(s), ${{
                viewAppointmentData.price
              }}
            </p>
          </div>
          <div class="col-4">
            <span>{{ "common.finalPriceText" | translate }}</span>
            <p>${{ viewAppointmentData.finalPrice }}</p>
          </div>
          <div class="col-4">
            <span>{{ "appointment.discountText" | translate }}</span>
            <p>{{ viewAppointmentData.discount }}%</p>
          </div>
        </div>
      </li>
      <li>
        <span>{{ "appointment.appointmentStatusText" | translate }}</span>
        <p>
          {{
            _globalFunction.getAppointmentStatus(
              viewAppointmentData.appointmentStatus
            )
          }}
        </p>
      </li>
    </ul> -->
    <!-- <div class="drawer-action mt-4 pt-2">
      <button class="btn btn-secondary" (click)="closeViewAppointmentPopup()">
        <img src="assets/images/white-back-icon.svg" alt="" class="me-2" />
        {{ "viewUser.backButtonText" | translate }}
      </button>
      <button
        class="btn btn-secondary ml-3"
        (click)="missedAppointment(viewAppointmentData)"
        *ngIf="
          viewAppointmentData.requestFromDashboard &&
          viewAppointmentData.appointmentStatus !==
            appointmentStatusEnum.Completed &&
          viewAppointmentData.appointmentStatus !==
            appointmentStatusEnum.Missed &&
          _commonService.isAppointmentPastDate(
            viewAppointmentData.appointmentDateEndTime
          )
        "
      >
        {{ "common.missedText" | translate }}
      </button>
      <button
        class="btn btn-secondary ml-3"
        (click)="completeAppointment(viewAppointmentData)"
        *ngIf="
          viewAppointmentData.requestFromDashboard &&
          viewAppointmentData.appointmentStatus !==
            appointmentStatusEnum.Completed &&
          viewAppointmentData.appointmentStatus !==
            appointmentStatusEnum.Missed &&
          _commonService.isAppointmentPastDate(
            viewAppointmentData.appointmentDateEndTime
          )
        "
      >
        {{ "common.completeText" | translate }}
      </button>
      <button
        class="btn btn-secondary ml-3"
        (click)="joinVideoCallForAppointment()"
        *ngIf="
          userRole === userTypeEnum.Provider.toString() &&
          (viewAppointmentData.appointmentStatus ===
            appointmentStatusEnum.Pending ||
            viewAppointmentData.appointmentStatus ===
              appointmentStatusEnum.Reschedule) &&
          _globalFunction.joinAppointmentByAppointmentDate(
            viewAppointmentData.appointmentDateTime,
            viewAppointmentData.startTime,
            viewAppointmentData.endTime
          )
        "
      >
        <img src="assets/images/white-right-sign.svg" alt="" class="me-2" />
        Join
      </button>
    </div> -->
  </div>
</div>

import { DatePipe } from "@angular/common";
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectionStrategy,
} from "@angular/core";
import * as moment from "moment-timezone";
import { GlobalFunctions } from "src/app/app.global";
import { HoverMenuDirective } from "src/app/directives/hover-menu.directive";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { CommonService } from "src/app/services/common.service";
@Component({
  selector: "app-clinic-dashboard-month-view",
  templateUrl: "./clinic-dashboard-month-view.component.html",
  styleUrls: ["./clinic-dashboard-month-view.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicDashboardMonthViewComponent implements OnInit, OnChanges {
  @Input() viewType: string = "";
  @Input() dashboardDataList: any = [];
  @Input() filteredDashboardDataList: any = [];
  @Input() selectedProviderValue: string = "";
  @Input() selectedDate: any;
  @Input() refreshDataView: boolean = false;
  @Input() searchData: string = "";
  @Output() viewAppointmentData = new EventEmitter<any>();
  @Output() addAppointmentBySlot = new EventEmitter<any>();
  @Output() pasteAppointmentData = new EventEmitter<any>();
  cutPasteAppointentData: any = null;
  cutAppointentData: any = null;
  weekDays: any = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  monthWeek1: any = [];
  monthWeek2: any = [];
  monthWeek3: any = [];
  monthWeek4: any = [];
  monthWeek5: any = [];
  monthWeek6: any = [];
  monthWeek7: any = [];
  @ViewChild(HoverMenuDirective) hoverMenu!: HoverMenuDirective;
  currentTimeZoneName: any;
  timeFormat: any;
  timeFormatCaps: any;
  constructor(
    public _commonService: CommonService,
    public _globalFunction: GlobalFunctions,
    private _toastrService: ToastrNotificationService,
    private datePipe: DatePipe
  ) {
    this.timeFormat = this._commonService.getTimeFormat();
    this.timeFormatCaps = this._commonService.getTimeFormat(true);
    this.currentTimeZoneName = this._commonService.getTimeZoneShortName();
  }

  ngOnInit(): void {
    if (this.viewType === "month") {
      this.setMonthDataView();
    }
  }

  ngOnChanges(): void {
    if (this.refreshDataView) {
      this.filteredDashboardDataList = this.dashboardDataList;
      this.setMonthDataView();
    }
    if (this.searchData) {
      this.filteredDashboardDataList = this.filteredDashboardDataList.filter(
        (x: any) =>
          x.patientName !== null &&
          x.patientName.toLowerCase().includes(this.searchData.toLowerCase())
      );
      this.setMonthDataView();
    }
  }

  /**
   * Method to get dashboard data by provider and date
   */
  // public getDashboardDataByProviderAndDate(date: any) {
  //   let dashboardData = [];
  //   if (
  //     this.filteredDashboardDataList &&
  //     this.filteredDashboardDataList.length > 0
  //   ) {
  //     dashboardData = this.filteredDashboardDataList.filter(
  //       (x: any) =>
  //         this.datePipe.transform(x.appointmentDateTime, "MM/dd/yyyy") ===
  //         this.datePipe.transform(date, "MM/dd/yyyy")
  //     );
  //   }
  //   return dashboardData;
  // }

  /**
   * Method to set month data view
   */
  public setMonthDataView() {
    this.filteredDashboardDataList = this.filteredDashboardDataList.filter(
      (x: any) => x.providerUserId === this.selectedProviderValue
    );

    this.dashboardDataList.forEach((element: any) => {
      // let startTime = moment(element.appointmentDateTime).format("HH:mm");
      // let utcStartTime: any = moment(
      //   this.datePipe.transform(new Date(), "MM/dd/yyyy") + " " + startTime
      // ).tz(moment.tz.guess());
      // element.formattedStartTime = moment(utcStartTime._d).format("HH:mm");
      // element.appointmentLocalStartDateTime = utcStartTime._d;
      element.formattedStartTime = moment(element.appointmentDateTime).format(
        "HH:mm"
      );
      element.appointmentLocalStartDateTime = element.appointmentDateTime;

      // let endTime = moment(element.appointmentDateEndTime).format("HH:mm");
      // let utcEndTime: any = moment(
      //   this.datePipe.transform(new Date(), "MM/dd/yyyy") + " " + endTime
      // ).tz(moment.tz.guess());
      // element.formattedEndTime = moment(utcEndTime._d).format("HH:mm");
      element.formattedEndTime = moment(element.appointmentDateEndTime).format(
        "HH:mm"
      );

      element.appointmentDateTimeString = this.datePipe.transform(
        element.appointmentDateTime,
        "MM/dd/yyyy"
      );
      element.patientNameInitials = element.patientName
        ? this._globalFunction.getNameInitials(
            element.patientFirstName,
            element.patientLastName
          )
        : "";

      this._globalFunction.setPatientProfilePhoto(element);

      element.isAppointmentPastDate = this._commonService.isAppointmentPastDate(
        element.appointmentDateTime
      );
    });

    //sort data by slots time
    this.filteredDashboardDataList.sort((a: any, b: any) =>
      a.formattedStartTime > b.formattedStartTime
        ? 1
        : b.formattedStartTime > a.formattedStartTime
        ? -1
        : 0
    );

    //Set time format
    this.filteredDashboardDataList.forEach((element: any) => {
      element.formattedStartTime = moment(element.appointmentDateTime).format(
        this.timeFormatCaps
      );
      element.formattedEndTime = moment(element.appointmentDateEndTime).format(
        this.timeFormatCaps
      );
    });

    this.prepareSelectedDateMonthArray();
  }

  /**
   * Method to get selected date month array
   */
  public prepareSelectedDateMonthArray() {
    this.clearMonthWeekArray();
    let monthStartDate = new Date(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth(),
      1
    );
    let monthEndDate = new Date(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth() + 1,
      0
    );

    let numberOfDaysInMonth = monthEndDate.getDate();
    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      let currentDay = new Date(monthStartDate);
      let weekNumber = this.datePipe.transform(currentDay, "W");
      if (weekNumber === "1")
        this.addSlotsByCurrentDayInWeekArray(currentDay, this.monthWeek1);
      if (weekNumber === "2")
        this.addSlotsByCurrentDayInWeekArray(currentDay, this.monthWeek2);
      if (weekNumber === "3")
        this.addSlotsByCurrentDayInWeekArray(currentDay, this.monthWeek3);
      if (weekNumber === "4")
        this.addSlotsByCurrentDayInWeekArray(currentDay, this.monthWeek4);
      if (weekNumber === "5")
        this.addSlotsByCurrentDayInWeekArray(currentDay, this.monthWeek5);
      if (weekNumber === "6")
        this.addSlotsByCurrentDayInWeekArray(currentDay, this.monthWeek6);

      monthStartDate.setDate(monthStartDate.getDate() + 1);
    }
  }

  /**
   * Method to clear month week array
   */
  public clearMonthWeekArray() {
    this.monthWeek1 = [];
    this.monthWeek2 = [];
    this.monthWeek3 = [];
    this.monthWeek4 = [];
    this.monthWeek5 = [];
    this.monthWeek6 = [];
    this.monthWeek7 = [];
  }

  /**
   * Method to add slots by current day in weeks array
   */
  public addSlotsByCurrentDayInWeekArray(currentDay: any, weekArray: any) {
    let currentDayString = this.datePipe.transform(currentDay, "MM/dd/yyyy");
    let dashboardSlots = [];
    if (
      this.filteredDashboardDataList &&
      this.filteredDashboardDataList.length > 0
    ) {
      dashboardSlots = this.filteredDashboardDataList.filter(
        (x: any) => x.appointmentDateTimeString === currentDayString
      );
    }

    weekArray.push({ day: currentDay, slots: dashboardSlots });
  }

  /**
   * Method to view appointment
   */
  public viewAppointment(data: any) {
    if (data && data.appointmentId && data.appointmentId !== null) {
      this.viewAppointmentData.emit(data);
    }
  }

  /**
   * Method to add appointment
   */
  public addAppointment(data: any) {
    if (
      data &&
      !data.appointmentId &&
      !data.isBlocked &&
      !this._commonService.isAppointmentPastDate(data.appointmentDateTime)
    ) {
      this.addAppointmentBySlot.emit(data);
    }
  }

  /**
   * Method to cut the appointment
   */
  public cutAppointment(data: any) {
    this.cutAppointentData = data;
    this._toastrService.showInfo("common.cutAppointmentMessage");
  }

  /**
   * Method to paste the appointment to other time slot
   */
  public pasteAppointment(data: any) {
    this.cutAppointentData.pasteSlotData = data;
    this.pasteAppointmentData.emit(this.cutAppointentData);
    this.cutPasteAppointentData = null;
    this.cutAppointentData = null;
  }

  /**
   * Method to clear the cut appointment data
   */
  public clearCutAppointment() {
    this.cutPasteAppointentData = null;
    this.cutAppointentData = null;
  }

  /**
   * Method to drag start on appointment
   */
  public onDragStart(event: DragEvent, appointmentData: any) {
    this.hoverMenu.clearTimer();
    this.cutAppointentData = appointmentData;
  }

  /**
   * Method to drag over on appointment
   */
  public onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  /**
   * Method to drop the dragged appointment data on empty slot
   */
  public onDrop(event: DragEvent, dropAppointmentSlot: any) {
    this.cutAppointentData.pasteSlotData = dropAppointmentSlot;
    this.pasteAppointmentData.emit(this.cutAppointentData);
    this.cutPasteAppointentData = null;
    this.cutAppointentData = null;
  }
}

import { Component, ElementRef, OnInit, Renderer2, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { GlobalFunctions } from "src/app/app.global";
import { CountryModel } from "src/app/models/countryModel";
import { SpecialityModel } from "src/app/models/specialityModel";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";
import { CustomPageTitleStrategy } from "src/app/services/custom-page-title-strategy";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { ProviderProfileModel } from "../../models/providerProfileModel";
import { ClinicService } from "../../services/clinic.service";

@Component({
  selector: "app-provider-profile",
  templateUrl: "./provider-profile.component.html",
  styleUrls: ["./provider-profile.component.css"],
})
export class ProviderProfileComponent implements OnInit {
  providerProfileModel = new ProviderProfileModel();
  providerProfileInfoValidation: FormGroup = new FormGroup("");
  formSubmitted: boolean = false;
  phoneCodeList: CountryModel[] = [];
  specialityList: SpecialityModel[] = [];
  languageList: any = [];
  visitTypeList: any = [];
  todayDate: Date = new Date();
  timezoneList: any = [];
  filteredTimezoneList: any = [];
  @ViewChild("imageFileInput") profileImageElement: any;
  @ViewChild("searchTimezoneInput") searchTimezoneInput!: ElementRef;
  
  constructor(
    private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _clinicService: ClinicService,
    private _toastrService: ToastrNotificationService,
    private _customPageTitleStrategy: CustomPageTitleStrategy,
    private _authService: AuthGuardService,
    public _globalFunction: GlobalFunctions,
    private _renderer: Renderer2
  ) {
    this.todayDate = this._commonService.getCurrentTimeZoneNewDate();
    this.createProviderProfileForm();
    this.getPhoneCode();
    this.getSpeciality();
    this.getLanguages();
    this.getVisitTypes();
    this.getProviderProfile();
    this.getTimezone();
    this._renderer.listen("window", "click", (e: Event) => {
      this.filteredTimezoneList = this.timezoneList;
      if (this.searchTimezoneInput)
        this.searchTimezoneInput.nativeElement.value = "";
    });
  }

  ngOnInit(): void {
  }

  /**
   * Method to get phone code data
   */
  public getPhoneCode() {
    this._commonService.getCountry().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.phoneCodeList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to create a provider profile form
   */
  public createProviderProfileForm() {
    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    this.providerProfileInfoValidation = this._formBuilder.group({
      profilePhoto: ["", []],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(emailPattern),
          this._globalFunction.removeSpaces.bind(this),
        ],
      ],
      phoneCode: ["", [Validators.required]],
      phoneNumber: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(10),
          Validators.pattern("^[0-9]*$"),
        ]),
      ],
      dob: ["", []],
      speciality: ["", [Validators.required]],
      visitTypes: [[], [Validators.required]],
      languages: [[], [Validators.required]],
      timezone: ["", [Validators.required]],
      language: ["", [Validators.required]],
      timeFormat: ["", [Validators.required]],
    });
  }

  /**
   * Method to get speciality data
   */
  public getSpeciality() {
    this._commonService.getSpeciality().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.specialityList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get language data
   */
  public getLanguages() {
    this._commonService.getLanguages().subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.languageList = data;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get visit types data
   */
  public getVisitTypes() {
    this._clinicService.getClinicSettings().subscribe({
      next: (data) => {
        if (data && data.clinicVisitTypes && data.clinicVisitTypes !== null) {
          this.visitTypeList = data.clinicVisitTypes;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to submit provider profile information
   */
  public submitProviderProfile() {
    this.formSubmitted = true;
    if (this.providerProfileInfoValidation.valid) {
      this._clinicService
        .updateProviderProfile(this.providerProfileModel)
        .subscribe({
          next: (data) => {
            if (data && data.success && data.success === true) {
              this._toastrService.showSuccess(
                "providerProfile.providerProfileSuccessMessage"
              );

              let currentToken = this._authService.getTokenDetails();
              this._commonService
                .refreshToken(
                  currentToken.access_token,
                  currentToken.refresh_token
                )
                .subscribe((data) => {
                  this._commonService.setCurrentLanguage();
                });
            }
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }

  /**
   * Method to upload profile image
   */
  public uploadProfileImage(event: any) {
    if (event.target.files) {
      const valid_file_ext = ["jpg", "jpeg", "png"];
      var files = event.target.files;
      if (files.length > 0) {
        const fileSize = event.target.files[0].size / 1048576;
        const fileName = event.target.files[0].name;
        const fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
        var file = files[0];
        if (fileSize > 5) {
          return this._toastrService.showError("common.documentSizeError");
        }
        if (valid_file_ext.indexOf(fileNameExt.toLowerCase()) < 0) {
          event.currentTarget.value = "";
          return this._toastrService.showError("common.documentTypeError");
        }
        if (file) {
          var reader = new FileReader();
          reader.onload = this.convertFileToByte.bind(this, fileName);
          reader.readAsBinaryString(file);
        }
      }
    }
  }

  /**
   * Method to convert file to byte
   */
  public convertFileToByte(fileName: string, readerEvt: any) {
    var binaryString = readerEvt.target.result;
    let base64textString = btoa(binaryString);
    this.providerProfileModel.providerPhoto = {
      DocumentId: 0,
      fileName: fileName,
      fileBytes: base64textString,
    };
  }

  /**
   * Method to get provider profile data
   */
  public getProviderProfile() {
    this._clinicService.getProviderProfile().subscribe({
      next: (data) => {
        if (data && data !== null) {
          if (data.profilePhoto && data.profilePhoto.fileBytes !== null)
            this.providerProfileModel.providerPhoto = data.profilePhoto;
          this.providerProfileModel.firstName = data.firstName;
          this.providerProfileModel.lastName = data.lastName;
          this.providerProfileModel.email = data.email;
          this.providerProfileModel.phoneCode = data.phoneCode;
          this.providerProfileModel.phoneNumber = data.phoneNo;
          this.providerProfileModel.dob = data.dob;
          this.providerProfileModel.speciality = data.specialtyId;
          this.providerProfileModel.language = data.appLanguage > 0 ? data.appLanguage : 1;
          if (!this.providerProfileModel.timezone)
            this.providerProfileModel.timezone = data.timeZoneId;
          this.providerProfileModel.timeFormat = data.timeFormat;
          var langArray = data.languages.split(",").map(function (value: any) {
            return Number(value);
          });
          var visitTypeArray = data.visitTypes
            .split(",")
            .map(function (value: any) {
              return Number(value);
            });
          this.providerProfileModel.languages = langArray;
          this.providerProfileModel.visitTypes = visitTypeArray;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get all timezone
   */
  public getTimezone() {
    this._commonService.getTimezone().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.timezoneList = data;
          this.filteredTimezoneList = this.timezoneList;
          // this.timezoneList.sort((a: any, b: any) =>
          //   a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          // );

          //Set selected timezone in timezone dropdown
          let currentTimezone = this._commonService.setCurrentTimezone(
            this.timezoneList
          );
          this.providerProfileModel.timezone = currentTimezone;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to change time format
   */
  public changeTimeFormat(event: MatSlideToggleChange) {
    this.providerProfileModel.timeFormat = event.checked;
  }

  /**
   * Method to search timezone
   */
   public searchTimeZone(event: any) {
    const val = event.target.value.toLowerCase();
    const tempTimezoneList = this.timezoneList.filter((x: any) => {
      if (
        x.displayName.toLowerCase().indexOf(val) !== -1 ||
        !val
      ) {
        return x;
      }
    });
    this.filteredTimezoneList = tempTimezoneList;
  }
}

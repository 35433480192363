<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/notification.svg" alt="" />
        </div>
        <h2>{{ "settings.clinicSettingsHeadingText" | translate }}</h2>
      </div>
    </div>
    <div class="content-page">
      <div class="settings-page form-page">
        <div class="setting-accordion pb-3">
          <mat-accordion [multi]="true">
            <mat-expansion-panel [expanded]="isAllExpanded">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "leftmenu.Appointments" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-class-body">
                <div class="appointments-form">
                  <div class="row">
                    <div class="col-md-12 col-lg-6">
                      <div class="form-group float-label pe-3">
                        <mat-label
                          >{{
                            "settings.advanceAppointmentBookingLabelText"
                              | translate
                          }}
                          <span class="required">*</span>
                        </mat-label>
                        <mat-form-field class="max-width-120 me-3">
                          <input
                            matInput
                            type="text"
                            min="0"
                            maxlength="4"
                            [(ngModel)]="advanceAppointmentBooking"
                            (keypress)="
                              _globalFunction.allowNumbersOnly($event)
                            "
                          />
                        </mat-form-field>
                        <span>{{ "common.daysText" | translate }}</span>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-6">
                      <div class="form-group float-label pe-3">
                        <mat-label
                          >{{
                            "settings.appointmentRescheduleBeforeLabelText"
                              | translate
                          }}
                          <span class="required">*</span>
                        </mat-label>
                        <mat-form-field class="max-width-120 me-3">
                          <input
                            matInput
                            min="0"
                            [(ngModel)]="appointmentRescheduleBefore"
                            (keypress)="
                              _globalFunction.allowNumbersWithDotOnly($event)
                            "
                            maxlength="3"
                          />
                        </mat-form-field>
                        <span>{{ "common.hrsText" | translate }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-lg-6">
                      <div class="form-group float-label pe-3">
                        <mat-label
                          >{{
                            "settings.appointmentCancellationBeforeLabelText"
                              | translate
                          }}
                          <span class="required">*</span>
                        </mat-label>
                        <mat-form-field class="max-width-120 me-3">
                          <input
                            matInput
                            min="0"
                            [(ngModel)]="appointmentCancellationBefore"
                            (keypress)="
                              _globalFunction.allowNumbersWithDotOnly($event)
                            "
                            maxlength="3"
                          />
                        </mat-form-field>
                        <span>{{ "common.hrsText" | translate }}</span>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-6">
                      <div class="form-group float-label pe-3">
                        <mat-label
                          >{{
                            "settings.noShowAppointmentChargesLabelText"
                              | translate
                          }}
                          <span class="required">*</span>
                        </mat-label>
                        <mat-form-field class="max-width-120 me-3">
                          <span matPrefix>$</span>
                          <input
                            matInput
                            min="0"
                            [(ngModel)]="noShowAppointmentCharges"
                            (keypress)="
                              _globalFunction.allowNumbersWithDotOnly($event)
                            "
                            maxlength="4"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-lg-6">
                      <div class="form-group float-label pe-3">
                        <mat-label
                          >{{ "settings.paymentAccountIdText" | translate }}
                          <span class="required">*</span>
                        </mat-label>
                        <mat-form-field class="me-3">
                          <input
                            matInput
                            type="text"
                            [(ngModel)]="paymentAccountId"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-md-12 col-lg-6">
                      <div class="form-group float-label">
                        <mat-label
                          >{{
                            "settings.appointmentCommissionPaidByLabelText"
                              | translate
                          }}
                          <span class="required">*</span>
                        </mat-label>
                        <mat-radio-group
                          aria-label="Select an option"
                          [(ngModel)]="appointmentCommissionPaidBy"
                        >
                          <mat-radio-button value="clinic" checked>{{
                            "common.clinicText" | translate
                          }}</mat-radio-button>
                          <mat-radio-button value="patient">{{
                            "common.patientText" | translate
                          }}</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <button
                type="button"
                class="btn btn-secondary me-3"
                (click)="saveAppointmentSettings()"
              >
                <img src="assets/images/save-icon.svg" alt="" class="me-2" />
                {{ "addUser.saveButtonText" | translate }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                routerLink="/dashboard"
              >
                <img
                  src="assets/images/white-close-icon.svg"
                  alt=""
                  class="me-2"
                />
                {{ "addUser.cancelButtonText" | translate }}
              </button>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="isAllExpanded">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "settings.appointmentsVisitPricingLabelText" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-class-body">
                <div class="d-flex flex-wrap">
                  <div class="form-group float-label">
                    <mat-label class="min-width-auto pe-2"
                      >{{ "settings.visitTypeLabelText" | translate }}
                      <span class="required">*</span>
                    </mat-label>
                    <div class="max-width-120 me-3">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="appointmentVisitType"
                          required
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="form-group float-label">
                    <mat-label class="min-width-auto pe-2"
                      >{{ "settings.visitPriceLabelText" | translate }}
                      <span class="required">*</span>
                    </mat-label>
                    <div class="max-width-120 me-3">
                      <mat-form-field>
                        <span matPrefix>$</span>
                        <input
                          matInput
                          [(ngModel)]="appointmentVisitPrice"
                          (keypress)="_globalFunction.allowNumbersOnly($event)"
                          maxlength="4"
                          required
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div type="button" class="form-group">
                    <button
                      class="btn btn-primary"
                      *ngIf="!isAppointmentVisitEditMode"
                      (click)="saveAppointmentVisit()"
                    >
                      <img
                        src="assets/images/plus-icon.svg"
                        alt=""
                        class="me-2"
                      />
                      {{ "common.addText" | translate }}
                    </button>
                    <button
                      class="btn btn-primary"
                      *ngIf="isAppointmentVisitEditMode"
                      (click)="saveAppointmentVisit()"
                    >
                      <img
                        src="assets/images/save-icon.svg"
                        alt=""
                        class="me-2"
                      />
                      {{ "common.updateText" | translate }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="mat-elevation-z8 table-responsive">
                <table
                  mat-table
                  [dataSource]="appointmentVisitDataSource"
                  class="table border"
                >
                  <ng-container matColumnDef="visitType">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "settings.visitTypeLabelText" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.visitType }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "settings.visitPriceLabelText" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.price }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "userList.actionLabelText" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="table-action">
                        <a
                          href="javascript:void(0)"
                          (click)="editAppointmentVisitType(element)"
                          ><img src="assets/images/edit-icon.svg" alt=""
                        /></a>
                        <a
                          href="javascript:void(0)"
                          (click)="deleteAppointmentVisitType(element)"
                          ><img src="assets/images/delete-icon.svg" alt=""
                        /></a>
                      </div>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="appointmentVisitDisplayedColumns"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="
                      let row;
                      columns: appointmentVisitDisplayedColumns
                    "
                  ></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td
                      class="mat-cell"
                      [attr.colspan]="appointmentVisitDisplayedColumns.length"
                    >
                      {{ "userList.noResultFoundText" | translate }}
                    </td>
                  </tr>
                </table>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="isAllExpanded">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "leftmenu.Notifications" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-class-body">
                <div class="d-flex flex-wrap">
                  <div class="form-group float-label">
                    <mat-label class="min-width-auto pe-2"
                      >{{
                        "settings.appointmentReminderDurationLabelText"
                          | translate
                      }}
                      <span class="required">*</span>
                    </mat-label>
                    <div class="max-width-120 me-3">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="appointmentNotificationDuration"
                          (keypress)="_globalFunction.allowNumbersOnly($event)"
                          maxlength="4"
                          required
                        />
                      </mat-form-field>
                    </div>
                    <div class="max-width-120 me-3">
                      <mat-form-field>
                        <mat-select
                          [(ngModel)]="appointmentNotificationDurationId"
                          required
                        >
                          <mat-option
                            *ngFor="
                              let duration of appointmentNotificationDurationList
                            "
                            [value]="duration.notificationDurationId"
                            >{{ duration.durationType }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div type="button" class="form-group">
                    <button
                      class="btn btn-primary"
                      *ngIf="!isAppointmentNotificationEditMode"
                      (click)="saveAppointmentNotification()"
                    >
                      <img
                        src="assets/images/plus-icon.svg"
                        alt=""
                        class="me-2"
                      />
                      {{ "common.addText" | translate }}
                    </button>
                    <button
                      class="btn btn-primary"
                      *ngIf="isAppointmentNotificationEditMode"
                      (click)="saveAppointmentNotification()"
                    >
                      <img
                        src="assets/images/save-icon.svg"
                        alt=""
                        class="me-2"
                      />
                      {{ "common.updateText" | translate }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="mat-elevation-z8 table-responsive">
                <table
                  mat-table
                  [dataSource]="appointmentNotificationDataSource"
                  class="table border"
                >
                  <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "settings.durationLabelText" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.notificationDuration }}
                      {{ element.durationType }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "userList.actionLabelText" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="table-action">
                        <a
                          href="javascript:void(0)"
                          (click)="editAppointmentNotification(element)"
                          ><img src="assets/images/edit-icon.svg" alt=""
                        /></a>
                        <a
                          href="javascript:void(0)"
                          (click)="deleteAppointmentNotification(element)"
                          ><img src="assets/images/delete-icon.svg" alt=""
                        /></a>
                      </div>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="appointmentNotificationDisplayedColumns"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="
                      let row;
                      columns: appointmentNotificationDisplayedColumns
                    "
                  ></tr>
                  <tr class="mat-row" *matNoDataRow>
                    <td
                      class="mat-cell"
                      [attr.colspan]="
                        appointmentNotificationDisplayedColumns.length
                      "
                    >
                      {{ "userList.noResultFoundText" | translate }}
                    </td>
                  </tr>
                </table>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>

import { DatePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment-timezone";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { ClinicService } from "../../services/clinic.service";
import { GlobalFunctions } from "src/app/app.global";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { UserTypeEnum } from "src/app/enums/userTypeEnum";

@Component({
  selector: "app-clinic-dashboard",
  templateUrl: "./clinic-dashboard.component.html",
  styleUrls: ["./clinic-dashboard.component.css"],
})
export class ClinicDashboardComponent implements OnInit {
  rightDrawer: boolean = false;
  filter: boolean = false;
  showFilterList: any = [
    "ProviderType",
    "Insurance",
    "AppointmentStatus",
    "MultiselectProvider",
  ];
  viewType: string = "day";
  dateStartView: any = "month";
  currentDayText: any = "";
  currentDateText: any = "";
  selectedDate: Date = new Date();
  fromDate: Date = new Date();
  toDate: Date = new Date();
  providerList: any = [];
  selectedProviderValue: any = null;
  addAppointment: boolean = false;
  addAppointmentBySlot: boolean = false;
  viewAppointment: boolean = false;
  viewAppointmentData: any;
  dashboardDataList: any = [];
  totalData: number = 0;
  filterProviderTypeValue: any = "all";
  filterInsuranceValue: any = 0;
  filterStatusValue: number = 0;
  filterMultiselectProviderValue: any = [];
  multiselectProviderList: any = [];
  requestFromClinicDashboard: any;
  refreshDataView: boolean = false;
  searchString: any = null;
  pasteAppointmentData: any;
  filteredProviderList: any = [];
  userRole: string = "";
  providerUserId: string = "";
  userTypeEnum = UserTypeEnum;
  @ViewChild("searchProviderInput") searchProviderInput!: ElementRef;

  constructor(
    private _commonService: CommonService,
    private _clinicService: ClinicService,
    private _toastrService: ToastrNotificationService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private _renderer: Renderer2,
    public _globalFunction: GlobalFunctions,
    private _authService: AuthGuardService
  ) {
    this.selectedDate = this._commonService.getCurrentTimeZoneNewDate();
    this.fromDate = this._commonService.getCurrentTimeZoneNewDate();
    this.toDate = this._commonService.getCurrentTimeZoneNewDate();
    let paymentResult = this.route.snapshot.queryParamMap.get("payment");
    if (paymentResult && paymentResult === "success") {
      this._toastrService.showSuccess(
        "appointment.addAppointmentSuccessMessage"
      );
      let viewType = localStorage.getItem("dashboardViewType");
      let filterProviderValue = localStorage.getItem("selectedProviderValue");
      if (viewType) this.viewType = viewType;
      if (filterProviderValue) this.selectedProviderValue = filterProviderValue;
      // Remove query params
      this.router.navigate([], {
        queryParams: {
          payment: null,
        },
        queryParamsHandling: "merge",
      });
    }
    this.getSelectedDateData();
    this._renderer.listen("window", "click", (e: Event) => {
      this.filteredProviderList = this.providerList;
      if (this.searchProviderInput)
        this.searchProviderInput.nativeElement.value = "";
    });
  }

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
        this.providerUserId = data["userid"];
      }
    });
    localStorage.setItem("dashboardViewType", "day");
    this.getAllProviders();
    if (!localStorage.getItem("timeZoneCommonList")) {
      this.getTimezone();
    }
  }

  /**
   * Method to toggle filter section
   */
  public filterClick() {
    this.filter = !this.filter;
  }

  /**
   * Method to get filter data
   */
  public getFilterData(data: any) {
    this.filterProviderTypeValue = data.filterProviderTypeValue;
    this.filterInsuranceValue = data.filterInsuranceValue;
    this.filterStatusValue = data.filterStatusValue;
    this.filterMultiselectProviderValue = data.filterMultiselectProviderValue;
  }

  /**
   * Method to apply filter data
   */
  public applyFilterData() {
    this.multiselectProviderList = this.filterMultiselectProviderValue;
    this.getDashboardData();
  }

  /**
   * Method to change calendar view
   */
  public changeView(view: string) {
    localStorage.setItem("dashboardViewType", view);
    this.viewType = view;
    this.selectedDate = this._commonService.getCurrentTimeZoneNewDate();

    this.getSelectedDateData();
    if (this.viewType === "month") {
      this.dateStartView = "year";
      this.showFilterList = ["ProviderType", "Insurance", "AppointmentStatus"];
    } else {
      this.dateStartView = "month";
      this.showFilterList = [
        "ProviderType",
        "Insurance",
        "AppointmentStatus",
        "MultiselectProvider",
      ];
    }
  }

  /**
   * Method to change date
   */
  public changeDate() {
    this.getSelectedDateData();
  }

  /**
   * Method to close date picker
   */
  public closeDatePicker(date: any, dp?: any) {
    this.selectedDate = date;
    this.getSelectedDateData();
    dp.close();
  }

  /**
   * Method to select next date
   */
  public selectNext() {
    if (this.viewType === "day") {
      this.selectedDate.setDate(this.selectedDate.getDate() + 1);
    }
    if (this.viewType === "week") {
      this.selectedDate.setDate(this.selectedDate.getDate() + 7);
    }
    if (this.viewType === "month") {
      this.selectedDate.setMonth(this.selectedDate.getMonth() + 1);
    }
    this.getSelectedDateData();
  }

  /**
   * Method to select previous data
   */
  public selectPrevious() {
    if (this.viewType === "day") {
      this.selectedDate.setDate(this.selectedDate.getDate() - 1);
    }
    if (this.viewType === "week") {
      this.selectedDate.setDate(this.selectedDate.getDate() - 7);
    }
    if (this.viewType === "month") {
      this.selectedDate.setMonth(this.selectedDate.getMonth() - 1);
    }
    this.getSelectedDateData();
  }

  /**
   * Method to get current date data
   */
  public getSelectedDateData() {
    this.filterMultiselectProviderValue = "";
    if (this.viewType === "day") {
      this.currentDayText = this.datePipe.transform(this.selectedDate, "EEEE");
      this.currentDateText = this.datePipe.transform(
        this.selectedDate,
        "MMM dd"
      );
      this.fromDate = this.selectedDate;
      this.toDate = this.selectedDate;
    }
    if (this.viewType === "week") {
      this.currentDayText = "";
      var currentDay = new Date(this.selectedDate);
      var first = currentDay.getDate() - currentDay.getDay();
      let weekFirstDay = new Date(currentDay.setDate(first));
      this.currentDateText = this.datePipe.transform(weekFirstDay, "MMM dd");
      let weekLastDay = new Date(
        weekFirstDay.setDate(weekFirstDay.getDate() + 6)
      );
      this.currentDateText =
        this.currentDateText +
        " - " +
        this.datePipe.transform(weekLastDay, "MMM dd");

      this.fromDate = new Date(this.selectedDate.setDate(first));
      this.toDate = weekLastDay;
    }
    if (this.viewType === "month") {
      this.currentDayText = "";
      this.currentDateText = this.datePipe.transform(
        this.selectedDate,
        "MMM - yyyy"
      );

      this.fromDate = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        1
      );
      this.toDate = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth() + 1,
        0
      );
      //Set first provider selected
      if (
        this.providerList &&
        this.providerList.length > 0 &&
        this.selectedProviderValue === null
      ) {
        let provider = this.providerList[0];
        this.selectedProviderValue = provider.userId;
        this.filterMultiselectProviderValue = this.selectedProviderValue;
      }
    }
    this.getDashboardData();
  }

  /**
   * Method to get medical provider data by clinic
   */
  public getAllProviders() {
    this._clinicService.getAllProviderByClinic().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.providerList = data;
          if (
            this.providerList &&
            this.providerList.length > 0 &&
            this.userRole === UserTypeEnum.Provider.toString()
          )
            this.providerList = this.providerList.filter(
              (x: any) => x.userId === this.providerUserId
            );
          this.filteredProviderList = this.providerList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to search by patient
   */
  public searchByPatient() {
    this.getCountBySearchData();
  }

  /**
   * Method to get dashboard data count by search data
   */
  public getCountBySearchData() {
    let dashboardList = this.dashboardDataList;
    if (this.searchString)
      this.totalData = dashboardList.filter(
        (x: any) =>
          x.patientName !== null &&
          x.patientName.toLowerCase().includes(this.searchString.toLowerCase())
      ).length;
    else this.totalData = dashboardList.length;
  }

  /**
   * Method to change provider
   */
  public changeProvider() {
    this.filterMultiselectProviderValue = this.selectedProviderValue;
    this.getDashboardData();
    this.refreshDataView = true;
    localStorage.setItem("selectedProviderValue", this.selectedProviderValue);
  }

  /**
   * Method to get dashboard data count by provider
   */
  public getCountByProvider() {
    let dashboardList = this.dashboardDataList;
    if (this.viewType === "month")
      this.totalData = dashboardList.filter(
        (x: any) => x.providerUserId === this.selectedProviderValue
      ).length;
    else this.totalData = dashboardList.length;
  }

  /**
   * Method to open add new appointment section
   */
  public addNewAppointment() {
    this.rightDrawer = !this.rightDrawer;
    this.addAppointment = true;
    this.addAppointmentBySlot = false;
    this.viewAppointment = false;
    this.requestFromClinicDashboard = true;
    this.pasteAppointmentData = null;
  }

  /**
   * Method to show/hide appointment section
   */
  public showHideAppointmentPopup(showUserPopup: any): void {
    this.rightDrawer = showUserPopup;
    this.addAppointment = showUserPopup ? true : false;
    this.addAppointmentBySlot = showUserPopup ? true : false;
    this.viewAppointment = showUserPopup ? true : false;
    if (localStorage.getItem("reloadData") === "true")
      this.getSelectedDateData();
    localStorage.removeItem("reloadData");
  }

  /**
   * Method to get dashboard data
   */
  public getDashboardData(): void {
    this.refreshDataView = false;
    this.dashboardDataList = [];
    let dashboardParam = {
      fromDate: this.datePipe.transform(this.fromDate, "yyyy-MM-dd"),
      toDate: this.datePipe.transform(this.toDate, "yyyy-MM-dd"),
      providerUserId: this.filterMultiselectProviderValue.toString(),
      withAppointment:
        this.filterProviderTypeValue === "withAppointment" ? true : false,
      withFreeTime:
        this.filterProviderTypeValue === "withFreeTime" ? true : false,
      insuranceId: this.filterInsuranceValue,
      appointmentStatusId: this.filterStatusValue,
      offset: moment().tz(moment.tz.guess()).utcOffset(),
    };
    this._clinicService.getDashboardDetails(dashboardParam).subscribe({
      next: (data) => {
        if (data && data !== null) {
          this.dashboardDataList = data;
        }
        this.getCountByProvider();
        this.refreshDataView = true;
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to open view appointment section
   */
  public viewAppointmentPopup(
    data: any,
    requestFromDashboard: boolean = false
  ) {
    this.rightDrawer = !this.rightDrawer;
    this.viewAppointment = true;
    this.addAppointment = false;
    this.addAppointmentBySlot = false;
    this.viewAppointmentData = data;
    this.viewAppointmentData.requestFromDashboard = requestFromDashboard;
  }

  /**
   * Method to open add appointment section
   */
  public addAppointmentPopup(data: any) {
    this.rightDrawer = !this.rightDrawer;
    this.viewAppointment = false;
    this.addAppointment = false;
    this.addAppointmentBySlot = true;
    this.viewAppointmentData = data;
  }

  /**
   * Method to refresh dashboard
   */
  public refreshDashboard(refreshData: any): void {
    if (refreshData) {
      this.rightDrawer = false;
      this.addAppointment = false;
      this.addAppointmentBySlot = false;
      this.viewAppointment = false;
      this.getDashboardData();
    }
  }

  /**
   * Method to add patient
   */
  public addPatient() {
    this.router.navigateByUrl("/users", {
      state: { requestFrom: "clinicdashboard" },
    });
  }

  /**
   * Method to open add appointment section and paste appointment data
   */
  public pasteAppointmentPopup(
    data: any,
    requestFromDashboard: boolean = false
  ) {
    this.addNewAppointment();
    this.pasteAppointmentData = data;
    this.pasteAppointmentData.requestFromDashboard = requestFromDashboard;
  }

  /**
   * Method to search providers
   */
  public searchProvider(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.providerList.filter((x: any) => {
      if (x.name.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredProviderList = temp;
  }

  /**
   * Method to get all timezone
   */
  public getTimezone() {
    this._commonService.getTimezone().subscribe({
      next: (data) => {},
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }
}

<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/view-profile-icon.svg" alt="" />
        </div>
        <h2>{{ "providerProfile.updateProfileText" | translate }}</h2>
      </div>
    </div>

    <div class="content-page">
      <div class="form-page">
        <div class="view-profile-top">
          <div class="profile-image">
            <input
              type="file"
              id="profileImageUpload"
              accept=".png, .jpg, .jpeg"
              #imageFileInput
              (change)="uploadProfileImage($event)"
              hidden
            />
            <img
              src="data:image/png;base64,{{
                providerProfileModel.providerPhoto.fileBytes
              }}"
              alt=""
              *ngIf="
                providerProfileModel.providerPhoto &&
                providerProfileModel.providerPhoto.fileBytes &&
                providerProfileModel.providerPhoto.fileBytes !== null
              "
            />
            <img
              src="assets/images/profile-image.png"
              alt=""
              *ngIf="providerProfileModel.providerPhoto === null"
            />
            <a
              href="javascript:void(0);"
              class="edit-icon"
              (click)="imageFileInput.click()"
            >
              <img src="assets/images/edit-icon.svg" alt="" />
            </a>
          </div>
          <div class="profile-details">
            <h3>
              {{ providerProfileModel.firstName }}
              {{ providerProfileModel.lastName }}
            </h3>
            <h4>{{ providerProfileModel.email }}</h4>
          </div>
        </div>
        <form
          [formGroup]="providerProfileInfoValidation"
          (ngSubmit)="submitProviderProfile()"
        >
          <div class="view-profile-form">
            <div class="row">
              <div class="col-xxl-10">
                <div class="row">
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label
                        >{{ "addUser.firstNameLabelText" | translate
                        }}<span class="required">*</span></mat-label
                      >
                      <div class="w-100">
                        <mat-form-field class="example-full-width">
                          <input
                            matInput
                            type="text"
                            class="field-disable"
                            formControlName="firstName"
                            [(ngModel)]="providerProfileModel.firstName"
                            placeholder="{{
                              'addUser.firstNamePlaceholder' | translate
                            }}"
                            required
                            readonly
                          />
                        </mat-form-field>
                        <div
                          *ngIf="
                            providerProfileInfoValidation.controls['firstName']
                              .invalid &&
                            (providerProfileInfoValidation.controls['firstName']
                              .dirty ||
                              providerProfileInfoValidation.controls[
                                'firstName'
                              ].touched ||
                              formSubmitted)
                          "
                        >
                          <mat-error
                            *ngIf="
                              providerProfileInfoValidation
                                .get('firstName')
                                ?.hasError('required')
                            "
                            >{{
                              "addUser.requiredFirstNameError" | translate
                            }}</mat-error
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label
                        >{{ "addUser.lastNameLabelText" | translate
                        }}<span class="required">*</span></mat-label
                      >
                      <div class="w-100">
                        <mat-form-field class="example-full-width">
                          <input
                            matInput
                            type="text"
                            class="field-disable"
                            formControlName="lastName"
                            [(ngModel)]="providerProfileModel.lastName"
                            placeholder="{{
                              'addUser.lastNamePlaceholder' | translate
                            }}"
                            required
                            readonly
                          />
                        </mat-form-field>
                        <div
                          *ngIf="
                            providerProfileInfoValidation.controls['lastName']
                              .invalid &&
                            (providerProfileInfoValidation.controls['lastName']
                              .dirty ||
                              providerProfileInfoValidation.controls['lastName']
                                .touched ||
                              formSubmitted)
                          "
                        >
                          <mat-error
                            *ngIf="
                              providerProfileInfoValidation
                                .get('lastName')
                                ?.hasError('required')
                            "
                            >{{
                              "addUser.requiredLastNameError" | translate
                            }}</mat-error
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label>{{
                        "viewUser.emailAddressLabelText" | translate
                      }}</mat-label>
                      <div class="w-100">
                        <mat-form-field class="example-full-width">
                          <input
                            matInput
                            type="text"
                            class="field-disable"
                            formControlName="email"
                            [(ngModel)]="providerProfileModel.email"
                            placeholder="{{
                              'login.emailPlaceholder' | translate
                            }}"
                            required
                            readonly
                          />
                        </mat-form-field>
                        <div
                          *ngIf="
                            providerProfileInfoValidation.controls['email']
                              .invalid &&
                            (providerProfileInfoValidation.controls['email']
                              .dirty ||
                              providerProfileInfoValidation.controls['email']
                                .touched ||
                              formSubmitted)
                          "
                        >
                          <mat-error
                            *ngIf="
                              providerProfileInfoValidation
                                .get('email')
                                ?.hasError('required')
                            "
                            >{{
                              "common.requiredEmailError" | translate
                            }}</mat-error
                          >
                          <mat-error
                            *ngIf="
                              providerProfileInfoValidation
                                .get('email')
                                ?.hasError('pattern')
                            "
                            >{{
                              "common.invalidEmailError" | translate
                            }}</mat-error
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label
                        >{{ "common.phoneNumberText" | translate
                        }}<span class="required">*</span></mat-label
                      >
                      <div class="d-flex w-100">
                        <div class="country-code">
                          <mat-form-field class="example-full-width">
                            <mat-select
                              placeholder="{{
                                'signup.phoneCodePlaceholder' | translate
                              }}"
                              formControlName="phoneCode"
                              [(ngModel)]="providerProfileModel.phoneCode"
                            >
                              <mat-option
                                *ngFor="let code of phoneCodeList"
                                [value]="code.phoneCode"
                                >{{ code.phoneCode }}</mat-option
                              >
                            </mat-select>
                          </mat-form-field>
                          <div
                            *ngIf="
                              providerProfileInfoValidation.controls[
                                'phoneCode'
                              ].invalid &&
                              (providerProfileInfoValidation.controls[
                                'phoneCode'
                              ].dirty ||
                                providerProfileInfoValidation.controls[
                                  'phoneCode'
                                ].touched ||
                                formSubmitted)
                            "
                          >
                            <mat-error
                              *ngIf="
                                providerProfileInfoValidation
                                  .get('phoneCode')
                                  ?.hasError('required')
                              "
                              >{{
                                "signup.requiredPhoneCodeError" | translate
                              }}</mat-error
                            >
                          </div>
                        </div>
                        <div class="w-100 ml-5">
                          <mat-form-field class="example-form-field">
                            <input
                              matInput
                              type="text"
                              formControlName="phoneNumber"
                              [(ngModel)]="providerProfileModel.phoneNumber"
                              placeholder="{{
                                'viewUser.contactNumberLabelText' | translate
                              }}"
                              (keypress)="
                                _globalFunction.allowNumbersOnly($event)
                              "
                              required
                            />
                          </mat-form-field>
                          <div
                            *ngIf="
                              providerProfileInfoValidation.controls[
                                'phoneNumber'
                              ].invalid &&
                              (providerProfileInfoValidation.controls[
                                'phoneNumber'
                              ].dirty ||
                                providerProfileInfoValidation.controls[
                                  'phoneNumber'
                                ].touched ||
                                formSubmitted)
                            "
                          >
                            <mat-error
                              *ngIf="
                                providerProfileInfoValidation
                                  .get('phoneNumber')
                                  ?.hasError('required')
                              "
                              >{{
                                "signup.requiredPhoneError" | translate
                              }}</mat-error
                            >
                            <mat-error
                              *ngIf="
                                providerProfileInfoValidation
                                  .get('phoneNumber')
                                  ?.hasError('minlength')
                              "
                              >{{
                                "signup.minLengthPhoneError" | translate
                              }}</mat-error
                            >
                            <mat-error
                              *ngIf="
                                providerProfileInfoValidation
                                  .get('phoneNumber')
                                  ?.hasError('maxlength')
                              "
                              >{{
                                "signup.maxLengthPhoneError" | translate
                              }}</mat-error
                            >
                            <mat-error
                              *ngIf="
                                providerProfileInfoValidation
                                  .get('phoneNumber')
                                  ?.hasError('pattern')
                              "
                              >{{
                                "signup.patternPhoneError" | translate
                              }}</mat-error
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label>{{
                        "viewUser.dateOfBirthLabelText" | translate
                      }}</mat-label>
                      <div class="w-100">
                        <mat-form-field class="example-full-width">
                          <input
                            matInput
                            type="text"
                            class="dob-input field-disable"
                            formControlName="dob"
                            [(ngModel)]="providerProfileModel.dob"
                            placeholder="{{
                              'viewUser.dateOfBirthPlaceholder' | translate
                            }}"
                            [matDatepicker]="picker"
                            [max]="todayDate"
                            required
                            readonly
                          />
                          <mat-datepicker-toggle
                            matIconSuffix
                            [for]="picker"
                          ></mat-datepicker-toggle>
                          <mat-datepicker
                            #picker
                            [disabled]="true"
                          ></mat-datepicker>
                        </mat-form-field>
                        <div
                          *ngIf="
                            providerProfileInfoValidation.controls['dob']
                              .invalid &&
                            (providerProfileInfoValidation.controls['dob']
                              .dirty ||
                              providerProfileInfoValidation.controls['dob']
                                .touched ||
                              formSubmitted)
                          "
                        >
                          <mat-error
                            *ngIf="
                              providerProfileInfoValidation
                                .get('dob')
                                ?.hasError('required')
                            "
                            >{{
                              "addUser.requiredDateOfBirth" | translate
                            }}</mat-error
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label
                        >{{ "viewUser.specialityLabelText" | translate
                        }}<span class="required">*</span></mat-label
                      >
                      <div class="w-100">
                        <mat-form-field class="example-full-width">
                          <mat-select
                            placeholder="{{
                              'addUser.specialityPlaceholder' | translate
                            }}"
                            formControlName="speciality"
                            [(ngModel)]="providerProfileModel.speciality"
                          >
                            <mat-option
                              *ngFor="let item of specialityList"
                              [value]="item.specialtyId"
                              >{{ item.specialty }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                        <div
                          *ngIf="
                            providerProfileInfoValidation.controls['speciality']
                              .invalid &&
                            (providerProfileInfoValidation.controls[
                              'speciality'
                            ].dirty ||
                              providerProfileInfoValidation.controls[
                                'speciality'
                              ].touched ||
                              formSubmitted)
                          "
                        >
                          <mat-error
                            *ngIf="
                              providerProfileInfoValidation
                                .get('speciality')
                                ?.hasError('required')
                            "
                            >{{
                              "addUser.requiredSpecialityError" | translate
                            }}</mat-error
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label
                        >{{ "common.visitTypeText" | translate
                        }}<span class="required">*</span></mat-label
                      >
                      <div class="w-100">
                        <mat-form-field class="example-full-width">
                          <mat-select
                            multiple
                            placeholder="Select Visit Type"
                            formControlName="visitTypes"
                            [(ngModel)]="providerProfileModel.visitTypes"
                          >
                            <mat-option
                              *ngFor="let item of visitTypeList"
                              [value]="item.clinicVisitTypeId"
                              >{{ item.visitType }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                        <div
                          *ngIf="
                            providerProfileInfoValidation.controls['visitTypes']
                              .invalid &&
                            (providerProfileInfoValidation.controls[
                              'visitTypes'
                            ].dirty ||
                              providerProfileInfoValidation.controls[
                                'visitTypes'
                              ].touched ||
                              formSubmitted)
                          "
                        >
                          <mat-error
                            *ngIf="
                              providerProfileInfoValidation
                                .get('visitTypes')
                                ?.hasError('required')
                            "
                            >{{
                              "providerProfile.requiredVisitTypeError"
                                | translate
                            }}</mat-error
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label
                        >{{ "common.languagesText" | translate
                        }}<span class="required">*</span></mat-label
                      >
                      <div class="w-100">
                        <mat-form-field class="example-full-width">
                          <mat-select
                            multiple
                            placeholder="{{
                              'common.selectLanguageLabel' | translate
                            }}"
                            formControlName="languages"
                            [(ngModel)]="providerProfileModel.languages"
                          >
                            <mat-option
                              *ngFor="let item of languageList"
                              [value]="item.languageId"
                              >{{ item.name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                        <div
                          *ngIf="
                            providerProfileInfoValidation.controls['languages']
                              .invalid &&
                            (providerProfileInfoValidation.controls['languages']
                              .dirty ||
                              providerProfileInfoValidation.controls[
                                'languages'
                              ].touched ||
                              formSubmitted)
                          "
                        >
                          <mat-error
                            *ngIf="
                              providerProfileInfoValidation
                                .get('languages')
                                ?.hasError('required')
                            "
                            >{{
                              "providerProfile.requiredLanguageError"
                                | translate
                            }}</mat-error
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label
                        >{{ "appointment.selectTimezoneText" | translate
                        }}<span class="required">*</span></mat-label
                      >
                      <div class="w-100">
                        <mat-form-field class="example-full-width timezone">
                          <mat-select
                            placeholder="{{
                              'appointment.selectTimezoneText' | translate
                            }}"
                            formControlName="timezone"
                            [(ngModel)]="providerProfileModel.timezone"
                          >
                            <input
                              type="text"
                              #searchTimezoneInput
                              (keyup)="searchTimeZone($event)"
                              (keydown)="_globalFunction.handleInput($event)"
                              placeholder="{{
                                'common.searchTimezoneText' | translate
                              }}"
                              class="search-input"
                            />
                            <mat-option
                              *ngFor="let tz of filteredTimezoneList"
                              [value]="tz"
                              >{{ tz.displayName }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                        <div
                          *ngIf="
                            providerProfileInfoValidation.controls['timezone']
                              .invalid &&
                            (providerProfileInfoValidation.controls['timezone']
                              .dirty ||
                              providerProfileInfoValidation.controls['timezone']
                                .touched ||
                              formSubmitted)
                          "
                        >
                          <mat-error
                            *ngIf="
                              providerProfileInfoValidation
                                .get('timezone')
                                ?.hasError('required')
                            "
                            >{{
                              "common.RequiredTimezoneError" | translate
                            }}</mat-error
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label
                        >{{ "common.selectLanguageLabel" | translate
                        }}<span class="required">*</span></mat-label
                      >
                      <div class="w-100">
                        <mat-form-field class="example-full-width">
                          <mat-select
                            placeholder="{{
                              'common.selectLanguageLabel' | translate
                            }}"
                            formControlName="language"
                            [(ngModel)]="providerProfileModel.language"
                          >
                            <mat-option
                              *ngFor="let item of languageList"
                              [value]="item.languageId"
                              >{{ item.name }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                        <div
                          *ngIf="
                            providerProfileInfoValidation.controls['language']
                              .invalid &&
                            (providerProfileInfoValidation.controls['language']
                              .dirty ||
                              providerProfileInfoValidation.controls['language']
                                .touched ||
                              formSubmitted)
                          "
                        >
                          <mat-error
                            *ngIf="
                              providerProfileInfoValidation
                                .get('language')
                                ?.hasError('required')
                            "
                            >{{
                              "providerProfile.requiredLanguageError"
                                | translate
                            }}</mat-error
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="float-label form-group">
                      <mat-label class="pt-1"
                        >{{ "common.timeFormatText" | translate
                        }}<span class="required">*</span></mat-label
                      >
                      <div class="w-100">
                        <div class="form-group mb-0">
                          <div class="toggle-time">
                            <mat-slide-toggle
                              class="toggle"
                              [checked]="providerProfileModel.timeFormat"
                              formControlName="timeFormat"
                              [(ngModel)]="providerProfileModel.timeFormat"
                              (change)="changeTimeFormat($event)"
                            ></mat-slide-toggle>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-action">
              <button class="btn btn-secondary me-3">
                <img src="assets/images/save-icon.svg" alt="" class="me-2" />
                {{ "common.updateText" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="filter-section" [ngClass]="filter ? 'open' : ''" #getHeight>
  <div class="filter-icon">
    <img src="assets/images/blue-filter-icon.svg" alt="" class="me-2" />
  </div>
  <div class="filter-form">
    <ul>
      <li *ngIf="showFilterList.includes('Speciality')">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{
              "viewUser.specialityLabelText" | translate
            }}</mat-label>
            <span matPrefix style="width: 20px; display: inline-block"
              ><img src="assets/images/user-icon.svg" alt=""
            /></span>
            <mat-select
              placeholder="{{ 'addUser.specialityPlaceholder' | translate }}"
              [(ngModel)]="filterSpecialityValue"
              (selectionChange)="changeFilter()"
            >
              <mat-option
                *ngFor="let item of specialityList"
                [value]="item.specialtyId"
                >{{ item.specialty }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('Status')">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{ "viewUser.statusLabelText" | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'userList.statusPlaceholder' | translate }}"
              [(ngModel)]="filterStatusValue"
              (selectionChange)="changeFilter()"
            >
              <mat-option value="1">{{
                "common.Active" | translate
              }}</mat-option>
              <mat-option value="2">{{
                "common.Inactive" | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('Provider')">
        <div class="form-group">
          <mat-form-field class="example-form-field">
            <mat-label>{{
              "appointment.medicalProviderText" | translate
            }}</mat-label>
            <mat-select
              placeholder="{{
                'scheduler.selectProviderPlaceholder' | translate
              }}"
              [(ngModel)]="filterProviderValue"
              (selectionChange)="changeProvider($event.value)"
            >
              <input
                type="text"
                #searchProviderInput
                (keyup)="searchProvider($event)"
                (keydown)="_globalFunction.handleInput($event)"
                placeholder="{{ 'appointment.searchProviderText' | translate }}"
                class="search-input"
              />
              <mat-option
                *ngFor="let provider of filteredProviderList"
                [value]="provider.userId"
                >{{ provider.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('VisitType')">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{
              "settings.visitTypeLabelText" | translate
            }}</mat-label>
            <mat-select
              placeholder="{{
                'scheduler.selectVisitTypePlaceholder' | translate
              }}"
              [(ngModel)]="filterVisitTypeValue"
              (selectionChange)="changeFilter()"
            >
              <mat-option
                *ngFor="let visitType of visitTypeList"
                [value]="visitType.clinicVisitTypeId"
                >{{ visitType.visitType }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li
        class="date-range"
        *ngIf="showFilterList.includes('StartDateEndDate')"
      >
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{
              "scheduler.selectStartDateandEndDateLabelText" | translate
            }}</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                placeholder="{{ 'scheduler.startDatePlaceholder' | translate }}"
                [(ngModel)]="filterStartDateValue"
                readonly
              />
              <input
                matEndDate
                placeholder="{{ 'scheduler.endDatePlaceholder' | translate }}"
                [(ngModel)]="filterEndDateValue"
                (dateChange)="setFilterData()"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('Role')">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{
              "manageOfficeStaffUsers.roleLabelText" | translate
            }}</mat-label>
            <mat-select
              placeholder="{{
                'manageOfficeStaffUsers.rolePlaceholder' | translate
              }}"
              [(ngModel)]="filterRoleValue"
              (selectionChange)="changeFilter()"
            >
              <mat-option value="5">Admin</mat-option>
              <mat-option value="6">Office Staff</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('State')">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{ "common.StateLabel" | translate }}</mat-label>
            <span matPrefix style="width: 20px; display: inline-block"
              ><img src="assets/images/user-icon.svg" alt=""
            /></span>
            <mat-select
              placeholder="{{ 'common.StatePlaceholder' | translate }}"
              [(ngModel)]="filterStateValue"
              (selectionChange)="changeState($event.value)"
            >
              <input
                type="text"
                #searchStateInput
                (keyup)="searchStates($event)"
                (keydown)="_globalFunction.handleInput($event)"
                placeholder="{{ 'common.searchStateText' | translate }}"
                class="search-input"
              />
              <mat-option
                *ngFor="let item of filteredStateList"
                [value]="item.stateId"
                >{{ item.stateName }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('City')">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{ "common.CityLabel" | translate }}</mat-label>
            <span matPrefix style="width: 20px; display: inline-block"
              ><img src="assets/images/user-icon.svg" alt=""
            /></span>
            <mat-select
              placeholder="{{ 'common.CityPlaceholder' | translate }}"
              [(ngModel)]="filterCityValue"
              (selectionChange)="changeFilter()"
            >
              <input
                type="text"
                #searchCityInput
                (keyup)="searchCities($event)"
                (keydown)="_globalFunction.handleInput($event)"
                placeholder="{{ 'common.searchCityText' | translate }}"
                class="search-input"
              />
              <mat-option
                *ngFor="let item of filteredCityList"
                [value]="item.cityId"
                >{{ item.cityName }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('ClinicRequestStatus')">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{ "viewUser.statusLabelText" | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'userList.statusPlaceholder' | translate }}"
              [(ngModel)]="filterStatusValue"
              (selectionChange)="changeFilter()"
            >
              <mat-option value="1">{{
                "common.Approved" | translate
              }}</mat-option>
              <mat-option value="2">{{
                "common.Rejected" | translate
              }}</mat-option>
              <mat-option value="3">{{
                "common.Pending" | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('UpdateBy')">
        <div class="form-group">
          <mat-form-field>
            <mat-label
              >{{ "common.updatedByLabelText" | translate }}:</mat-label
            >
            <span matPrefix style="width: 20px; display: inline-block"
              ><img src="assets/images/user-icon.svg" alt=""
            /></span>
            <mat-select
              placeholder="{{ 'common.selectUserPlaceholder' | translate }}"
              [(ngModel)]="filterUpdatedByValue"
              (selectionChange)="changeFilter()"
            >
              <input
                type="text"
                #searchUpdatedByInput
                (keyup)="searchUpdatedBy($event)"
                (keydown)="_globalFunction.handleInput($event)"
                placeholder="{{ 'common.searchUpdatedByText' | translate }}"
                class="search-input"
              />
              <mat-option
                *ngFor="let user of filteredupdatedByUserList"
                [value]="user.userId"
                >{{ user.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li class="date-range" *ngIf="showFilterList.includes('FromDateToDate')">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{
              "appointment.selectFromDateandToDateText" | translate
            }}</mat-label>
            <mat-date-range-input
              [rangePicker]="picker"
              [min]="
                !showFilterList.includes('AppointmentHistory') &&
                !showFilterList.includes('Notification') &&
                !showFilterList.includes('TransactionHistory')
                  ? todayDate
                  : null
              "
              [max]="
                showFilterList.includes('AppointmentHistory') &&
                !showFilterList.includes('Notification') &&
                !showFilterList.includes('TransactionHistory')
                  ? todayDate
                  : null
              "
            >
              <input
                matStartDate
                placeholder="{{ 'appointment.fromDate' | translate }}"
                [(ngModel)]="filterStartDateValue"
                readonly
              />
              <input
                matEndDate
                placeholder="{{ 'appointment.toDate' | translate }}"
                [(ngModel)]="filterEndDateValue"
                (dateChange)="setFilterData()"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </li>
      <li class="date-range" *ngIf="showFilterList.includes('TransactionDate')">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{
              "transaction.selectTransactionDateText" | translate
            }}</mat-label>
            <mat-date-range-input
              [rangePicker]="picker"
              [min]="
                !showFilterList.includes('AppointmentHistory') &&
                !showFilterList.includes('Notification') &&
                !showFilterList.includes('TransactionHistory')
                  ? todayDate
                  : null
              "
              [max]="
                showFilterList.includes('AppointmentHistory') &&
                !showFilterList.includes('Notification') &&
                !showFilterList.includes('TransactionHistory')
                  ? todayDate
                  : null
              "
            >
              <input
                matStartDate
                placeholder="{{ 'appointment.fromDate' | translate }}"
                [(ngModel)]="filterStartDateValue"
                readonly
              />
              <input
                matEndDate
                placeholder="{{ 'appointment.toDate' | translate }}"
                [(ngModel)]="filterEndDateValue"
                (dateChange)="setFilterData()"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('Patient')">
        <div class="form-group">
          <mat-form-field class="example-form-field">
            <mat-label>{{ "common.patientText" | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'appointment.selectPatientText' | translate }}"
              [(ngModel)]="filterPatientValue"
              (selectionChange)="changeFilter()"
            >
              <input
                type="text"
                #searchPatientInput
                (keyup)="searchPatient($event)"
                (keydown)="_globalFunction.handleInput($event)"
                placeholder="{{ 'appointment.searchPatientText' | translate }}"
                class="search-input"
              />
              <mat-option
                *ngFor="let patient of filteredPatientList"
                [value]="patient.userId"
                >{{ patient.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li class="date-range" *ngIf="showFilterList.includes('PatientEmail')">
        <div class="form-group">
          <mat-form-field class="example-form-field">
            <mat-label>{{ "common.patientEmailText" | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'common.selectPatientEmailText' | translate }}"
              [(ngModel)]="filterPatientEmailValue"
              (selectionChange)="changeFilter()"
            >
              <input
                type="text"
                #searchPatientEmailInput
                (keyup)="searchPatientEmail($event)"
                (keydown)="_globalFunction.handleInput($event)"
                placeholder="{{ 'common.searchPatientEmailText' | translate }}"
                class="search-input"
              />
              <mat-option
                *ngFor="let patient of filteredPatientList"
                [value]="patient.email"
                >{{ patient.email }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('Insurance')">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{ "appointment.insuranceText" | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'appointment.selectInsuranceText' | translate }}"
              [(ngModel)]="filterInsuranceValue"
              (selectionChange)="changeFilter()"
            >
              <mat-option
                *ngFor="let insurance of insuranceList"
                [value]="insurance.insuranceCompanyId"
                >{{ insurance.company }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('AppointmentStatus')">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{ "viewUser.statusLabelText" | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'userList.statusPlaceholder' | translate }}"
              [(ngModel)]="filterStatusValue"
              (selectionChange)="changeFilter()"
            >
              <mat-option value="3">{{
                "common.Completed" | translate
              }}</mat-option>
              <mat-option value="4">{{
                "common.Missed" | translate
              }}</mat-option>
              <mat-option value="5">{{
                "common.Canceled" | translate
              }}</mat-option>
              <mat-option value="1">{{
                "common.Pending" | translate
              }}</mat-option>
              <mat-option value="2">{{
                "common.Reschedule" | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('TransactionFor')">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{
              "transaction.transactionForText" | translate
            }}</mat-label>
            <mat-select
              placeholder="{{ 'transaction.transactionForText' | translate }}"
              [(ngModel)]="filterTransactionForValue"
              (selectionChange)="changeFilter()"
            >
              <mat-option value="1">{{
                "common.Appointment" | translate
              }}</mat-option>
              <mat-option
                value="2"
                *ngIf="userRole === userTypeEnum.Clinic.toString()"
                >{{ "common.Refund" | translate }}</mat-option
              >
              <mat-option
                value="3"
                *ngIf="
                  userRole === userTypeEnum.SuperAdmin.toString() ||
                  userRole === userTypeEnum.OfficeStaff.toString()
                "
                >{{ "common.Subscription" | translate }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('TransactionType')">
        <div class="form-group">
          <mat-form-field>
            <mat-label>{{
              "transaction.transactionTypeText" | translate
            }}</mat-label>
            <mat-select
              placeholder="{{ 'transaction.transactionTypeText' | translate }}"
              [(ngModel)]="filterTransactionTypeValue"
              (selectionChange)="changeFilter()"
            >
              <mat-option value="1">{{
                "common.Credit" | translate
              }}</mat-option>
              <mat-option value="2">{{
                "common.Debit" | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('Clinic')">
        <div class="form-group">
          <mat-form-field class="example-form-field">
            <mat-label>{{
              "manageClinics.clinicNameLabelText" | translate
            }}</mat-label>
            <mat-select
              placeholder="{{ 'common.selectClinicText' | translate }}"
              [(ngModel)]="filterClinicValue"
              (selectionChange)="changeFilter()"
            >
              <input
                type="text"
                #searchClinicInput
                (keyup)="searchClinic($event)"
                (keydown)="_globalFunction.handleInput($event)"
                placeholder="{{ 'common.searchClinicText' | translate }}"
                class="search-input"
              />
              <mat-option
                *ngFor="let clinic of filteredClinicList"
                [value]="clinic.userId"
                >{{ clinic.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li *ngIf="showFilterList.includes('ProviderType')">
        <div class="form-group">
          <mat-form-field class="example-form-field">
            <mat-label>{{ "common.providersText" | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'common.selectProviderTypeText' | translate }}"
              [(ngModel)]="filterProviderTypeValue"
              (selectionChange)="changeProviderType()"
            >
              <mat-option value="all">All</mat-option>
              <mat-option value="withAppointment">W/ Appointments</mat-option>
              <mat-option value="withFreeTime">W/ Free Time</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <li
        *ngIf="
          showFilterList.includes('MultiselectProvider') &&
          userRole !== userTypeEnum.Provider.toString()
        "
      >
        <div class="form-group">
          <mat-form-field class="example-form-field">
            <mat-label>{{
              "appointment.medicalProviderText" | translate
            }}</mat-label>
            <mat-select
              multiple
              placeholder="{{
                'scheduler.selectProviderPlaceholder' | translate
              }}"
              [(ngModel)]="filterMultiselectProviderValue"
              (selectionChange)="changeMultiselectProvider()"
            >
              <mat-option
                *ngFor="let provider of providerList"
                [value]="provider.userId"
                >{{ provider.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </li>
    </ul>
  </div>
  <div class="filter-action">
    <button class="btn btn-secondary" (click)="applyFilter()">
      {{ "userList.applyButtonText" | translate }}
    </button>
    <button class="btn btn-primary ms-3" (click)="clearFilter()">
      {{ "userList.clearButtonText" | translate }}
    </button>
  </div>
</div>

<form [formGroup]="signUpValidation" (ngSubmit)="submitSignUp()">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <mat-form-field class="example-full-width">
          <span matPrefix><img src="assets/images/clinic.svg" alt="" /></span>
          <mat-label>{{
            "signup.clinicNamePlaceholder" | translate
          }}</mat-label>
          <input
            type="text"
            matInput
            placeholder="{{ 'signup.clinicNamePlaceholder' | translate }}"
            formControlName="clinicName"
            [(ngModel)]="signUpModel.clinicName"
          />
        </mat-form-field>
        <div
          *ngIf="
            signUpValidation.controls['clinicName'].invalid &&
            (signUpValidation.controls['clinicName'].dirty ||
              signUpValidation.controls['clinicName'].touched ||
              formSubmitted)
          "
        >
          <mat-error
            *ngIf="signUpValidation.get('clinicName')?.hasError('required')"
            >{{ "signup.requiredClinicNameError" | translate }}</mat-error
          >
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-5">
          <div class="form-group">
            <mat-form-field>
              <span matPrefix style="width: 30px; display: inline-block"
                ><img src="assets/images/phone.svg" alt=""
              /></span>
              <mat-label>{{
                "signup.phoneCodePlaceholder" | translate
              }}</mat-label>
              <mat-select
                placeholder="{{ 'signup.phoneCodePlaceholder' | translate }}"
                [(value)]="codeSelect"
                formControlName="phoneCode"
                [(ngModel)]="signUpModel.phoneCode"
              >
                <mat-option
                  *ngFor="let code of phoneCodeList"
                  [value]="code.phoneCode"
                  >{{ code.phoneCode }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="
                signUpValidation.controls['phoneCode'].invalid &&
                (signUpValidation.controls['phoneCode'].dirty ||
                  signUpValidation.controls['phoneCode'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="signUpValidation.get('phoneCode')?.hasError('required')"
                >{{ "signup.requiredPhoneCodeError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="form-group">
            <mat-form-field class="example-full-width">
              <mat-label>{{
                "signup.phoneNoPlaceholder" | translate
              }}</mat-label>
              <input
                type="text"
                matInput
                placeholder="{{ 'signup.phoneNoPlaceholder' | translate }}"
                formControlName="phoneNo"
                [(ngModel)]="signUpModel.phoneNo"
                (keypress)="_globalFunction.allowNumbersOnly($event)"
              />
            </mat-form-field>
            <div
              *ngIf="
                signUpValidation.controls['phoneNo'].invalid &&
                (signUpValidation.controls['phoneNo'].dirty ||
                  signUpValidation.controls['phoneNo'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="signUpValidation.get('phoneNo')?.hasError('required')"
                >{{ "signup.requiredPhoneError" | translate }}</mat-error
              >
              <mat-error
                *ngIf="signUpValidation.get('phoneNo')?.hasError('minlength')"
                >{{ "signup.minLengthPhoneError" | translate }}</mat-error
              >
              <mat-error
                *ngIf="signUpValidation.get('phoneNo')?.hasError('maxlength')"
                >{{ "signup.maxLengthPhoneError" | translate }}</mat-error
              >
              <mat-error
                *ngIf="signUpValidation.get('phoneNo')?.hasError('pattern')"
                >{{ "signup.patternPhoneError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <mat-form-field class="document-input">
          <span matPrefix><img src="assets/images/document.svg" alt="" /></span>
          <mat-label>{{
            "signup.uploadDocumentPlaceholder" | translate
          }}</mat-label>
          <input
            type="text"
            matInput
            placeholder="{{ 'signup.uploadDocumentPlaceholder' | translate }}"
            formControlName="clinicDocument"
            [(ngModel)]="clinicDocumentList"
            readonly
          />
          <span matSuffix class="upload-btn">
            <input type="file" (change)="fileChange($event)" multiple />
          </span>
        </mat-form-field>
        <div
          *ngIf="
            signUpValidation.controls['clinicDocument'].invalid &&
            (signUpValidation.controls['clinicDocument'].dirty ||
              signUpValidation.controls['clinicDocument'].touched ||
              formSubmitted)
          "
        >
          <mat-error
            *ngIf="signUpValidation.get('clinicDocument')?.hasError('required')"
            >{{ "signup.requiredDocumentError" | translate }}</mat-error
          >
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group position-relative">
        <mat-form-field class="example-full-width">
          <span matPrefix
            ><img src="assets/images/user-password.svg" alt=""
          /></span>
          <mat-label>{{ "common.passwordPlaceholder" | translate }}</mat-label>
          <input
            [type]="showPassword ? 'text' : 'password'"
            matInput
            placeholder="{{ 'common.passwordPlaceholder' | translate }}"
            formControlName="password"
            [(ngModel)]="signUpModel.password"
            (input)="onPasswordChange($event)"
            #password
          />
          <span matSuffix
            ><mat-icon (click)="togglePasswordVisibility()">{{
              !showPassword ? "visibility_off" : "visibility"
            }}</mat-icon></span
          >
        </mat-form-field>
        <div
          *ngIf="
            signUpValidation.controls['password'].invalid &&
            (signUpValidation.controls['password'].dirty ||
              signUpValidation.controls['password'].touched ||
              formSubmitted)
          "
        >
          <mat-error
            *ngIf="signUpValidation.get('password')?.hasError('required')"
            >{{ "common.requiredPasswordError" | translate }}</mat-error
          >
        </div>
        <div
          class="password-strength"
          *ngIf="password.value && showPasswordStrength"
        >
          <mat-password-strength
            #passwordComponent
            [password]="password.value"
            (onStrengthChanged)="onPasswordStrengthChanged($event)"
          >
          </mat-password-strength>
          <mat-password-strength-info
            #passwordComponent
            lowerCaseCriteriaMsg="{{
              'common.passwordLowercaseError' | translate
            }}"
            upperCaseCriteriaMsg="{{
              'common.passwordUppercaseError' | translate
            }}"
            digitsCriteriaMsg="{{ 'common.passwordDigitError' | translate }}"
            specialCharsCriteriaMsg="{{
              'common.passwordSpecialCharError' | translate
            }}"
            minCharsCriteriaMsg="{{ 'common.passwordLengthError' | translate }}"
            [passwordComponent]="passwordComponent"
          >
          </mat-password-strength-info>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <mat-form-field class="example-full-width">
          <span matPrefix
            ><img src="assets/images/user-password.svg" alt=""
          /></span>
          <mat-label>{{
            "common.confirmPasswordPlaceholder" | translate
          }}</mat-label>
          <input
            [type]="showConfirmPassword ? 'text' : 'password'"
            matInput
            placeholder="{{ 'common.confirmPasswordPlaceholder' | translate }}"
            formControlName="confirmPassword"
            (input)="onConfirmPasswordChanged()"
          />
          <span matSuffix
            ><mat-icon (click)="toggleConfirmPasswordVisibility()">{{
              !showConfirmPassword ? "visibility_off" : "visibility"
            }}</mat-icon></span
          >
        </mat-form-field>
        <div
          *ngIf="
            signUpValidation.controls['confirmPassword'].invalid &&
            (signUpValidation.controls['confirmPassword'].dirty ||
              signUpValidation.controls['confirmPassword'].touched ||
              formSubmitted)
          "
        >
          <mat-error
            *ngIf="
              signUpValidation.get('confirmPassword')?.hasError('required')
            "
            >{{ "common.requiredConfirmPasswordError" | translate }}</mat-error
          >
          <mat-error
            *ngIf="
              signUpValidation.get('confirmPassword')?.hasError('NotEqual') &&
              !signUpValidation.get('confirmPassword')?.hasError('required')
            "
            >{{
              "common.passwordConfirmPasswordNotMatchError" | translate
            }}</mat-error
          >
        </div>
      </div>
    </div>
    <div class="remember-me-text">
      <div class="remember-left">
        <mat-checkbox
          formControlName="isTermsAndCondition"
          [(ngModel)]="isTermsAndCondition"
          >{{ "signup.termsAndConditionAgreeMessage" | translate }}
        </mat-checkbox>
        <div class="forgot-password">
          <a href="javascript:void(0)" (click)="openStaticContentPopup()">{{
            "signup.termsAndConditionLinkText" | translate
          }}</a>
        </div>
      </div>
    </div>

    <div class="login-btn">
      <button
        class="btn btn-primary"
        [disabled]="!signUpValidation.valid || !isValidPassword"
      >
        {{ "signup.signUpButtonText" | translate }}
      </button>
    </div>
  </div>
</form>

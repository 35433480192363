import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, throwError } from "rxjs";
import { superAdminApiList } from "src/app/enums/superAdminApiEnum";
import { CommonService } from "src/app/services/common.service";
import { RestAPIService } from "src/app/services/restapi.service";
import { environment } from "src/environments/environment";
import { ClinicModel } from "../models/clinicModel";
import { OfficeStaffModel } from "../models/officeStaffModel";
import { StaticContentModel } from "../models/staticContentModel";

@Injectable({
  providedIn: "root",
})
export class SuperAdminService {
  constructor(
    private _restAPIService: RestAPIService,
    private _commonService: CommonService
  ) {}

  /**
   * Method to login super admin user
   */
  public login(email: string, password: string): Observable<any> {
    let userParam = {
      email: email,
      password: password,
    };

    return this._restAPIService
      .post<any>(environment.apiURL + superAdminApiList.login, userParam)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method for forgot password
   */
  public forgotPassword(email: string): Observable<any> {
    let forgotPasswordParam = {
      email: email,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.forgotPassword,
        forgotPasswordParam
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(() => {
            return error;
          });
        })
      );
  }

  /**
   * Method to reset password
   */
  public resetPassword(
    email: any,
    password: string,
    userId: string
  ): Observable<any> {
    let resetPasswordParam = {
      email: email,
      password: password,
      userid: userId,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.resetPassword,
        resetPasswordParam
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(() => {
            return error;
          });
        })
      );
  }

  /**
   * Method to get all clinics
   */
  public getAllClinic(getClinicParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    let clinicParam = {
      cityId: getClinicParam.cityId,
      status: getClinicParam.status,
      pageIndex: getClinicParam.pageIndex,
      pageSize: getClinicParam.pageSize,
      sortColumnName: getClinicParam.sortColumnName,
      sortDirection: getClinicParam.sortDirection,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.getallClinic,
        clinicParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all clinic registration request data
   */
  public getAllClinicRegistration(getClinicRequestParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    let clinicRequestParam = {
      updatedBy: getClinicRequestParam.updatedBy,
      status: getClinicRequestParam.status,
      pageIndex: getClinicRequestParam.pageIndex,
      pageSize: getClinicRequestParam.pageSize,
      sortColumnName: getClinicRequestParam.sortColumnName,
      sortDirection: getClinicRequestParam.sortDirection,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.getallClinicRegistration,
        clinicRequestParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to add/update clinic
   */
  public addClinic(clinicParam: ClinicModel, apiPath: string): Observable<any> {
    return this._restAPIService
      .post<any>(environment.apiURL + apiPath, clinicParam)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get clinic data by id
   */
  public getClinicDataById(userId: any, apiPath: string): Observable<any> {
    return this._restAPIService
      .get<any>(environment.apiURL + apiPath + "?UserId=" + userId)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get clinic request data by id
   */
  public getClinicRequestDataById(
    userId: any,
    apiPath: string
  ): Observable<any> {
    return this._restAPIService
      .get<any>(environment.apiURL + apiPath + "?UserId=" + userId)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all office staff
   */
  public getAllOfficeStaff(officeStaffParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.getAllOfficeStaff,
        officeStaffParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all admin office staff
   */
  public getAllAdminOfficeStaff(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(
        environment.apiURL + superAdminApiList.getAllAdminOfficeStaff,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to update clinic registration request
   */
  public updateClinicRegistration(clinicParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.updateClinicRegistration,
        clinicParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all subscription plan
   */
  public getAllSubscriptionPlan(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(
        environment.apiURL + superAdminApiList.getAllSubscriptionPlan,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to add subscription plan
   */
  public addSubscriptionPlan(planParams: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.addSubscriptionPlan,
        planParams,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to update subscription plan
   */
  public updateSubscriptionPlan(planParams: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.updateSubscriptionPlan,
        planParams,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to delete subscription plan
   */
  public deleteSubscriptionPlan(Id: any): Observable<any> {
    let deletePlanParam = {
      subscriptionPlanId: Id,
    };
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.deleteSubscriptionPlan,
        deletePlanParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get settings
   */
  public getSettings(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.getICareVIPSettings,
        null,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to save settings
   */
  public saveSettings(settingsParams: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.updateICareVIPSettings,
        settingsParams,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to add office staff
   */
  public addOfficeStaff(
    officeStaffParam: OfficeStaffModel,
    apiPath: string
  ): Observable<any> {
    return this._restAPIService
      .post<any>(environment.apiURL + apiPath, officeStaffParam)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get office staff data by id
   */
  public getOfficeStaffDataById(Id: any, apiPath: string): Observable<any> {
    return this._restAPIService
      .get<any>(environment.apiURL + apiPath + "?UserId=" + Id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get transaction history for admin
   */
  public getAdminTransactionHistory(transactionParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.getAdminTransactionHistory,
        transactionParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all clinic details
   */
  public getAllClinicDetails(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(
        environment.apiURL + superAdminApiList.getAllClinicDetails,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all patient by clinic
   */
  public getAllPatientByClinic(clinicUserId: string): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(
        environment.apiURL +
          superAdminApiList.getAllPatientByClinic +
          "?ClinicUserId=" +
          clinicUserId,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all static content
   */
  public getAllStaticContent(staticContentParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.getallStaticContent,
        staticContentParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to add static content data
   */
  public addStaticContentScheduler(
    staticContentParam: StaticContentModel,
    apiPath: string
  ): Observable<any> {
    return this._restAPIService
      .post<any>(environment.apiURL + apiPath, staticContentParam)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to delete static content
   */
  public deleteStaticContent(Id: any): Observable<any> {
    let deleteStaticContentParam = {
      id: Id,
    };
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.deleteStaticContent,
        deleteStaticContentParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all clinic subscription plan by clinic id
   */
  public getAllClinicSubscriptionPlan(ClinicUserId: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(
        environment.apiURL +
          superAdminApiList.getAllClinicSubscriptionPlan +
          "?ClinicUserId=" +
          ClinicUserId,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to add refund transaction
   */
  public addRefundTransaction(refundParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + superAdminApiList.addRefundTransaction,
        refundParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }
}

<div class="drawer-body">
  <div class="drawer-title">
    <img src="assets/images/view-icon.svg" alt="" />
    {{ "common.viewText" | translate }} {{ "common.userText" | translate }}
  </div>
  <div class="drawer-content">
    <ul class="view-user">
      <li>
        <span>{{ "viewUser.emailAddressLabelText" | translate }}</span>
        <p>{{ viewOfficeStaffData.email }}</p>
      </li>
      <li>
        <span>{{ "viewUser.nameLabelText" | translate }}</span>
        <p>{{ viewOfficeStaffData.name }}</p>
      </li>
      <li>
        <span>{{ "viewUser.contactNumberLabelText" | translate }}</span>
        <p>{{ viewOfficeStaffData.contact }}</p>
      </li>
      <li>
        <span>{{ "manageOfficeStaffUsers.roleLabelText" | translate }}</span>
        <p>
          {{
            viewOfficeStaffData.role === 5
              ? "Admin"
              : viewOfficeStaffData.role === 6
              ? "Office Staff"
              : ""
          }}
        </p>
      </li>
      <li>
        <span>{{ "viewUser.statusLabelText" | translate }}</span>
        <p>
          {{
            (viewOfficeStaffData.status === true
              ? "common.Active"
              : "common.Inactive"
            ) | translate
          }}
        </p>
      </li>
    </ul>
    <div class="drawer-action mt-4 pt-2">
      <button class="btn btn-secondary" (click)="closeViewOfficeStaffPopup()">
        <img src="assets/images/white-back-icon.svg" alt="" class="me-2" />
        {{ "viewUser.backButtonText" | translate }}
      </button>
    </div>
  </div>
</div>

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { AuthGuardService } from "./auth-guard.service";

@Injectable({
  providedIn: "root",
})
export class RestAPIService {
  constructor(
    private http: HttpClient,
    private _authService: AuthGuardService
  ) {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  get<T>(url: any, headers: any = null): Observable<T> {
    if (headers !== null && headers.length > 0) {
      headers.forEach((element: any) => {
        this.httpOptions.headers = this.httpOptions.headers.delete(element.key);
        this.httpOptions.headers = this.httpOptions.headers.append(
          element.key,
          element.value
        );
      });
    }
    return this.http
      .get<T>(url, this.httpOptions)
      .pipe(retry(0), catchError(this.handleError));
  }

  post<T>(url: any, body: any, headers: any = null): Observable<T> {
    if (headers !== null && headers.length > 0) {
      headers.forEach((element: any) => {
        this.httpOptions.headers = this.httpOptions.headers.delete(element.key);
        this.httpOptions.headers = this.httpOptions.headers.append(
          element.key,
          element.value
        );
      });
    }
    return this.http
      .post<T>(url, JSON.stringify(body), this.httpOptions)
      .pipe(retry(0), catchError(this.handleError));
  }

  put<T>(url: any, body: any, headers: any = null): Observable<T> {
    if (headers !== null && headers.length > 0) {
      headers.forEach((element: any) => {
        this.httpOptions.headers = this.httpOptions.headers.delete(element.key);
        this.httpOptions.headers = this.httpOptions.headers.append(
          element.key,
          element.value
        );
      });
    }
    return this.http
      .put<T>(url, JSON.stringify(body), this.httpOptions)
      .pipe(retry(0), catchError(this.handleError));
  }

  delete<T>(url: any, body: string, headers: any = null): Observable<T> {
    if (headers !== null && headers.length > 0) {
      headers.forEach((element: any) => {
        this.httpOptions.headers = this.httpOptions.headers.delete(element.key);
        this.httpOptions.headers = this.httpOptions.headers.append(
          element.key,
          element.value
        );
      });
    }
    return this.http
      .delete<T>(url, this.httpOptions)
      .pipe(retry(0), catchError(this.handleError));
  }

  // Error handling
  handleError(error: any) {
    return throwError(() => {
      return error;
    });
  }
}

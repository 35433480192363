<div class="drawer-body">
  <div class="drawer-title">
    <img src="assets/images/add-icon.svg" alt="" />
    {{ addEditOfficeStaffText | translate }}
    {{ "common.userText" | translate }}
  </div>
  <div class="drawer-content">
    <form
      [formGroup]="officeStaffInfoValidation"
      (ngSubmit)="submitOfficeStaffInfo()"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "viewUser.emailAddressLabelText" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                [ngClass]="{ 'field-disable': editOfficeStaffId !== '' }"
                formControlName="email"
                [(ngModel)]="officeStaffInfoModel.email"
                placeholder="{{ 'login.emailPlaceholder' | translate }}"
                required
                [readonly]="editOfficeStaffId !== ''"
              />
              <span matPrefix
                ><img src="assets/images/email.svg" alt=""
              /></span>
            </mat-form-field>
            <div
              *ngIf="
                officeStaffInfoValidation.controls['email'].invalid &&
                (officeStaffInfoValidation.controls['email'].dirty ||
                  officeStaffInfoValidation.controls['email'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  officeStaffInfoValidation.get('email')?.hasError('required')
                "
                >{{ "common.requiredEmailError" | translate }}</mat-error
              >
              <mat-error
                *ngIf="
                  officeStaffInfoValidation.get('email')?.hasError('pattern')
                "
                >{{ "common.invalidEmailError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "addUser.firstNameLabelText" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="firstName"
                [(ngModel)]="officeStaffInfoModel.firstName"
                placeholder="{{ 'addUser.firstNamePlaceholder' | translate }}"
                required
              />
              <span matPrefix
                ><img src="assets/images/user-icon.svg" alt=""
              /></span>
            </mat-form-field>
            <div
              *ngIf="
                officeStaffInfoValidation.controls['firstName'].invalid &&
                (officeStaffInfoValidation.controls['firstName'].dirty ||
                  officeStaffInfoValidation.controls['firstName'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  officeStaffInfoValidation
                    .get('firstName')
                    ?.hasError('required')
                "
                >{{ "addUser.requiredFirstNameError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "addUser.lastNameLabelText" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="lastName"
                [(ngModel)]="officeStaffInfoModel.lastName"
                placeholder="{{ 'addUser.lastNamePlaceholder' | translate }}"
                required
              />
              <span matPrefix
                ><img src="assets/images/user-icon.svg" alt=""
              /></span>
            </mat-form-field>
            <div
              *ngIf="
                officeStaffInfoValidation.controls['lastName'].invalid &&
                (officeStaffInfoValidation.controls['lastName'].dirty ||
                  officeStaffInfoValidation.controls['lastName'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  officeStaffInfoValidation
                    .get('lastName')
                    ?.hasError('required')
                "
                >{{ "addUser.requiredLastNameError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-3">
                <mat-form-field>
                  <mat-label>{{
                    "signup.phoneCodePlaceholder" | translate
                  }}</mat-label>
                  <span matPrefix style="width: 20px; display: inline-block"
                    ><img src="assets/images/phone.svg" alt=""
                  /></span>
                  <mat-select
                    placeholder="{{
                      'signup.phoneCodePlaceholder' | translate
                    }}"
                    [(value)]="codeSelect"
                    formControlName="phoneCode"
                    [(ngModel)]="officeStaffInfoModel.phoneCode"
                  >
                    <mat-option
                      *ngFor="let code of phoneCodeList"
                      [value]="code.phoneCode"
                      >{{ code.phoneCode }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="
                    officeStaffInfoValidation.controls['phoneCode'].invalid &&
                    (officeStaffInfoValidation.controls['phoneCode'].dirty ||
                      officeStaffInfoValidation.controls['phoneCode'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      officeStaffInfoValidation
                        .get('phoneCode')
                        ?.hasError('required')
                    "
                    >{{
                      "signup.requiredPhoneCodeError" | translate
                    }}</mat-error
                  >
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group mb-3">
                <mat-form-field class="example-form-field">
                  <mat-label>{{
                    "userList.contactLabelText" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="phoneNumber"
                    [(ngModel)]="officeStaffInfoModel.phoneNumber"
                    placeholder="{{
                      'viewUser.contactNumberLabelText' | translate
                    }}"
                    (keypress)="_globalFunction.allowNumbersOnly($event)"
                    required
                  />
                </mat-form-field>
                <div
                  *ngIf="
                    officeStaffInfoValidation.controls['phoneNumber'].invalid &&
                    (officeStaffInfoValidation.controls['phoneNumber'].dirty ||
                      officeStaffInfoValidation.controls['phoneNumber']
                        .touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      officeStaffInfoValidation
                        .get('phoneNumber')
                        ?.hasError('required')
                    "
                    >{{ "signup.requiredPhoneError" | translate }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      officeStaffInfoValidation
                        .get('phoneNumber')
                        ?.hasError('minlength')
                    "
                    >{{ "signup.minLengthPhoneError" | translate }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      officeStaffInfoValidation
                        .get('phoneNumber')
                        ?.hasError('maxlength')
                    "
                    >{{ "signup.maxLengthPhoneError" | translate }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      officeStaffInfoValidation
                        .get('phoneNumber')
                        ?.hasError('pattern')
                    "
                    >{{ "signup.patternPhoneError" | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field>
              <mat-label>{{
                "manageOfficeStaffUsers.roleLabelText" | translate
              }}</mat-label>
              <span matPrefix style="width: 20px; display: inline-block"
                ><img src="assets/images/user-icon.svg" alt=""
              /></span>
              <mat-select
                placeholder="{{
                  'manageOfficeStaffUsers.rolePlaceholder' | translate
                }}"
                formControlName="role"
                [(ngModel)]="officeStaffInfoModel.role"
              >
                <mat-option value="5">Admin</mat-option>
                <mat-option value="6">Office Staff</mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="
                officeStaffInfoValidation.controls['role'].invalid &&
                (officeStaffInfoValidation.controls['role'].dirty ||
                  officeStaffInfoValidation.controls['role'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  officeStaffInfoValidation.get('role')?.hasError('required')
                "
                >{{
                  "manageOfficeStaffUsers.requiredRoleError" | translate
                }}</mat-error
              >
            </div>
          </div>
        </div>
      </div>
      <div class="drawer-action">
        <button type="submit" class="btn btn-secondary">
          <img src="assets/images/save-icon.svg" alt="" class="me-2" />
          {{ "addUser.saveButtonText" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary ms-3"
          (click)="closeOfficeStaffPopup()"
        >
          <img src="assets/images/white-close-icon.svg" alt="" class="me-2" />
          {{ "addUser.cancelButtonText" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { StaticContentDialogComponent } from "../components/static-content-dialog/static-content-dialog.component";
import { clinicApiList } from "../enums/clinicApiEnum";
import { generalApiList } from "../enums/generalApiEnum";
import { UserTypeEnum } from "../enums/userTypeEnum";
import { videoApiList } from "../enums/videoApiEnum";
import { SignUpModel } from "../models/signUpModel";
import { TokenModel } from "../models/tokenModel";
import { AuthGuardService } from "./auth-guard.service";
import { RestAPIService } from "./restapi.service";
import { ToastrNotificationService } from "./toastr-notification.service";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(
    public _restAPIService: RestAPIService,
    private _toastrService: ToastrNotificationService,
    private _authService: AuthGuardService,
    private _router: Router,
    public _translate: TranslateService,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) {}

  /**
   * Method to login user
   */
  public login(email: string, password: string): Observable<any> {
    let userParam = {
      email: email,
      password: password,
    };

    return this._restAPIService
      .post<any>(environment.apiURL + clinicApiList.login, userParam)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to logout the user
   */
  public logout(): void {
    localStorage.removeItem("userTokenDetails");
  }

  /**
   * Method to get new token
   */
  public refreshToken(
    accessToken: string,
    refreshToken: string
  ): Observable<any> {
    let tokenParam = {
      accessToken: accessToken,
      refreshToken: refreshToken,
    };

    return this._restAPIService
      .post<any>(environment.apiURL + clinicApiList.refreshToken, tokenParam)
      .pipe(
        map((data: any) => {
          if (data && data.accessToken && data.expiresOn && data.refreshToken) {
            const userTokenDetails: TokenModel = {
              access_token: data.accessToken,
              expiry_date: data.expiresOn,
              refresh_token: data.refreshToken,
            };
            localStorage.setItem(
              "userTokenDetails",
              JSON.stringify(userTokenDetails)
            );
          }
        }),
        catchError((error) => {
          return throwError(() => {
            return error;
          });
        })
      );
  }

  /**
   * Method for forgot password
   */
  public forgotPassword(email: string): Observable<any> {
    let forgotPasswordParam = {
      email: email,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.forgotPassword,
        forgotPasswordParam
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(() => {
            return error;
          });
        })
      );
  }

  /**
   * Method to reset password
   */
  public resetPassword(
    email: any,
    password: string,
    userId: string
  ): Observable<any> {
    let resetPasswordParam = {
      email: email,
      password: password,
      userid: userId,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.resetPassword,
        resetPasswordParam
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(() => {
            return error;
          });
        })
      );
  }

  /**
   * Method to signup email
   */
  public signupEmail(email: string, password: string): Observable<any> {
    let userParam = {
      email: email,
      password: password,
    };

    return this._restAPIService
      .post<any>(environment.apiURL + clinicApiList.signupVerify, userParam)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to signup user
   */
  public signUp(signUpdata: SignUpModel): Observable<any> {
    let signUpParam = {
      userId: signUpdata.userId,
      email: signUpdata.email,
      clinicName: signUpdata.clinicName,
      phoneCode: signUpdata.phoneCode,
      phoneNo: signUpdata.phoneNo,
      password: signUpdata.password,
      documents: signUpdata.documents,
    };

    return this._restAPIService
      .post<any>(environment.apiURL + clinicApiList.signupComplete, signUpParam)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get country
   */
  public getCountry(): Observable<any> {
    return this._restAPIService
      .get<any>(environment.apiURL + generalApiList.country)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get state
   */
  public getState(countryId: number): Observable<any> {
    return this._restAPIService
      .get<any>(
        environment.apiURL + generalApiList.state + "?countryId=" + countryId
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get city
   */
  public getCity(stateId: number): Observable<any> {
    return this._restAPIService
      .get<any>(
        environment.apiURL + generalApiList.city + "?stateId=" + stateId
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Function used to get common headers
   */
  public getHeaders() {
    let headers = [];
    let tokenData = this._authService.getTokenDetails();
    if (tokenData && tokenData !== null) {
      if (this._authService.checkTokenExpired(tokenData)) {
        this.refreshToken(
          tokenData.access_token,
          tokenData.refresh_token
        ).subscribe((data) => {
          tokenData = this._authService.getTokenDetails();
          headers.push({
            key: "Authorization",
            value: "Bearer " + tokenData.access_token,
          });
        });
      } else {
        headers.push({
          key: "Authorization",
          value: "Bearer " + tokenData.access_token,
        });
      }
    }
    return headers;
  }

  /**
   * Method to get speciality
   */
  public getSpeciality(): Observable<any> {
    let headers = this.getHeaders();
    return this._restAPIService
      .get<any>(environment.apiURL + clinicApiList.specialties, headers)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get languages
   */
  public getLanguages(): Observable<any> {
    let headers = this.getHeaders();
    return this._restAPIService
      .get<any>(environment.apiURL + generalApiList.languages, headers)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to show error messages
   */
  public showErrorMessage(error: any) {
    let errorcode = "";
    if (
      error &&
      error.error &&
      (error.error.ErrorCode || error.error.errorCode) &&
      (error.error.ErrorCode !== null || error.error.errorCode !== null)
    ) {
      errorcode = error.error.ErrorCode || error.error.errorCode;
    }

    if (errorcode && errorcode != "") {
      switch (errorcode) {
        case "InvalidCredentials":
        case "AccountIsInActive":
        case "NoDataFound":
        case "EmailExist":
        case "EmailFormatInvalid":
        case "MinimumLength":
        case "WeekPassword":
        case "RecordExists":
        case "PlanExpired":
        case "PlanGoingToExpired":
        case "AdvancedBookingLimitExceeded":
        case "RescheduleBeforeLimitExceeded":
        case "CancelBeforeLimitExceeded":
        case "PastDateNotModified":
        case "AppointmentBooked":
        case "InvalidData":
        case "RequiredField":
        case "LengthExceeded":
        case "DateFormatInvalid":
        case "TimeSpanInvalid":
        case "VisitTypeNotExist":
        case "ProviderSchedularNotExist":
        case "InvalidDiscountOrPrice":
        case "AppointmentNotExist":
        case "PlanNotExist":
        case "ClinicSettingsNotExist":
        case "PatientNotExist":
        case "ProviderNotExist":
        case "StripeError":
        case "StripePaymentLinkCreateError":
        case "RefundSuccess":
        case "RefundError":
        case "StripePaymentIntentIdNotFound":
        case "StripePaymentIntentNotFound":
        case "StripeAccountIdNotFound":
        case "StripeAccountNotFound":
        case "CanNotChangeProvider":
        case "EmailNotExist":
        case "RecordAlreadyUsed":
        case "RefundAmountInvalid":
        case "RefundAlreadyExist":
        case "PaymentAccountIdNotFound":
        case "DuplicatePaymentId":
        case "DuplicateRefundId":
        case "AppointmentAlreadyExist":
        case "AppointmentStatusInvalid":
        case "ClinicCodeAlreadyExist":
        case "StripeAccountRestricted":
        case "VisitTypeSlotsCreated": {
          this._toastrService.showError("ErrorCode." + error.error.ErrorCode);
          break;
        }
        case "RecordAlreadyUsed": {
          this._toastrService.showError("ErrorCode.RecordExists");
          break;
        }
        case "AvailabilityInvalidData": {
          this._toastrService.showError("ErrorCode.AvailabilityInvalidData");
          break;
        }
        case "Unauthorized": {
          this.logout();
          location.reload();
          break;
        }
        default: {
          this._toastrService.showError("ErrorCode.GenericError");
          break;
        }
      }
    } else {
      this._toastrService.showError("ErrorCode.GenericError");
    }
  }

  /**
   * Method to redirect to landing page according to user role
   */
  public redirectToLandingPage(userTypeId: string) {
    switch (userTypeId) {
      case UserTypeEnum.Clinic.toString(): {
        this._router.navigate(["/dashboard"]);
        break;
      }
      case UserTypeEnum.ClinicStaff.toString(): {
        this._router.navigate(["/dashboard"]);
        break;
      }
      case UserTypeEnum.Provider.toString(): {
        this._router.navigate(["/dashboard"]);
        break;
      }
      case UserTypeEnum.SuperAdmin.toString(): {
        this._router.navigate(["/manage-clinic"]);
        break;
      }
      case UserTypeEnum.OfficeStaff.toString(): {
        this._router.navigate(["/manage-clinic"]);
        break;
      }
      default: {
        this._router.navigate(["/"]);
        break;
      }
    }

    //Set current language
    this.setCurrentLanguage();
  }

  /**
   * Method to redirect to login page according to user role
   */
  public redirectToLoginPageByUserRole(userTypeId: string) {
    switch (userTypeId) {
      case UserTypeEnum.Clinic.toString(): {
        this._router.navigate(["/login"]);
        break;
      }
      case UserTypeEnum.ClinicStaff.toString(): {
        this._router.navigate(["/login/staff"]);
        break;
      }
      case UserTypeEnum.Provider.toString(): {
        this._router.navigate(["/login/provider"]);
        break;
      }
      case UserTypeEnum.SuperAdmin.toString():
      case UserTypeEnum.OfficeStaff.toString(): {
        this._router.navigate(["/superadmin/login"]);
        break;
      }
      default: {
        this._router.navigate(["/login"]);
        break;
      }
    }
  }

  /**
   * Method to translate message
   */
  public getTranslatedMessage(message: string) {
    this._translate.get(message).subscribe((response: string) => {
      message = response;
    });
    return message;
  }

  /**
   * Method to get user data by id
   */
  public getTimezone(): Observable<any> {
    return this._restAPIService
      .get<any>(environment.apiURL + generalApiList.timezone)
      .pipe(
        map((response: any) => {
          if (!localStorage.getItem("timeZoneCommonList")) {
            localStorage.setItem(
              "timeZoneCommonList",
              JSON.stringify(response)
            );
          }
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get static content by page title id
   */
  public getStaticContentByPageTitleId(pageTitleId: any) {
    this.getStaticContentById(pageTitleId).subscribe({
      next: (contentData) => {
        if (contentData && contentData !== null) {
          const dialogRef = this.dialog.open(StaticContentDialogComponent, {
            data: {
              contentType: pageTitleId,
              pageTitle: contentData.pageTitle,
              staticContent: contentData.content,
              buttonText: {
                cancel: "common.closeText",
              },
            },
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {});
        } else {
          return this._toastrService.showError("ErrorCode.NoDataFound");
        }
      },
      error: (error) => {
        this.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get static content by page title id
   */
  public getStaticContentById(Id: any): Observable<any> {
    return this._restAPIService
      .get<any>(
        environment.apiURL + generalApiList.getStaticContentById + "?Id=" + Id
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get room by appointment id
   */
  public getRoomByAppointmentId(appointmentId: any): Observable<any> {
    let headers = this.getHeaders();
    return this._restAPIService
      .get<any>(
        environment.apiURL +
          videoApiList.getRoomByAppointmentId +
          "?AppointmentId=" +
          appointmentId,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to update appointment room
   */
  public updateAppointmentRoom(
    appointmentId: any,
    roomId: any
  ): Observable<any> {
    let headers = this.getHeaders();
    let roomParam = {
      appointmentId: appointmentId,
      roomId: roomId,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + videoApiList.updateAppointmentRoom,
        roomParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to delete appointment room
   */
  public deleteAppointmentRoom(appointmentId: any): Observable<any> {
    let headers = this.getHeaders();
    let roomParam = {
      appointmentId: appointmentId,
      roomId: "",
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + videoApiList.deleteAppointmentRoom,
        roomParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to set current language
   */
  public setCurrentLanguage() {
    let currentToken = this._authService.getTokenDetails();
    if (currentToken && currentToken.access_token) {
      let tokenDetails = this._authService.getDecodedAccessToken(
        currentToken.access_token
      );
      this.getLanguages().subscribe((languages) => {
        let language = languages.filter(
          (x: any) => x.languageId === parseInt(tokenDetails.languageid)
        );
        if (language && language.length > 0) {
          localStorage.setItem("Language", language[0].code);
          this._translate.use(language[0].code);
        } else {
          localStorage.setItem("Language", "en");
          this._translate.use("en");
        }
      });
    }
  }

  /**
   * Method to set current timezone
   */
  public setCurrentTimezone(timezoneList: any) {
    let currentToken = this._authService.getTokenDetails();
    if (currentToken && currentToken.access_token) {
      let tokenDetails = this._authService.getDecodedAccessToken(
        currentToken.access_token
      );
      let currentTimezone = timezoneList.filter(
        (i: any) => i.timezoneId === parseInt(tokenDetails.timezoneid)
      );
      return currentTimezone && currentTimezone.length > 0
        ? currentTimezone[0]
        : null;
    }
  }

  /**
   * Method to get time format
   */
  public getTimeFormat(isDashboard: boolean = false) {
    let isFormatTime = isDashboard ? "A" : "a";
    let currentToken = this._authService.getTokenDetails();
    if (currentToken && currentToken.access_token) {
      let tokenDetails = this._authService.getDecodedAccessToken(
        currentToken.access_token
      );
      if (tokenDetails?.timeformat == "True") return "HH:mm";
      else return "hh:mm " + isFormatTime;
    }
    return "hh:mm " + isFormatTime;
  }

  /**
   * Method to get timezone short name
   */
  public getTimeZoneShortName() {
    let currentToken = this._authService.getTokenDetails();
    let localTimeZoneShortName = moment.tz(moment.tz.guess()).zoneAbbr();
    if (currentToken && currentToken.access_token) {
      let tokenDetails = this._authService.getDecodedAccessToken(
        currentToken.access_token
      );
      if (tokenDetails?.timezoneshortname != "")
        return tokenDetails.timezoneshortname;
      else return localTimeZoneShortName;
    }
    return localTimeZoneShortName;
  }

  /**
   * Method to get timezone offset minutes
   */
  public getTimeZoneOffsetMinutes() {
    let currentToken = this._authService.getTokenDetails();
    let localTimeZoneOffsetMinutes = moment.tz(moment.tz.guess()).utcOffset();
    if (currentToken && currentToken.access_token) {
      let tokenDetails = this._authService.getDecodedAccessToken(
        currentToken.access_token
      );
      if (tokenDetails?.offsetminutes != "") return tokenDetails.offsetminutes;
      else return localTimeZoneOffsetMinutes;
    }
    return localTimeZoneOffsetMinutes;
  }

  /**
   * Method to get format time in day dashboard
   */
  formatTime(time: any) {
    var currentDate = this.datePipe.transform(new Date(), "MM/dd/yyyy " + time);
    return moment(currentDate).format(this.getTimeFormat(true));
  }

  /**
   * Method to check appointment past or not
   */
  public isAppointmentPastDate(appointmentDateTime: any) {
    let isPast = true;
    let appointmentDate: any = this.datePipe.transform(
      appointmentDateTime,
      "MM/dd/yyyy"
    );

    let currentUtcDate = moment(new Date())
      .utc()
      .add(this.getTimeZoneOffsetMinutes(), "minutes")
      .format()
      .replace("Z", "");

    let todayDate: any = this.datePipe.transform(currentUtcDate, "MM/dd/yyyy");

    if (new Date(appointmentDate) > new Date(todayDate)) isPast = false;

    if (appointmentDate === todayDate) {
      let currentTime: any = this.datePipe.transform(currentUtcDate, "HH:mm");
      let appointmentTime: any = this.datePipe.transform(
        appointmentDateTime,
        "HH:mm"
      );
      isPast = appointmentTime < currentTime;
    }
    return isPast;
  }

  public getCurrentTimeZoneNewDate() {
    let currentTimeZoneDate = moment(new Date())
      .utc()
      .add(this.getTimeZoneOffsetMinutes(), "minutes")
      .format()
      .replace("Z", "");
    return new Date(currentTimeZoneDate);
  }

  /**
   * Method to get static content data by page title id
   */
  public getStaticContentDataByPageTitleId(pageTitleId: any): Observable<any> {
    return this._restAPIService
      .get<any>(
        environment.apiURL +
          generalApiList.getStaticContentById +
          "?Id=" +
          pageTitleId
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }
}

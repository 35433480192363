<div class="drawer-body">
  <div class="drawer-title">
    <img src="assets/images/add-icon.svg" alt="" />
    {{ addEditSchedulerText | translate }}
    {{ "common.schedulerText" | translate }}
  </div>
  <div class="drawer-content">
    <form
      [formGroup]="schedulerInfoValidation"
      (ngSubmit)="submitSchedulerInfo()"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field" *ngIf="userRole === '3'">
              <mat-label>{{
                "scheduler.providerLabelText" | translate
              }}</mat-label>
              <input matInput [value]="userName" required disabled />
            </mat-form-field>

            <mat-form-field class="example-form-field" *ngIf="userRole !== '3'">
              <mat-label>{{
                "scheduler.providerLabelText" | translate
              }}</mat-label>
              <span matPrefix style="width: 20px; display: inline-block"
                ><img src="assets/images/user-icon.svg" alt=""
              /></span>
              <mat-select
                placeholder="{{
                  'scheduler.selectProviderPlaceholder' | translate
                }}"
                [disabled]="true"
                formControlName="providerUserId"
                [(ngModel)]="schedulerInfoModel.providerUserId"
                (selectionChange)="changeProvider($event.value)"
              >
                <input
                  type="text"
                  #searchProviderInput
                  (keyup)="searchProvider($event)"
                  (keydown)="_globalFunction.handleInput($event)"
                  placeholder="{{
                    'appointment.searchProviderText' | translate
                  }}"
                  class="search-input"
                />
                <mat-option
                  *ngFor="let provider of filteredProviderList"
                  [value]="provider.userId"
                  >{{ provider.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="
                schedulerInfoValidation.controls['providerUserId'].invalid &&
                (schedulerInfoValidation.controls['providerUserId'].dirty ||
                  schedulerInfoValidation.controls['providerUserId'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  schedulerInfoValidation
                    .get('providerUserId')
                    ?.hasError('required')
                "
                >{{ "scheduler.requiredProviderError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mb-3">
            <!-- <mat-form-field class="example-form-field">
              <mat-label>{{
                "scheduler.startTimeLabelText" | translate
              }}</mat-label>
              <input
                matInput
                [ngxTimepicker]="startTime"
                placeholder="{{ 'scheduler.startTimeLabelText' | translate }}"
                formControlName="startTime"
                [(ngModel)]="schedulerInfoModel.startTime"
                [format]="24"
                (ngModelChange)="changeStartTime()"
                required
              />
              <ngx-material-timepicker-toggle
                matSuffix
                [for]="startTime"
              ></ngx-material-timepicker-toggle>
              <ngx-material-timepicker #startTime></ngx-material-timepicker>
            </mat-form-field> -->
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "scheduler.startTimeLabelText" | translate
              }}</mat-label>
              <mat-select
                placeholder="{{ 'scheduler.startTimeLabelText' | translate }}"
                formControlName="startTime"
                [(ngModel)]="schedulerInfoModel.startTime"
                (selectionChange)="changeStartTime()"
              >
                <mat-option
                  *ngFor="let time of startDayTimes"
                  [value]="_globalFunction.formatTime(time.time)"
                  >{{
                    _globalFunction.formatTimeTo12Hour(time.time)
                  }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="
                schedulerInfoValidation.controls['startTime'].invalid &&
                (schedulerInfoValidation.controls['startTime'].dirty ||
                  schedulerInfoValidation.controls['startTime'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  schedulerInfoValidation.get('startTime')?.hasError('required')
                "
                >{{ "scheduler.requiredStartTimeError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mb-3">
            <!-- <mat-form-field class="example-form-field">
              <mat-label>{{
                "scheduler.endTimeLabelText" | translate
              }}</mat-label>
              <input
                matInput
                [ngxTimepicker]="endTime"
                placeholder="{{ 'scheduler.endTimeLabelText' | translate }}"
                formControlName="endTime"
                [(ngModel)]="schedulerInfoModel.endTime"
                [format]="24"
                [min]="schedulerInfoModel.startTime"
                (ngModelChange)="changeEndTime()"
                required
                readonly
              />
              <ngx-material-timepicker-toggle
                matSuffix
                [for]="endTime"
              ></ngx-material-timepicker-toggle>
              <ngx-material-timepicker #endTime></ngx-material-timepicker>
            </mat-form-field> -->
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "scheduler.endTimeLabelText" | translate
              }}</mat-label>
              <mat-select
                placeholder="{{ 'scheduler.endTimeLabelText' | translate }}"
                formControlName="endTime"
                [(ngModel)]="schedulerInfoModel.endTime"
                (selectionChange)="changeEndTime()"
              >
                <mat-option
                  *ngFor="let time of endDayTimes"
                  [value]="_globalFunction.formatTime(time.time)"
                  >{{
                    _globalFunction.formatTimeTo12Hour(time.time)
                  }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="
                schedulerInfoValidation.controls['endTime'].invalid &&
                (schedulerInfoValidation.controls['endTime'].dirty ||
                  schedulerInfoValidation.controls['endTime'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  schedulerInfoValidation.get('endTime')?.hasError('required')
                "
                >{{ "scheduler.requiredEndTimeText" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-4" *ngIf="!editSchedulerId">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "scheduler.slotMinutesLabelText" | translate
              }}</mat-label>
              <mat-select
                placeholder="{{ 'scheduler.slotMinutesLabelText' | translate }}"
                formControlName="slotMinutes"
                [(ngModel)]="schedulerInfoModel.slotMinutes"
              >
                <mat-option
                  *ngFor="let minutes of slotMinutesArray"
                  [value]="minutes"
                  >{{ minutes }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="
                schedulerInfoValidation.controls['slotMinutes'].invalid &&
                (schedulerInfoValidation.controls['slotMinutes'].dirty ||
                  schedulerInfoValidation.controls['slotMinutes'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  schedulerInfoValidation
                    .get('slotMinutes')
                    ?.hasError('required')
                "
                >{{
                  "scheduler.requiredSlotMinutesText" | translate
                }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-4" *ngIf="editSchedulerId">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "scheduler.slotTimeLabelText" | translate
              }}</mat-label>
              <input
                matInput
                [value]="schedulerInfoModel.slotTime"
                readonly
                disabled
              />
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "settings.visitTypeLabelText" | translate
              }}</mat-label>
              <span matPrefix style="width: 20px; display: inline-block"
                ><img src="assets/images/visit-type-icon.svg" alt=""
              /></span>
              <mat-select
                placeholder="{{
                  'scheduler.selectVisitTypePlaceholder' | translate
                }}"
                formControlName="visitType"
                [(ngModel)]="schedulerInfoModel.visitType"
              >
                <mat-option
                  *ngFor="let visitType of visitTypeList"
                  [value]="visitType.clinicVisitTypeId"
                  >{{ visitType.visitType }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="
                schedulerInfoValidation.controls['visitType'].invalid &&
                (schedulerInfoValidation.controls['visitType'].dirty ||
                  schedulerInfoValidation.controls['visitType'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  schedulerInfoValidation.get('visitType')?.hasError('required')
                "
                >{{
                  "providerProfile.requiredVisitTypeError" | translate
                }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field
              appearance="fill"
              class="example-form-field"
              *ngIf="editSchedulerId === ''"
            >
              <mat-label>{{
                "scheduler.selectStartDateandEndDateLabelText" | translate
              }}</mat-label>
              <mat-date-range-input [rangePicker]="picker" [min]="todayDate">
                <input
                  matStartDate
                  placeholder="{{
                    'scheduler.startDatePlaceholder' | translate
                  }}"
                  formControlName="startDate"
                  [(ngModel)]="schedulerInfoModel.startDate"
                  required
                  readonly
                />
                <input
                  matEndDate
                  placeholder="{{ 'scheduler.endDatePlaceholder' | translate }}"
                  formControlName="endDate"
                  [(ngModel)]="schedulerInfoModel.endDate"
                  required
                  readonly
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <mat-form-field
              class="example-form-field"
              *ngIf="editSchedulerId !== ''"
            >
              <mat-label>{{
                "scheduler.selectDateLabelText" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                class="field-disable"
                formControlName="startDate"
                [(ngModel)]="schedulerInfoModel.startDate"
                placeholder="{{ 'scheduler.selectDateLabelText' | translate }}"
                [matDatepicker]="datePicker"
                [min]="todayDate"
                required
                readonly
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="datePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
              <span matPrefix
                ><img src="assets/images/calender-icon.svg" alt=""
              /></span>
            </mat-form-field>
            <div
              *ngIf="
                schedulerInfoValidation.controls['startDate'].invalid &&
                (schedulerInfoValidation.controls['startDate'].dirty ||
                  schedulerInfoValidation.controls['startDate'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  schedulerInfoValidation.get('startDate')?.hasError('required')
                "
                >{{ "scheduler.requiredStartDateError" | translate }}</mat-error
              >
            </div>
            <div
              *ngIf="
                schedulerInfoValidation.controls['endDate'].invalid &&
                (schedulerInfoValidation.controls['endDate'].dirty ||
                  schedulerInfoValidation.controls['endDate'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  schedulerInfoValidation.get('endDate')?.hasError('required')
                "
                >{{ "scheduler.requiredEndDateError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="editSchedulerId === ''">
          <div class="form-group mb-3">
            <mat-label>{{
              "scheduler.excludeWeekendsLabelText" | translate
            }}</mat-label>
            <mat-checkbox
              formControlName="excludeWeekend"
              [(ngModel)]="schedulerInfoModel.excludeWeekend"
            ></mat-checkbox>
          </div>
        </div>
        <div class="col-md-12" *ngIf="editSchedulerId">
          <div class="form-group mb-3">
            <mat-label>{{ "scheduler.blockLabelText" | translate }}</mat-label>
            <mat-checkbox
              formControlName="blockSlot"
              [(ngModel)]="schedulerInfoModel.blockSlot"
            ></mat-checkbox>
          </div>
        </div>
      </div>
      <div class="drawer-action">
        <button type="submit" class="btn btn-secondary">
          <img src="assets/images/save-icon.svg" alt="" class="me-2" />
          {{ "addUser.saveButtonText" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary ms-3"
          (click)="closeSchedulerPopup()"
        >
          <img src="assets/images/white-close-icon.svg" alt="" class="me-2" />
          {{ "addUser.cancelButtonText" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<form [formGroup]="signupEmailValidation" (ngSubmit)="submitSignUpEmail()">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <mat-form-field class="example-full-width">
          <span matPrefix><img src="assets/images/email.svg" alt="" /></span>
          <mat-label>{{ "common.emailPlaceholder" | translate }}</mat-label>
          <input
            type="email"
            matInput
            placeholder="{{ 'common.emailPlaceholder' | translate }}"
            formControlName="email"
            [(ngModel)]="loginModel.email"
          />
        </mat-form-field>
        <div
          *ngIf="
            signupEmailValidation.controls['email'].invalid &&
            (signupEmailValidation.controls['email'].dirty ||
              signupEmailValidation.controls['email'].touched ||
              formSubmitted)
          "
        >
          <mat-error
            *ngIf="signupEmailValidation.get('email')?.hasError('required')"
            >{{ "common.requiredEmailError" | translate }}</mat-error
          >
          <mat-error
            *ngIf="signupEmailValidation.get('email')?.hasError('pattern')"
            >{{ "common.invalidEmailError" | translate }}</mat-error
          >
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <mat-form-field>
          <span matPrefix style="width: 25px; display: inline-block"
            ><img src="assets/images/language.svg" alt=""
          /></span>
          <mat-label>{{ "common.selectLanguageLabel" | translate }}</mat-label>
          <mat-select
            placeholder="{{ 'common.language.English' | translate }}"
            [(value)]="languageSelect"
            (selectionChange)="changeLanguage($event.value)"
          >
            <mat-option
              *ngFor="let lang of languageList"
              [value]="lang.value"
              >{{lang.text}}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="login-btn">
      <button class="btn btn-primary">
        {{ "common.nextButtonText" | translate }}
      </button>
    </div>

    <div class="col-md-12">
      <div class="remember-me-text">
        <div class="signup-email">
          <span class="already-have-email-label"
            >{{ "signup.alreadyHaveAccountText" | translate }}
          </span>
          <a href="javascript:void(0);" (click)="signIn()">{{
            "signup.signInButtonText" | translate
          }}</a>
        </div>
      </div>
    </div>
  </div>
</form>

import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, throwError } from "rxjs";
import { clinicApiList } from "src/app/enums/clinicApiEnum";
import { CommonService } from "src/app/services/common.service";
import { RestAPIService } from "src/app/services/restapi.service";
import { environment } from "src/environments/environment";
import { ClinicProfileModel } from "../models/clinicProfileModel";
import { ProviderProfileModel } from "../models/providerProfileModel";
import { SchedulerModel } from "../models/schedulerModel";

@Injectable({
  providedIn: "root",
})
export class ClinicService {
  constructor(
    private _restAPIService: RestAPIService,
    private _commonService: CommonService
  ) {}

  /**
   * Method to add/update clinic profile
   */
  public updateProfile(clinicProfiledata: ClinicProfileModel): Observable<any> {
    let headers = this._commonService.getHeaders();
    let clinicProfileParam = {
      einNumber: clinicProfiledata.einNumber,
      clinicCode: clinicProfiledata.clinicCode,
      cityId: clinicProfiledata.city,
      address: clinicProfiledata.address,
      aboutClinic: clinicProfiledata.clinicInfo,
      isClinicDocuments: clinicProfiledata.isClinicDocuments,
      clinicDocuments: clinicProfiledata.clinicPhoto,
      timeZoneId: clinicProfiledata.timezone?.timezoneId,
      appLanguageId: clinicProfiledata.language,
      timeFormat: clinicProfiledata.timeFormat,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.clinicUpdateProfile,
        clinicProfileParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to add/update clinic profile
   */
  public getProfile(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(environment.apiURL + clinicApiList.clinicgetProfile, headers)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get clinic settings data
   */
  public getClinicSettings(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(environment.apiURL + clinicApiList.getClinicSettings, headers)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to save appointment settings
   */
  public saveAppointmentSettings(appPercentageParams: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.updateAppointmentSettings,
        appPercentageParams,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to save clinic appointment visit type
   */
  public saveClinicVisitType(
    clinicVisitParams: any,
    apiPath: string
  ): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(environment.apiURL + apiPath, clinicVisitParams, headers)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to delete clinic appointment visit type
   */
  public deleteClinicVisitType(Id: any): Observable<any> {
    let deleteVisitParam = {
      clinicVisitTypeId: Id,
    };
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.deleteClinicVisitType,
        deleteVisitParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get notification durations
   */
  public getNotificationDurations(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(
        environment.apiURL + clinicApiList.getNotificationDurations,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to save appointment notification
   */
  public saveAppointmentNotification(
    notificationParams: any,
    apiPath: string
  ): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(environment.apiURL + apiPath, notificationParams, headers)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to delete appointment notification
   */
  public deleteAppointmentNotification(Id: any): Observable<any> {
    let deleteNotificationParam = {
      appointmentNotificationId: Id,
    };
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.deleteAppointmentNotification,
        deleteNotificationParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get provider profile
   */
  public getProviderProfile(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(environment.apiURL + clinicApiList.getProviderProfile, headers)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to update provider profile
   */
  public updateProviderProfile(
    providerProfiledata: ProviderProfileModel
  ): Observable<any> {
    let headers = this._commonService.getHeaders();
    let providerProfileParam = {
      phoneCode: providerProfiledata.phoneCode,
      phoneNo: providerProfiledata.phoneNumber,
      specialtyId: providerProfiledata.speciality,
      visitTypes: providerProfiledata.visitTypes.join(","),
      languages: providerProfiledata.languages.join(","),
      profilePhoto: providerProfiledata.providerPhoto,
      timeZoneId: providerProfiledata.timezone?.timezoneId,
      appLanguageId: providerProfiledata.language,
      timeFormat: providerProfiledata.timeFormat,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.updateProviderProfile,
        providerProfileParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all scheduler
   */
  public getAllScheduler(schedulerParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.getAllproviderScheduler,
        schedulerParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to delete scheduler
   */
  public deleteScheduler(Id: any): Observable<any> {
    let deleteSchedulerParam = {
      providerSchedulerId: Id,
    };
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.deleteProviderScheduler,
        deleteSchedulerParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get user data by id
   */
  public getVisitTypeByProviderId(userId: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(
        environment.apiURL +
          clinicApiList.getProviderClinicVisitType +
          "?ProviderUserId=" +
          userId,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to add provider scheduler data
   */
  public addProviderScheduler(
    schedulerParam: SchedulerModel,
    apiPath: string
  ): Observable<any> {
    return this._restAPIService
      .post<any>(environment.apiURL + apiPath, schedulerParam)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get scheduler data by id
   */
  public getSchedulerDataById(
    Id: any,
    offset: any,
    apiPath: string
  ): Observable<any> {
    return this._restAPIService
      .get<any>(
        environment.apiURL + apiPath + "?Id=" + Id + "&Offset=" + offset
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all clinic subscription plan
   */
  public getAllClinicSubscriptionPlan(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(
        environment.apiURL + clinicApiList.getAllSubscriptionPlan,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to add subscription plan
   */
  public addSubscriptionPlan(planParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.addClinicSubscriptionPlan,
        planParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get clinic subscription plan
   */
  public getClinicSubscriptionPlan(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(
        environment.apiURL + clinicApiList.getAllClinicSubscriptionPlan,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get insurance company
   */
  public getInsuranceCompany(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(environment.apiURL + clinicApiList.getInsuranceCompany, headers)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all notification logs
   */
  public getNotificationLogs(
    notificationParam: any,
    apiPath: string
  ): Observable<any> {
    let headers = this._commonService.getHeaders();
    let notificationLogParam = {
      sortColumnName: notificationParam.sortColumnName,
      sortDirection: notificationParam.sortDirection,
      pageIndex: notificationParam.pageIndex,
      pageSize: notificationParam.pageSize,
      fromDate: notificationParam.fromDate,
      toDate: notificationParam.toDate,
      isReadAll: notificationParam.isReadAll,
      offset: notificationParam.offset,
    };

    return this._restAPIService
      .post<any>(environment.apiURL + apiPath, notificationLogParam, headers)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get wallet settings
   */
  public getWalletSettings(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(environment.apiURL + clinicApiList.getWalletSettings, headers)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to update wallet settings
   */
  public updateWalletSettings(walletParams: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.updateWalletSettings,
        walletParams,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get transaction history for clinic
   */
  public getClinicTransactionHistory(transactionParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.getClinicTransactionHistory,
        transactionParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all patient by clinic
   */
  public getAllPatientByClinic(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(
        environment.apiURL + clinicApiList.getAllPatientByClinic,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all medical providers by clinic
   */
  public getAllProviderByClinic(): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(
        environment.apiURL + clinicApiList.getAllProviderByClinic,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all appointment data by clinic
   */
  public getAppointmentByClinic(
    appointmentParam: any,
    apiPath: string
  ): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(environment.apiURL + apiPath, appointmentParam, headers)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to add refund transaction
   */
  public addRefundTransaction(refundParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.addRefundTransaction,
        refundParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get dashboard details
   */
  public getDashboardDetails(dashboardParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.getDashboardDetails,
        dashboardParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get patient details
   */
  public getPatientDetails(patientUserId: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<any>(
        environment.apiURL +
          clinicApiList.getPatientDetails +
          "?patientUserId=" +
          patientUserId,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }
}

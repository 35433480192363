<div class="row">
  <div class="widgets-section mb-3">
    <div class="widgets-box blue-bg">
      <div class="widgets-content">
        <div class="widgets-content-left">
          <p>{{ "common.totalAmountText" | translate }}</p>
          <h3>$ {{ totalAmount }}</h3>
        </div>
        <div class="widgets-content-img">
          <img src="assets/images/dollar-blue-icon.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="widgets-box yellow-bg">
      <div class="widgets-content">
        <div class="widgets-content-left">
          <p>{{ "common.amountCreditedText" | translate }}</p>
          <h3>$ {{ amountCredited }}</h3>
        </div>
        <div class="widgets-content-img">
          <img src="assets/images/credit-card-icon.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="widgets-box green-bg">
      <div class="widgets-content">
        <div class="widgets-content-left">
          <p>{{ "common.refundAmountText" | translate }}</p>
          <h3>$ {{ refundAmount }}</h3>
        </div>
        <div class="widgets-content-img">
          <img src="assets/images/refund-icon.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="tabs-body">
  <div class="mat-elevation-z8 table-responsive">
    <table mat-table [dataSource]="dataSource" class="table" matSort>
      <!-- <ng-container matColumnDef="appointmentDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "appointment.appointmentDateTimeText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            _globalFunction.convertUTCDateTimeToLocalDateTime(
              element.appointmentDateTime
            )
          }}
        </td>
      </ng-container> -->
      <ng-container matColumnDef="transactionDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "transaction.transactionDateTimeText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.transactionDateTime | date : "MMM dd, yyyy " + timeFormat
          }}
          {{ currentTimeZoneName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="clinicName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "manageClinics.clinicNameLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.clinicName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="clinicEmail">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "userList.emailLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.clinicEmail }}
        </td>
      </ng-container>
      <ng-container matColumnDef="clinicContact">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "userList.contactLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.clinicContact }}
        </td>
      </ng-container>
      <ng-container matColumnDef="transactionForId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "transaction.transactionForText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            "common." +
              _globalFunction.getTransactionForById(element.transactionForId)
              | translate
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "common.amountText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">$ {{ element.price }}</td>
      </ng-container>
      <ng-container matColumnDef="transactionTypeId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "transaction.transactionTypeText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            "common." +
              _globalFunction.getTransactionTypeById(element.transactionTypeId)
              | translate
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{ "userList.actionLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="table-action">
            <a
              href="javascript:void(0)"
              (click)="this.viewTransactionDetails(element)"
              >{{ "common.viewText" | translate }}</a
            >
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          {{ "userList.noResultFoundText" | translate }}
        </td>
      </tr>
    </table>
  </div>
  <mat-paginator
    [hidden]="!showPaginator"
    #paginator
    style-paginator
    showFirstLastButtons
    itemsPerPageLabel="Page Size"
    [length]="totalRows"
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageChanged($event)"
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>

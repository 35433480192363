<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/users-icon.svg" alt="" />
        </div>
        <h2>{{ "userList.manageUserHeadingText" | translate }}</h2>
      </div>
      <div class="page-title-right">
        <div class="search">
          <div class="form-group">
            <mat-form-field class="example-form-field">
              <input
                matInput
                [(ngModel)]="searchText"
                type="text"
                placeholder="{{ 'userList.searchUserPlaceholder' | translate }}"
                (keyup)="searchDataByKeyword()"
              />
              <button matSuffix>
                <img src="assets/images/icon-search.svg" alt="" />
              </button>
            </mat-form-field>
          </div>
        </div>
        <button class="btn btn-primary ms-3" (click)="addUser()">
          <img src="assets/images/plus-icon.svg" alt="" class="me-2" />
          {{ "userList.addUsersButtonText" | translate }}
        </button>
        <button class="btn btn-primary ms-3" (click)="sendInviteToUsers()">
          <img src="assets/images/send-icon.svg" alt="" class="me-2" />
          {{ "userList.sendInviteButtonText" | translate }}
        </button>
        <button
          class="btn btn-primary ms-3"
          (click)="filterClick()"
          [ngClass]="filter ? 'active' : ''"
        >
          <img src="assets/images/filter-icon.svg" alt="" />
        </button>
      </div>
    </div>

    <app-filter
      (filterData)="getFilterData($event)"
      (applyFilterData)="applyFilterData()"
      [filter]="filter"
      [showFilterList]="showFilterList"
    ></app-filter>

    <div class="content-page">
      <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
        <mat-tab label="{{ 'userList.patientTabText' | translate }}">
          <app-patients
            *ngIf="tabIndex === 0"
            [refreshUserData]="refreshUserGrid"
            [searchData]="searchString"
            (viewUserData)="viewUserPopup($event)"
            (editUserId)="editUserPopup($event)"
            (selectedPatients)="selectUsers($event)"
            #patientUsers
          ></app-patients>
        </mat-tab>
        <mat-tab
          label="{{ 'userList.medicalProviderTabText' | translate }}"
          *ngIf="userRole === '1'"
        >
          <app-medical-providers
            *ngIf="tabIndex === 1"
            [refreshUserData]="refreshUserGrid"
            [searchData]="searchString"
            (viewUserData)="viewUserPopup($event)"
            (editUserId)="editUserPopup($event)"
            (selectedProviders)="selectUsers($event)"
            #medicalProviderUsers
          ></app-medical-providers>
        </mat-tab>
        <mat-tab
          label="{{ 'userList.staffTabText' | translate }}"
          *ngIf="userRole === '1'"
        >
          <app-staff
            *ngIf="tabIndex === 2"
            [refreshUserData]="refreshUserGrid"
            [searchData]="searchString"
            (viewUserData)="viewUserPopup($event)"
            (editUserId)="editUserPopup($event)"
            (selectedStaff)="selectUsers($event)"
            #staffUsers
          ></app-staff>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="drawer-backdrop" [ngClass]="rightDrawer ? 'open' : ''"></div>
    <div class="right-drawer" [ngClass]="rightDrawer ? 'open' : ''">
      <button class="btn btn-link" (click)="showHideUserPopup(false)">
        <img src="assets/images/close-icon.svg" alt="" />
      </button>
      <app-add-edit-user
        *ngIf="addEditUser"
        [userType]="userType"
        (refreshData)="loadUserData($event)"
        (showUserPopup)="showHideUserPopup($event)"
        [editUserId]="editUserId"
      ></app-add-edit-user>
      <app-view-user
        *ngIf="viewUser"
        [userType]="userType"
        [viewUserData]="viewUserData"
        (showViewUserPopup)="showHideUserPopup($event)"
      ></app-view-user>
    </div>
  </div>
</div>

import { DatePipe } from "@angular/common";
import {
  Component,
  OnInit,
  AfterViewInit,
  OnChanges,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment-timezone";
import { GlobalFunctions } from "src/app/app.global";
import { StylePaginatorDirective } from "src/app/directives/style-paginator.directive";
import { TransactionForEnum } from "src/app/enums/transactionForEnum";
import { ClinicService } from "src/app/modules/clinic/services/clinic.service";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-clinic-transaction-history",
  templateUrl: "./clinic-transaction-history.component.html",
  styleUrls: ["./clinic-transaction-history.component.css"],
})
export class ClinicTransactionHistoryComponent
  implements OnInit, AfterViewInit, OnChanges
{
  displayedColumns: string[] = [
    "patientName",
    "medicalProviderName",
    "patientEmail",
    // "appointmentDateTime",
    "transactionDateTime",
    "startTime",
    "visitType",
    "price",
    "transactionForId",
    "transactionTypeId",
  ];
  dataSource = new MatTableDataSource([]);
  @ViewChild(StylePaginatorDirective, { static: true })
  paginator!: StylePaginatorDirective;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @Input() searchData: string = "";
  @Input() refreshTransactionHistoryData: boolean = false;
  pageNumber: number = 1;
  totalRows: number = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  @Input() filterStartDate: any = "";
  @Input() filterEndDate: any = "";
  @Input() filterPatient: any = null;
  @Input() filterProvider: any = null;
  @Input() filterVisitType: any = 0;
  @Input() filterTransactionFor: any = 0;
  @Input() filterTransactionType: any = 0;
  showPaginator: boolean = true;
  userRole: string = "";
  userId: any;
  transactionForEnum = TransactionForEnum;
  timeFormat: any;
  currentTimeZoneName: any;

  constructor(
    private _clinicService: ClinicService,
    private _commonService: CommonService,
    public _globalFunction: GlobalFunctions,
    private _authService: AuthGuardService,
    private datePipe: DatePipe
  ) {
    this.currentTimeZoneName = this._commonService.getTimeZoneShortName();
    this.timeFormat = this._commonService.getTimeFormat();
  }

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
        this.userId = data["userid"];
      }
    });
    this.loadClinicTransactionHistoryData();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() =>
      this.loadClinicTransactionHistoryData()
    );
  }

  ngOnChanges() {
    this.filterData();
  }

  /**
   * Method to get transaction history data for clinic
   */
  public loadClinicTransactionHistoryData(applyFilter: boolean = false) {
    if (this.sort.direction === "") this.sort.active = "";
    this.currentPage = applyFilter ? 0 : this.currentPage;
    let transactionParam = {
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      sortColumnName: this.sort.active,
      sortDirection: this.sort.direction === "asc" ? true : false,
      fromDate:
        this.filterStartDate !== ""
          ? this.datePipe.transform(this.filterStartDate, "yyyy-MM-dd")
          : null,
      toDate:
        this.filterEndDate !== ""
          ? this.datePipe.transform(this.filterEndDate, "yyyy-MM-dd")
          : null,
      patientUserId: this.filterPatient,
      providerUserId: this.filterProvider,
      visitTypeId: this.filterVisitType,
      transactionForId: this.filterTransactionFor,
      transactionTypeId: this.filterTransactionType,
      offset: moment().tz(moment.tz.guess()).utcOffset(),
    };
    this._clinicService
      .getClinicTransactionHistory(transactionParam)
      .subscribe({
        next: (data) => {
          if (
            data &&
            data !== null &&
            data.data &&
            data.data !== null &&
            data.data.length > 0
          ) {
            this.dataSource = new MatTableDataSource(data.data);
          } else {
            this.dataSource = new MatTableDataSource([]);
          }
          this.totalRows = data.totalRecords;
          this.paginator.matPag.length = this.totalRows;
          if (applyFilter) {
            this.paginator._curPageObj.pageIndex = 0;
            this.paginator._rangeStart = 0;
            this.paginator._rangeEnd = 1;
          }
          this.paginator.initPageRange();
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
  }

  /**
   * Method to filter data
   */
  public pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadClinicTransactionHistoryData();
  }

  /**
   * Method to filter data
   */
  public filterData() {
    this.showPaginator = true;
    if (this.searchData.trim() !== "") {
      this.showPaginator = false;
    }
    this.refreshTransactionHistoryGrid(this.refreshTransactionHistoryData);
    this.dataSource.filter = this.searchData.trim().toLocaleLowerCase();
  }

  /**
   * Method to refresh grid
   */
  public refreshTransactionHistoryGrid(isRefreshData: boolean) {
    if (isRefreshData) {
      this.loadClinicTransactionHistoryData();
    }
  }
}

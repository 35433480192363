import { DatePipe } from "@angular/common";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { GlobalFunctions } from "src/app/app.global";
import { clinicApiList } from "src/app/enums/clinicApiEnum";
import { StaticPageTitleEnum } from "src/app/enums/staticPageTitleEnum";
import { AppointmentService } from "src/app/modules/appointment/services/appointment.service";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { environment } from "src/environments/environment";
import { ClinicService } from "../../services/clinic.service";

@Component({
  selector: "app-clinic-dashboard-add-appointment",
  templateUrl: "./clinic-dashboard-add-appointment.component.html",
  styleUrls: ["./clinic-dashboard-add-appointment.component.css"],
})
export class ClinicDashboardAddAppointmentComponent implements OnInit {
  @Output() showAppointmentPopup = new EventEmitter<boolean>();
  @Input() appointmentData: any;
  @Output() refreshData = new EventEmitter<boolean>();
  formSubmitted: boolean = false;
  patientList: any = [];
  filteredPatientList: any = [];
  patientUserValue: any = "";
  appointmentNotes: any = "";
  slotDuration: any;
  appointmentDiscount: number = 0;
  appointmentDiscountAmount: any = "";
  isTermsAndCondition: boolean = false;
  usersInsuranceCompany: any;
  usersInsurancesubscriberMemberId: any;
  currentTimeZoneName: any;
  @ViewChild("searchPatientInput") searchPatientInput!: ElementRef;

  constructor(
    public _globalFunction: GlobalFunctions,
    private _commonService: CommonService,
    private _clinicService: ClinicService,
    private _appointmentService: AppointmentService,
    private _toastrService: ToastrNotificationService,
    private datePipe: DatePipe,
    private _renderer: Renderer2
  ) {
    this.currentTimeZoneName = this._commonService.getTimeZoneShortName();
    this._renderer.listen("window", "click", (e: Event) => {
      this.filteredPatientList = this.patientList;
      if (this.searchPatientInput)
        this.searchPatientInput.nativeElement.value = "";
    });
  }

  ngOnInit(): void {
    this.slotDuration = this._globalFunction.getSlotTime(
      this.appointmentData.formattedStartTime,
      this.appointmentData.formattedEndTime
    );
    if (this.appointmentDiscount === 0)
      this.appointmentDiscountAmount = this.appointmentData.price;
    this.getAllPatients();
  }

  /**
   * Method to close add appointment section
   */
  public closeAppointmentPopup() {
    this.showAppointmentPopup.emit(false);
  }

  /**
   * Method to set amount when discount change
   */
  public onDiscountChange(event: any) {
    if (event.target.value > 100) {
      this.appointmentDiscount = 0;
      this.appointmentDiscountAmount = 0;
    } else {
      this.appointmentDiscountAmount =
        this.appointmentData.price -
        this.appointmentData.price * (event.target.value / 100);
    }
    this.appointmentDiscountAmount = this.appointmentDiscountAmount.toFixed(2);
    return this.appointmentDiscountAmount;
  }

  /**
   * Method to open static content popup
   */
  public async openStaticContentPopup() {
    this._commonService.getStaticContentByPageTitleId(
      StaticPageTitleEnum.TermsOfUse
    );
  }

  /**
   * Method to get all patients data
   */
  public getAllPatients() {
    this._clinicService.getAllPatientByClinic().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.patientList = data;
          this.filteredPatientList = this.patientList;
          this.filteredPatientList.sort((a: any, b: any) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to search patients
   */
  public searchPatient(event: any) {
    const val = event.target.value.toLowerCase();
    const tempPatientList = this.patientList.filter((x: any) => {
      var formattedDOB = this.datePipe.transform(x.dob, "MM/dd/yyyy");
      if (
        x.name.toLowerCase().indexOf(val) !== -1 ||
        formattedDOB?.toLowerCase().indexOf(val) !== -1 ||
        !val
      ) {
        return x;
      }
    });
    this.filteredPatientList = tempPatientList;
  }

  /**
   * Method to submit appointment
   */
  public submitAppointment() {
    this.formSubmitted = true;
    // if (!this.isTermsAndCondition) {
    //   return this._toastrService.showError("signup.requiredTermsAndConditions");
    // }
    if (this.patientUserValue !== "") {
      let appointmentParam: any = {};
      appointmentParam["successUrl"] =
        environment.clinicDashboardAppointmentSuccessUrl;
      appointmentParam["cancelUrl"] =
        environment.clinicDashboardAppointmentCancelUrl;
      appointmentParam["providerSchedulerId"] =
        this.appointmentData.providerSchedulerId;
      appointmentParam["providerUserId"] = this.appointmentData.providerUserId;
      appointmentParam["patientUserId"] = this.patientUserValue?.userId;
      appointmentParam["notes"] = this.appointmentNotes;
      appointmentParam["sendNotification"] = true;
      appointmentParam["discount"] = this.appointmentDiscount;

      this._appointmentService
        .addClinicAppointment(
          appointmentParam,
          clinicApiList.addClinicAppointment
        )
        .subscribe({
          next: (data) => {
            if (data && data.success && data.success === true) {
              if (data.paymentUrl && data.paymentUrl !== null) {
                window.open(data.paymentUrl, "_self");
              } else {
                this.refreshData.emit(true);
                this._toastrService.showSuccess(
                  "appointment.addAppointmentSuccessMessage"
                );
                this.closeAppointmentPopup();
              }
            }
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }

  /**
   * Method to change patient
   */
  public changePatient(event: any) {
    let patientUserId = event?.value?.userId;
    if (patientUserId) this.getPatientDetails(patientUserId);
  }

  /**
   * Method to get patient details
   */
  public getPatientDetails(patientUserId: any) {
    this._clinicService.getPatientDetails(patientUserId).subscribe({
      next: (data) => {
        if (
          data &&
          data !== null &&
          data.insuranceInformation &&
          data.insuranceInformation !== null
        ) {
          this.usersInsuranceCompany =
            data.insuranceInformation.insuranceCompany;
          this.usersInsurancesubscriberMemberId =
            data.insuranceInformation.subscriberMemberId;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }
}

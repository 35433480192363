import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { environment } from "src/environments/environment";
import { SuperAdminService } from "../../services/super-admin.service";
import * as JSZip from "jszip";

@Component({
  selector: "app-view-edit-clinic-request",
  templateUrl: "./view-edit-clinic-request.component.html",
  styleUrls: ["./view-edit-clinic-request.component.css"],
})
export class ViewEditClinicRequestComponent implements OnInit {
  @Input() tabName: string = "";
  @Input() viewClinicRequestData: any;
  @Output() showViewClinicRequestPopup = new EventEmitter<boolean>();
  @Output() refreshData = new EventEmitter<boolean>();
  loggedUserName: string = "";
  requestStatus: string = "";

  constructor(
    private _authService: AuthGuardService,
    private _superAdminService: SuperAdminService,
    private _commonService: CommonService,
    private _toastrService: ToastrNotificationService
  ) {}

  ngOnInit(): void {
    if (
      this.viewClinicRequestData.updatedBy !== "" &&
      this.viewClinicRequestData.status === true
    ) {
      this.requestStatus = "1";
    }
    if (
      this.viewClinicRequestData.updatedBy !== "" &&
      this.viewClinicRequestData.status === false
    ) {
      this.requestStatus = "2";
    }
    this._authService.getUserData().subscribe((data) => {
      this.loggedUserName = data["firstname"] + " " + data["lastname"];
    });
  }

  /**
   * Method to close view clinic request section
   */
  public closeViewClinicRequestPopup() {
    this.showViewClinicRequestPopup.emit(false);
  }

  /**
   * Method to save clinic request
   */
  public saveClinicRequest() {
    if (this.requestStatus === "") {
      return this._toastrService.showError(
        "manageClinics.selectRequestStatusRequiredErrorMessage"
      );
    }
    let clinicParam = {
      userId: this.viewClinicRequestData.userId,
      status: this.requestStatus === "1" ? true : false,
      appURL: environment.clinicAppUrl,
    };
    this.refreshData.emit(false);
    this._superAdminService.updateClinicRegistration(clinicParam).subscribe({
      next: (data) => {
        if (data && data.success && data.success === true) {
          this.refreshData.emit(true);
          this._toastrService.showSuccess(
            "manageClinics.updateClinicRequestSuccessMessage"
          );
          this.closeViewClinicRequestPopup();
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to download clinic documents
   */
  public downloadClinicDocuments(data: any) {
    if (data && data !== null) {
      let fileArray = this.base64ToArrayBuffer(data.fileBytes);
      this.saveByteArray(data.fileName, fileArray);
    }
  }

  /**
   * Method to download all clinic documents
   */
  public downloadAllClinicDocuments() {
    var zip = new JSZip();
    if (
      this.viewClinicRequestData &&
      this.viewClinicRequestData.documents &&
      this.viewClinicRequestData.documents.length > 0
    ) {
      this.viewClinicRequestData.documents.forEach((element: any) => {
        let fileArray = this.base64ToArrayBuffer(element.fileBytes);
        zip.file(element.fileName, fileArray);
      });
      let clinicComponent = this;
      zip.generateAsync({ type: "blob" }).then(function (content) {
        clinicComponent.saveByteArray("clinicdocuments.zip", content);
      });
    }
  }

  /**
   * Method to download file from file bytes
   */
  public base64ToArrayBuffer(base64: any) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  /**
   * Method to download file from file bytes
   */
  public saveByteArray(fileName: any, byte: any) {
    var blob = new Blob([byte]);
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
}

<div class="drawer-body">
  <div class="top-actions text-end">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="submitAppointment()"
    >
      <span>
        {{ "appointment.confirmPaymentText" | translate }}
      </span>
    </button>
  </div>
  <div class="drawer-title">
    <span>{{ "common.addAppointmentText" | translate }}</span>
  </div>
  <div class="drawer-content">
    <ul class="view-user">
      <li>
        <div class="d-flex align-items-center provider-name">
          <img
            src="assets/images/icon-appointment-doctor.svg"
            alt=""
            class="me-3"
          />
          {{ appointmentData.medicalProviderName }}
        </div>
      </li>
      <li class="appointment-available">
        <div class="d-flex align-items-center">
          <img
            src="assets/images/icon-appointment-calender.svg"
            alt=""
            class="lable-img me-3"
          />
          {{ appointmentData.appointmentDateTime | date : "MMM dd, yyyy" }}
          {{ appointmentData.formattedStartTime }} -
          {{ appointmentData.formattedEndTime }} {{ currentTimeZoneName }}
        </div>
      </li>
      <li>
        <span>
          <p class="d-flex align-items-center">
            <img
              src="assets/images/icon-appointment-stethoscope.svg"
              alt=""
              class="doctor-stethoscope lable-img me-3"
            />
            {{ appointmentData.visitType }},
            {{ slotDuration }}
            ${{ appointmentData.price }}
          </p>
        </span>
      </li>
      <li>
        <div class="p-0">
          <div class="d-flex align-items-center">
            <div class="lable-img">
              <img
                src="assets/images/icon-appointment-user.svg"
                alt=""
                width="32px"
              />
            </div>
            <div class="form-group ms-3 flex-fill">
              <mat-form-field class="example-form-field">
                <mat-label>{{ "common.patientText" | translate }}</mat-label>
                <mat-select
                  placeholder="{{
                    'appointment.selectPatientText' | translate
                  }}"
                  [(ngModel)]="patientUserValue"
                  (selectionChange)="changePatient($event)"
                >
                  <input
                    type="text"
                    #searchPatientInput
                    (keyup)="searchPatient($event)"
                    (keydown)="_globalFunction.handleInput($event)"
                    placeholder="{{
                      'appointment.searchPatientText' | translate
                    }}"
                    class="search-input"
                  />
                  <mat-select-trigger>
                    {{ patientUserValue?.name + " - " + (patientUserValue?.dob | date : "MMM dd, yyyy")}}
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let patient of filteredPatientList"
                    [value]="patient"
                    ><span class="value">{{ patient?.name }}</span>
                    <span class="value"
                      >{{ patient?.dob | date : "MMM dd, yyyy" }}
                    </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="formSubmitted">
                <mat-error *ngIf="patientUserValue === ''">{{
                  "appointment.selectPatientErrorText" | translate
                }}</mat-error>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li *ngIf="usersInsuranceCompany">
        <div class="p-0">
          <div class="d-flex align-items-start">
            <div class="lable-img">
              <img
                src="assets/images/icon-appointment-dollar.svg"
                alt=""
                width="32px"
              />
            </div>
            <div class="appointment-top-header">
              <div class="appointment-option ml-3" style="font-size: 14px">
                <p class="mb-0">{{ "appointment.healthInsurance" | translate }}</p>
                <span class="d-block"
                  ><strong>{{ usersInsuranceCompany }}</strong></span
                >
                <span class="d-block"
                  ><strong>{{ usersInsurancesubscriberMemberId }}</strong></span
                >
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="apointment-notes border-bottom-0 p-0">
          <div class="d-flex align-items-center">
            <div class="lable-img">
              <img src="assets/images/icon-appointment-textarea.svg" alt="" />
            </div>
            <div class="form-group ms-3 flex-fill">
              <mat-form-field class="example-form-field type-textarea">
                <mat-label>{{
                  "appointment.addAppointmentNotesText" | translate
                }}</mat-label>
                <textarea
                  matInput
                  type="text"
                  rows="3"
                  [(ngModel)]="appointmentNotes"
                  placeholder="{{
                    'appointment.addAppointmentNotesText' | translate
                  }}"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="form-group">
          <mat-form-field class="example-full-width">
            <mat-label>{{ "appointment.discountText" | translate }}</mat-label>
            <span matSuffix>%</span>
            <input
              matInput
              type="text"
              [(ngModel)]="appointmentDiscount"
              placeholder="{{
                'appointment.enterDiscountPercentageText' | translate
              }}"
              (keypress)="_globalFunction.allowNumbersWithDotOnly($event)"
              maxlength="5"
              (input)="onDiscountChange($event)"
            />
          </mat-form-field>
        </div>
      </li>
      <li>
        <p>
          {{ "appointment.totalText" | translate }}: ${{
            appointmentDiscountAmount
          }}
        </p>
      </li>
    </ul>
    <!-- <div>
      <div>
        <mat-checkbox [(ngModel)]="isTermsAndCondition"
          >{{ "signup.termsAndConditionAgreeMessage" | translate }}
          <a href="javascript:void(0)" (click)="openStaticContentPopup()">{{
            "signup.termsAndConditionLinkText" | translate
          }}</a>
        </mat-checkbox>
      </div>
    </div> -->
    <!-- <div class="drawer-action mt-4 pt-2">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="submitAppointment()"
      >
        <span>
          {{ "appointment.confirmPaymentText" | translate }}
        </span>
      </button>
      <button
        type="button"
        class="btn btn-primary ms-3"
        (click)="closeAppointmentPopup()"
      >
        <img src="assets/images/white-close-icon.svg" alt="" class="me-2" />
        {{ "addUser.cancelButtonText" | translate }}
      </button>
    </div> -->
  </div>
</div>

<div class="jumbotron">
  <!-- <h4 class="display-4"><i class="fas fa-cogs"></i> Settings</h4> -->
  <!-- <form class="form">
    <div class="form-group" *ngIf="hasAudioInputOptions">
      <app-device-select
        [kind]="'audioinput'"
        [label]="'Audio Input Source'"
        [devices]="devices"
        (settingsChanged)="onSettingsChanged($event)"
      ></app-device-select>
    </div>
    <div class="form-group" *ngIf="hasAudioOutputOptions">
      <app-device-select
        [kind]="'audiooutput'"
        [label]="'Audio Output Source'"
        [devices]="devices"
        (settingsChanged)="onSettingsChanged($event)"
      ></app-device-select>
    </div>
    <div class="form-group" *ngIf="hasVideoInputOptions">
      <app-device-select
        [kind]="'videoinput'"
        #videoSelect
        [label]="'Video Input Source'"
        [devices]="devices"
        (settingsChanged)="onSettingsChanged($event)"
      ></app-device-select>
    </div>
  </form> -->
  <div [style.display]="isPreviewing ? 'block' : 'none'">
    <app-camera #camera [showProviderVideo]="showProviderVideo"></app-camera>
  </div>
</div>

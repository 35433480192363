export class OfficeStaffModel {
    email: string;
    firstName: string;
    lastName: string;
    phoneCode: string;
    phoneNumber: string;
    role: string;
    
    constructor() {
        this.email = "";
        this.firstName = "";
        this.lastName = "";
        this.phoneCode = "";
        this.phoneNumber = "";
        this.role = "";
    }
}

import { DatePipe } from "@angular/common";
import {
  Component,
  OnInit,
  AfterViewInit,
  OnChanges,
  EventEmitter,
  ViewChild,
  Input,
  Output,
} from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment-timezone";
import { GlobalFunctions } from "src/app/app.global";
import { StylePaginatorDirective } from "src/app/directives/style-paginator.directive";
import { SuperAdminService } from "src/app/modules/super-admin/services/super-admin.service";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-admin-transaction-history",
  templateUrl: "./admin-transaction-history.component.html",
  styleUrls: ["./admin-transaction-history.component.css"],
})
export class AdminTransactionHistoryComponent
  implements OnInit, AfterViewInit, OnChanges
{
  displayedColumns: string[] = [
    // "appointmentDateTime",
    "transactionDateTime",
    "clinicName",
    "clinicEmail",
    "clinicContact",
    "transactionForId",
    "price",
    "transactionTypeId",
    "action",
  ];
  dataSource = new MatTableDataSource([]);
  @ViewChild(StylePaginatorDirective, { static: true })
  paginator!: StylePaginatorDirective;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @Input() searchData: string = "";
  @Input() refreshTransactionHistoryData: boolean = false;
  @Input() filterStartDate: any = "";
  @Input() filterEndDate: any = "";
  @Input() filterClinic: any = null;
  @Input() filterTransactionFor: any = 0;
  @Output() viewTransactionData = new EventEmitter<any>();
  pageNumber: number = 1;
  totalRows: number = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  showPaginator: boolean = true;
  userRole: string = "";
  userId: any;
  totalAmount: number = 0;
  amountCredited: number = 0;
  refundAmount: number = 0;
  timeFormat: any;
  currentTimeZoneName: any;

  constructor(
    private _superAdminService: SuperAdminService,
    private _commonService: CommonService,
    public _globalFunction: GlobalFunctions,
    private _authService: AuthGuardService,
    private datePipe: DatePipe
  ) {
    this.currentTimeZoneName = this._commonService.getTimeZoneShortName();
    this.timeFormat = this._commonService.getTimeFormat();
  }

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
        this.userId = data["userid"];
      }
    });
    this.loadAdminTransactionHistoryData();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() =>
      this.loadAdminTransactionHistoryData()
    );
  }

  ngOnChanges() {
    this.filterData();
  }

  /**
   * Method to view transaction details
   */
  public viewTransactionDetails(data: any) {
    this.viewTransactionData.emit(data);
  }

  /**
   * Method to get transaction history data for admin
   */
  public loadAdminTransactionHistoryData(applyFilter: boolean = false) {
    if (this.sort.direction === "") this.sort.active = "";
    this.currentPage = applyFilter ? 0 : this.currentPage;
    let transactionParam = {
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      sortColumnName: this.sort.active,
      sortDirection: this.sort.direction === "asc" ? true : false,
      fromDate:
        this.filterStartDate !== ""
          ? this.datePipe.transform(this.filterStartDate, "yyyy-MM-dd")
          : null,
      toDate:
        this.filterEndDate !== ""
          ? this.datePipe.transform(this.filterEndDate, "yyyy-MM-dd")
          : null,
      clinicUserId: this.filterClinic,
      transactionForId: this.filterTransactionFor,
      offset: moment().tz(moment.tz.guess()).utcOffset(),
    };
    this._superAdminService
      .getAdminTransactionHistory(transactionParam)
      .subscribe({
        next: (data) => {
          if (
            data &&
            data !== null &&
            data.data &&
            data.data !== null &&
            data.data.length > 0
          ) {
            this.totalAmount = data.totalAmount;
            this.amountCredited = data.amountCredited;
            this.refundAmount = data.refundAmount;
            this.dataSource = new MatTableDataSource(data.data);
          } else {
            this.totalAmount = data.totalAmount;
            this.amountCredited = data.amountCredited;
            this.refundAmount = data.refundAmount;
            this.dataSource = new MatTableDataSource([]);
          }
          this.totalRows = data.totalRecords;
          this.paginator.matPag.length = this.totalRows;
          if (applyFilter) {
            this.paginator._curPageObj.pageIndex = 0;
            this.paginator._rangeStart = 0;
            this.paginator._rangeEnd = 1;
          }
          this.paginator.initPageRange();
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
  }

  /**
   * Method to filter data
   */
  public pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadAdminTransactionHistoryData();
  }

  /**
   * Method to filter data
   */
  public filterData() {
    this.showPaginator = true;
    if (this.searchData.trim() !== "") {
      this.showPaginator = false;
    }
    this.refreshTransactionHistoryGrid(this.refreshTransactionHistoryData);
    this.dataSource.filter = this.searchData.trim().toLocaleLowerCase();
  }

  /**
   * Method to refresh grid
   */
  public refreshTransactionHistoryGrid(isRefreshData: boolean) {
    if (isRefreshData) {
      this.loadAdminTransactionHistoryData();
    }
  }
}

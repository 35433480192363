import { DatePipe } from "@angular/common";
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment-timezone";
import { GlobalFunctions } from "src/app/app.global";
import { clinicApiList } from "src/app/enums/clinicApiEnum";
import { UserService } from "src/app/modules/user/services/user.service";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { SchedulerModel } from "../../models/schedulerModel";
import { ClinicService } from "../../services/clinic.service";

@Component({
  selector: "app-add-edit-scheduler",
  templateUrl: "./add-edit-scheduler.component.html",
  styleUrls: ["./add-edit-scheduler.component.css"],
})
export class AddEditSchedulerComponent implements OnInit, OnChanges {
  schedulerInfoModel = new SchedulerModel();
  schedulerInfoValidation: FormGroup = new FormGroup("");
  formSubmitted: boolean = false;
  addEditSchedulerText: string = "";
  @Input() editSchedulerId: any = "";
  @Output() showSchedulerPopup = new EventEmitter<boolean>();
  @Output() refreshSchedulerData = new EventEmitter<boolean>();
  providerList: any = [];
  visitTypeList: any = [];
  todayDate: Date = new Date();
  userRole: string = "";
  userId: any;
  userName: any;
  startDayTimes: any = [];
  endDayTimes: any = [];
  slotMinutesArray: any = [];
  filteredProviderList: any = [];
  @ViewChild("searchProviderInput") searchProviderInput!: ElementRef;

  constructor(
    private _commonService: CommonService,
    private _userService: UserService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrNotificationService,
    private _clinicService: ClinicService,
    public _globalFunction: GlobalFunctions,
    private _authService: AuthGuardService,
    private datePipe: DatePipe,
    private _renderer: Renderer2
  ) {
    this.todayDate = this._commonService.getCurrentTimeZoneNewDate();
    this._renderer.listen("window", "click", (e: Event) => {
      this.filteredProviderList = this.providerList;
      if (this.searchProviderInput)
        this.searchProviderInput.nativeElement.value = "";
    });
  }

  ngOnInit(): void {
    this.startDayTimes = this._globalFunction.getDayHours("10", "00:00");
    this.endDayTimes = this._globalFunction.getDayHours("10", "00:00");
    this.createSchedulerForm();

    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
        this.userId = data["userid"];
        this.userName = data["firstname"] + " " + data["lastname"];
      }
    });

    if (this.userRole !== "3") this.getAllProviders();
    if (this.userRole === "3") {
      this.schedulerInfoModel.providerUserId = this.userId;
      this.schedulerInfoValidation.patchValue({
        providerUserId: this.schedulerInfoModel.providerUserId,
      });
      this.getVisitTypeByProvider(this.schedulerInfoModel.providerUserId);
    }
  }

  ngOnChanges(): void {
    if (this.editSchedulerId && this.editSchedulerId !== null) {
      this.addEditSchedulerText = "Edit";
      this.getSchedulerDataById(this.editSchedulerId);
    } else {
      this.addEditSchedulerText = "Add";
    }
  }

  /**
   * Method to create a scheduler form
   */
  public createSchedulerForm() {
    this.schedulerInfoValidation = this._formBuilder.group({
      providerUserId: ["", [Validators.required]],
      startTime: ["", [Validators.required]],
      endTime: ["", [Validators.required]],
      visitType: ["", [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      slotMinutes: [""],
      excludeWeekend: [""],
      blockSlot: [""],
    });
  }

  /**
   * Method to get medical provider data
   */
  public getAllProviders() {
    this._clinicService.getAllProviderByClinic().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.providerList = data;
          this.filteredProviderList = this.providerList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to open add scheduler section
   */
  public closeSchedulerPopup() {
    this.showSchedulerPopup.emit(false);
  }

  /**
   * Method to change start time
   */
  public changeStartTime() {
    // var isEndTimeValid =
    //   this.schedulerInfoModel.startTime < this.schedulerInfoModel.endTime;
    // if (!isEndTimeValid) {
    //   this.schedulerInfoModel.endTime = "";
    //   this.schedulerInfoModel.slotTime = "";
    // }

    this.setEndTimeList();
    this.schedulerInfoModel.endTime = "";
    this.schedulerInfoModel.slotTime = "";
  }

  /**
   * Method to change end time
   */
  public changeEndTime() {
    var slotTime = this._globalFunction.getSlotTime(
      this.schedulerInfoModel.startTime,
      this.schedulerInfoModel.endTime
    );
    this.schedulerInfoModel.slotTime = slotTime;

    let slotDuration = this._globalFunction.getSlotDuration(
      this.schedulerInfoModel.startTime,
      this.schedulerInfoModel.endTime
    );
    this.slotMinutesArray = [];
    for (let i = 1; i <= 12; i++) {
      if (slotDuration / 5 >= i && i !== 1) this.slotMinutesArray.push(i * 5);
    }
  }

  /**
   * Method to submit scheduler data
   */
  public submitSchedulerInfo() {
    this.formSubmitted = true;
    if (
      this.schedulerInfoModel.startTime &&
      this.schedulerInfoModel.endTime &&
      this.schedulerInfoModel.startTime === this.schedulerInfoModel.endTime
    ) {
      this._toastrService.showError(
        "scheduler.sameStartAndEndTimeErrorMessage"
      );
      return;
    }

    let schedulerParam: any = {};
    if (this.editSchedulerId && this.editSchedulerId !== null) {
      schedulerParam["providerSchedulerId"] = this.editSchedulerId;
      schedulerParam["isBlocked"] = this.schedulerInfoModel.blockSlot;

      if (this.schedulerInfoModel.endDate === "") {
        this.schedulerInfoModel.endDate = this.schedulerInfoModel.startDate;
        this.schedulerInfoValidation.patchValue({
          endDate: this.schedulerInfoModel.startDate,
        });
      }
    } else {
      if (this.schedulerInfoModel.slotMinutes === "")
        this.schedulerInfoModel.slotMinutes = this._globalFunction
          .getSlotDuration(
            this.schedulerInfoModel.startTime,
            this.schedulerInfoModel.endTime
          )
          .toString();
      schedulerParam["slotMinute"] = this.schedulerInfoModel.slotMinutes;
    }

    if (this.schedulerInfoValidation.valid) {
      let localStartDate = this.datePipe.transform(
        this.schedulerInfoModel.startDate,
        "yyyy-MM-dd"
      );
      let localEndDate = this.datePipe.transform(
        this.schedulerInfoModel.endDate,
        "yyyy-MM-dd"
      );
      let localStartDatetime =
        localStartDate + " " + this.schedulerInfoModel.startTime;
      let localEndDatetime =
        localEndDate + " " + this.schedulerInfoModel.endTime;
      let utcStartDatetime =
        this._globalFunction.convertLocalDatetimeToUTCDatetime(
          localStartDatetime
        );

      var diffDays = Math.floor(
        (new Date(this.schedulerInfoModel.endDate).getTime() -
          new Date(this.schedulerInfoModel.startDate).getTime()) /
          (1000 * 60 * 60 * 24)
      );
      let endDate = new Date(utcStartDatetime.replace("Z", ""));
      endDate.setDate(endDate.getDate() + diffDays);

      let utcEndDatetime =
        this._globalFunction.convertLocalDatetimeToUTCDatetime(
          localEndDatetime
        );
      let utcStartDate = this.datePipe.transform(
        utcStartDatetime.replace("Z", ""),
        "yyyy-MM-dd"
      );
      // let utcEndDate = this.datePipe.transform(
      //   utcEndDatetime.replace("Z", ""),
      //   "yyyy-MM-dd"
      // );
      let utcEndDate = this.datePipe.transform(endDate, "yyyy-MM-dd");
      let utcStartTime = this.datePipe.transform(
        utcStartDatetime.replace("Z", ""),
        "HH:mm"
      );
      let utcEndTime = this.datePipe.transform(
        utcEndDatetime.replace("Z", ""),
        "HH:mm"
      );

      let localStartTime = this.datePipe.transform(
        localStartDatetime.replace("Z", ""),
        "HH:mm"
      );
      let localEndTime = this.datePipe.transform(
        localEndDatetime.replace("Z", ""),
        "HH:mm"
      );

      let duration = this._globalFunction.getSlotDuration(
        this.schedulerInfoModel.startTime,
        this.schedulerInfoModel.endTime
      );

      // var startDate: any = this.datePipe.transform(
      //   this._globalFunction.getUTCDate(
      //     new Date(this.schedulerInfoModel.startDate + " UTC")
      //   ),
      //   "yyyy-MM-dd"
      // );
      // var endDate: any = this.datePipe.transform(
      //   this._globalFunction.getUTCDate(
      //     new Date(this.schedulerInfoModel.endDate + " UTC")
      //   ),
      //   "yyyy-MM-dd"
      // );
      schedulerParam["providerUserId"] = this.schedulerInfoModel.providerUserId;
      schedulerParam["clinicVisitTypeId"] = this.schedulerInfoModel.visitType;
      schedulerParam["startDate"] = localStartDate;
      schedulerParam["endDate"] = localEndDate;
      schedulerParam["startTime"] = localStartTime;
      schedulerParam["endTime"] = localEndTime;
      schedulerParam["duration"] = duration;
      schedulerParam["isExcludeWeekEnds"] =
        this.schedulerInfoModel.excludeWeekend;
      schedulerParam["offset"] = moment().tz(moment.tz.guess()).utcOffset();

      let apiPath =
        this.editSchedulerId && this.editSchedulerId !== null
          ? clinicApiList.updateProviderScheduler
          : clinicApiList.addProviderScheduler;
      let successMessage =
        this.editSchedulerId && this.editSchedulerId !== null
          ? "scheduler.updateSchedulerSuccessMessage"
          : "scheduler.addSchedulerSuccessMessage";

      this.refreshSchedulerData.emit(false);
      this._clinicService
        .addProviderScheduler(schedulerParam, apiPath)
        .subscribe({
          next: (data) => {
            if (data && data.success && data.success === true) {
              this.refreshSchedulerData.emit(true);
              this._toastrService.showSuccess(successMessage);
              this.closeSchedulerPopup();
            }
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }

  /**
   * Method to get office staff data by userid
   */
  public getSchedulerDataById(Id: any) {
    let offset = moment().tz(moment.tz.guess()).utcOffset();
    this._clinicService
      .getSchedulerDataById(Id, offset, clinicApiList.getProviderScheduler)
      .subscribe({
        next: (data) => {
          if (data && data !== null) {
            this.showSchedulerPopup.emit(true);
            this.schedulerInfoModel.providerSchedulerId =
              data.providerSchedulerId;
            this.schedulerInfoModel.providerUserId = data.userId;
            this.schedulerInfoModel.startTime = this._globalFunction.formatTime(
              data.startTime
            );
            this.schedulerInfoModel.endTime = this._globalFunction.formatTime(
              data.endTime
            );
            // this.schedulerInfoModel.startTime =
            //   this._globalFunction.convertUTCTimeToLocalTime(data.startTime);
            // this.schedulerInfoModel.endTime =
            //   this._globalFunction.convertUTCTimeToLocalTime(data.endTime);
            var startDate: any = this.datePipe.transform(
              new Date(data.scheduleDate),
              "yyyy-MM-dd"
            );
            this.schedulerInfoModel.slotTime = this._globalFunction
              .getSlotDuration(
                this.schedulerInfoModel.startTime,
                this.schedulerInfoModel.endTime
              )
              .toString();
            this.schedulerInfoModel.startDate = startDate;
            this.schedulerInfoModel.blockSlot = data.isBlocked;
            this.changeProvider(this.schedulerInfoModel.providerUserId);
            this.schedulerInfoModel.visitType = data.clinicVisitTypeId;
            this.setEndTimeList();
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
  }

  /**
   * Method to change provider
   */
  public changeProvider(userId: any) {
    this.getVisitTypeByProvider(userId);
  }

  /**
   * Method to get visit type by provider
   */
  public getVisitTypeByProvider(userId: any) {
    this._clinicService.getVisitTypeByProviderId(userId).subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.visitTypeList = data;
        } else {
          this.visitTypeList = [];
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to set end time list
   */
  public setEndTimeList() {
    let minStartTime = this._globalFunction.addMinutesToTime(
      this.schedulerInfoModel.startTime,
      "10"
    );
    this.endDayTimes = this._globalFunction.getDayHours("10", minStartTime);
  }

  /**
   * Method to search providers
   */
  public searchProvider(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.providerList.filter((x: any) => {
      if (x.name.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredProviderList = temp;
  }
}

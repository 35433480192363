<div class="tabs-body">
  <div class="mat-elevation-z8 table-responsive">
    <table mat-table [dataSource]="dataSource" class="table" matSort>
      <ng-container matColumnDef="name" *ngIf="userRole !== '3'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "scheduler.providerNameLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email" *ngIf="userRole !== '3'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "userList.emailLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="startTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "scheduler.startTimeLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.startDateTime | date : timeFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="endTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "scheduler.endTimeLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.endDateTime | date : timeFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="slotTime">
        <th mat-header-cell *matHeaderCellDef>
          {{ "scheduler.slotTimeLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.duration + " min(s)" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="visitType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "settings.visitTypeLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.visitType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="scheduleDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "common.dateText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.startDateTime | date : "MMM dd, yyyy" }}
          {{ (element.timeZoneShortName == "" ? currentTimeZoneName : element.timeZoneShortName) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{ "userList.actionLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="table-action">
            <a href="javascript:void(0)" (click)="this.deleteScheduler(element)"
              ><img src="assets/images/delete-icon.svg" alt=""
            /></a>
            <a href="javascript:void(0)" (click)="this.editScheduler(element)"
              ><img src="assets/images/edit-icon.svg" alt=""
            /></a>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          {{ "userList.noResultFoundText" | translate }}
        </td>
      </tr>
    </table>
  </div>
  <mat-paginator
    [hidden]="!showPaginator"
    #paginator
    style-paginator
    showFirstLastButtons
    itemsPerPageLabel="Page Size"
    [length]="totalRows"
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageChanged($event)"
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>

<div class="login-page">
  <div class="login-box">
    <div class="login-content">
      <div class="login-logo">
        <a href="javascript:void()"
          ><img src="assets/images/logo.svg" alt=""
        /></a>
      </div>
      <div class="customer-tabs">
        <h2 class="page-headings">
          {{ "resetpassword.resetPasswordHeadingText" | translate }}
        </h2>
        <form
          [formGroup]="resetPasswordValidation"
          (ngSubmit)="submitResetPassword()"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="form-group position-relative">
                <mat-form-field class="example-full-width">
                  <span matPrefix
                    ><img src="assets/images/icon-lock.png" alt=""
                  /></span>
                  <mat-label>{{
                    "resetpassword.newPasswordPlaceholder" | translate
                  }}</mat-label>
                  <input
                    [type]="showPassword ? 'text' : 'password'"
                    matInput
                    placeholder="{{
                      'resetpassword.newPasswordPlaceholder' | translate
                    }}"
                    formControlName="password"
                    (input)="onPasswordChange($event)"
                    #password
                  />
                  <span matSuffix
                    ><mat-icon (click)="togglePasswordVisibility()">{{
                      !showPassword ? "visibility_off" : "visibility"
                    }}</mat-icon></span
                  >
                </mat-form-field>
                <div
                  *ngIf="
                    resetPasswordValidation.controls['password'].invalid &&
                    (resetPasswordValidation.controls['password'].dirty ||
                      resetPasswordValidation.controls['password'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      resetPasswordValidation
                        .get('password')
                        ?.hasError('required')
                    "
                    >{{ "common.requiredPasswordError" | translate }}</mat-error
                  >
                </div>
                <div
                  class="password-strength"
                  *ngIf="password.value && showPasswordStrength"
                >
                  <mat-password-strength
                    #passwordComponent
                    [password]="password.value"
                    (onStrengthChanged)="onPasswordStrengthChanged($event)"
                  >
                  </mat-password-strength>
                  <mat-password-strength-info
                    #passwordComponent
                    lowerCaseCriteriaMsg="{{
                      'common.passwordLowercaseError' | translate
                    }}"
                    upperCaseCriteriaMsg="{{
                      'common.passwordUppercaseError' | translate
                    }}"
                    digitsCriteriaMsg="{{
                      'common.passwordDigitError' | translate
                    }}"
                    specialCharsCriteriaMsg="{{
                      'common.passwordSpecialCharError' | translate
                    }}"
                    minCharsCriteriaMsg="{{
                      'common.passwordLengthError' | translate
                    }}"
                    [passwordComponent]="passwordComponent"
                  >
                  </mat-password-strength-info>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <mat-form-field class="example-full-width">
                  <span matPrefix
                    ><img src="assets/images/icon-lock.png" alt=""
                  /></span>
                  <mat-label>{{
                    "common.confirmPasswordPlaceholder" | translate
                  }}</mat-label>
                  <input
                    [type]="showConfirmPassword ? 'text' : 'password'"
                    matInput
                    placeholder="{{
                      'common.confirmPasswordPlaceholder' | translate
                    }}"
                    formControlName="confirmPassword"
                  />
                  <span matSuffix
                    ><mat-icon (click)="toggleConfirmPasswordVisibility()">{{
                      !showConfirmPassword ? "visibility_off" : "visibility"
                    }}</mat-icon></span
                  >
                </mat-form-field>
                <div
                  *ngIf="
                    resetPasswordValidation.controls['confirmPassword']
                      .invalid &&
                    (resetPasswordValidation.controls['confirmPassword']
                      .dirty ||
                      resetPasswordValidation.controls['confirmPassword']
                        .touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      resetPasswordValidation
                        .get('confirmPassword')
                        ?.hasError('required')
                    "
                    >{{
                      "common.requiredConfirmPasswordError" | translate
                    }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      resetPasswordValidation
                        .get('confirmPassword')
                        ?.hasError('NotEqual') &&
                      !resetPasswordValidation
                        .get('confirmPassword')
                        ?.hasError('required')
                    "
                    >{{
                      "common.passwordConfirmPasswordNotMatchError" | translate
                    }}</mat-error
                  >
                </div>
              </div>
            </div>
            <div class="login-btn">
              <button class="btn btn-primary">
                {{ "resetpassword.submitButtonText" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <p class="copy-right">{{ "footer.footerText" | translate }}</p>
</div>

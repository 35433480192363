<h2 mat-dialog-title>{{ pageTitle }}</h2>
<mat-dialog-content class="mat-typography" [innerHTML]="staticContent">
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    class="btn btn-secondary"
    (click)="onCloseClick()"
    mat-dialog-close
    tabindex="-1"
  >
    {{ closeButtonText | translate }}
  </button>
</mat-dialog-actions>

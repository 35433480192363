import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class ToastrNotificationService {
  constructor(
    private _toastrService: ToastrService,
    public _translate: TranslateService
  ) {}

  showSuccess(message: string) {
    let errorTitle: string;
    this._translate.get("toastrMessage.Success").subscribe((res: string) => {
      errorTitle = res;
    });

    this._translate.get(message).subscribe((res: string) => {
      this._toastrService.success(res, errorTitle);
    });
  }

  showError(message: string, customClass: string = "") {
    let errorTitle: string;
    this._translate.get("toastrMessage.Error").subscribe((res: string) => {
      errorTitle = res;
    });

    this._translate.get(message).subscribe((res: string) => {
      this._toastrService.error(res, errorTitle, {
        enableHtml: true,
        toastClass: "ngx-toastr " + customClass + "",
      });
    });
  }

  showInfo(message: string) {
    let errorTitle: string;
    this._translate.get("toastrMessage.Info").subscribe((res: string) => {
      errorTitle = res;
    });

    this._translate.get(message).subscribe((res: string) => {
      this._toastrService.info(res, errorTitle);
    });
  }

  showWarning(message: string) {
    let errorTitle: string;
    this._translate.get("toastrMessage.Warning").subscribe((res: string) => {
      errorTitle = res;
    });

    this._translate.get(message).subscribe((res: string) => {
      this._toastrService.warning(res, errorTitle);
    });
  }
}

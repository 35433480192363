import { Component, EventEmitter, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";

@Component({
  selector: "app-signup-email-verification",
  templateUrl: "./signup-email-verification.component.html",
  styleUrls: ["./signup-email-verification.component.css"],
})
export class SignupEmailVerificationComponent {
  @Output() activeLoginSignUpTab = new EventEmitter<number>();
  @Output() activeSignUpComponent = new EventEmitter<string>();
  emailId: string = "";
  password: string = "";

  constructor(
    public _translate: TranslateService,
    public _toastrService: ToastrNotificationService,
    public _commonService: CommonService
  ) {
    this.emailId = localStorage.getItem("signupEmail") || "";
  }

  /**
   * Method to go to login tab
   */
  public signIn() {
    this.activeLoginSignUpTab.emit(0);
  }

  /**
   * Method to continue to signup
   */
  public signUpContinue() {
    if (this.password) {
      this._commonService.signupEmail(this.emailId, this.password).subscribe({
        next: (data) => {
          if (
            data &&
            data.success &&
            data.success === true &&
            data.userId &&
            data.userId !== null
          ) {
            localStorage.setItem("signupUserId", data.userId);
            this.activeSignUpComponent.emit("signup");
          }
        },
        error: (error) => {
          this._commonService.showErrorMessage(error);
        },
      });
    } else {
      this._toastrService.showError("common.requiredPasswordError");
    }
  }

  /**
   * Method to resend the email for temporary password
   */
  public resendPasswordEmail() {
    let email = localStorage.getItem("signupEmail") || "";
    this._commonService.signupEmail(email, "").subscribe({
      next: (data) => {
        if (
          data &&
          data.success &&
          data.success === true &&
          data.userId &&
          data.userId !== null
        ) {
          this._toastrService.showSuccess("common.resendEmailSuccessMessage");
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }
}

import { DatePipe } from "@angular/common";
import {
  Component,
  OnInit,
  OnChanges,
  AfterViewInit,
  EventEmitter,
  Output,
  Input,
  Renderer2,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "src/app/services/common.service";
import { AppointmentModel } from "../../models/appointmentModel";
import { AppointmentService } from "../../services/appointment.service";
import * as moment from "moment-timezone";
import { Slick } from "ngx-slickjs";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { ClinicService } from "src/app/modules/clinic/services/clinic.service";
import { GlobalFunctions } from "src/app/app.global";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { clinicApiList } from "src/app/enums/clinicApiEnum";
import { environment } from "src/environments/environment";
import { AppointmentStatusEnum } from "src/app/enums/appointmentStatusEnum";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { UserTypeEnum } from "src/app/enums/userTypeEnum";
import { StaticPageTitleEnum } from "src/app/enums/staticPageTitleEnum";

@Component({
  selector: "app-add-appointment",
  templateUrl: "./add-appointment.component.html",
  styleUrls: ["./add-appointment.component.css"],
})
export class AddAppointmentComponent
  implements OnInit, OnChanges, AfterViewInit
{
  userRole: string = "";
  userId: any;
  userName: any;
  userTypeEnum = UserTypeEnum;
  providerList: any = [];
  patientList: any = [];
  filteredPatientList: any = [];
  tzNames: string[] = [];
  timezoneList: any = [];
  appointmentInfoModel = new AppointmentModel();
  appointmentInfoValidation: FormGroup = new FormGroup("");
  @Input() editAppointmentData: any = null;
  @Input() requestFromClinicDashboard: boolean = false;
  @Output() showAppointmentPopup = new EventEmitter<boolean>();
  @Output() refreshAppointmentData = new EventEmitter<boolean>();
  schedulerSlotList: any = [];
  earlyMorningSchedulerSlotList: any = [];
  morningSchedulerSlotList: any = [];
  afternoonSchedulerSlotList: any = [];
  eveningSchedulerSlotList: any = [];
  visitTypeList: any = [];
  selectedTimeFormat: boolean = false;
  selectedSlotVisitTypeId: any = "";
  selectedSlotDuration: any = "";
  selectedProviderName: string = "";
  isShowOnlyAvailableTimes: boolean = false;
  datesForSlider: any = [];
  formSubmitted: boolean = false;
  todayDate: Date = new Date();
  appointmentButtonText: string = "";
  appointentHeadingText: string = "";
  appointmentVisitPrice: number = 0;
  appointmentDiscount: number = 0;
  appointmentDiscountAmount: any = "";
  appointmentSection: boolean = true;
  confirmPaymentSection: boolean = false;
  maxAdvanceAppointmentBookingDate: any = "";
  isTermsAndCondition: boolean = false;
  isMakeReschedulePayment: boolean = true;
  selectedAppointmentTimeTabIndex: number = 0;
  showUpcomingProviderSlot: any;
  upcomingProviderScheduleDatetime: any;
  slickSliderStartDatetime: any;
  currentTimeZoneName: any;
  appointmentAdvanceBookingDays: any;
  sliderStartDatetime: any;
  usersInsuranceCompany: any;
  usersInsurancesubscriberMemberId: any;
  isSliderDateChanged: boolean = false;
  isTimeZoneChanged: boolean = false;
  isProviderChanged: boolean = false;
  filteredProviderList: any = [];
  filteredTimezoneList: any = [];
  @ViewChild("searchPatientInput") searchPatientInput!: ElementRef;
  @ViewChild("searchProviderInput") searchProviderInput!: ElementRef;
  @ViewChild("searchTimezoneInput") searchTimezoneInput!: ElementRef;

  config: Slick.Config = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
  };
  timeFormat: any;
  timezoneOffsetMinutes: any;

  constructor(
    private _commonService: CommonService,
    private _formBuilder: FormBuilder,
    private _appointmentService: AppointmentService,
    private _clinicService: ClinicService,
    private _toastrService: ToastrNotificationService,
    public _globalFunction: GlobalFunctions,
    private _authService: AuthGuardService,
    private datePipe: DatePipe,
    private _renderer: Renderer2
  ) {
    this.todayDate = this._commonService.getCurrentTimeZoneNewDate();
    this.timeFormat = this._commonService.getTimeFormat();
    this.selectedTimeFormat = this.timeFormat == "HH:mm" ? true : false;
    this.currentTimeZoneName = this._commonService.getTimeZoneShortName();
    this.tzNames = moment.tz.names();
    this.appointmentInfoModel.timezone = moment.tz.guess();
    this._renderer.listen("window", "click", (e: Event) => {
      this.filteredProviderList = this.providerList;
      this.filteredPatientList = this.patientList;
      this.filteredTimezoneList = this.timezoneList;
      if (this.searchPatientInput)
        this.searchPatientInput.nativeElement.value = "";
      if (this.searchProviderInput)
        this.searchProviderInput.nativeElement.value = "";
      if (this.searchTimezoneInput)
        this.searchTimezoneInput.nativeElement.value = "";
    });
    this.timezoneOffsetMinutes = this._commonService.getTimeZoneOffsetMinutes();
  }

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
        this.userId = data["userid"];
        this.userName = data["firstname"] + " " + data["lastname"];
      }
    });
    this.createAppointmentForm();
    this.getAllProviders();
    this.getAllPatients();
    this.getClinicSettings();
    this.getTimezone();
    if (this.userRole === this.userTypeEnum.Provider.toString()) {
      this.selectedProviderName = this.userName;
      this.appointmentInfoModel.providerUserId = this.userId;
      this.appointmentInfoValidation.patchValue({
        providerUserId: this.appointmentInfoModel.providerUserId,
      });
      this.getVisitTypeByProvider(this.appointmentInfoModel.providerUserId);
      this.getUpcomingProviderSlotData();
    }
  }

  ngOnChanges(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.editAppointmentData && this.editAppointmentData !== null) {
        this.appointentHeadingText = "appointment.rescheduleAppointmentText";
        this.appointmentButtonText = "appointment.rescheduleText";
        this.setAppointmentData();
        if (!this.editAppointmentData?.requestFromDashboard)
          this.showAppointmentPopup.emit(true);
      } else {
        this.appointentHeadingText = "appointment.newAppointmentText";
        this.appointmentButtonText = "appointment.scheduleText";
      }
    }, 0);
  }

  getTimezoneCode(timezoneName: any) {
    // use timezone to get code
    let localTimeZoneCode = moment().tz(timezoneName).format("z");
    // use timezone to get offset
    let localUtcOffset = moment().tz(timezoneName).utcOffset() / 60;
    return timezoneName + " (" + localTimeZoneCode + ")";
  }

  /**
   * Method to create a scheduler form
   */
  public createAppointmentForm() {
    this.appointmentInfoValidation = this._formBuilder.group({
      providerSchedulerId: ["", [Validators.required]],
      providerUserId: ["", [Validators.required]],
      scheduleDate: ["", [Validators.required]],
      slotStartTime: ["", []],
      slotEndTime: ["", []],
      timezone: [""],
      patientUserId: ["", [Validators.required]],
      appointmentNotes: ["", []],
    });
  }

  /**
   * Method to get some dates from the current date
   */
  public getDates(advanceBookingDays: any): any {
    this.datesForSlider = [];
    for (let i = 0; i < 10; i++) {
      // if (i === 10) break;
      this.datesForSlider.push(
        moment(this.slickSliderStartDatetime).add(i, "days")
      );
      // this.datesForSlider.push(moment(new Date()).add(i, "days"));
    }
  }

  /**
   * Method to set appointment data
   */
  public setAppointmentData() {
    if (this.editAppointmentData?.requestFromDashboard) {
      this.prepareAppointmentDataForCutPasteAppointment();
    } else {
      this.prepareAppointmentDataForEditAppointment();
    }
  }

  public prepareAppointmentDataForCutPasteAppointment() {
    this.setPatientTimeZone(this.editAppointmentData?.patientTimeZoneId);
    this.getPatientDetails(this.editAppointmentData?.patientUserId);
    this.selectedProviderName =
      this.editAppointmentData?.pasteSlotData?.medicalProviderName;
    this.appointmentInfoModel.providerUserId =
      this.editAppointmentData?.pasteSlotData?.providerUserId;
    this.appointmentInfoModel.patientUser !== null
      ? (this.appointmentInfoModel.patientUser.userId =
          this.editAppointmentData?.patientUserId)
      : (this.appointmentInfoModel.patientUser = {
          userId: this.editAppointmentData?.patientUserId,
          name: this.editAppointmentData?.patientName,
          dob: this.editAppointmentData?.patientDOB,
        });
    this.appointmentInfoModel.appointmentNotes =
      this.editAppointmentData?.pasteSlotData?.notes;
    let tempScheduleDateTime: any = new Date(
      this.datePipe.transform(
        this.editAppointmentData?.pasteSlotData?.scheduleDate,
        "MM/dd/yyyy"
      ) +
        " " +
        this.editAppointmentData?.pasteSlotData?.startTime
    );
    tempScheduleDateTime = moment(tempScheduleDateTime).add(
      this.appointmentInfoModel.timezone?.totalMinutes,
      "minutes"
    );
    this.appointmentInfoModel.scheduleDate = tempScheduleDateTime;
    this.editAppointmentData.providerSchedulerId =
      this.editAppointmentData?.pasteSlotData?.providerSchedulerId;
    this.selectedSlotVisitTypeId =
      this.editAppointmentData?.pasteSlotData?.visitTypeId;
    this.upcomingProviderScheduleDatetime = moment(
      this.editAppointmentData?.appointmentDateTime
    );

    this.showUpcomingProviderSlot = true;
    this.getUpcomingProviderSlotData();
    this.getSchedulerSlotData();
    this.appointmentInfoModel.providerSchedulerId =
      this.editAppointmentData?.pasteSlotData?.providerSchedulerId;
    this.appointmentInfoValidation.patchValue({
      providerSchedulerId: this.appointmentInfoModel.providerSchedulerId,
      providerUserId: this.appointmentInfoModel.providerUserId,
      patientUserId: this.appointmentInfoModel.patientUser.userId,
      scheduleDate: this.appointmentInfoModel.scheduleDate,
    });
    this.getVisitTypeByProvider(this.appointmentInfoModel.providerUserId);
  }

  public prepareAppointmentDataForEditAppointment() {
    this.setPatientTimeZone(this.editAppointmentData?.patientTimeZoneId);
    this.getPatientDetails(this.editAppointmentData?.patientUserId);
    this.selectedProviderName = this.editAppointmentData?.medicalProviderName;
    this.appointmentInfoModel.providerUserId =
      this.editAppointmentData?.providerUserId;
    this.appointmentInfoModel.patientUser !== null
      ? (this.appointmentInfoModel.patientUser.userId =
          this.editAppointmentData?.patientUserId)
      : (this.appointmentInfoModel.patientUser = {
          userId: this.editAppointmentData?.patientUserId,
          name: this.editAppointmentData?.patientName,
          dob: this.editAppointmentData?.patientDOB,
        });
    this.appointmentInfoModel.appointmentNotes =
      this.editAppointmentData?.notes;
    let tempScheduleDateTime: any = new Date(
      this.datePipe.transform(
        this.editAppointmentData?.scheduleDate,
        "MM/dd/yyyy"
      ) +
        " " +
        this.editAppointmentData?.startTime
    );
    tempScheduleDateTime = moment(tempScheduleDateTime).add(
      this.appointmentInfoModel.timezone?.totalMinutes,
      "minutes"
    );
    this.appointmentInfoModel.scheduleDate = tempScheduleDateTime;
    this.appointmentInfoModel.providerSchedulerId =
      this.editAppointmentData?.providerSchedulerId;
    this.selectedSlotVisitTypeId = this.editAppointmentData?.visitTypeId;
    this.upcomingProviderScheduleDatetime = moment(
      this.editAppointmentData?.appointmentDateTime
    );
    this.showUpcomingProviderSlot = true;
    this.getUpcomingProviderSlotData();
    this.appointmentInfoValidation.patchValue({
      providerSchedulerId: this.appointmentInfoModel.providerSchedulerId,
      providerUserId: this.appointmentInfoModel.providerUserId,
      patientUserId: this.appointmentInfoModel.patientUser.userId,
      scheduleDate: this.appointmentInfoModel.scheduleDate,
    });
    this.getSchedulerSlotData();
    this.getVisitTypeByProvider(this.appointmentInfoModel.providerUserId);
  }

  /**
   * Method to select date from the date slider
   */
  public selectDate(date: any) {
    this.isSliderDateChanged = true;
    this.appointmentInfoModel.scheduleDate = date;
    this.selectedSlotVisitTypeId = "";
    this.appointmentInfoModel.slotStartTime = "";
    this.appointmentInfoModel.slotEndTime = "";
    this.getSchedulerSlotData();
  }

  /**
   * Method to get medical provider data
   */
  public getAllProviders() {
    this._clinicService.getAllProviderByClinic().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.providerList = data;
          this.providerList.sort((a: any, b: any) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
          this.filteredProviderList = this.providerList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get all patients data
   */
  public getAllPatients() {
    this._clinicService.getAllPatientByClinic().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.patientList = data;
          this.filteredPatientList = this.patientList;
          this.filteredPatientList.sort((a: any, b: any) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to search patients
   */
  public searchPatient(event: any) {
    const val = event.target.value.toLowerCase();
    const tempPatientList = this.patientList.filter((x: any) => {
      var formattedDOB = this.datePipe.transform(x.dob, "MMM dd, yyyy");
      if (
        x.name.toLowerCase().indexOf(val) !== -1 ||
        formattedDOB?.toLowerCase().indexOf(val) !== -1 ||
        !val
      ) {
        return x;
      }
    });
    this.filteredPatientList = tempPatientList;
  }

  /**
   * Method to close add appointment section
   */
  public closeAppointmentPopup() {
    this.showAppointmentPopup.emit(false);
  }

  /**
   * Method to change provider
   */
  public changeProvider(event: any) {
    this.selectedProviderName = event.source.triggerValue;
    this.appointmentInfoModel.providerUserId = event.value;
    this.appointmentInfoModel.providerSchedulerId = "";
    this.selectedSlotVisitTypeId = "";
    this.isProviderChanged = true;
    this.getVisitTypeByProvider(event.value);
    this.getSchedulerSlotData();
    this.getUpcomingProviderSlotData();
  }

  /**
   * Method to change patient
   */
  public changePatient(event: any) {
    let patientUserId = event?.value?.userId;
    this.setPatientTimeZone(event?.value?.timeZoneId);
    if (patientUserId) this.getPatientDetails(patientUserId);
    if (
      this.isProviderChanged ||
      this.userRole == this.userTypeEnum.Provider.toString()
    ) {
      this.getSchedulerSlotData();
      this.getUpcomingProviderSlotData();
    }
  }

  /**
   * Method to change schedule date
   */
  public changeScheduleDate() {
    this.isSliderDateChanged = true;
    this.appointmentInfoModel.providerSchedulerId = "";
    this.selectedSlotVisitTypeId = "";
    this.appointmentInfoModel.slotStartTime = "";
    this.appointmentInfoModel.slotEndTime = "";
    this.getSchedulerSlotData();
  }

  /**
   * Method to change timezone
   */
  public changeTimezone(timezone: any) {
    this.appointmentInfoModel.timezone = timezone;
    this.isTimeZoneChanged = true;
    this.getSchedulerSlotData();
    //this.setSchedulerSlots();
  }

  /**
   * Method to divide timeslots in sections
   */
  public divideTimeslots(data: any, hours: number) {
    let isActiveSlot =
      (data.providerSchedulerId ===
        this.appointmentInfoModel.providerSchedulerId ||
        data.providerSchedulerId ===
          this.editAppointmentData?.pasteSlotData?.providerSchedulerId) &&
      (data.appointmentBooked === false ||
        data.providerSchedulerId ===
          this.editAppointmentData?.providerSchedulerId);

    // if (hours >= 0 && hours < 6) {
    //   this.earlyMorningSchedulerSlotList.push(data);
    //   if (isActiveSlot) this.selectedAppointmentTimeTabIndex = 0;
    // }
    // if (hours >= 6 && hours < 12) {
    //   this.morningSchedulerSlotList.push(data);
    //   if (isActiveSlot) this.selectedAppointmentTimeTabIndex = 1;
    // }
    // if (hours >= 12 && hours < 17) {
    //   this.afternoonSchedulerSlotList.push(data);
    //   if (isActiveSlot) this.selectedAppointmentTimeTabIndex = 2;
    // }
    // if (hours >= 17 && hours <= 23) {
    //   this.eveningSchedulerSlotList.push(data);
    //   if (isActiveSlot) this.selectedAppointmentTimeTabIndex = 3;
    // }
    if (hours >= 0 && hours < 12) {
      this.morningSchedulerSlotList.push(data);
      if (isActiveSlot) this.selectedAppointmentTimeTabIndex = 1;
    }
    if (hours >= 12 && hours < 18) {
      this.afternoonSchedulerSlotList.push(data);
      if (isActiveSlot) this.selectedAppointmentTimeTabIndex = 2;
    }
    if (hours >= 18 && hours <= 23) {
      this.eveningSchedulerSlotList.push(data);
      if (isActiveSlot) this.selectedAppointmentTimeTabIndex = 3;
    }
  }

  /**
   * Method to sort timeslots by time
   */
  public sortTimeSlotsByTime(timeSlots: any) {
    timeSlots.sort(function (a: any, b: any) {
      return (
        Date.parse(
          "1970/01/01 " +
            a.formattedStartTime.slice(0, -2) +
            " " +
            a.formattedStartTime.slice(-2)
        ) -
        Date.parse(
          "1970/01/01 " +
            b.formattedStartTime.slice(0, -2) +
            " " +
            b.formattedStartTime.slice(-2)
        )
      );
    });
  }

  /**
   * Method to set scheduler slots
   */
  public setSchedulerSlots() {
    let timeFormat = this.selectedTimeFormat ? "HH:mm" : "hh:mm A";
    this.clearTimeSlot();
    this.schedulerSlotList.forEach((element: any) => {
      if (
        (this.isShowOnlyAvailableTimes &&
          (!element.appointmentBooked ||
            this.appointmentInfoModel.scheduleDate ===
              element.scheduleDateTime)) ||
        !this.isShowOnlyAvailableTimes
      ) {
        // let utcStartTime: any = moment(
        //   this.datePipe.transform(new Date(), "MM/dd/yyyy") +
        //     " " +
        //     element.startTime
        // ).add(this.appointmentInfoModel.timezone?.totalMinutes, "minutes");
        // element.formattedStartTime = moment(utcStartTime._d).format(timeFormat);

        // let utcEndTime: any = moment(
        //   this.datePipe.transform(new Date(), "MM/dd/yyyy") +
        //     " " +
        //     element.endTime
        // ).add(this.appointmentInfoModel.timezone?.totalMinutes, "minutes");
        // element.formattedEndTime = moment(utcEndTime._d).format(timeFormat);
        let offsetMin = this.appointmentInfoModel.timezone?.totalMinutes;
        let startDateTime: any = moment(element.scheduleDateTime).add(
          offsetMin,
          "minutes"
        );
        element.formattedStartTime = moment(startDateTime).format(timeFormat);

        let endDateTime: any = moment(element.scheduleEndDateTime).add(
          offsetMin,
          "minutes"
        );
        element.formattedEndTime = moment(endDateTime).format(timeFormat);

        // let utcStartTime: any = moment(
        //   this.datePipe.transform(new Date(), "MM/dd/yyyy") +
        //     " " +
        //     element.startTime
        // ).tz(this.appointmentInfoModel.timezone);
        // element.formattedStartTime = moment(utcStartTime._d).format(timeFormat);

        // let utcEndTime: any = moment(
        //   this.datePipe.transform(new Date(), "MM/dd/yyyy") +
        //     " " +
        //     element.endTime
        // ).tz(this.appointmentInfoModel.timezone);
        // element.formattedEndTime = moment(utcEndTime._d).format(timeFormat);

        let hours = moment(startDateTime).hour();
        let currentUtcDate = moment(new Date())
          .utc()
          .add(offsetMin, "minutes")
          .format()
          .replace("Z", "");
        let currentDate = this.datePipe.transform(currentUtcDate, "MM/dd/yyyy");
        let currentUtcDatetime = new Date(currentUtcDate);
        let scheduleDate = this.datePipe.transform(
          this.appointmentInfoModel.scheduleDate,
          "MM/dd/yyyy"
        );
        //let currentDatetime = new Date();
        // let slotDatetime: any = new Date(
        //   this.datePipe.transform(new Date(), "MM/dd/yyyy") +
        //     " " +
        //     moment(element.scheduleDateTime).format("HH:mm")
        // );
        let slotDatetime: any = new Date(startDateTime);
        //add slots only if slot time not passed from current datetime
        if (currentDate === scheduleDate) {
          if (currentUtcDatetime.getTime() < slotDatetime.getTime()) {
            this.divideTimeslots(element, hours);
          }
        } else {
          this.divideTimeslots(element, hours);
        }

        if (
          this.editAppointmentData &&
          this.editAppointmentData !== null &&
          element.providerSchedulerId ===
            this.editAppointmentData?.providerSchedulerId
        ) {
          this.appointmentInfoModel.slotStartTime = element.formattedStartTime;
          this.appointmentInfoModel.slotEndTime = element.formattedEndTime;
          this.selectedSlotDuration = this._globalFunction.getSlotTime(
            this._globalFunction.convertUTCTimeToLocalTime(
              this.appointmentInfoModel.slotStartTime
            ),
            this._globalFunction.convertUTCTimeToLocalTime(
              this.appointmentInfoModel.slotEndTime
            )
          );
        }
      }
    });

    //sort time slots by time
    this.sortTimeSlotsByTime(this.earlyMorningSchedulerSlotList);
    this.sortTimeSlotsByTime(this.morningSchedulerSlotList);
    this.sortTimeSlotsByTime(this.afternoonSchedulerSlotList);
    this.sortTimeSlotsByTime(this.eveningSchedulerSlotList);

    //Set slot selected if seleted date and edit appointment date is same
    let scheduleDate = this.datePipe.transform(
      this.appointmentInfoModel.scheduleDate,
      "yyyy-MM-dd"
    );
    let appointmentDateTime = this.datePipe.transform(
      this.editAppointmentData?.appointmentDateTime,
      "yyyy-MM-dd"
    );
    if (
      this.appointmentInfoModel.providerSchedulerId == "" ||
      (scheduleDate === appointmentDateTime && !this.isProviderChanged)
    ) {
      this.appointmentInfoModel.providerSchedulerId =
        this.editAppointmentData?.providerSchedulerId;
      this.appointmentInfoValidation.patchValue({
        providerSchedulerId: this.appointmentInfoModel.providerSchedulerId,
      });
    }
  }

  /**
   * Method to get scheduler slot data
   */
  public getSchedulerSlotData() {
    if (
      this.appointmentInfoModel.providerUserId !== "" &&
      this.appointmentInfoModel.scheduleDate !== ""
    ) {
      this.appointmentInfoModel.providerSchedulerId = "";
      this.appointmentInfoValidation.patchValue({
        providerSchedulerId: this.appointmentInfoModel.providerSchedulerId,
      });
      let offsetMin = this.appointmentInfoModel.timezone?.totalMinutes;
      let schedulerSlotParam = {
        clinicId: null,
        providerUserId: this.appointmentInfoModel.providerUserId,
        scheduleDate: this.datePipe.transform(
          this.appointmentInfoModel.scheduleDate,
          "yyyy-MM-dd"
        ),
        //offset: moment().tz(moment.tz.guess()).utcOffset(),
        offset: offsetMin,
        isTimeZoneChanged: this.isTimeZoneChanged,
      };
      this._appointmentService
        .getAllProviderSchedulerSlot(schedulerSlotParam)
        .subscribe({
          next: (data) => {
            if (data && data !== null && data.length > 0) {
              this.schedulerSlotList = data;
            } else {
              this.schedulerSlotList = [];
            }
            setTimeout(() => {
              this.setSchedulerSlots();
            }, 500);
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }

  /**
   * Method to select slot
   */
  public changeSlot(slotData: any) {
    if (this.editAppointmentData?.pasteSlotData?.providerSchedulerId)
      this.editAppointmentData.pasteSlotData.providerSchedulerId = null;
    if (
      (!slotData.appointmentBooked && !slotData.isBlocked) ||
      this.editAppointmentData?.providerSchedulerId ===
        slotData.providerSchedulerId
    ) {
      this.appointmentInfoModel.providerSchedulerId =
        slotData.providerSchedulerId;
      this.selectedSlotVisitTypeId = slotData.visitType;
      this.appointmentInfoModel.slotStartTime = slotData.formattedStartTime;
      this.appointmentInfoModel.slotEndTime = slotData.formattedEndTime;
      this.selectedSlotDuration = this._globalFunction.getSlotTime(
        this._globalFunction.convertUTCTimeToLocalTime(slotData.startTime),
        this._globalFunction.convertUTCTimeToLocalTime(slotData.endTime)
      );
      this.appointmentInfoValidation.patchValue({
        providerSchedulerId: this.appointmentInfoModel.providerSchedulerId,
      });
      let timezoneOffsetMinutes = this.isTimeZoneChanged
        ? this.appointmentInfoModel.timezone?.totalMinutes
        : this.timezoneOffsetMinutes;
      this.upcomingProviderScheduleDatetime = moment(
        slotData.scheduleDateTime
      ).add(timezoneOffsetMinutes, "minutes");
      this.sliderStartDatetime = this.upcomingProviderScheduleDatetime;
      this.showUpcomingProviderSlot = true;
    }
  }

  /**
   * Method to change time format
   */
  public changeTimeFormat(event: MatSlideToggleChange) {
    this.selectedTimeFormat = event.checked;
    this.setSchedulerSlots();
  }

  /**
   * Method to show only available times
   */
  public showOnlyAvailableTimes(data: any) {
    this.isShowOnlyAvailableTimes = data.checked ? true : false;
    this.setSchedulerSlots();
  }

  /**
   * Method to clear time slot
   */
  public clearTimeSlot() {
    this.earlyMorningSchedulerSlotList = [];
    this.morningSchedulerSlotList = [];
    this.afternoonSchedulerSlotList = [];
    this.eveningSchedulerSlotList = [];
  }

  /**
   * Method to get visit type by provider
   */
  public getVisitTypeByProvider(userId: any) {
    this._clinicService.getVisitTypeByProviderId(userId).subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.visitTypeList = data;
        } else {
          this.visitTypeList = [];
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to set appointment visit price
   */
  public setAppointmentVisitPrice(price: number) {
    this.appointmentVisitPrice = price;
    return price;
  }

  /**
   * Method to set appointment discount amount
   */
  public setAppointmentDiscountAmount() {
    let selectedSlotVisitType = this.visitTypeList.filter(
      (x: any) => x.clinicVisitTypeId === this.selectedSlotVisitTypeId
    );
    if (this.appointmentDiscount === 0 && selectedSlotVisitType.length > 0)
      this.appointmentDiscountAmount = selectedSlotVisitType[0].price;
    return this.appointmentDiscountAmount;
  }

  /**
   * Method to set amount when discount change
   */
  public onDiscountChange(event: any) {
    if (event.target.value > 100) {
      this.appointmentDiscount = 0;
      this.appointmentDiscountAmount = 0;
    } else {
      this.appointmentDiscountAmount =
        this.appointmentVisitPrice -
        this.appointmentVisitPrice * (event.target.value / 100);
    }
    this.appointmentDiscountAmount = this.appointmentDiscountAmount.toFixed(2);
    return this.appointmentDiscountAmount;
  }

  /**
   * Method to get all timezone
   */
  public getTimezone() {
    this._commonService.getTimezone().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.timezoneList = data;
          this.filteredTimezoneList = this.timezoneList;
          // this.timezoneList.sort((a: any, b: any) =>
          //   a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          // );

          //set current timezone selected in dropdown
          let currentTimezoneName = moment.tz.guess();
          let currentTimeZoneCode = moment()
            .tz(currentTimezoneName)
            .format("z");
          let currentTimezoneOffsetMinutes = moment()
            .tz(currentTimezoneName)
            .utcOffset();
          // let currentTimezone = this.timezoneList.filter(
          //   (i: any) =>
          //     i.shortName === currentTimeZoneCode &&
          //     i.totalMinutes === currentTimezoneOffsetMinutes
          // );
          let currentTimezone = this._commonService.setCurrentTimezone(
            this.timezoneList
          );
          if (!currentTimezone) {
            currentTimezone = this.timezoneList.filter(
              (i: any) =>
                i.shortName === currentTimeZoneCode ||
                i.generic === currentTimeZoneCode ||
                i.daylight === currentTimeZoneCode ||
                i.offsetShortName === currentTimeZoneCode
            );
            if (currentTimezone && currentTimezone.length > 0)
              currentTimezone = currentTimezone[0];
          }

          if (!this.appointmentInfoModel.timezone?.totalMinutes) {
            this.appointmentInfoModel.timezone = currentTimezone
              ? currentTimezone
              : null;
          }
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get appointments settings data
   */
  public getClinicSettings() {
    this._clinicService.getClinicSettings().subscribe({
      next: (data) => {
        if (data && data !== null) {
          if (data.appointmentSettings && data.appointmentSettings !== null) {
            if (
              data.appointmentSettings.advanceBookingDays &&
              data.appointmentSettings.advanceBookingDays !== null
            ) {
              let todayDate = this._commonService.getCurrentTimeZoneNewDate();
              todayDate.setDate(
                todayDate.getDate() +
                  (data.appointmentSettings.advanceBookingDays - 1)
              );
              this.maxAdvanceAppointmentBookingDate = todayDate;
              this.appointmentAdvanceBookingDays =
                data.appointmentSettings.advanceBookingDays;
              // this.getDates(data.appointmentSettings.advanceBookingDays);
            }
          }
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to move back to appointent section
   */
  public backToAppointment() {
    this.appointmentSection = true;
    this.confirmPaymentSection = false;
  }

  /**
   * Method to schedule appointment
   */
  public scheduleAppointment() {
    if (this.sliderStartDatetime === null) {
      return this._toastrService.showError("common.noAvailableSlots");
    }
    this.appointmentInfoValidation.patchValue({
      scheduleDate: this.datePipe.transform(
        this.appointmentInfoModel.scheduleDate,
        "yyyy-MM-dd"
      ),
    });
    let selectedVisit = this.visitTypeList.filter(
      (x: any) => x.clinicVisitTypeId === this.selectedSlotVisitTypeId
    );
    if (selectedVisit && selectedVisit.length > 0) {
      if (this.editAppointmentData?.price === selectedVisit[0].price)
        this.isMakeReschedulePayment = false;
      else this.isMakeReschedulePayment = true;
    }

    this.formSubmitted = true;
    if (this.appointmentInfoValidation.valid) {
      this.appointmentSection = false;
      this.confirmPaymentSection = true;
    }
  }

  /**
   * Method to confirm payment
   */
  public confirmPayment() {
    this.submitAppointment();
  }

  /**
   * Method to submit appointment data
   */
  public submitAppointment() {
    this.formSubmitted = true;
    if (this.appointmentInfoValidation.valid) {
      // if (!this.isTermsAndCondition && this.isMakeReschedulePayment) {
      //   return this._toastrService.showError(
      //     "signup.requiredTermsAndConditions"
      //   );
      // }
      let appointmentParam: any = {};
      if (this.editAppointmentData && this.editAppointmentData !== null) {
        appointmentParam["appointmentId"] =
          this.editAppointmentData?.appointmentId;
        appointmentParam["status"] = AppointmentStatusEnum.Reschedule;
      }
      appointmentParam["successUrl"] = this.requestFromClinicDashboard
        ? environment.clinicDashboardAppointmentSuccessUrl
        : environment.appointmentSuccessUrl;
      appointmentParam["cancelUrl"] = this.requestFromClinicDashboard
        ? environment.clinicDashboardAppointmentCancelUrl
        : environment.appointmentCancelUrl;
      appointmentParam["providerSchedulerId"] =
        this.appointmentInfoModel.providerSchedulerId;
      appointmentParam["providerUserId"] =
        this.appointmentInfoModel.providerUserId;
      appointmentParam["patientUserId"] =
        this.appointmentInfoModel.patientUser.userId;
      appointmentParam["notes"] = this.appointmentInfoModel.appointmentNotes
        ? this.appointmentInfoModel.appointmentNotes
        : "";
      appointmentParam["sendNotification"] = true;
      appointmentParam["discount"] = this.appointmentDiscount;

      let apiPath =
        this.editAppointmentData && this.editAppointmentData !== null
          ? clinicApiList.updateClinicAppointment
          : clinicApiList.addClinicAppointment;

      this.refreshAppointmentData.emit(false);
      this._appointmentService
        .addClinicAppointment(appointmentParam, apiPath)
        .subscribe({
          next: (data) => {
            if (data && data.success && data.success === true) {
              if (data.paymentUrl && data.paymentUrl !== null) {
                window.open(data.paymentUrl, "_self");
              } else {
                this.refreshAppointmentData.emit(true);
                this._toastrService.showSuccess(
                  "appointment.addAppointmentSuccessMessage"
                );
                localStorage.setItem("reloadData", "true");
                this.closeAppointmentPopup();
              }
            }

            // if (data && data.success && data.success === true) {
            //   this.refreshAppointmentData.emit(true);
            //   this._toastrService.showSuccess(
            //     "appointment.addAppointmentSuccessMessage"
            //   );
            //   this.closeAppointmentPopup();
            // }
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }

  /**
   * Method to open static content popup
   */
  public async openStaticContentPopup() {
    this._commonService.getStaticContentByPageTitleId(
      StaticPageTitleEnum.TermsOfUse
    );
  }

  /**
   * Method to get upcoming provider scheduler slot data
   */
  public getUpcomingProviderSlotData() {
    let localUtcOffsetMinutes = moment().tz(moment.tz.guess()).utcOffset();
    let timezoneOffsetMinutes = this.isTimeZoneChanged
      ? this.appointmentInfoModel.timezone?.totalMinutes
      : this.timezoneOffsetMinutes;
    if (this.appointmentInfoModel.providerUserId !== "") {
      let providerSlotParam = {
        providerUserId: this.appointmentInfoModel.providerUserId,
        offset: localUtcOffsetMinutes,
      };
      this._appointmentService
        .getUpcomingProviderSchedulerSlot(providerSlotParam)
        .subscribe({
          next: (data) => {
            if (data && data !== null) {
              //To set slick slider start date time
              this.slickSliderStartDatetime = moment(data.scheduleDateTime).add(
                timezoneOffsetMinutes,
                "minutes"
              );

              this.sliderStartDatetime = moment(data.scheduleDateTime).add(
                timezoneOffsetMinutes,
                "minutes"
              );
              if (!this.editAppointmentData)
                this.upcomingProviderScheduleDatetime =
                  this.sliderStartDatetime;

              let timeFormat = this.selectedTimeFormat ? "HH:mm" : "hh:mm A";
              let startDateTime: any = moment(data.scheduleDateTime).add(
                timezoneOffsetMinutes,
                "minutes"
              );
              data.formattedStartTime =
                moment(startDateTime).format(timeFormat);

              let endDateTime: any = moment(data.scheduleEndDateTime).add(
                timezoneOffsetMinutes,
                "minutes"
              );
              data.formattedEndTime = moment(endDateTime).format(timeFormat);

              this.showUpcomingProviderSlot = true;
              this.appointmentInfoModel.scheduleDate = this.sliderStartDatetime;
              if (this.isProviderChanged || !this.editAppointmentData) {
                this.selectDate(this.sliderStartDatetime);
                this.changeSlot(data);
              } else {
                let rescheduleDatetimeTemp = this.editAppointmentData
                  ?.pasteSlotData?.appointmentDateTime
                  ? this.editAppointmentData?.pasteSlotData?.appointmentDateTime
                  : this.editAppointmentData?.appointmentDateTime;
                let rescheduleDatetime = moment(rescheduleDatetimeTemp)
                  .add(-this.timezoneOffsetMinutes, "minutes") //Remove login user timezone offset
                  .add(timezoneOffsetMinutes, "minutes"); //Add patient user timezone offset

                this.sliderStartDatetime = moment(rescheduleDatetime);
                this.appointmentInfoModel.scheduleDate =
                  this.sliderStartDatetime;
                //this.selectDate(this.sliderStartDatetime);
                this.selectedSlotVisitTypeId = this.editAppointmentData
                  ?.pasteSlotData?.visitTypeId
                  ? this.editAppointmentData?.pasteSlotData?.visitTypeId
                  : this.editAppointmentData?.visitTypeId;
                // this.goToDateSelectionSection();
              }

              // this.getDates(this.appointmentAdvanceBookingDays);
              this.appointmentInfoValidation.patchValue({
                scheduleDate: this.datePipe.transform(
                  this.appointmentInfoModel.scheduleDate,
                  "yyyy-MM-dd"
                ),
              });
            } else {
              this.showUpcomingProviderSlot = null;
              this.sliderStartDatetime = null;
              this.upcomingProviderScheduleDatetime = null;
            }
            setTimeout(() => {
              this.setSchedulerSlots();
            }, 500);
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }

  /**
   * Method to go to the date selection section
   */
  public goToDateSelectionSection() {
    this.getDates(this.appointmentAdvanceBookingDays);
    this.showUpcomingProviderSlot = false;
  }

  /**
   * Method to get patient details
   */
  public getPatientDetails(patientUserId: any) {
    this._clinicService.getPatientDetails(patientUserId).subscribe({
      next: (data) => {
        if (
          data &&
          data !== null &&
          data.insuranceInformation &&
          data.insuranceInformation !== null
        ) {
          this.usersInsuranceCompany =
            data.insuranceInformation.insuranceCompany;
          this.usersInsurancesubscriberMemberId =
            data.insuranceInformation.subscriberMemberId;
        }
        if (
          data &&
          data !== null &&
          data.parsonalInformation &&
          data.parsonalInformation !== null
        ) {
          this.setPatientTimeZone(data.parsonalInformation.timeZoneId);
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to search providers
   */
  public searchProvider(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.providerList.filter((x: any) => {
      if (x.name.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredProviderList = temp;
  }

  /**
   * Method to search timezone
   */
  public searchTimeZone(event: any) {
    const val = event.target.value.toLowerCase();
    const tempTimezoneList = this.timezoneList.filter((x: any) => {
      if (x.displayName.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredTimezoneList = tempTimezoneList;
  }

  /**
   * Method to set patient timezone
   */
  public setPatientTimeZone(timezoneId: any) {
    this.isTimeZoneChanged = true;
    let tempTimeZoneList = this.timezoneList;
    if (tempTimeZoneList.length <= 0) {
      tempTimeZoneList = localStorage.getItem("timeZoneCommonList");
      tempTimeZoneList = JSON.parse(tempTimeZoneList);
    }
    let currentTimezone = tempTimeZoneList.filter(
      (i: any) => i.timezoneId === timezoneId
    );
    if (currentTimezone && currentTimezone.length > 0)
      currentTimezone = currentTimezone[0];
    this.appointmentInfoModel.timezone = currentTimezone;
  }
}

import { Component } from "@angular/core";
import { Subscription } from "rxjs";
import { GlobalSpinnerService } from "src/app/services/global-spinner.service";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.css"],
})
export class SpinnerComponent {
  visible = false;
  _spinnerStateChanged: Subscription = new Subscription();

  constructor(private _spinnerService: GlobalSpinnerService) {}

  ngOnInit() {
    this._spinnerStateChanged = this._spinnerService.spinnerState.subscribe(
      (state: boolean) => (this.visible = state)
    );
  }

  ngOnDestroy() {
    this._spinnerStateChanged.unsubscribe();
  }
}

export const environment = {
  production: false,
  apiURL: "https://www.betternowmedical.com/host.clinic.prod/api",
  inviteUserAppUrl: "https://www.betternowmedical.com/login",
  inviteUserPatientAppUrl:
    "https://play.google.com/store/apps/details?id=com.betternowmedical.betternow",
  clinicAppUrl: "https://www.betternowmedical.com/login",
  superAdminAppUrl: "https://www.betternowmedical.com/superadmin",
  subscriptionSuccessUrl:
    "https://www.betternowmedical.com/subscription?payment=success",
  subscriptionCancelUrl:
    "https://www.betternowmedical.com/upgrade-subscription",
  appointmentSuccessUrl:
    "https://www.betternowmedical.com/appointments?payment=success",
  appointmentCancelUrl: "https://www.betternowmedical.com/appointments",
  clinicDashboardAppointmentSuccessUrl:
    "https://www.betternowmedical.com/dashboard?payment=success",
  clinicDashboardAppointmentCancelUrl:
    "https://www.betternowmedical.com/dashboard",
  documentPathUrl: "https://www.betternowmedical.com/documents/",
  videoApiURL: "https://www.betternowmedical.com/host.clinic.prod",
  googlePlayStoreAppURL:
    "https://play.google.com/store/apps/details?id=com.betternowmedical.betternow",
  appStoreAppURL:
    "https://apps.apple.com/us/app/better-now-medical/id6459228711",
};

<div class="drawer-body">
  <div class="drawer-title">
    <img src="assets/images/add-icon.svg" alt="" /> {{ addEditClinicText }}
    {{ "common.clinicText" | translate }}
  </div>
  <div class="drawer-content">
    <form [formGroup]="clinicInfoValidation" (ngSubmit)="submitClinicInfo()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "manageClinics.clinicNameLabelText" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="clinicName"
                [(ngModel)]="clinicInfoModel.clinicName"
                placeholder="{{ 'signup.clinicNamePlaceholder' | translate }}"
                required
              />
              <span matPrefix
                ><img src="assets/images/clinic.svg" alt=""
              /></span>
            </mat-form-field>
            <div
              *ngIf="
                clinicInfoValidation.controls['clinicName'].invalid &&
                (clinicInfoValidation.controls['clinicName'].dirty ||
                  clinicInfoValidation.controls['clinicName'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  clinicInfoValidation.get('clinicName')?.hasError('required')
                "
                >{{ "signup.requiredClinicNameError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-3">
                <mat-form-field>
                  <mat-label>{{
                    "signup.phoneCodePlaceholder" | translate
                  }}</mat-label>
                  <span matPrefix style="width: 20px; display: inline-block"
                    ><img src="assets/images/phone.svg" alt=""
                  /></span>
                  <mat-select
                    placeholder="{{
                      'signup.phoneCodePlaceholder' | translate
                    }}"
                    [(value)]="codeSelect"
                    formControlName="phoneCode"
                    [(ngModel)]="clinicInfoModel.phoneCode"
                  >
                    <mat-option
                      *ngFor="let code of phoneCodeList"
                      [value]="code.phoneCode"
                      >{{ code.phoneCode }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="
                    clinicInfoValidation.controls['phoneCode'].invalid &&
                    (clinicInfoValidation.controls['phoneCode'].dirty ||
                      clinicInfoValidation.controls['phoneCode'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      clinicInfoValidation
                        .get('phoneCode')
                        ?.hasError('required')
                    "
                    >{{
                      "signup.requiredPhoneCodeError" | translate
                    }}</mat-error
                  >
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group mb-3">
                <mat-form-field class="example-form-field">
                  <mat-label>{{
                    "userList.contactLabelText" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="phoneNumber"
                    [(ngModel)]="clinicInfoModel.phoneNumber"
                    placeholder="{{
                      'viewUser.contactNumberLabelText' | translate
                    }}"
                    (keypress)="_globalFunction.allowNumbersOnly($event)"
                    required
                  />
                </mat-form-field>
                <div
                  *ngIf="
                    clinicInfoValidation.controls['phoneNumber'].invalid &&
                    (clinicInfoValidation.controls['phoneNumber'].dirty ||
                      clinicInfoValidation.controls['phoneNumber'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      clinicInfoValidation
                        .get('phoneNumber')
                        ?.hasError('required')
                    "
                    >{{ "signup.requiredPhoneError" | translate }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      clinicInfoValidation
                        .get('phoneNumber')
                        ?.hasError('minlength')
                    "
                    >{{ "signup.minLengthPhoneError" | translate }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      clinicInfoValidation
                        .get('phoneNumber')
                        ?.hasError('maxlength')
                    "
                    >{{ "signup.maxLengthPhoneError" | translate }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      clinicInfoValidation
                        .get('phoneNumber')
                        ?.hasError('pattern')
                    "
                    >{{ "signup.patternPhoneError" | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "viewUser.emailAddressLabelText" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="email"
                [(ngModel)]="clinicInfoModel.email"
                placeholder="{{ 'login.emailPlaceholder' | translate }}"
                required
              />
              <span matPrefix
                ><img src="assets/images/email.svg" alt=""
              /></span>
            </mat-form-field>
            <div
              *ngIf="
                clinicInfoValidation.controls['email'].invalid &&
                (clinicInfoValidation.controls['email'].dirty ||
                  clinicInfoValidation.controls['email'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="clinicInfoValidation.get('email')?.hasError('required')"
                >{{ "common.requiredEmailError" | translate }}</mat-error
              >
              <mat-error
                *ngIf="clinicInfoValidation.get('email')?.hasError('pattern')"
                >{{ "common.invalidEmailError" | translate }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="example-form-field">
              <mat-label>{{
                "clinicProfile.EINNumberLabel" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="einNumber"
                [(ngModel)]="clinicInfoModel.einNumber"
                placeholder="{{
                  'clinicProfile.EINNumberPlaceholder' | translate
                }}"
                required
              />
              <span matPrefix
                ><img src="assets/images/user-icon.svg" alt=""
              /></span>
            </mat-form-field>
            <div
              *ngIf="
                clinicInfoValidation.controls['einNumber'].invalid &&
                (clinicInfoValidation.controls['einNumber'].dirty ||
                  clinicInfoValidation.controls['einNumber'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  clinicInfoValidation.get('einNumber')?.hasError('required')
                "
                >{{
                  "clinicProfile.RequiredEINNumberError" | translate
                }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-3">
                <mat-form-field class="example-form-field">
                  <mat-label>{{
                    "clinicProfile.ClinicCodeLabel" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="clinicCode"
                    [(ngModel)]="clinicInfoModel.clinicCode"
                    placeholder="{{
                      'clinicProfile.ClinicCodePlaceholder' | translate
                    }}"
                    minlength="6"
                    maxlength="6"
                    required
                  />
                  <span matPrefix
                    ><img src="assets/images/clinic.svg" alt=""
                  /></span>
                </mat-form-field>
                <div
                  *ngIf="
                    clinicInfoValidation.controls['clinicCode'].invalid &&
                    (clinicInfoValidation.controls['clinicCode'].dirty ||
                      clinicInfoValidation.controls['clinicCode'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      clinicInfoValidation
                        .get('clinicCode')
                        ?.hasError('required')
                    "
                    >{{
                      "clinicProfile.RequiredClinicCodeError" | translate
                    }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      clinicInfoValidation
                        .get('clinicCode')
                        ?.hasError('pattern')
                    "
                    >{{
                      "clinicProfile.ClinicCodePatternError" | translate
                    }}</mat-error
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <mat-form-field>
                  <mat-label>{{ "common.CountryLabel" | translate }}</mat-label>
                  <mat-select
                    (selectionChange)="changeCountry($event.value)"
                    [(value)]="countrySelect"
                    formControlName="countryId"
                    [(ngModel)]="clinicInfoModel.countryId"
                    placeholder="{{ 'common.CountryPlaceholder' | translate }}"
                    required
                  >
                    <mat-option
                      *ngFor="let country of countryList"
                      [value]="country.countryId"
                      >{{ country.countryName }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="
                    clinicInfoValidation.controls['countryId'].invalid &&
                    (clinicInfoValidation.controls['countryId'].dirty ||
                      clinicInfoValidation.controls['countryId'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      clinicInfoValidation
                        .get('countryId')
                        ?.hasError('required')
                    "
                    >{{ "common.RequiredCountryError" | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-3">
                <mat-form-field>
                  <mat-label>{{ "common.StateLabel" | translate }}</mat-label>
                  <mat-select
                    (selectionChange)="changeState($event.value)"
                    [(value)]="stateSelect"
                    formControlName="stateId"
                    [(ngModel)]="clinicInfoModel.stateId"
                    placeholder="{{ 'common.StatePlaceholder' | translate }}"
                    required
                  >
                    <input
                      type="text"
                      #searchStateInput
                      (keyup)="searchStates($event)"
                      (keydown)="_globalFunction.handleInput($event)"
                      placeholder="{{ 'common.searchStateText' | translate }}"
                      class="search-input"
                    />
                    <mat-option
                      *ngFor="let state of filteredStateList"
                      [value]="state.stateId"
                      >{{ state.stateName }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="
                    clinicInfoValidation.controls['stateId'].invalid &&
                    (clinicInfoValidation.controls['stateId'].dirty ||
                      clinicInfoValidation.controls['stateId'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      clinicInfoValidation.get('stateId')?.hasError('required')
                    "
                    >{{ "common.RequiredStateError" | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <mat-form-field>
                  <mat-label>{{ "common.CityLabel" | translate }}</mat-label>
                  <mat-select
                    [(value)]="citySelect"
                    formControlName="cityId"
                    [(ngModel)]="clinicInfoModel.cityId"
                    placeholder="{{ 'common.CityPlaceholder' | translate }}"
                    required
                  >
                  <input
                          type="text"
                          #searchCityInput
                          (keyup)="searchCities($event)"
                          (keydown)="_globalFunction.handleInput($event)"
                          placeholder="{{
                            'common.searchCityText' | translate
                          }}"
                          class="search-input"
                        />
                    <mat-option
                      *ngFor="let city of filteredCityList"
                      [value]="city.cityId"
                      >{{ city.cityName }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="
                    clinicInfoValidation.controls['cityId'].invalid &&
                    (clinicInfoValidation.controls['cityId'].dirty ||
                      clinicInfoValidation.controls['cityId'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      clinicInfoValidation.get('cityId')?.hasError('required')
                    "
                    >{{ "common.RequiredCityError" | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="type-textarea">
              <mat-label>{{
                "clinicProfile.AddressLabel" | translate
              }}</mat-label>
              <textarea
                matInput
                type="text"
                formControlName="address"
                [(ngModel)]="clinicInfoModel.address"
                placeholder="{{
                  'clinicProfile.AddressPlaceholder' | translate
                }}"
                required
              ></textarea>
            </mat-form-field>
            <div
              *ngIf="
                clinicInfoValidation.controls['address'].invalid &&
                (clinicInfoValidation.controls['address'].dirty ||
                  clinicInfoValidation.controls['address'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  clinicInfoValidation.get('address')?.hasError('required')
                "
                >{{
                  "clinicProfile.RequiredAddressError" | translate
                }}</mat-error
              >
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <mat-form-field class="document-input">
              <mat-label>{{
                "clinicProfile.ClinicPhotoLabel" | translate
              }}</mat-label>
              <span matPrefix
                ><img src="assets/images/document.svg" alt=""
              /></span>
              <input
                type="text"
                matInput
                formControlName="clinicPhotos"
                [(ngModel)]="clinicPhotoList"
                placeholder="{{
                  'clinicProfile.ClinicPhotoPlaceholder' | translate
                }}"
                readonly
              />
              <span matSuffix class="upload-btn">
                <input
                  type="file"
                  onclick="this.value = null"
                  (change)="uploadClinicPhoto($event)"
                  multiple
                />
              </span>
            </mat-form-field>

            <ul class="upload-photos">
              <div *ngFor="let photo of clinicPhotos">
                <li *ngIf="photo.fileBytes">
                  <span class="upload-photo-image"
                    ><img
                      src="data:image/png;base64,{{ photo.fileBytes }}"
                      alt=""
                  /></span>
                  <a href="javascript:void(0)"
                    ><img
                      src="assets/images/close-icon.png"
                      alt=""
                      (click)="removeClinicPhoto(photo)"
                  /></a>
                  <p>{{ photo.fileName }}</p>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <mat-form-field class="document-input">
              <mat-label>{{
                "manageClinics.clinicDocumentsLabelText" | translate
              }}</mat-label>
              <span matPrefix
                ><img src="assets/images/document.svg" alt=""
              /></span>
              <input
                type="text"
                matInput
                formControlName="clinicDocuments"
                [(ngModel)]="clinicDocumentList"
                placeholder="{{
                  'signup.uploadDocumentPlaceholder' | translate
                }}"
                readonly
              />
              <span matSuffix class="upload-btn">
                <input
                  type="file"
                  onclick="this.value = null"
                  (change)="uploadClinicDocument($event)"
                  multiple
                />
              </span>
            </mat-form-field>
            <div
              *ngIf="
                clinicInfoValidation.controls['clinicDocuments'].invalid &&
                (clinicInfoValidation.controls['clinicDocuments'].dirty ||
                  clinicInfoValidation.controls['clinicDocuments'].touched ||
                  formSubmitted)
              "
            >
              <mat-error
                *ngIf="
                  clinicInfoValidation
                    .get('clinicDocuments')
                    ?.hasError('required')
                "
                >{{
                  "manageClinics.uploadClinicDocumentRequiredError" | translate
                }}</mat-error
              >
            </div>
            <ul class="upload-photos">
              <div *ngFor="let document of clinicDocuments">
                <li *ngIf="document.fileBytes" title="{{ document.fileName }}">
                  <a href="javascript:void(0)"
                    ><img
                      src="assets/images/close-icon.png"
                      alt=""
                      (click)="removeClinicDocument(document)"
                  /></a>
                  <p>{{ document.fileName }}</p>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group mb-3">
            <mat-form-field class="type-textarea">
              <mat-label>{{
                "clinicProfile.AboutClinicLabel" | translate
              }}</mat-label>
              <textarea
                matInput
                type="text"
                formControlName="aboutClinic"
                [(ngModel)]="clinicInfoModel.aboutClinic"
                placeholder="{{
                  'clinicProfile.ClinicInfoPlaceholder' | translate
                }}"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="drawer-action">
        <button type="submit" class="btn btn-secondary">
          <img src="assets/images/save-icon.svg" alt="" class="me-2" />
          {{ "addUser.saveButtonText" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary ms-3"
          (click)="closeClinicPopup()"
        >
          <img src="assets/images/white-close-icon.svg" alt="" class="me-2" />
          {{ "addUser.cancelButtonText" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="drawer-body">
  <div class="drawer-title">
    <img src="assets/images/add-icon.svg" alt="" />
    {{ refundHeading | translate }}
  </div>
  <div class="drawer-content">
    <div class="row">
      <div
        class="col-md-12"
        *ngIf="
          userRole === userTypeEnum.SuperAdmin.toString() ||
          userRole === userTypeEnum.OfficeStaff.toString()
        "
      >
        <div class="form-group mb-3">
          <mat-form-field class="example-form-field">
            <mat-label>{{ "common.clinicText" | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'common.selectClinicText' | translate }}"
              [(ngModel)]="clinicUserId"
              (selectionChange)="changeClinic()"
              required
            >
              <input
                type="text"
                #searchClinicInput
                (keyup)="searchClinic($event)"
                (keydown)="_globalFunction.handleInput($event)"
                placeholder="{{ 'common.searchClinicText' | translate }}"
                class="search-input"
              />
              <mat-option
                *ngFor="let clinic of filteredClinicList"
                [value]="clinic.userId"
                >{{ clinic.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <div *ngIf="formSubmitted">
            <mat-error *ngIf="clinicUserId === ''">{{
              "transaction.requiredClinicErrorText" | translate
            }}</mat-error>
          </div>
        </div>
      </div>
      <div
        class="col-md-12"
        *ngIf="
          userRole === userTypeEnum.SuperAdmin.toString() ||
          userRole === userTypeEnum.OfficeStaff.toString()
        "
      >
        <div class="form-group mb-3">
          <mat-form-field>
            <mat-label>{{
              "transaction.transactionForText" | translate
            }}</mat-label>
            <mat-select
              placeholder="{{ 'transaction.transactionForText' | translate }}"
              [(ngModel)]="transactionForValue"
              (selectionChange)="changeTransactionFor()"
              required
            >
              <!-- <mat-option value="2">Appointment</mat-option> -->
              <mat-option value="4">Subscription</mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="formSubmitted">
            <mat-error *ngIf="transactionForValue === ''">{{
              "transaction.requiredTransactionForErrorText" | translate
            }}</mat-error>
          </div>
        </div>
      </div>
      <div
        class="col-md-12"
        *ngIf="
          transactionForValue !==
          transactionForEnum.SubscriptionRefund.toString()
        "
      >
        <div class="form-group mb-3">
          <mat-form-field class="example-form-field">
            <mat-label>{{ "common.dateText" | translate }}</mat-label>
            <input
              matInput
              type="text"
              class="field-disable"
              [(ngModel)]="date"
              placeholder="{{ 'scheduler.selectDateLabelText' | translate }}"
              [matDatepicker]="picker"
              [max]="todayDate"
              (dateChange)="changeDate()"
              required
              readonly
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <!-- <span matPrefix
              ><img src="assets/images/calender-icon.svg" alt=""
            /></span> -->
          </mat-form-field>
          <div *ngIf="formSubmitted">
            <mat-error *ngIf="date === ''">{{
              "transaction.requiredDateErrorText" | translate
            }}</mat-error>
          </div>
        </div>
      </div>
      <div
        class="col-md-12"
        *ngIf="
          transactionForValue ===
            transactionForEnum.AppointmentRefund.toString() ||
          userRole === userTypeEnum.Clinic.toString()
        "
      >
        <div class="form-group mb-3">
          <mat-form-field class="example-form-field">
            <mat-label>{{ "common.patientText" | translate }}</mat-label>
            <mat-select
              placeholder="{{ 'appointment.selectPatientText' | translate }}"
              [(ngModel)]="patientUserId"
              (selectionChange)="changePatient()"
              required
            >
              <input
                type="text"
                #searchPatientInput
                (keyup)="searchPatient($event)"
                (keydown)="_globalFunction.handleInput($event)"
                placeholder="{{ 'appointment.searchPatientText' | translate }}"
                class="search-input"
              />
              <mat-option
                *ngFor="let patient of filteredPatientList"
                [value]="patient.userId"
                >{{ patient.name }} -
                {{ patient?.dob | date : "MMM dd, yyyy" }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <div *ngIf="formSubmitted">
            <mat-error *ngIf="patientUserId === ''">{{
              "appointment.selectPatientErrorText" | translate
            }}</mat-error>
          </div>
        </div>
      </div>
      <div
        class="col-md-12"
        *ngIf="
          transactionForValue ===
            transactionForEnum.AppointmentRefund.toString() ||
          userRole === userTypeEnum.Clinic.toString()
        "
      >
        <div class="form-group mb-3">
          <mat-form-field class="example-form-field">
            <mat-label>{{ "common.appointmentText" | translate }}</mat-label>
            <mat-select
              placeholder="{{
                'transaction.selectAppointmentText' | translate
              }}"
              [(ngModel)]="appointmentId"
              (selectionChange)="changeAppointment()"
              required
            >
              <mat-option
                *ngFor="let appointment of appointmentList"
                [value]="appointment.appointmentId"
                >{{ appointment.visitType }} -
                {{ appointment.providerName }} ({{
                  _globalFunction.convertUTCTimeToLocalTime(
                    appointment.startTime
                  )
                }}
                -
                {{
                  _globalFunction.convertUTCTimeToLocalTime(
                    appointment.endTime
                  )
                }})</mat-option
              >
            </mat-select>
          </mat-form-field>
          <div *ngIf="formSubmitted">
            <mat-error *ngIf="appointmentId === ''">{{
              "transaction.requiredAppointmentErrorText" | translate
            }}</mat-error>
          </div>
        </div>
      </div>
      <div
        class="col-md-12"
        *ngIf="
          transactionForValue ===
          transactionForEnum.SubscriptionRefund.toString()
        "
      >
        <div class="form-group mb-3">
          <mat-form-field class="example-form-field">
            <mat-label>{{ "common.subscriptionText" | translate }}</mat-label>
            <mat-select
              placeholder="{{
                'transaction.selectSubscriptionText' | translate
              }}"
              [(ngModel)]="subscriptionId"
              required
            >
              <mat-option
                *ngFor="let subscription of subscriptionList"
                [value]="subscription.clinicSubscriptionPlanId"
                >{{ subscription.planName }} ({{
                  subscription.startDate | date : "MMM dd, yyyy"
                }}
                - {{ subscription.endDate | date : "MMM dd, yyyy" }})</mat-option
              >
            </mat-select>
          </mat-form-field>
          <div *ngIf="formSubmitted">
            <mat-error *ngIf="subscriptionId === ''">{{
              "transaction.requiredSubscriptionErrorText" | translate
            }}</mat-error>
          </div>
        </div>
      </div>
      <div class="col-md-12 description">
        <div class="form-group mb-3">
          <mat-form-field class="example-full-width type-textarea">
            <mat-label>{{ "common.descriptionText" | translate }}</mat-label>
            <textarea
              matInput
              type="text"
              rows="3"
              [(ngModel)]="description"
              placeholder="{{ 'common.enterDescriptionText' | translate }}"
              required
            ></textarea>
          </mat-form-field>
          <div *ngIf="formSubmitted">
            <mat-error *ngIf="description === ''">{{
              "common.RequiredDescriptionErrorText" | translate
            }}</mat-error>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-3">
          <mat-form-field class="example-form-field">
            <mat-label>{{ "common.amountText" | translate }}</mat-label>
            <!-- <span matPrefix>$</span> -->
            <input
              matInput
              type="text"
              maxlength="7"
              [(ngModel)]="amount"
              placeholder="{{ 'common.enterAmountText' | translate }}"
              (keypress)="_globalFunction.allowDecimalNumbersOnly($event)"
              required
            />
          </mat-form-field>
          <div *ngIf="formSubmitted">
            <mat-error *ngIf="amount === ''">{{
              "common.RequiredAmountErrorText" | translate
            }}</mat-error>
          </div>
        </div>
      </div>
    </div>
    <div class="drawer-action">
      <button type="button" class="btn btn-secondary" (click)="saveRefund()">
        <img src="assets/images/save-icon.svg" alt="" class="me-2" />
        {{ "addUser.saveButtonText" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary ms-3"
        (click)="closeRefundPopup()"
      >
        <img src="assets/images/white-close-icon.svg" alt="" class="me-2" />
        {{ "addUser.cancelButtonText" | translate }}
      </button>
    </div>
  </div>
</div>

<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/plan-icon.svg" alt="" />
        </div>
        <h2>{{ "subscription.subscriptionDetailsHeadingText" | translate }}</h2>
      </div>
    </div>
    <div class="">
      <div class="row pt-4">
        <div
          class="col-md-4 col-xxl-3"
          *ngFor="let plan of currentSubscriptionPlan"
        >
          <div class="selected-plan">
            <div class="plan-header" *ngIf="plan.currentActivePlan">
              {{ "subscription.currentSubscriptionPlanText" | translate }}
            </div>
            <div class="plan-header" *ngIf="!plan.currentActivePlan">
              {{ "subscription.upcomingSubscriptionPlanText" | translate }}
            </div>
            <div class="selected-plan-details">
              <ul>
                <li>
                  <span>{{ "settings.planNameLabelText" | translate }}</span>
                  <strong>{{ plan.planName }}</strong>
                </li>
                <li>
                  <span>{{ "subscription.StartDateText" | translate }}</span>
                  <strong
                    >{{ plan.startDate | date : "MMM dd, yyyy" }}
                    {{ currentTimeZoneName }}</strong
                  >
                </li>
                <li>
                  <span>{{ "subscription.ExpiresOnText" | translate }}</span>
                  <strong
                    >{{ plan.endDate | date : "MMM dd, yyyy" }}
                    {{ currentTimeZoneName }}</strong
                  >
                </li>
              </ul>
            </div>
            <div class="elected-plan-footer" *ngIf="plan.currentActivePlan">
              <p>
                *{{ "subscription.ClickText" | translate }}
                <a
                  href="javascript:void(0)"
                  routerLink="/upgrade-subscription"
                  >{{ "subscription.hereText" | translate }}</a
                >, {{ "subscription.upgradePlanText" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

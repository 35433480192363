<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/clinic-profile.svg" alt="" />
        </div>
        <h2>{{ "clinicProfile.clinicProfileHeadingText" | translate }}</h2>
      </div>
    </div>

    <div class="content-page">
      <div class="form-page">
        <h4 class="pb-3">
          {{ "clinicProfile.clinicProfileHeadingLabel" | translate }}
        </h4>

        <form
          [formGroup]="clinicProfileInfoValidation"
          (ngSubmit)="submitClinicProfile()"
        >
          <div class="row">
            <div class="col-xxl-10">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <mat-form-field class="example-form-field">
                      <mat-label>{{
                        "manageClinics.clinicNameLabelText" | translate
                      }}</mat-label>
                      <input
                        matInput
                        type="text"
                        [value]="clinicName"
                        readonly
                        disabled
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <mat-form-field class="example-form-field">
                      <mat-label>{{
                        "clinicProfile.EINNumberLabel" | translate
                      }}</mat-label>
                      <input
                        matInput
                        type="text"
                        formControlName="einNumber"
                        [(ngModel)]="clinicProfileModel.einNumber"
                        placeholder="{{
                          'clinicProfile.EINNumberPlaceholder' | translate
                        }}"
                        required
                      />
                    </mat-form-field>
                    <div
                      *ngIf="
                        clinicProfileInfoValidation.controls['einNumber']
                          .invalid &&
                        (clinicProfileInfoValidation.controls['einNumber']
                          .dirty ||
                          clinicProfileInfoValidation.controls['einNumber']
                            .touched ||
                          formSubmitted)
                      "
                    >
                      <mat-error
                        *ngIf="
                          clinicProfileInfoValidation
                            .get('einNumber')
                            ?.hasError('required')
                        "
                        >{{
                          "clinicProfile.RequiredEINNumberError" | translate
                        }}</mat-error
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <mat-form-field class="example-form-field">
                      <mat-label>{{
                        "clinicProfile.ClinicCodeLabel" | translate
                      }}</mat-label>
                      <input
                        matInput
                        type="text"
                        formControlName="clinicCode"
                        [(ngModel)]="clinicProfileModel.clinicCode"
                        placeholder="{{
                          'clinicProfile.ClinicCodePlaceholder' | translate
                        }}"
                        minlength="6"
                        maxlength="6"
                        required
                      />
                    </mat-form-field>
                    <div
                      *ngIf="
                        clinicProfileInfoValidation.controls['clinicCode']
                          .invalid &&
                        (clinicProfileInfoValidation.controls['clinicCode']
                          .dirty ||
                          clinicProfileInfoValidation.controls['clinicCode']
                            .touched ||
                          formSubmitted)
                      "
                    >
                      <mat-error
                        *ngIf="
                          clinicProfileInfoValidation
                            .get('clinicCode')
                            ?.hasError('required')
                        "
                        >{{
                          "clinicProfile.RequiredClinicCodeError" | translate
                        }}</mat-error
                      >
                      <mat-error
                        *ngIf="
                          clinicProfileInfoValidation
                            .get('clinicCode')
                            ?.hasError('pattern')
                        "
                        >{{
                          "clinicProfile.ClinicCodePatternError" | translate
                        }}</mat-error
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-10">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <mat-form-field>
                      <mat-label>{{
                        "common.CountryLabel" | translate
                      }}</mat-label>
                      <mat-select
                        (selectionChange)="changeCountry($event.value)"
                        [(value)]="countrySelect"
                        formControlName="country"
                        [(ngModel)]="clinicProfileModel.country"
                        placeholder="{{
                          'common.CountryPlaceholder' | translate
                        }}"
                        required
                      >
                        <mat-option
                          *ngFor="let country of countryList"
                          [value]="country.countryId"
                          >{{ country.countryName }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                    <div
                      *ngIf="
                        clinicProfileInfoValidation.controls['country']
                          .invalid &&
                        (clinicProfileInfoValidation.controls['country']
                          .dirty ||
                          clinicProfileInfoValidation.controls['country']
                            .touched ||
                          formSubmitted)
                      "
                    >
                      <mat-error
                        *ngIf="
                          clinicProfileInfoValidation
                            .get('country')
                            ?.hasError('required')
                        "
                        >{{
                          "common.RequiredCountryError" | translate
                        }}</mat-error
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <mat-form-field>
                      <mat-label>{{
                        "common.StateLabel" | translate
                      }}</mat-label>
                      <mat-select
                        (selectionChange)="changeState($event.value)"
                        formControlName="state"
                        [(ngModel)]="clinicProfileModel.state"
                        placeholder="{{
                          'common.StatePlaceholder' | translate
                        }}"
                        required
                      >
                        <input
                          type="text"
                          #searchStateInput
                          (keyup)="searchStates($event)"
                          (keydown)="_globalFunction.handleInput($event)"
                          placeholder="{{
                            'common.searchStateText' | translate
                          }}"
                          class="search-input"
                        />
                        <mat-option
                          *ngFor="let state of filteredStateList"
                          [value]="state.stateId"
                          >{{ state.stateName }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                    <div
                      *ngIf="
                        clinicProfileInfoValidation.controls['state'].invalid &&
                        (clinicProfileInfoValidation.controls['state'].dirty ||
                          clinicProfileInfoValidation.controls['state']
                            .touched ||
                          formSubmitted)
                      "
                    >
                      <mat-error
                        *ngIf="
                          clinicProfileInfoValidation
                            .get('state')
                            ?.hasError('required')
                        "
                        >{{
                          "common.RequiredStateError" | translate
                        }}</mat-error
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <mat-form-field>
                      <mat-label>{{
                        "common.CityLabel" | translate
                      }}</mat-label>
                      <mat-select
                        [(value)]="citySelect"
                        formControlName="city"
                        [(ngModel)]="clinicProfileModel.city"
                        placeholder="{{ 'common.CityPlaceholder' | translate }}"
                        required
                      >
                        <input
                          type="text"
                          #searchCityInput
                          (keyup)="searchCities($event)"
                          (keydown)="_globalFunction.handleInput($event)"
                          placeholder="{{
                            'common.searchCityText' | translate
                          }}"
                          class="search-input"
                        />
                        <mat-option
                          *ngFor="let city of filteredCityList"
                          [value]="city.cityId"
                          >{{ city.cityName }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                    <div
                      *ngIf="
                        clinicProfileInfoValidation.controls['city'].invalid &&
                        (clinicProfileInfoValidation.controls['city'].dirty ||
                          clinicProfileInfoValidation.controls['city']
                            .touched ||
                          formSubmitted)
                      "
                    >
                      <mat-error
                        *ngIf="
                          clinicProfileInfoValidation
                            .get('city')
                            ?.hasError('required')
                        "
                        >{{ "common.RequiredCityError" | translate }}</mat-error
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-10">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field class="type-textarea">
                      <mat-label>{{
                        "clinicProfile.AddressLabel" | translate
                      }}</mat-label>
                      <textarea
                        matInput
                        type="text"
                        rows="3"
                        formControlName="address"
                        [(ngModel)]="clinicProfileModel.address"
                        placeholder="{{
                          'clinicProfile.AddressPlaceholder' | translate
                        }}"
                        required
                      ></textarea>
                    </mat-form-field>
                    <div
                      *ngIf="
                        clinicProfileInfoValidation.controls['address']
                          .invalid &&
                        (clinicProfileInfoValidation.controls['address']
                          .dirty ||
                          clinicProfileInfoValidation.controls['address']
                            .touched ||
                          formSubmitted)
                      "
                    >
                      <mat-error
                        *ngIf="
                          clinicProfileInfoValidation
                            .get('address')
                            ?.hasError('required')
                        "
                        >{{
                          "clinicProfile.RequiredAddressError" | translate
                        }}</mat-error
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field class="type-textarea">
                      <mat-label>{{
                        "clinicProfile.AboutClinicLabel" | translate
                      }}</mat-label>
                      <textarea
                        matInput
                        type="text"
                        rows="3"
                        formControlName="clinicInfo"
                        [(ngModel)]="clinicProfileModel.clinicInfo"
                        placeholder="{{
                          'clinicProfile.ClinicInfoPlaceholder' | translate
                        }}"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-10">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field class="document-input">
                      <mat-label>{{
                        "clinicProfile.ClinicPhotoLabel" | translate
                      }}</mat-label>
                      <span matPrefix
                        ><img src="assets/images/document.svg" alt=""
                      /></span>
                      <input
                        type="text"
                        matInput
                        formControlName="clinicPhoto"
                        [(ngModel)]="clinicPhotoList"
                        placeholder="{{
                          'clinicProfile.ClinicPhotoPlaceholder' | translate
                        }}"
                        readonly
                      />
                      <span matSuffix class="upload-btn">
                        <input
                          type="file"
                          onclick="this.value = null"
                          (change)="uploadClinicPhoto($event)"
                          multiple
                        />
                      </span>
                    </mat-form-field>

                    <ul class="upload-photos">
                      <div *ngFor="let photo of clinicPhotos">
                        <li *ngIf="photo.fileBytes">
                          <span class="upload-photo-image"
                            ><img
                              src="data:image/png;base64,{{ photo.fileBytes }}"
                              alt=""
                          /></span>
                          <a href="javascript:void(0)"
                            ><img
                              src="assets/images/close-icon.png"
                              alt=""
                              (click)="removeClinicPhoto(photo)"
                          /></a>
                          <p>{{ photo.fileName }}</p>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <mat-form-field class="example-form-field timezone">
                      <mat-label>{{
                        "appointment.selectTimezoneText" | translate
                      }}</mat-label>
                      <mat-select
                        placeholder="{{
                          'appointment.selectTimezoneText' | translate
                        }}"
                        formControlName="timezone"
                        [(ngModel)]="clinicProfileModel.timezone"
                      >
                        <input
                          type="text"
                          #searchTimezoneInput
                          (keyup)="searchTimeZone($event)"
                          (keydown)="_globalFunction.handleInput($event)"
                          placeholder="{{
                            'common.searchTimezoneText' | translate
                          }}"
                          class="search-input"
                        />
                        <mat-option
                          *ngFor="let tz of filteredTimezoneList"
                          [value]="tz"
                          >{{ tz.displayName }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                    <div
                      *ngIf="
                        clinicProfileInfoValidation.controls['timezone']
                          .invalid &&
                        (clinicProfileInfoValidation.controls['timezone']
                          .dirty ||
                          clinicProfileInfoValidation.controls['timezone']
                            .touched ||
                          formSubmitted)
                      "
                    >
                      <mat-error
                        *ngIf="
                          clinicProfileInfoValidation
                            .get('timezone')
                            ?.hasError('required')
                        "
                        >{{
                          "common.RequiredTimezoneError" | translate
                        }}</mat-error
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="w-100">
                    <div class="form-group">
                      <label for="">Time Format</label>
                      <div class="toggle-time">
                        <mat-slide-toggle
                          class="toggle"
                          [checked]="clinicProfileModel.timeFormat"
                          formControlName="timeFormat"
                          [(ngModel)]="clinicProfileModel.timeFormat"
                          (change)="changeTimeFormat($event)"
                        ></mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xxl-10">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <mat-form-field class="example-form-field timezone">
                      <mat-label>{{
                        "common.selectLanguageLabel" | translate
                      }}</mat-label>
                      <mat-select
                        placeholder="{{
                          'common.selectLanguageLabel' | translate
                        }}"
                        formControlName="language"
                        [(ngModel)]="clinicProfileModel.language"
                      >
                        <mat-option
                          *ngFor="let item of languageList"
                          [value]="item.languageId"
                          >{{ item.name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                    <div
                      *ngIf="
                        clinicProfileInfoValidation.controls['language']
                          .invalid &&
                        (clinicProfileInfoValidation.controls['language']
                          .dirty ||
                          clinicProfileInfoValidation.controls['language']
                            .touched ||
                          formSubmitted)
                      "
                    >
                      <mat-error
                        *ngIf="
                          clinicProfileInfoValidation
                            .get('language')
                            ?.hasError('required')
                        "
                        >{{
                          "providerProfile.requiredLanguageError" | translate
                        }}</mat-error
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <!-- <mat-label
                    >{{ "common.timeFormatText" | translate
                    }}<span class="required">*</span></mat-label
                  > -->
                </div>
              </div>
            </div>
          </div>
          <div class="btn-action">
            <button class="btn btn-primary">
              <img src="assets/images/continue-icon.svg" alt="" class="me-2" />
              {{ "common.continueButtonText" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

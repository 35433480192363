import { DatePipe } from "@angular/common";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment-timezone";
import { GlobalFunctions } from "src/app/app.global";
import { ConfirmationDialogComponent } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { clinicApiList } from "src/app/enums/clinicApiEnum";
import { superAdminApiList } from "src/app/enums/superAdminApiEnum";
import { TransactionForEnum } from "src/app/enums/transactionForEnum";
import { UserTypeEnum } from "src/app/enums/userTypeEnum";
import { ClinicService } from "src/app/modules/clinic/services/clinic.service";
import { SuperAdminService } from "src/app/modules/super-admin/services/super-admin.service";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";

@Component({
  selector: "app-refund",
  templateUrl: "./refund.component.html",
  styleUrls: ["./refund.component.css"],
})
export class RefundComponent implements OnInit {
  userRole: string = "";
  userId: any;
  userTypeEnum = UserTypeEnum;
  refundHeading = "";
  transactionForEnum = TransactionForEnum;
  formSubmitted: boolean = false;
  clinicList: any = [];
  filteredClinicList: any = [];
  patientList: any = [];
  filteredPatientList: any = [];
  appointmentList: any = [];
  subscriptionList: any = [];
  date: any = "";
  clinicUserId: string = "";
  transactionForValue: string = "4";
  patientUserId: string = "";
  appointmentId: string = "";
  subscriptionId: string = "";
  description: string = "";
  amount: any = "";
  todayDate: Date = new Date();
  @Output() showRefundPopup = new EventEmitter<boolean>();
  @Output() refreshTransactionHistoryData = new EventEmitter<boolean>();
  @ViewChild("searchPatientInput") searchPatientInput!: ElementRef;
  @ViewChild("searchClinicInput") searchClinicInput!: ElementRef;

  constructor(
    private _commonService: CommonService,
    private _superAdminService: SuperAdminService,
    private _clinicService: ClinicService,
    private _authService: AuthGuardService,
    public _globalFunction: GlobalFunctions,
    private _toastrService: ToastrNotificationService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private _renderer: Renderer2
  ) {
    this.todayDate = this._commonService.getCurrentTimeZoneNewDate();
    this._renderer.listen("window", "click", (e: Event) => {
      this.filteredPatientList = this.patientList;
      this.filteredClinicList = this.clinicList;
      if (this.searchPatientInput)
        this.searchPatientInput.nativeElement.value = "";
      if (this.searchClinicInput)
        this.searchClinicInput.nativeElement.value = "";
    });
  }

  ngOnInit(): void {
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
        this.userId = data["userid"];
      }
    });
    if (
      this.userRole === this.userTypeEnum.SuperAdmin.toString() ||
      this.userRole === this.userTypeEnum.OfficeStaff.toString()
    ) {
      this.refundHeading = "transaction.refundText";
      this.getAllClinics();
    }
    if (this.userRole === this.userTypeEnum.Clinic.toString()) {
      this.refundHeading = "transaction.appointmentRefundText";
      this.getAllPatients();
      this.clinicUserId = this.userId;
      this.transactionForValue = "1";
    }
  }

  /**
   * Method to get all clinics data
   */
  public getAllClinics() {
    this._superAdminService.getAllClinicDetails().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.clinicList = data;
          this.filteredClinicList = this.clinicList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to search clinic
   */
  public searchClinic(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.clinicList.filter((x: any) => {
      if (x.name.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredClinicList = temp;
  }

  /**
   * Method to change clinic
   */
  public changeClinic() {
    this.patientUserId = "";
    this.getAllPatientsByClinic();
    this.getAppointmentData();
    this.getAllClinicSubscriptionPlan();
  }

  /**
   * Method to get all patients data by clinic
   */
  public getAllPatientsByClinic() {
    this._superAdminService.getAllPatientByClinic(this.clinicUserId).subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.patientList = data;
          this.filteredPatientList = this.patientList;
        } else {
          this.patientList = [];
          this.filteredPatientList = this.patientList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to get all patients data
   */
  public getAllPatients() {
    this._clinicService.getAllPatientByClinic().subscribe({
      next: (data) => {
        if (data && data !== null && data.length > 0) {
          this.patientList = data;
          this.filteredPatientList = this.patientList;
        } else {
          this.patientList = [];
          this.filteredPatientList = this.patientList;
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to search patients
   */
  public searchPatient(event: any) {
    const val = event.target.value.toLowerCase();
    const temp = this.patientList.filter((x: any) => {
      if (x.name.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.filteredPatientList = temp;
  }

  /**
   * Method to close add refund section
   */
  public closeRefundPopup() {
    this.showRefundPopup.emit(false);
  }

  /**
   * Method to change date
   */
  public changeDate() {
    this.getAppointmentData();
  }

  /**
   * Method to change patient
   */
  public changePatient() {
    this.getAppointmentData();
  }

  /**
   * Method to change appointment
   */
  public changeAppointment() {
    if (this.appointmentId !== "") {
      let appointment = this.appointmentList.filter(
        (x: any) => x.appointmentId === this.appointmentId
      );
      if (appointment && appointment.length > 0)
        this.amount = appointment[0].finalPrice;
    }
  }

  /**
   * Method to change transaction for
   */
  public changeTransactionFor() {
    this.getAppointmentData();
    this.getAllClinicSubscriptionPlan();
  }

  /**
   * Method to get appointment data
   */
  public getAppointmentData() {
    if (
      this.date &&
      this.date !== "" &&
      this.patientUserId &&
      this.patientUserId !== "" &&
      this.clinicUserId &&
      this.clinicUserId !== ""
    ) {
      let appointmentParam = {
        // scheduleDate: this.datePipe.transform(
        //   this._globalFunction.getUTCDate(new Date(this.date + " UTC")),
        //   "yyyy-MM-dd"
        // ),
        scheduleDate: this.datePipe.transform(this.date, "yyyy-MM-dd"),
        clinicUserId: this.clinicUserId,
        patientUserId: this.patientUserId,
        offset: moment().tz(moment.tz.guess()).utcOffset(),
      };
      let apiPath = "";
      if (this.userRole === this.userTypeEnum.Clinic.toString())
        apiPath = clinicApiList.getAllAppointmentByClinic;
      if (
        this.userRole === this.userTypeEnum.SuperAdmin.toString() ||
        this.userRole === this.userTypeEnum.OfficeStaff.toString()
      )
        apiPath = superAdminApiList.getAllAppointmentByClinic;
      this._clinicService
        .getAppointmentByClinic(appointmentParam, apiPath)
        .subscribe({
          next: (data) => {
            if (data && data !== null && data.length > 0) {
              this.appointmentList = data;
            } else {
              this.appointmentList = [];
            }
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }

  /**
   * Method to get all clinic subscription plan by clinic id
   */
  public getAllClinicSubscriptionPlan() {
    if (
      this.transactionForValue ===
        this.transactionForEnum.SubscriptionRefund.toString() &&
      this.clinicUserId &&
      this.clinicUserId !== ""
    ) {
      this._superAdminService
        .getAllClinicSubscriptionPlan(this.clinicUserId)
        .subscribe({
          next: (data) => {
            if (data && data !== null && data.length > 0) {
              this.subscriptionList = data;
            } else {
              this.subscriptionList = [];
            }
          },
          error: (error) => {
            this._commonService.showErrorMessage(error);
          },
        });
    }
  }

  /**
   * Method to save refund
   */
  public saveRefund() {
    this.formSubmitted = true;
    if (
      this.transactionForValue !== "" &&
      (this.appointmentId !== "" || this.subscriptionId !== "") &&
      this.description !== "" &&
      this.amount !== ""
    ) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: "transaction.addRefundConfirmText",
          confirmTitleText: "common.confirmText",
          buttonText: {
            ok: "common.yesText",
            cancel: "common.noText",
          },
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          if (this.userRole === this.userTypeEnum.Clinic.toString())
            this.addClinicRefundTransaction();
          if (
            this.userRole === this.userTypeEnum.SuperAdmin.toString() ||
            this.userRole === this.userTypeEnum.OfficeStaff.toString()
          )
            this.addAdminRefundTransaction();
        }
      });
    }
  }

  /**
   * Method to add clinic refund transaction
   */
  public addClinicRefundTransaction() {
    let refundParam = {
      transactionFor: this.transactionForValue,
      referenceId: this.appointmentId,
      description: this.description,
      amount: parseFloat(this.amount),
    };
    this._clinicService.addRefundTransaction(refundParam).subscribe({
      next: (data) => {
        if (
          data &&
          data.success &&
          data.success === true &&
          data.refund === "RefundSuccess"
        ) {
          this._toastrService.showSuccess(
            "transaction.AddRefundSuccessMessage"
          );
          this.closeRefundPopup();
          this.refreshTransactionHistoryData.emit(true);
        } else {
          this._toastrService.showError("ErrorCode." + data.refund);
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }

  /**
   * Method to add admin refund transaction
   */
  public addAdminRefundTransaction() {
    let refundParam = {
      transactionFor: this.transactionForValue,
      referenceId:
        this.transactionForValue ===
        this.transactionForEnum.AppointmentRefund.toString()
          ? this.appointmentId
          : this.subscriptionId.toString(),
      description: this.description,
      amount: parseFloat(this.amount),
    };
    this._superAdminService.addRefundTransaction(refundParam).subscribe({
      next: (data) => {
        if (
          data &&
          data.success &&
          data.success === true &&
          data.refund &&
          data.refund === "RefundSuccess"
        ) {
          this._toastrService.showSuccess(
            "transaction.AddRefundSuccessMessage"
          );
          this.closeRefundPopup();
          this.refreshTransactionHistoryData.emit(true);
        } else {
          this._toastrService.showError("ErrorCode." + data.refund);
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }
}

<div class="tabs-body">
  <div class="mat-elevation-z8 table-responsive">
    <table mat-table [dataSource]="dataSource" class="table" matSort>
      <ng-container matColumnDef="patientName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "appointment.patientNameText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.patientName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="patientEmail">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "appointment.patientEmail" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.patientEmail }}</td>
      </ng-container>

      <ng-container
        matColumnDef="medicalProviderName"
        *ngIf="userRole !== userTypeEnum.Provider.toString()"
      >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "appointment.medicalProviderNameText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.medicalProviderName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="appointmentDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "appointment.appointmentDateTimeText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.appointmentDateTime | date : "MMM dd, yyyy " + timeFormat
          }}
          -
          {{ element.appointmentDateEndTime | date : timeFormat }}
          {{ currentTimeZoneName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef>
          {{ "userList.contactLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.contact }}
        </td>
      </ng-container>

      <ng-container matColumnDef="visitType">
        <th mat-header-cell *matHeaderCellDef>
          {{ "settings.visitTypeLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.visitType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="insurance">
        <th mat-header-cell *matHeaderCellDef>
          {{ "appointment.insuranceText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.insurance }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{ "userList.actionLabelText" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="table-action">
            <a href="javascript:void(0)" (click)="this.viewAppointment(element)"
              ><img src="assets/images/view-icon.svg" alt=""
            /></a>
            <a
              href="javascript:void(0)"
              (click)="this.editAppointment(element)"
              *ngIf="
                !_commonService.isAppointmentPastDate(
                  element.appointmentDateEndTime
                )
              "
              ><img src="assets/images/edit-icon.svg" alt=""
            /></a>
            <div
              class="dropdown"
              *ngIf="
              _commonService.isAppointmentPastDate(
                  element.appointmentDateEndTime
                )
              "
            >
              <a
                href="javascript:void(0)"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><img src="assets/images/edit-icon.svg" alt=""
              /></a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    (click)="this.missedAppointment(element)"
                    >{{ "appointment.missedAppointmentText" | translate }}</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    (click)="this.completeAppointment(element)"
                    >{{ "appointment.completeAppointmentText" | translate }}</a
                  >
                </li>
              </ul>
            </div>
            <a
              *ngIf="element.appointmentStatus !== 5"
              href="javascript:void(0)"
              (click)="this.deleteAppointment(element)"
              ><img src="assets/images/delete-icon.svg" alt=""
            /></a>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          {{ "userList.noResultFoundText" | translate }}
        </td>
      </tr>
    </table>
  </div>
  <mat-paginator
    [hidden]="!showPaginator"
    #paginator
    style-paginator
    showFirstLastButtons
    itemsPerPageLabel="Page Size"
    [length]="totalRows"
    [pageIndex]="currentPage"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageChanged($event)"
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>

import { DatePipe } from "@angular/common";
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  Renderer2,
  ViewChild,
  ChangeDetectionStrategy,
} from "@angular/core";
import * as moment from "moment-timezone";
import { GlobalFunctions } from "src/app/app.global";
import { MatMenuTrigger } from "@angular/material/menu";
import { HoverMenuDirective } from "src/app/directives/hover-menu.directive";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { CommonService } from "src/app/services/common.service";
@Component({
  selector: "app-clinic-dashboard-day-view",
  templateUrl: "./clinic-dashboard-day-view.component.html",
  styleUrls: ["./clinic-dashboard-day-view.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicDashboardDayViewComponent implements OnInit, OnChanges {
  @Input() viewType: string = "";
  dayHours: any = [];
  filteredDashboardDataList: any = [];
  @Input() dashboardDataList: any = [];
  @Input() providerList: any = [];
  @Input() filterMultiselectProviderValue: any = [];
  @Input() refreshDataView: boolean = false;
  @Input() searchData: string = "";
  @Output() viewAppointmentData = new EventEmitter<any>();
  @Output() addAppointmentBySlot = new EventEmitter<any>();
  @Output() pasteAppointmentData = new EventEmitter<any>();
  contextMenuElement!: HTMLElement;
  showCutOption: boolean = false;
  showPasteOption: boolean = false;
  cutPasteAppointentData: any = null;
  cutAppointentData: any = null;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @ViewChild(HoverMenuDirective) hoverMenu!: HoverMenuDirective;
  currentTimeZoneName: any;
  timeFormat: any;
  timeFormatCaps: any;
  constructor(
    public _commonService: CommonService,
    public _globalFunction: GlobalFunctions,
    private _toastrService: ToastrNotificationService,
    private datePipe: DatePipe,
    private _renderer: Renderer2
  ) {
    this.timeFormat = this._commonService.getTimeFormat();
    this.timeFormatCaps = this._commonService.getTimeFormat(true);
    this.currentTimeZoneName = this._commonService.getTimeZoneShortName();
    this._renderer.listen("window", "click", (e: Event) => {
      if (this.contextMenuElement) {
        let isClassExists = this.contextMenuElement.classList.contains("show");
        if (isClassExists) {
          this.contextMenuElement.classList.remove("show");
          this.cutPasteAppointentData = null;
        }
      }
    });
  }

  ngOnInit(): void {
    this.dayHours = this._globalFunction.getDayHours("60", "00:00");
  }

  ngOnChanges(): void {
    this.filteredDashboardDataList = this.dashboardDataList;
    if (this.refreshDataView) this.setDayDataView();
    if (this.searchData) {
      this.filteredDashboardDataList = this.dashboardDataList.filter(
        (x: any) =>
          x.patientName !== null &&
          x.patientName.toLowerCase().includes(this.searchData.toLowerCase())
      );
    }
  }

  ngAfterViewInit() {
    // this.hoverMenu.trigger = this.trigger;
  }

  /**
   * Method to set day data view
   */
  public setDayDataView() {
    if (this.viewType === "day") {
      this.dashboardDataList.forEach((element: any) => {
        // let startTime = moment(element.appointmentDateTime).format("HH:mm");
        // let utcStartTime: any = moment(
        //   this.datePipe.transform(new Date(), "MM/dd/yyyy") + " " + startTime
        // ).tz(moment.tz.guess());
        // element.formattedStartTime = moment(utcStartTime._d).format("HH:mm");
        // element.appointmentLocalStartDateTime = utcStartTime._d;

        element.formattedStartTime = moment(element.appointmentDateTime).format(
          this.timeFormatCaps
        );
        element.appointmentLocalStartDateTime = element.appointmentDateTime;

        // let endTime = moment(element.appointmentDateEndTime).format("HH:mm");
        // let utcEndTime: any = moment(
        //   this.datePipe.transform(new Date(), "MM/dd/yyyy") + " " + endTime
        // ).tz(moment.tz.guess());
        // element.formattedEndTime = moment(utcEndTime._d).format("HH:mm");
        element.formattedEndTime = moment(
          element.appointmentDateEndTime
        ).format(this.timeFormatCaps);
        element.patientNameInitials = element.patientName
          ? this._globalFunction.getNameInitials(
              element.patientFirstName,
              element.patientLastName
            )
          : "";

        this._globalFunction.setPatientProfilePhoto(element);

        element.isAppointmentPastDate =
          this._commonService.isAppointmentPastDate(
            element.appointmentDateTime
          );
      });

      setTimeout(() => {
        let currentHour: any = this._commonService.getCurrentTimeZoneNewDate().getHours();
        currentHour = (currentHour < 10 ? "0" : "") + currentHour;
        const element = document.getElementById("tr_" + currentHour + ":00:00");
        if (element && this.dashboardDataList.length > 0)
          element.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  }

  /**
   * Method to get dashboard data by provider and slot
   */
  public getDashboardDataByProviderAndSlot(providerUserId: any, time: any) {
    let hour = parseInt(time.substr(0, 2));
    let dashboardData = [];
    if (
      this.filteredDashboardDataList &&
      this.filteredDashboardDataList.length > 0
    ) {
      dashboardData = this.filteredDashboardDataList.filter(
        (x: any) =>
          x.providerUserId === providerUserId &&
          moment(x.appointmentLocalStartDateTime).hour() >= hour &&
          moment(x.appointmentLocalStartDateTime).hour() < hour + 1
      );
    }

    return dashboardData;
  }

  /**
   * Method to view appointment
   */
  public viewAppointment(data: any) {
    if (data && data.appointmentId && data.appointmentId !== null) {
      this.viewAppointmentData.emit(data);
    }
  }

  /**
   * Method to add appointment
   */
  public addAppointment(data: any) {
    if (
      data &&
      !data.appointmentId &&
      !data.isBlocked &&
      !this._commonService.isAppointmentPastDate(data.appointmentDateTime)
    ) {
      this.addAppointmentBySlot.emit(data);
    }
  }

  /**
   * Method to show cut-paste menu on mouse right click
   */
  public onRightClick(event: MouseEvent, data: any) {
    event.preventDefault();
    event.stopPropagation();
    let isClassExists = this.contextMenuElement?.classList?.contains("show");
    if (isClassExists) {
      this.contextMenuElement.classList.remove("show");
    }

    if (
      data.appointmentId !== null ||
      !this._commonService.isAppointmentPastDate(data.appointmentDateTime)
    ) {
      this.cutPasteAppointentData = data;
      this.showCutOption =
        this.cutAppointentData === null &&
        this.cutPasteAppointentData?.appointmentId !== null
          ? true
          : false;
      this.showPasteOption = this.cutAppointentData !== null ? true : false;
      const dropdown = event.currentTarget as HTMLElement;
      this.contextMenuElement = dropdown?.parentNode
        ?.children[1] as HTMLElement;
      this.contextMenuElement.classList.add("show");
    }
    return false;
  }

  /**
   * Method to cut the appointment
   */
  public cutAppointment(data: any) {
    this.cutAppointentData = data;
    this._toastrService.showInfo("common.cutAppointmentMessage");
  }

  /**
   * Method to paste the appointment to other time slot
   */
  public pasteAppointment(data: any) {
    this.cutAppointentData.pasteSlotData = data;
    this.pasteAppointmentData.emit(this.cutAppointentData);
    this.cutPasteAppointentData = null;
    this.cutAppointentData = null;
  }

  /**
   * Method to clear the cut appointment data
   */
  public clearCutAppointment() {
    this.cutPasteAppointentData = null;
    this.cutAppointentData = null;
  }

  /**
   * Method to drag start on appointment
   */
  public onDragStart(event: DragEvent, appointmentData: any) {
    this.hoverMenu.clearTimer();
    this.cutAppointentData = appointmentData;
  }

  /**
   * Method to drag over on appointment
   */
  public onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  /**
   * Method to drop the dragged appointment data on empty slot
   */
  public onDrop(event: DragEvent, dropAppointmentSlot: any) {
    this.cutAppointentData.pasteSlotData = dropAppointmentSlot;
    this.pasteAppointmentData.emit(this.cutAppointentData);
    this.cutPasteAppointentData = null;
    this.cutAppointentData = null;
  }
}

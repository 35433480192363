import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./components/footer/footer/footer.component";
import { HeaderComponent } from "./components/header/header/header.component";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MatIconModule } from "@angular/material/icon";
import { LoginComponent } from "./components/login/login.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { SignupEmailComponent } from "./components/signup-email/signup-email.component";
import { SignupEmailVerificationComponent } from "./components/signup-email-verification/signup-email-verification.component";
import { SignupComponent } from "./components/signup/signup.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { NetworkInterceptor } from "./services/network.interceptor";
import { LeftMenuComponent } from "./components/left-menu/left-menu.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ToastrModule } from "ngx-toastr";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTableModule } from "@angular/material/table";
import { TitleStrategy } from "@angular/router";
import { CustomPageTitleStrategy } from "./services/custom-page-title-strategy";
import { ClinicProfileComponent } from "./modules/clinic/components/clinic-profile/clinic-profile.component";
import { UserListComponent } from "./modules/user/components/user-list/user-list.component";
import { AddEditUserComponent } from "./modules/user/components/add-edit-user/add-edit-user.component";
import { ViewUserComponent } from "./modules/user/components/view-user/view-user.component";
import { PatientsComponent } from "./modules/user/components/patients/patients.component";
import { MedicalProvidersComponent } from "./modules/user/components/medical-providers/medical-providers.component";
import { StaffComponent } from "./modules/user/components/staff/staff.component";
import { MatSortModule } from "@angular/material/sort";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { GlobalFunctions } from "./app.global";
import { NotFoundErrorComponent } from "./components/not-found-error/not-found-error.component";
import { UnAuthorizationComponent } from "./components/unauthorization/unauthorization.component";
import { StylePaginatorDirective } from "./directives/style-paginator.directive";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { SuperAdminLoginComponent } from "./modules/super-admin/components/login/login.component";
import { SuperAdminForgotPasswordComponent } from "./modules/super-admin/components/forgot-password/forgot-password.component";
import { SuperAdminResetPasswordComponent } from "./modules/super-admin/components/reset-password/reset-password.component";
import { ClinicListComponent } from "./modules/super-admin/components/clinic-list/clinic-list.component";
import { ClinicRequestListComponent } from "./modules/super-admin/components/clinic-request-list/clinic-request-list.component";
import { ManageClinicComponent } from "./modules/super-admin/components/manage-clinic/manage-clinic.component";
import { DatePipe } from "@angular/common";
import { AddEditClinicComponent } from "./modules/super-admin/components/add-edit-clinic/add-edit-clinic.component";
import { ViewClinicComponent } from "./modules/super-admin/components/view-clinic/view-clinic.component";
import { ViewEditClinicRequestComponent } from "./modules/super-admin/components/view-edit-clinic-request/view-edit-clinic-request.component";
import { SettingsComponent } from "./modules/super-admin/components/settings/settings.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatRadioModule } from "@angular/material/radio";
import { AddEditOfficestaffUserComponent } from "./modules/super-admin/components/add-edit-officestaff-user/add-edit-officestaff-user.component";
import { ViewOfficestaffUserComponent } from "./modules/super-admin/components/view-officestaff-user/view-officestaff-user.component";
import { OfficestaffUserComponent } from "./modules/super-admin/components/officestaff-user/officestaff-user.component";
import { OfficestaffUserListComponent } from "./modules/super-admin/components/officestaff-user-list/officestaff-user-list.component";
import { ClinicSettingsComponent } from "./modules/clinic/components/clinic-settings/clinic-settings.component";
import { ProviderProfileComponent } from "./modules/clinic/components/provider-profile/provider-profile.component";
import { SchedulerListComponent } from "./modules/clinic/components/scheduler-list/scheduler-list.component";
import { SchedulerComponent } from "./modules/clinic/components/scheduler/scheduler.component";
import { AddEditSchedulerComponent } from "./modules/clinic/components/add-edit-scheduler/add-edit-scheduler.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { SubscriptionDetailsComponent } from "./modules/clinic/components/subscription-details/subscription-details.component";
import { UpgradeSubscriptionComponent } from "./modules/clinic/components/upgrade-subscription/upgrade-subscription.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { AppointmentListComponent } from "./modules/appointment/components/appointment-list/appointment-list.component";
import { UpcomingAppointmentsComponent } from "./modules/appointment/components/upcoming-appointments/upcoming-appointments.component";
import { AppointmentHistoryComponent } from "./modules/appointment/components/appointment-history/appointment-history.component";
import { AddAppointmentComponent } from "./modules/appointment/components/add-appointment/add-appointment.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ViewAppointmentComponent } from "./modules/appointment/components/view-appointment/view-appointment.component";
import { NgxSlickJsModule } from "ngx-slickjs";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { NotificationListComponent } from "./modules/notifications/components/notification-list/notification-list.component";
import { NotificationComponent } from "./modules/notifications/components/notification/notification.component";
import { TransactionHistoryListComponent } from "./modules/transaction/components/transaction-history-list/transaction-history-list.component";
import { ClinicTransactionHistoryComponent } from "./modules/transaction/components/clinic-transaction-history/clinic-transaction-history.component";
import { AdminTransactionHistoryComponent } from "./modules/transaction/components/admin-transaction-history/admin-transaction-history.component";
import { AddEditWalletComponent } from "./modules/transaction/components/add-edit-wallet/add-edit-wallet.component";
import { FilterComponent } from "./components/filter/filter.component";
import { StaticContentListComponent } from "./modules/super-admin/components/static-content-list/static-content-list.component";
import { StaticContentComponent } from "./modules/super-admin/components/static-content/static-content.component";
import { AddEditStaticContentComponent } from "./modules/super-admin/components/add-edit-static-content/add-edit-static-content.component";
import { CameraComponent } from "./modules/live-consultation/components/camera/camera.component";
import { HomeComponent } from "./modules/live-consultation/components/home/home.component";
import { ParticipantsComponent } from "./modules/live-consultation/components/participants/participants.component";
import { RoomsComponent } from "./modules/live-consultation/components/rooms/rooms.component";
import { DeviceSelectComponent } from "./modules/live-consultation/components/settings/device-select.component";
import { LiveConsultationSettingsComponent } from "./modules/live-consultation/components/settings/settings.component";
import { DeviceService } from "./modules/live-consultation/services/device.service";
import { VideoChatService } from "./modules/live-consultation/services/videochat.service";
import { StorageService } from "./modules/live-consultation/services/storage.service";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { ViewTransactionComponent } from "./modules/transaction/components/view-transaction/view-transaction.component";
import { RefundComponent } from "./modules/transaction/components/refund/refund.component";
import { StaticContentDialogComponent } from './components/static-content-dialog/static-content-dialog.component';
import { ClinicDashboardComponent } from './modules/clinic/components/clinic-dashboard/clinic-dashboard.component';
import { ClinicDashboardDayViewComponent } from './modules/clinic/components/clinic-dashboard-day-view/clinic-dashboard-day-view.component';
import { ClinicDashboardWeekViewComponent } from './modules/clinic/components/clinic-dashboard-week-view/clinic-dashboard-week-view.component';
import { ClinicDashboardMonthViewComponent } from './modules/clinic/components/clinic-dashboard-month-view/clinic-dashboard-month-view.component';
import { ClinicDashboardAddAppointmentComponent } from './modules/clinic/components/clinic-dashboard-add-appointment/clinic-dashboard-add-appointment.component';
import {MatMenuModule} from '@angular/material/menu';
import { HoverMenuDirective } from './directives/hover-menu.directive';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SignupEmailComponent,
    SignupEmailVerificationComponent,
    SignupComponent,
    SpinnerComponent,
    LeftMenuComponent,
    ClinicProfileComponent,
    UserListComponent,
    AddEditUserComponent,
    ViewUserComponent,
    PatientsComponent,
    MedicalProvidersComponent,
    StaffComponent,
    StylePaginatorDirective,
    NotFoundErrorComponent,
    UnAuthorizationComponent,
    SuperAdminLoginComponent,
    SuperAdminForgotPasswordComponent,
    SuperAdminResetPasswordComponent,
    ClinicListComponent,
    ClinicRequestListComponent,
    ManageClinicComponent,
    AddEditClinicComponent,
    ViewClinicComponent,
    ViewEditClinicRequestComponent,
    SettingsComponent,
    OfficestaffUserListComponent,
    AddEditOfficestaffUserComponent,
    ViewOfficestaffUserComponent,
    OfficestaffUserComponent,
    ClinicSettingsComponent,
    ProviderProfileComponent,
    SchedulerListComponent,
    SchedulerComponent,
    AddEditSchedulerComponent,
    SubscriptionDetailsComponent,
    UpgradeSubscriptionComponent,
    BreadcrumbComponent,
    AppointmentListComponent,
    UpcomingAppointmentsComponent,
    AppointmentHistoryComponent,
    AddAppointmentComponent,
    ViewAppointmentComponent,
    ConfirmationDialogComponent,
    NotificationListComponent,
    NotificationComponent,
    TransactionHistoryListComponent,
    ClinicTransactionHistoryComponent,
    AdminTransactionHistoryComponent,
    AddEditWalletComponent,
    FilterComponent,
    StaticContentListComponent,
    StaticContentComponent,
    AddEditStaticContentComponent,
    ViewTransactionComponent,
    CameraComponent,
    HomeComponent,
    ParticipantsComponent,
    RoomsComponent,
    DeviceSelectComponent,
    LiveConsultationSettingsComponent,
    RefundComponent,
    StaticContentDialogComponent,
    ClinicDashboardComponent,
    ClinicDashboardDayViewComponent,
    ClinicDashboardWeekViewComponent,
    ClinicDashboardMonthViewComponent,
    ClinicDashboardAddAppointmentComponent,
    HoverMenuDirective,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
    }),
    MatPasswordStrengthModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatRadioModule,
    MatSlideToggleModule,
    NgxMaterialTimepickerModule,
    MatAutocompleteModule,
    NgxSlickJsModule.forRoot(),
    MatDialogModule,
    CKEditorModule,
    MatMenuModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: NetworkInterceptor, multi: true },
    { provide: TitleStrategy, useClass: CustomPageTitleStrategy },
    CustomPageTitleStrategy,
    GlobalFunctions,
    DatePipe,
    HeaderComponent,
    DeviceService,
    VideoChatService,
    StorageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/");
}

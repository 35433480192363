<h1 mat-dialog-title>{{ confirmTitleText | translate }}</h1>
<mat-dialog-content>
  <p>
    {{ message | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button class="btn btn-secondary me-3" (click)="onConfirmClick()" tabindex="1">
    {{ confirmButtonText | translate }}
  </button>
  <button class="btn btn-primary" mat-dialog-close tabindex="-1">
    {{ cancelButtonText | translate }}
  </button>
</mat-dialog-actions>

import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalSpinnerService {
  visibility = new Subject();
  spinnerState = <Observable<boolean>>this.visibility;

  constructor() {}

  /**
   * Method to show loader
   */
  show() {
    this.visibility.next(true);
  }

  /**
   * Method to hide loader
   */
  hide() {
    this.visibility.next(false);
  }
}

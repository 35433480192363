<div
  class="table-responsive month-day"
  [ngClass]="{ 'd-none': viewType !== 'month' }"
>
  <table>
    <thead>
      <tr>
        <th *ngFor="let day of weekDays">{{ day }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td *ngFor="let day of weekDays">
          <div *ngFor="let currentDay of monthWeek1">
            <div
              class="schedule-date"
              *ngIf="(currentDay.day | date : 'EEEE') === day"
            >
              {{ currentDay.day | date : "MMM dd" }}
            </div>
            <div
              class="schedule-appointment"
              *ngIf="(currentDay.day | date : 'EEEE') === day"
            >
              <a
                href="javascript:void(0)"
                [ngClass]="{
                  booked: slot.appointmentId !== null,
                  'read-only':
                    (slot.appointmentId === null &&
                      slot.isAppointmentPastDate) ||
                    slot.isBlocked
                }"
                *ngFor="let slot of currentDay.slots"
                ><span
                  *ngIf="slot.appointmentId !== null"
                  [matMenuTriggerFor]="menu"
                  [matMenuTriggerData]="{slot}"
                  hoverMenu
                  #week1CutMenuTrigger="matMenuTrigger"
                  (mouseenter)="hoverMenu.openMenu(week1CutMenuTrigger)"
                  (mouseleave)="hoverMenu.clearTimer()"
                  [draggable]="!slot.isAppointmentPastDate"
                  (dragstart)="onDragStart($event, slot)"
                  >{{ slot.patientNameInitials }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    cutAppointentData &&
                    !slot.isAppointmentPastDate
                  "
                  (click)="addAppointment(slot)"
                  (dragover)="onDragOver($event)"
                  (drop)="onDrop($event, slot)"
                  >{{ slot.formattedStartTime }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    (cutAppointentData === null || slot.isAppointmentPastDate)
                  "
                  (click)="addAppointment(slot)"
                  >{{ slot.formattedStartTime }}</span
                >
              </a>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td *ngFor="let day of weekDays">
          <div *ngFor="let currentDay of monthWeek2">
            <div
              class="schedule-date"
              *ngIf="(currentDay.day | date : 'EEEE') === day"
            >
              {{ currentDay.day | date : "MMM dd" }}
            </div>
            <div
              class="schedule-appointment"
              *ngIf="(currentDay.day | date : 'EEEE') === day"
            >
              <a
                href="javascript:void(0)"
                [ngClass]="{
                  booked: slot.appointmentId !== null,
                  'read-only':
                    (slot.appointmentId === null &&
                      slot.isAppointmentPastDate) ||
                    slot.isBlocked
                }"
                *ngFor="let slot of currentDay.slots"
                ><span
                  *ngIf="slot.appointmentId !== null"
                  [matMenuTriggerFor]="menu"
                  [matMenuTriggerData]="{slot}"
                  hoverMenu
                  #week2CutMenuTrigger="matMenuTrigger"
                  (mouseenter)="hoverMenu.openMenu(week2CutMenuTrigger)"
                  (mouseleave)="hoverMenu.clearTimer()"
                  [draggable]="!slot.isAppointmentPastDate"
                  (dragstart)="onDragStart($event, slot)"
                  >{{ slot.patientNameInitials }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    cutAppointentData &&
                    !slot.isAppointmentPastDate
                  "
                  (click)="addAppointment(slot)"
                  (dragover)="onDragOver($event)"
                  (drop)="onDrop($event, slot)"
                  >{{ slot.formattedStartTime }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    (cutAppointentData === null || slot.isAppointmentPastDate)
                  "
                  (click)="addAppointment(slot)"
                  >{{ slot.formattedStartTime }}</span
                ></a
              >
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td *ngFor="let day of weekDays">
          <div *ngFor="let currentDay of monthWeek3">
            <div
              class="schedule-date"
              *ngIf="(currentDay.day | date : 'EEEE') === day"
            >
              {{ currentDay.day | date : "MMM dd" }}
            </div>
            <div
              class="schedule-appointment"
              *ngIf="(currentDay.day | date : 'EEEE') === day"
            >
              <a
                href="javascript:void(0)"
                [ngClass]="{
                  booked: slot.appointmentId !== null,
                  'read-only':
                    (slot.appointmentId === null &&
                      slot.isAppointmentPastDate) ||
                    slot.isBlocked
                }"
                *ngFor="let slot of currentDay.slots"
                ><span
                  *ngIf="slot.appointmentId !== null"
                  [matMenuTriggerFor]="menu"
                  [matMenuTriggerData]="{slot}"
                  hoverMenu
                  #week3CutMenuTrigger="matMenuTrigger"
                  (mouseenter)="hoverMenu.openMenu(week3CutMenuTrigger)"
                  (mouseleave)="hoverMenu.clearTimer()"
                  [draggable]="!slot.isAppointmentPastDate"
                  (dragstart)="onDragStart($event, slot)"
                  >{{ slot.patientNameInitials }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    cutAppointentData &&
                    !slot.isAppointmentPastDate
                  "
                  (click)="addAppointment(slot)"
                  (dragover)="onDragOver($event)"
                  (drop)="onDrop($event, slot)"
                  >{{ slot.formattedStartTime }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    (cutAppointentData === null || slot.isAppointmentPastDate)
                  "
                  (click)="addAppointment(slot)"
                  >{{ slot.formattedStartTime }}</span
                ></a
              >
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td *ngFor="let day of weekDays">
          <div *ngFor="let currentDay of monthWeek4">
            <div
              class="schedule-date"
              *ngIf="(currentDay.day | date : 'EEEE') === day"
            >
              {{ currentDay.day | date : "MMM dd" }}
            </div>
            <div
              class="schedule-appointment"
              *ngIf="(currentDay.day | date : 'EEEE') === day"
            >
              <a
                href="javascript:void(0)"
                [ngClass]="{
                  booked: slot.appointmentId !== null,
                  'read-only':
                    (slot.appointmentId === null &&
                      slot.isAppointmentPastDate) ||
                    slot.isBlocked
                }"
                *ngFor="let slot of currentDay.slots"
                ><span
                  *ngIf="slot.appointmentId !== null"
                  [matMenuTriggerFor]="menu"
                  [matMenuTriggerData]="{slot}"
                  hoverMenu
                  #week4CutMenuTrigger="matMenuTrigger"
                  (mouseenter)="hoverMenu.openMenu(week4CutMenuTrigger)"
                  (mouseleave)="hoverMenu.clearTimer()"
                  [draggable]="!slot.isAppointmentPastDate"
                  (dragstart)="onDragStart($event, slot)"
                  >{{ slot.patientNameInitials }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    cutAppointentData &&
                    !slot.isAppointmentPastDate
                  "
                  (click)="addAppointment(slot)"
                  (dragover)="onDragOver($event)"
                  (drop)="onDrop($event, slot)"
                  >{{ slot.formattedStartTime }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    (cutAppointentData === null || slot.isAppointmentPastDate)
                  "
                  (click)="addAppointment(slot)"
                  >{{ slot.formattedStartTime }}</span
                ></a
              >
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td *ngFor="let day of weekDays">
          <div *ngFor="let currentDay of monthWeek5">
            <div
              class="schedule-date"
              *ngIf="(currentDay.day | date : 'EEEE') === day"
            >
              {{ currentDay.day | date : "MMM dd" }}
            </div>
            <div
              class="schedule-appointment"
              *ngIf="(currentDay.day | date : 'EEEE') === day"
            >
              <a
                href="javascript:void(0)"
                [ngClass]="{
                  booked: slot.appointmentId !== null,
                  'read-only':
                    (slot.appointmentId === null &&
                      slot.isAppointmentPastDate) ||
                    slot.isBlocked
                }"
                *ngFor="let slot of currentDay.slots"
                ><span
                  *ngIf="slot.appointmentId !== null"
                  [matMenuTriggerFor]="menu"
                  [matMenuTriggerData]="{slot}"
                  hoverMenu
                  #week5CutMenuTrigger="matMenuTrigger"
                  (mouseenter)="hoverMenu.openMenu(week5CutMenuTrigger)"
                  (mouseleave)="hoverMenu.clearTimer()"
                  [draggable]="!slot.isAppointmentPastDate"
                  (dragstart)="onDragStart($event, slot)"
                  >{{ slot.patientNameInitials }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    cutAppointentData &&
                    !slot.isAppointmentPastDate
                  "
                  (click)="addAppointment(slot)"
                  (dragover)="onDragOver($event)"
                  (drop)="onDrop($event, slot)"
                  >{{ slot.formattedStartTime }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    (cutAppointentData === null || slot.isAppointmentPastDate)
                  "
                  (click)="addAppointment(slot)"
                  >{{ slot.formattedStartTime }}</span
                ></a
              >
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="monthWeek6 && monthWeek6.length > 0">
        <td *ngFor="let day of weekDays">
          <div *ngFor="let currentDay of monthWeek6">
            <div
              class="schedule-date"
              *ngIf="(currentDay.day | date : 'EEEE') === day"
            >
              {{ currentDay.day | date : "MMM dd" }}
            </div>
            <div
              class="schedule-appointment"
              *ngIf="(currentDay.day | date : 'EEEE') === day"
            >
              <a
                href="javascript:void(0)"
                [ngClass]="{
                  booked: slot.appointmentId !== null,
                  'read-only':
                    (slot.appointmentId === null &&
                      slot.isAppointmentPastDate) ||
                    slot.isBlocked
                }"
                *ngFor="let slot of currentDay.slots"
                ><span
                  *ngIf="slot.appointmentId !== null"
                  [matMenuTriggerFor]="menu"
                  [matMenuTriggerData]="{slot}"
                  hoverMenu
                  #week6CutMenuTrigger="matMenuTrigger"
                  (mouseenter)="hoverMenu.openMenu(week6CutMenuTrigger)"
                  (mouseleave)="hoverMenu.clearTimer()"
                  [draggable]="!slot.isAppointmentPastDate"
                  (dragstart)="onDragStart($event, slot)"
                  >{{ slot.patientNameInitials }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    cutAppointentData &&
                    !slot.isAppointmentPastDate
                  "
                  (click)="addAppointment(slot)"
                  (dragover)="onDragOver($event)"
                  (drop)="onDrop($event, slot)"
                  >{{ slot.formattedStartTime }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    (cutAppointentData === null || slot.isAppointmentPastDate)
                  "
                  (click)="addAppointment(slot)"
                  >{{ slot.formattedStartTime }}</span
                ></a
              >
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- <mat-menu #menu="matMenu">
    <ng-template matMenuContent let-slotData="slot">
      <div class="view-profile-menu">
        <div
          class="view-profile-header"
          *ngIf="slotData?.appointmentId !== null"
        >
          <div class="image">
            <img src="{{ slotData?.patientProfilePhoto }}" alt="" />
          </div>
          <span class="name">{{ slotData?.patientNameInitials }}</span>
          <span class="close-icon">
            <img src="assets/images/close-icon.png" alt="" />
          </span>
        </div>
        <div class="view-profile-body">
          <ul>
            <li *ngIf="slotData?.appointmentId !== null">
              <span>{{ slotData?.patientDOB | date : "MM/dd/yyyy" }}</span>
            </li>
            <li *ngIf="slotData?.appointmentId !== null">
              <span>{{ slotData?.patientEmail }}</span>
            </li>
            <li *ngIf="slotData?.appointmentId !== null">
              <span>{{ slotData?.patientContact }}</span>
            </li>
            <li *ngIf="slotData.appointmentId !== null">
              <span>{{
                slotData.appointmentDateTime | date : "EEE, MMM dd"
              }}</span>
            </li>
            <li *ngIf="slotData.appointmentId !== null">
              <span
                >{{ slotData.appointmentDateTime | date : "hh:mm a" }} -
                {{ slotData.appointmentDateEndTime | date : "hh:mm a" }}
                {{ currentTimeZoneName }}</span
              >
            </li>
            <li *ngIf="slotData.appointmentId !== null">
              <span>{{ slotData.insurance }}</span>
            </li>
            <li *ngIf="slotData.appointmentId !== null">
              <span>{{ slotData.visitType }}: ${{ slotData.finalPrice }}</span>
            </li>
            <li>
              <button
                class="btn btn-primary mr-2"
                (click)="cutAppointment(slotData)"
                *ngIf="
                  !this._commonService.isAppointmentPastDate(
                    slotData?.appointmentDateTime
                  )
                "
              >
                {{ "common.cutText" | translate }}
              </button>
              <button
                class="btn btn-primary mr-2"
                (click)="viewAppointment(slotData)"
              >
                {{ "common.viewText" | translate }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>
  </mat-menu> -->
  <mat-menu #menu="matMenu">
    <ng-template matMenuContent let-slotData="slot">
      <div class="view-profile-menu">
        <div
          class="view-profile-header"
          *ngIf="slotData.appointmentId !== null"
        >
          <div class="view-profile-header-name">
            <div class="image">
              <img src="{{ slotData.patientProfilePhoto }}" alt="" />
            </div>
            <span class="name">{{ slotData.patientName }}</span>
          </div>

          <div class="view-profile-header-right">
            <button
              class="btn btn-secondary"
              (click)="viewAppointment(slotData)"
            >
              {{ "common.viewText" | translate }}
            </button>
            <!-- <span class="close-icon">
              <img src="assets/images/close-icon.png" alt="" />
            </span> -->
          </div>
        </div>
        <div class="view-profile-body">
          <ul>
            <li *ngIf="slotData.appointmentId !== null">
              <span>{{ slotData.patientDOB | date : "MMM dd, yyyy" }}</span>
            </li>
            <li *ngIf="slotData.appointmentId !== null">
              <span>{{ slotData.patientEmail }}</span>
            </li>
            <li *ngIf="slotData.appointmentId !== null">
              <span>{{ slotData.patientContact }}</span>
            </li>
          </ul>

          <div class="view-profile-more-details">
            <p *ngIf="slotData.appointmentId !== null">
              {{ slotData.appointmentDateTime | date : "MMM dd, yyyy" }}
            </p>
            <p *ngIf="slotData.appointmentId !== null">
              {{ slotData.appointmentDateTime | date : timeFormat }} -
              {{ slotData.appointmentDateEndTime | date : timeFormat }}
              {{ currentTimeZoneName }}
            </p>
            <p *ngIf="slotData.appointmentId !== null">
              {{ slotData.insurance }}
            </p>
            <p *ngIf="slotData.appointmentId !== null">
              {{ slotData.visitType }}: ${{ slotData.finalPrice }}
            </p>
          </div>
          <!-- <div class="view-profile-buttons">
            <button
              class="btn btn-primary mr-2"
              (click)="cutAppointment(slotData)"
              *ngIf="
                !this._commonService.isAppointmentPastDate(
                  slotData.appointmentDateTime
                )
              "
            >
              {{ "common.cutText" | translate }}
            </button>
            <button
              class="btn btn-primary mr-2"
              (click)="viewAppointment(slotData)"
            >
              {{ "common.viewText" | translate }}
            </button>
          </div> -->
          <!-- <div class="profile-alert-blue">
            <span>Health Insurance</span>
  
            <img
              src="assets/images/alert-icon.png"
              alt=""
              width="15px"
            />
          </div>
          <div class="profile-alert-yellow">
            <span>Paid</span>
  
            <img
              src="assets/images/alert-icon.png"
              alt=""
              width="15px"
            />
          </div> -->
        </div>
      </div>
    </ng-template>
  </mat-menu>
  <mat-menu #cutpastemenu="matMenu">
    <ng-template matMenuContent let-slotData="slot">
      <div class="view-profile-menu">
        <div class="view-profile-body">
          <ul>
            <li>
              <span>{{ slotData?.visitType }}</span>
            </li>
            <li>
              <span>{{
                _globalFunction.convertUTCTimeToLocalTime(slotData?.startTime)
              }}</span>
            </li>
            <li>
              <span>${{ slotData?.price }}</span>
            </li>
            <li *ngIf="cutAppointentData">
              <button
                class="btn btn-primary mr-2"
                (click)="pasteAppointment(slotData)"
              >
                {{ "common.pasteText" | translate }}
              </button>
              <button
                class="btn btn-primary mr-2"
                (click)="clearCutAppointment()"
              >
                {{ "addUser.cancelButtonText" | translate }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>
  </mat-menu>
</div>

import { connect, ConnectOptions, LocalTrack, Room } from "twilio-video";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ReplaySubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { videoApiList } from "src/app/enums/videoApiEnum";
import { CommonService } from "src/app/services/common.service";
import { RestAPIService } from "src/app/services/restapi.service";

interface AuthToken {
  token: string;
}

export interface NamedRoom {
  id: string;
  name: string;
  maxParticipants?: number;
  participantCount: number;
}

export type Rooms = NamedRoom[];

@Injectable()
export class VideoChatService {
  $roomsUpdated: Observable<boolean>;

  private roomBroadcast = new ReplaySubject<boolean>();

  constructor(
    private readonly http: HttpClient,
    private _commonService: CommonService,
    private _restAPIService: RestAPIService
  ) {
    this.$roomsUpdated = this.roomBroadcast.asObservable();
  }

  private async getAuthToken() {
    let headers = this._commonService.getHeaders();
    const auth = await this._restAPIService
      .get<AuthToken>(environment.apiURL + videoApiList.videoToken, headers)
      .toPromise();

    return auth?.token;
  }

  getAllRooms() {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<Rooms>(environment.apiURL + videoApiList.videoRooms, headers)
      .toPromise();
  }

  getRoomByUniqueName(roomName: string) {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .get<NamedRoom>(
        environment.apiURL +
          videoApiList.roomByUniqueName +
          "?uniqueRoomName=" +
          roomName,
        headers
      )
      .toPromise();
  }

  async joinOrCreateRoom(name: string, tracks: LocalTrack[]) {
    let room: any = null;
    try {
      const token: any = await this.getAuthToken();
      room = await connect(token, {
        name,
        tracks,
        dominantSpeaker: true,
      } as ConnectOptions);
    } catch (error: any) {
      console.error(`Unable to connect to Room: ${error.message}`);
    } finally {
      if (room) {
        this.roomBroadcast.next(true);
      }
    }

    return room;
  }

  nudge() {
    this.roomBroadcast.next(true);
  }
}

<header *ngIf="isLoggedIn | async">
  <div class="logo">
    <img src="assets/images/logo.svg" alt="" class="expand-logo" />
    <img src="assets/images/nav-logo.svg" alt="" class="collapse-logo" />
  </div>
  <div class="header-top">
    <div class="header-left">
      <div class="toggle-section d-xl-none">
        <a class="" href="javascript:void(0)" (click)="changeBodyClass()">
          <img src="assets/images/toggle-icon.png" alt="" />
        </a>
      </div>
      <div class="breadcrumb">
        <app-breadcrumb></app-breadcrumb>
      </div>
    </div>
    <div class="header-right">
      <div
        class="notification"
        *ngIf="
          userRole === '1' ||
          userRole === '3' ||
          userRole === '5' ||
          userRole === '6'
        "
      >
        <a href="javascript:void(0)" routerLink="/notifications">
          <img src="assets/images/notification-icon.svg" alt="" />
        </a>
        <span *ngIf="unReadNotificationCount !== '0'">{{
          unReadNotificationCount
        }}</span>
      </div>
      <div class="user-dropdown">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-secondary dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <!-- <div class="user-img">
              <img src="assets/images/user-icon.svg" alt="" />
            </div> -->
            <div class="user-content">
              <!-- <p>{{ "header.userHeaderWelcomText" | translate }}</p> -->
              <p>{{ loggedUserName }}</p>
              <p>{{ loggedUserClinicCode }}</p>
            </div>
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li *ngIf="userRole === '1'">
              <button
                class="dropdown-item"
                type="button"
                routerLink="/clinic-profile"
              >
                <span class="dropdown-icon">
                  <img src="assets/images/dropdown-profile-icon.svg" alt="" />
                </span>
                {{ "header.menu.profile" | translate }}
              </button>
            </li>
            <li *ngIf="userRole === '3'">
              <button
                class="dropdown-item"
                type="button"
                routerLink="/provider-profile"
              >
                <span class="dropdown-icon">
                  <img src="assets/images/dropdown-profile-icon.svg" alt="" />
                </span>
                {{ "header.menu.profile" | translate }}
              </button>
            </li>
            <li>
              <button class="dropdown-item" type="button" (click)="logout()">
                <span class="dropdown-icon">
                  <img src="assets/images/dropdown-logout-icon.svg" alt="" />
                </span>
                {{ "header.menu.logout" | translate }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>

import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SpecialityModel } from "src/app/models/specialityModel";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { CommonService } from "src/app/services/common.service";
import { ToastrNotificationService } from "src/app/services/toastr-notification.service";
import { environment } from "src/environments/environment";
import { UserService } from "../../services/user.service";
import { MedicalProvidersComponent } from "../medical-providers/medical-providers.component";
import { PatientsComponent } from "../patients/patients.component";
import { StaffComponent } from "../staff/staff.component";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.css"],
})
export class UserListComponent implements OnInit {
  filter: boolean = false;
  showFilterList: any = ["Status"];
  rightDrawer: boolean = false;
  tabIndex = 0;
  searchText: string = "";
  searchString: string = "";
  addEditUser: boolean = false;
  viewUser: boolean = false;
  userType: string = "";
  refreshUserGrid: boolean = false;
  viewUserData: any;
  editUserId: any;
  filterSpecialityValue: number = 0;
  filterStatusValue: number = 0;
  @ViewChild(PatientsComponent, { static: false })
  patientUsers!: PatientsComponent;
  @ViewChild(MedicalProvidersComponent, { static: false })
  medicalProviderUsers!: MedicalProvidersComponent;
  @ViewChild(StaffComponent, { static: false }) staffUsers!: StaffComponent;
  selectedUsersArray = [];
  userRole: string = "";
  breadcrumbs: any = [];

  constructor(
    private _userService: UserService,
    private _commonService: CommonService,
    private _translate: TranslateService,
    private _toastrService: ToastrNotificationService,
    private _authService: AuthGuardService,
    private _router: Router
  ) {
    let requestParamData = this._router.getCurrentNavigation()?.extras.state;
    if (
      requestParamData &&
      requestParamData["requestFrom"] === "clinicdashboard"
    ) {
      this.addUser();
    }

    if (
      requestParamData &&
      requestParamData["requestFrom"] === "appointment" &&
      requestParamData["patientUserID"]
    ) {
      let patientUserID = requestParamData["patientUserID"];
      this.userType = "Patients";
      this.editUserPopup(patientUserID);
    }
  }

  ngOnInit(): void {
    // this._translate.get("userList.patientTabText").subscribe((data: string) => {
    //   this.userType = data;
    // });
    this.userType = "Patients";
    this._authService.getUserData().subscribe((data) => {
      if (data) {
        this.userRole = data["usertypeid"];
      }
    });
  }

  /**
   * Method to get filter data
   */
  public getFilterData(data: any) {
    this.filterStatusValue = data.filterStatusValue;
    this.filterSpecialityValue = data.filterSpecialityValue;
  }

  /**
   * Method to apply filter data
   */
  public applyFilterData() {
    this.loadUserGrid(true);
  }

  /**
   * Method to toggle filter section
   */
  public filterClick() {
    this.filter = !this.filter;
  }

  /**
   * Method to open add user section
   */
  public addUser() {
    this.rightDrawer = !this.rightDrawer;
    this.addEditUser = true;
    this.viewUser = false;
    this.editUserId = "";
  }

  /**
   * Method to open view user section
   */
  public viewUserPopup(data: any) {
    this.rightDrawer = !this.rightDrawer;
    this.addEditUser = false;
    this.viewUser = true;
    this.viewUserData = data;
  }

  /**
   * Method to open edit user section
   */
  public editUserPopup(userId: any) {
    this.addEditUser = true;
    this.viewUser = false;
    this.editUserId = userId;
  }

  /**
   * Method to show/hide user section
   */
  public showHideUserPopup(showUserPopup: any): void {
    this.rightDrawer = showUserPopup;
    this.addEditUser = showUserPopup ? true : false;
    this.viewUser = false;
  }

  /**
   * Method to load user data after add/update user
   */
  public loadUserData(isRefreshData: boolean): void {
    this.refreshUserGrid = isRefreshData;
  }

  /**
   * Method to changes user tab
   */
  public changeTab(event: MatTabChangeEvent) {
    this.tabIndex = event.index;
    this.userType = event.tab.textLabel;
    if (this.tabIndex === 1) {
      this.showFilterList = ["Status", "Speciality"];
    } else {
      this.showFilterList = ["Status"];
    }

    if (this.tabIndex === 0) this.userType = "Patients";
    else if (this.tabIndex === 1) this.userType = "Medical Providers";
    else if (this.tabIndex === 2) this.userType = "Staff";
  }

  /**
   * Method to search data
   */
  public searchDataByKeyword() {
    this.refreshUserGrid = false;
    this.searchString = this.searchText;
  }

  /**
   * Method to load user grid
   */
  public loadUserGrid(applyFilter: boolean = false) {
    if (this.tabIndex === 0) {
      this.patientUsers.filterStatus = this.filterStatusValue;
      this.patientUsers.loadPatientData(applyFilter);
    }
    if (this.tabIndex === 1) {
      this.medicalProviderUsers.filterSpecialtyId = this.filterSpecialityValue;
      this.medicalProviderUsers.filterStatus = this.filterStatusValue;
      this.medicalProviderUsers.loadMedicalProviderData(applyFilter);
    }
    if (this.tabIndex === 2) {
      this.staffUsers.filterStatus = this.filterStatusValue;
      this.staffUsers.loadStaffData(applyFilter);
    }
  }

  /**
   * Method to get selected users
   */
  public selectUsers(selectedUserIds: any) {
    this.selectedUsersArray = selectedUserIds;
  }

  /**
   * Method to send invite to users
   */
  public sendInviteToUsers() {
    if (this.selectedUsersArray && this.selectedUsersArray.length > 0) {
      this.sendInvite();
    } else {
      return this._toastrService.showError("userList.sendInviteError");
    }
  }

  /**
   * Method to send invite
   */
  public sendInvite() {
    let inviteAppUrl =
      this.tabIndex === 1
        ? environment.inviteUserAppUrl + "/provider"
        : this.tabIndex === 2
        ? environment.inviteUserAppUrl + "/staff"
        : environment.inviteUserPatientAppUrl;

    let inviteParam = {
      appURL: inviteAppUrl,
      userIds: this.selectedUsersArray,
    };
    this._userService.sendInvite(inviteParam).subscribe({
      next: (data) => {
        if (data && data.success && data.success === true) {
          this._toastrService.showSuccess("userList.sendInviteSuccessMessage");
        }
      },
      error: (error) => {
        this._commonService.showErrorMessage(error);
      },
    });
  }
}

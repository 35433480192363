<div class="login-page clinic-login-page">
  <div class="login-header">
    <div class="login-header-left">
      <a href="javascript:void(0)"
        ><img src="assets/images/logo-with-slogan.svg" alt=""
      /></a>
    </div>
    <div class="login-header-right">
      {{ "login.anyQuestionsText" | translate }}
      <a href="mailto:admin@betternowmedical.com">{{
        "login.emailUsText" | translate
      }}</a>
    </div>
  </div>
  <div class="login-box">
    <div class="download-app-login">
      <div class="download-app-title">
        {{ "userList.patientTabText" | translate }}
      </div>
      <div class="download-app-content">
        {{ "login.downloadAppHeadingText" | translate }}
      </div>
      <div class="download-app-img">
        <a href="{{ environmentUrl.appStoreAppURL }}"
          ><img src="assets/images/app-store-icon.png" alt=""
        /></a>
        <a href="{{ environmentUrl.googlePlayStoreAppURL }}"
          ><img src="assets/images/google-play-icon.png" alt=""
        /></a>
      </div>
    </div>
    <div class="login-content">
      <div class="login-type">{{ "common.clinicText" | translate }}</div>
      <div class="customer-tabs">
        <mat-tab-group
          mat-stretch-tabs="false"
          mat-align-tabs="center"
          #loginSignUpTab
        >
          <mat-tab label="{{ 'common.login.tabtext' | translate }}">
            <form [formGroup]="loginValidation" (ngSubmit)="submitLogin()">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <span matPrefix
                        ><img src="assets/images/email.svg" alt=""
                      /></span>
                      <mat-label>{{
                        "login.emailPlaceholder" | translate
                      }}</mat-label>
                      <input
                        type="email"
                        matInput
                        placeholder="{{ 'login.emailPlaceholder' | translate }}"
                        formControlName="email"
                        [(ngModel)]="loginModel.email"
                      />
                    </mat-form-field>
                    <div
                      *ngIf="
                        loginValidation.controls['email'].invalid &&
                        (loginValidation.controls['email'].dirty ||
                          loginValidation.controls['email'].touched ||
                          formSubmitted)
                      "
                    >
                      <mat-error
                        *ngIf="
                          loginValidation.get('email')?.hasError('required')
                        "
                        >{{
                          "common.requiredEmailError" | translate
                        }}</mat-error
                      >
                      <mat-error
                        *ngIf="
                          loginValidation.get('email')?.hasError('pattern')
                        "
                        >{{ "common.invalidEmailError" | translate }}</mat-error
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <span matPrefix
                        ><img src="assets/images/user-password.svg" alt=""
                      /></span>
                      <mat-label>{{
                        "common.passwordPlaceholder" | translate
                      }}</mat-label>
                      <input
                        [type]="showPassword ? 'text' : 'password'"
                        matInput
                        placeholder="{{
                          'common.passwordPlaceholder' | translate
                        }}"
                        formControlName="password"
                        [(ngModel)]="loginModel.password"
                      />
                      <span matSuffix
                        ><mat-icon (click)="togglePasswordVisibility()">{{
                          !showPassword ? "visibility_off" : "visibility"
                        }}</mat-icon></span
                      >
                    </mat-form-field>
                    <div
                      *ngIf="
                        loginValidation.controls['password'].invalid &&
                        (loginValidation.controls['password'].dirty ||
                          loginValidation.controls['password'].touched ||
                          formSubmitted)
                      "
                    >
                      <mat-error
                        *ngIf="
                          loginValidation.get('password')?.hasError('required')
                        "
                        >{{
                          "common.requiredPasswordError" | translate
                        }}</mat-error
                      >
                    </div>
                  </div>
                </div>
                <div class="remember-me-text">
                  <div class="remember-left">
                    <!-- <mat-checkbox
                      formControlName="rememberMe"
                      [(ngModel)]="loginModel.remember_me"
                      >{{ "login.rememberMeText" | translate }}</mat-checkbox
                    > -->
                  </div>
                  <div class="forgot-password">
                    <a routerLink="/forgot-password">{{
                      "login.forgotPasswordText" | translate
                    }}</a>
                  </div>
                </div>
                <div class="login-btn">
                  <button class="btn btn-primary">
                    {{ "login.loginButtonText" | translate }}
                  </button>
                </div>
              </div>
            </form>
          </mat-tab>
          <mat-tab
            label="{{ 'common.signup.tabtext' | translate }}"
            *ngIf="allowSignUp"
          >
            <app-signup-email
              *ngIf="signupEmail"
              (activeSignUpComponent)="showSignUpComponent($event)"
              (activeLoginSignUpTab)="loginSignUpTabChange($event)"
            ></app-signup-email>
            <app-signup-email-verification
              *ngIf="signupEmailVerification"
              (activeSignUpComponent)="showSignUpComponent($event)"
              (activeLoginSignUpTab)="loginSignUpTabChange($event)"
            ></app-signup-email-verification>
            <app-signup
              *ngIf="signup"
              (activeSignUpComponent)="showSignUpComponent($event)"
              (activeLoginSignUpTab)="loginSignUpTabChange($event)"
            ></app-signup>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
  <p class="copy-right">{{ "footer.footerText" | translate }}</p>
</div>

<div class="sidebar">
  <div class="right-content">
    <div class="page-title">
      <div class="page-title-left">
        <div class="page-title-icon">
          <img src="assets/images/transaction-history.svg" alt="" />
        </div>
        <h2>{{ "transaction.transactionHistoryText" | translate }}</h2>
      </div>
      <div class="page-title-right">
        <div class="search">
          <div class="form-group">
            <mat-form-field class="example-form-field">
              <input
                matInput
                [(ngModel)]="searchText"
                type="text"
                placeholder="{{ 'userList.searchUserPlaceholder' | translate }}"
                (keyup)="searchDataByKeyword()"
              />
              <button matSuffix>
                <img src="assets/images/icon-search.svg" alt="" />
              </button>
            </mat-form-field>
          </div>
        </div>
        <button class="btn btn-primary ms-3" (click)="addNewRefund()">
          <img src="assets/images/plus-icon.svg" alt="" class="me-2" />
          {{ "transaction.refundText" | translate }}
        </button>
        <button
          class="btn btn-primary ms-3"
          (click)="filterClick()"
          [ngClass]="filter ? 'active' : ''"
          *ngIf="showFilter"
        >
          <img src="assets/images/filter-icon.svg" alt="" />
        </button>
      </div>
    </div>

    <app-filter
      (filterData)="getFilterData($event)"
      (applyFilterData)="applyFilterData()"
      [filter]="filter"
      [showFilterList]="showFilterList"
      *ngIf="showFilter"
    ></app-filter>

    <div class="content-page">
      <app-admin-transaction-history
        *ngIf="userRole === '5' || userRole === '6'"
        [refreshTransactionHistoryData]="refreshTransactionHistoryGrid"
        [searchData]="searchString"
        (viewTransactionData)="viewTransactionDetailsPopup($event)"
        #adminTransactionHistory
      ></app-admin-transaction-history>
      <app-clinic-transaction-history
        *ngIf="userRole === '1'"
        [refreshTransactionHistoryData]="refreshTransactionHistoryGrid"
        [searchData]="searchString"
        #clinicTransactionHistory
      ></app-clinic-transaction-history>

      <mat-tab-group
        selectedIndex="0"
        (selectedTabChange)="changeTab($event)"
        *ngIf="userRole !== '5' && userRole !== '6'"
      >
        <!-- <mat-tab label="{{ 'transaction.transactionHistoryText' | translate }}">
          <app-clinic-transaction-history
            *ngIf="userRole === '1'"
            [refreshTransactionHistoryData]="refreshTransactionHistoryGrid"
            [searchData]="searchString"
            #clinicTransactionHistory
          ></app-clinic-transaction-history>
        </mat-tab> -->
        <!-- <mat-tab
          label="{{ 'common.walletText' | translate }}"
          *ngIf="userRole !== '5' || userRole !== '6'"
        >
          <app-add-edit-wallet></app-add-edit-wallet>
        </mat-tab> -->
      </mat-tab-group>
    </div>

    <div class="drawer-backdrop" [ngClass]="rightDrawer ? 'open' : ''"></div>
    <div class="right-drawer" [ngClass]="rightDrawer ? 'open' : ''">
      <button class="btn btn-link" (click)="showHidePopup(false)">
        <img src="assets/images/close-icon.svg" alt="" />
      </button>
      <app-refund
        *ngIf="addRefund"
        (showRefundPopup)="showHidePopup($event)"
        (refreshTransactionHistoryData)="loadTransactionHistoryData($event)"
      ></app-refund>
      <app-view-transaction
        *ngIf="viewTransaction"
        [viewTransactionData]="viewTransactionData"
        (showViewTransactionPopup)="viewTransactionDetailsPopup($event)"
      ></app-view-transaction>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { StaticContentComponent } from "../static-content/static-content.component";

@Component({
  selector: "app-static-content-list",
  templateUrl: "./static-content-list.component.html",
  styleUrls: ["./static-content-list.component.css"],
})
export class StaticContentListComponent implements OnInit {
  searchText: string = "";
  searchString: string = "";
  refreshStaticContentGrid: boolean = false;
  @ViewChild(StaticContentComponent, { static: false })
  staticContent!: StaticContentComponent;

  constructor(
    private _router: Router
  ) {}

  ngOnInit(): void {}

  /**
   * Method to open add static content section
   */
  public addStaticContent() {
    this._router.navigate(["/add-edit-static-content"]);
  }

  /**
   * Method to load static content data after add/update static content
   */
  public loadStaticContentData(isRefreshData: boolean): void {
    this.refreshStaticContentGrid = isRefreshData;
  }

  /**
   * Method to search data
   */
  public searchDataByKeyword() {
    this.refreshStaticContentGrid = false;
    this.searchString = this.searchText;
  }

  /**
   * Method to load static content grid
   */
  public loadStaticContentGrid() {
    this.staticContent.loadStaticContentData();
  }
}

<div
  class="table-responsive week-day d-none"
  [ngClass]="{ 'd-none': viewType !== 'week' }"
>
  <table>
    <thead>
      <tr>
        <th>
          <div class="time-header">
            {{ "common.providersText" | translate }}
          </div>
        </th>
        <th *ngFor="let day of weekDayArray">
          {{ day | date : "EEEE, MMM dd" }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let provider of providerList">
        <tr
          *ngIf="
            filterMultiselectProviderValue.length === 0 ||
            filterMultiselectProviderValue.includes(provider.userId)
          "
        >
          <td>
            <div class="doctor-appointment-time">{{ provider.name }}</div>
          </td>
          <td *ngFor="let day of weekDayArray">
            <div class="schedule-appointment">
              <a
                href="javascript:void(0)"
                [ngClass]="{
                  booked: slot.appointmentId !== null,
                  'read-only':
                    (slot.appointmentId === null &&
                      slot.isAppointmentPastDate) ||
                    slot.isBlocked
                }"
                *ngFor="
                  let slot of getDashboardDataByProviderAndDate(
                    provider.userId,
                    day
                  )
                "
                ><span
                  *ngIf="slot.appointmentId !== null"
                  [matMenuTriggerFor]="menu"
                  hoverMenu
                  #cutMenuTrigger="matMenuTrigger"
                  (mouseenter)="hoverMenu.openMenu(cutMenuTrigger)"
                  (mouseleave)="hoverMenu.clearTimer()"
                  [draggable]="!slot.isAppointmentPastDate"
                  (dragstart)="onDragStart($event, slot)"
                  >{{ slot.patientNameInitials }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    cutAppointentData &&
                    !slot.isAppointmentPastDate
                  "
                  (click)="addAppointment(slot)"
                  (dragover)="onDragOver($event)"
                  (drop)="onDrop($event, slot)"
                  >{{ slot.formattedStartTime }}</span
                >
                <span
                  *ngIf="
                    slot.appointmentId === null &&
                    (cutAppointentData === null || slot.isAppointmentPastDate)
                  "
                  (click)="addAppointment(slot)"
                  >{{ slot.formattedStartTime }}</span
                >
                <!-- <mat-menu #menu="matMenu">
                  <div class="view-profile-menu">
                    <div
                      class="view-profile-header"
                      *ngIf="slot.appointmentId !== null"
                    >
                      <div class="image">
                        <img src="{{ slot.patientProfilePhoto }}" alt="" />
                      </div>
                      <span class="name">{{ slot.patientNameInitials }}</span>
                      <span class="close-icon">
                        <img src="assets/images/close-icon.png" alt="" />
                      </span>
                    </div>
                    <div class="view-profile-body">
                      <ul>
                        <li *ngIf="slot.appointmentId !== null">
                          <span>{{
                            slot.patientDOB | date : "MM/dd/yyyy"
                          }}</span>
                        </li>
                        <li *ngIf="slot.appointmentId !== null">
                          <span>{{ slot.patientEmail }}</span>
                        </li>
                        <li *ngIf="slot.appointmentId !== null">
                          <span>{{ slot.patientContact }}</span>
                        </li>
                        <li *ngIf="slot.appointmentId !== null">
                          <span>{{
                            slot.appointmentDateTime | date : "EEE, MMM dd"
                          }}</span>
                        </li>
                        <li *ngIf="slot.appointmentId !== null">
                          <span
                            >{{ slot.appointmentDateTime | date : "hh:mm a" }} -
                            {{ slot.appointmentDateEndTime | date : "hh:mm a" }}
                            {{ currentTimeZoneName }}</span
                          >
                        </li>
                        <li *ngIf="slot.appointmentId !== null">
                          <span>{{ slot.insurance }}</span>
                        </li>
                        <li *ngIf="slot.appointmentId !== null">
                          <span
                            >{{ slot.visitType }}: ${{ slot.finalPrice }}</span
                          >
                        </li>
                        <li>
                          <button
                            class="btn btn-primary mr-2"
                            (click)="cutAppointment(slot)"
                            *ngIf="
                              !this._commonService.isAppointmentPastDate(
                                slot.appointmentDateTime
                              )
                            "
                          >
                            {{ "common.cutText" | translate }}
                          </button>
                          <button
                            class="btn btn-primary mr-2"
                            (click)="viewAppointment(slot)"
                          >
                            {{ "common.viewText" | translate }}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </mat-menu> -->
                <mat-menu #menu="matMenu">
                  <div class="view-profile-menu">
                    <div
                      class="view-profile-header"
                      *ngIf="slot.appointmentId !== null"
                    >
                      <div class="view-profile-header-name">
                        <div class="image">
                          <img src="{{ slot.patientProfilePhoto }}" alt="" />
                        </div>
                        <span class="name">{{ slot.patientName }}</span>
                      </div>

                      <div class="view-profile-header-right">
                        <button
                          class="btn btn-secondary"
                          (click)="viewAppointment(slot)"
                        >
                          {{ "common.viewText" | translate }}
                        </button>
                        <!-- <span class="close-icon">
                          <img src="assets/images/close-icon.png" alt="" />
                        </span> -->
                      </div>
                    </div>
                    <div class="view-profile-body">
                      <ul>
                        <li *ngIf="slot.appointmentId !== null">
                          <span>{{
                            slot.patientDOB | date : "MMM dd, yyyy"
                          }}</span>
                        </li>
                        <li *ngIf="slot.appointmentId !== null">
                          <span>{{ slot.patientEmail }}</span>
                        </li>
                        <li *ngIf="slot.appointmentId !== null">
                          <span>{{ slot.patientContact }}</span>
                        </li>
                      </ul>

                      <div class="view-profile-more-details">
                        <p *ngIf="slot.appointmentId !== null">
                          {{ slot.appointmentDateTime | date : "MMM dd, yyyy" }}
                        </p>
                        <p *ngIf="slot.appointmentId !== null">
                          {{ slot.appointmentDateTime | date : timeFormat }} -
                          {{ slot.appointmentDateEndTime | date : timeFormat }}
                          {{ currentTimeZoneName }}
                        </p>
                        <p *ngIf="slot.appointmentId !== null">
                          {{ slot.insurance }}
                        </p>
                        <p *ngIf="slot.appointmentId !== null">
                          {{ slot.visitType }}: ${{ slot.finalPrice }}
                        </p>
                      </div>
                      <!-- <div class="view-profile-buttons">
                        <button
                          class="btn btn-primary mr-2"
                          (click)="cutAppointment(slot)"
                          *ngIf="
                            !this._commonService.isAppointmentPastDate(
                              slot.appointmentDateTime
                            )
                          "
                        >
                          {{ "common.cutText" | translate }}
                        </button>
                        <button
                          class="btn btn-primary mr-2"
                          (click)="viewAppointment(slot)"
                        >
                          {{ "common.viewText" | translate }}
                        </button>
                      </div> -->
                      <!-- <div class="profile-alert-blue">
                        <span>Health Insurance</span>

                        <img
                          src="assets/images/alert-icon.png"
                          alt=""
                          width="15px"
                        />
                      </div>
                      <div class="profile-alert-yellow">
                        <span>Paid</span>

                        <img
                          src="assets/images/alert-icon.png"
                          alt=""
                          width="15px"
                        />
                      </div> -->
                    </div>
                  </div>
                </mat-menu>
                <mat-menu #cutpastemenu="matMenu">
                  <div class="view-profile-menu">
                    <div class="view-profile-body">
                      <ul>
                        <li>
                          <span>{{ slot.visitType }}</span>
                        </li>
                        <li>
                          <span>{{
                            _globalFunction.convertUTCTimeToLocalTime(
                              slot.startTime
                            )
                          }}</span>
                        </li>
                        <li>
                          <span>${{ slot.price }}</span>
                        </li>
                        <li *ngIf="cutAppointentData">
                          <button
                            class="btn btn-primary mr-2"
                            (click)="pasteAppointment(slot)"
                          >
                            {{ "common.pasteText" | translate }}
                          </button>
                          <button
                            class="btn btn-primary mr-2"
                            (click)="clearCutAppointment()"
                          >
                            {{ "addUser.cancelButtonText" | translate }}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </mat-menu></a
              >
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

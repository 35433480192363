import { Directive, ElementRef, HostListener } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";

@Directive({
  selector: "[hoverMenu]",
})
export class HoverMenuDirective {
  timer: any;
  public trigger: MatMenuTrigger;

  constructor(private el: ElementRef, trigger: MatMenuTrigger) {
    this.trigger = trigger;
  }

  // @HostListener('mouseenter') onMouseEnter() {
  //   this.trigger.openMenu();
  // }

  /**
   * Method to open patient menu
   */
  openMenu(triggerMenu: any) {
    let patientMenu = triggerMenu;
    this.timer = setTimeout(function () {
      patientMenu.openMenu();
    }, 1500);
  }

  /**
   * Method to clear open menu timer
   */
  clearTimer() {
    clearTimeout(this.timer);
  }
}

<div class="login-page">
  <div class="login-box">
    <div class="login-content">
      <div class="login-logo">
        <a href="javascript:void()"
          ><img src="assets/images/logo.svg" alt=""
        /></a>
      </div>
      <div class="customer-tabs">
        <h2 class="page-headings">{{ "common.login.tabtext" | translate }}</h2>
        <form [formGroup]="loginValidation" (ngSubmit)="submitLogin()">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <mat-form-field class="example-full-width">
                  <span matPrefix
                    ><img src="assets/images/email.svg" alt=""
                  /></span>
                  <mat-label>{{
                    "login.emailPlaceholder" | translate
                  }}</mat-label>
                  <input
                    type="email"
                    matInput
                    placeholder="{{ 'login.emailPlaceholder' | translate }}"
                    formControlName="email"
                    [(ngModel)]="loginModel.email"
                  />
                </mat-form-field>
                <div
                  *ngIf="
                    loginValidation.controls['email'].invalid &&
                    (loginValidation.controls['email'].dirty ||
                      loginValidation.controls['email'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="loginValidation.get('email')?.hasError('required')"
                    >{{ "common.requiredEmailError" | translate }}</mat-error
                  >
                  <mat-error
                    *ngIf="loginValidation.get('email')?.hasError('pattern')"
                    >{{ "common.invalidEmailError" | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <mat-form-field class="example-full-width">
                  <span matPrefix
                    ><img src="assets/images/user-password.svg" alt=""
                  /></span>
                  <mat-label>{{
                    "common.passwordPlaceholder" | translate
                  }}</mat-label>
                  <input
                    [type]="showPassword ? 'text' : 'password'"
                    matInput
                    placeholder="{{ 'common.passwordPlaceholder' | translate }}"
                    formControlName="password"
                    [(ngModel)]="loginModel.password"
                  />
                  <span matSuffix
                    ><mat-icon (click)="togglePasswordVisibility()">{{
                      !showPassword ? "visibility_off" : "visibility"
                    }}</mat-icon></span
                  >
                </mat-form-field>
                <div
                  *ngIf="
                    loginValidation.controls['password'].invalid &&
                    (loginValidation.controls['password'].dirty ||
                      loginValidation.controls['password'].touched ||
                      formSubmitted)
                  "
                >
                  <mat-error
                    *ngIf="
                      loginValidation.get('password')?.hasError('required')
                    "
                    >{{ "common.requiredPasswordError" | translate }}</mat-error
                  >
                </div>
              </div>
            </div>
            <div class="remember-me-text">
              <div class="remember-left">
                <!-- <mat-checkbox
                        formControlName="rememberMe"
                        [(ngModel)]="loginModel.remember_me"
                        >{{ "login.rememberMeText" | translate }}</mat-checkbox
                      > -->
              </div>
              <div class="forgot-password">
                <a routerLink="/superadmin/forgot-password">{{
                  "login.forgotPasswordText" | translate
                }}</a>
              </div>
            </div>
            <div class="login-btn">
              <button class="btn btn-primary">
                {{ "login.loginButtonText" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <p class="copy-right">{{ "footer.footerText" | translate }}</p>
</div>

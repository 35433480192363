import { Injectable } from "@angular/core";
import { clinicApiList } from "src/app/enums/clinicApiEnum";
import { catchError, map, Observable, throwError } from "rxjs";
import { RestAPIService } from "src/app/services/restapi.service";
import { CommonService } from "src/app/services/common.service";
import { HttpErrorResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AppointmentModel } from "../models/appointmentModel";

@Injectable({
  providedIn: "root",
})
export class AppointmentService {
  constructor(
    private _restAPIService: RestAPIService,
    private _commonService: CommonService
  ) {}

  /**
   * Method to get all provider scheduler slot data
   */
  public getAllProviderSchedulerSlot(schedulerSlotParam: any): Observable<any> {
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.getAllProviderSchedulerSlot,
        schedulerSlotParam
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to add clinic appointment
   */
  public addClinicAppointment(
    appointmentParam: any,
    apiPath: string
  ): Observable<any> {
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(environment.apiURL + apiPath, appointmentParam, headers)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all upcoming appointment
   */
  public getAllUpcomingAppointment(appointmentParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    let upcommingAppointmentParam = {
      sortColumnName: appointmentParam.sortColumnName,
      sortDirection: appointmentParam.sortDirection,
      pageIndex: appointmentParam.pageIndex,
      pageSize: appointmentParam.pageSize,
      fromDate: appointmentParam.fromDate,
      toDate: appointmentParam.toDate,
      patientUserId: appointmentParam.patientUserId,
      patientEmail: appointmentParam.patientEmail,
      providerUserId: appointmentParam.providerUserId,
      visitTypeId: appointmentParam.visitTypeId,
      insuranceId: appointmentParam.insuranceId,
      status: appointmentParam.status,
      offset: appointmentParam.offset,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.getAllUpcomingAppointment,
        upcommingAppointmentParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get all appointment history
   */
  public getAllAppointmentHistory(appointmentParam: any): Observable<any> {
    let headers = this._commonService.getHeaders();
    let upcommingAppointmentParam = {
      sortColumnName: appointmentParam.sortColumnName,
      sortDirection: appointmentParam.sortDirection,
      pageIndex: appointmentParam.pageIndex,
      pageSize: appointmentParam.pageSize,
      fromDate: appointmentParam.fromDate,
      toDate: appointmentParam.toDate,
      patientUserId: appointmentParam.patientUserId,
      patientEmail: appointmentParam.patientEmail,
      providerUserId: appointmentParam.providerUserId,
      visitTypeId: appointmentParam.visitTypeId,
      insuranceId: appointmentParam.insuranceId,
      status: appointmentParam.status,
      offset: appointmentParam.offset,
    };

    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.getAllAppointmentHistory,
        upcommingAppointmentParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to delete appointment
   */
  public deleteAppointment(Id: any): Observable<any> {
    let deleteAppointmentParam = {
      appointmentId: Id,
    };
    let headers = this._commonService.getHeaders();
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.deleteClinicAppointment,
        deleteAppointmentParam,
        headers
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  /**
   * Method to get upcoming provider scheduler slot data
   */
  public getUpcomingProviderSchedulerSlot(
    providerSlotParam: any
  ): Observable<any> {
    return this._restAPIService
      .post<any>(
        environment.apiURL + clinicApiList.getUpcomingProviderSchedulerSlot,
        providerSlotParam
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }
}
